import { ResidentInterfaces } from './resident.interfaces';

export class ResidentContact {
  // "name" is the field formerly used to hold the family member's first name
  name: string;

  // contact_image_data is set by Portal as part of the profile image upload
  // and is not saved to the database
  contact_image_data?: string;

  first_name: string;
  last_name: string;
  relation: string;
  description: string;
  email: string;
  phone: string;
  twilio_blacklist: string;
  twilio_blacklist_date: string;

  constructor(data?: ResidentInterfaces.IResidentContact) {
    data = data || ({} as ResidentInterfaces.IResidentContact);

    this.name = this.name || '';
    this.first_name = this.first_name || '';
    this.last_name = this.last_name || '';
    this.relation = this.relation || '';
    this.description = this.description || '';
    this.email = this.email || '';
    this.phone = data.phone || '';
    this.twilio_blacklist = data.twilio_blacklist || 'false';
    this.twilio_blacklist_date = data.twilio_blacklist_date || '';
  }
}
