import { In2lUserType, User } from './user';

export class PortalUserList {
  members: Array<In2lUserType>;

  constructor(data: any = {}) {
    this.members = [];
    if (data && Array.isArray(data)) {
      data.forEach(user => {
        this.members.push(new User(user));
      });
    }
  }
}
