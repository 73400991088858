import { CustomValidators } from 'ng2-validation';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormUtils } from '../../../util/form-utils';
import { UnsubscribeOnDestroy } from '../../../util/unsubscribe-on-destroy';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
})
export class RecoverComponent extends UnsubscribeOnDestroy implements OnInit {
  disableButton = false;
  errorMessage: string;
  successMessage: string;
  valForm: FormGroup;

  constructor(
    public settings: SettingsService,
    private authService: AuthenticationService,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.valForm = this.fb.group({
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email]),
      ],
    });
  }

  submitForm($ev) {
    $ev.preventDefault();

    this.disableButton = true;
    this.successMessage = '';
    this.errorMessage = '';

    FormUtils.markAllAsTouched(this.valForm);

    if (this.valForm.valid) {
      this.subscriptionTracker.track = this.authService
        .resetPassword(this.valForm.get('email').value)
        .subscribe(
          result => {
            if ([200, 202].includes(result.status)) {
              this.successMessage = `Instructions for reseting your password have been sent to
                            ${this.valForm.get('email').value}.`;
            } else {
              this.errorMessage =
                'We could not reset your password because of an unknown error.';
            }
          },
          error => {
            this.errorMessage = this.authService.processResetPasswordError(
              error
            );
          }
        );
    }
  }

  tryAgain() {
    this.valForm.reset();
    this.successMessage = '';
    this.errorMessage = '';
    this.disableButton = false;
  }
}
