import { ModalDirective } from 'ngx-bootstrap/modal';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ContentConstants } from '../../../../constants/content.constants';
import { DocTypeConstants } from '../../../../constants/doc-types';
import { Account } from '../../../../model/account/account';
import { ContentPackageInterfaces } from '../../../../model/content/content-package.interfaces';
import { ContentModels } from '../../../../model/content/content.models';
import { ISortColumn } from '../../../../shared/components/sortable-report/sortable-report.component';
import { BaseFormComponent } from '../../../../util/form-utils';

@Component({
  selector: 'app-content-package-list',
  styleUrls: ['./content-package-list.component.scss'],
  templateUrl: './content-package-list.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ContentPackageListComponent
  extends BaseFormComponent
  implements OnChanges {
  @ViewChild('addEditPackageModal')
  addEditPackageModal: ModalDirective;
  @ViewChild('deletePackageModal')
  deletePackageModal: ModalDirective;

  @Input()
  packages: ContentModels.ContentPackage[] = [];

  @Input()
  accounts: Account[] = [];

  @Input()
  isDataLoading: boolean;

  @Input()
  isUserAdmin: boolean;

  @Output()
  changeEvent: EventEmitter<ContentPackageInterfaces.IPackageChangeEvent> = new EventEmitter<ContentPackageInterfaces.IPackageChangeEvent>();

  accountsLoaded = false;
  displayedPackages: ContentModels.ContentPackage[] = [];
  changeDetails: ContentPackageInterfaces.IPackageChangeEvent = {};
  modalPackage: ContentModels.ContentPackage = null;
  selectedAccountIds: string[] = [];
  selectedAccountsList: Array<{ id: string; text: string }> = [];
  filteredAccounts: Array<{ id: string; text: string }> = [];

  form: FormGroup;

  columns: ISortColumn[] = [
    {
      title: 'Name',
      key: 'name',
      sort: 'asc',
      type: 'string',
    },
    {
      title: 'Abbreviation',
      key: 'abbreviation',
      sort: '',
      type: 'string',
    },
    {
      title: 'Notes',
      key: 'notes',
      sort: '',
      type: 'string',
    },
    {
      title: 'Selected Accounts',
      key: 'allowed_account_ids',
      sort: '',
      type: 'string',
    },
  ];

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnChanges() {
    if (this.accounts) {
      this.accountsLoaded = true;
    }
  }

  changeSort(sortColumn: ISortColumn) {
    this.columns.forEach(c => {
      if (c.key === sortColumn.key) {
        c.sort = c.sort === 'asc' ? 'desc' : 'asc';
        return;
      }

      c.sort = '';
    });

    this.sortData(sortColumn);
  }

  sortData(sortColumn?: ISortColumn) {
    // apply sorting
    if (sortColumn) {
      this.packages.sort((a, b) => {
        const val1 =
          sortColumn.type === 'number' && a[sortColumn.key]
            ? Number(a[sortColumn.key] || 0)
            : (a[sortColumn.key] || '').toString().toLowerCase();
        const val2 =
          sortColumn.type === 'number' && b[sortColumn.key]
            ? Number(b[sortColumn.key] || 0)
            : (b[sortColumn.key] || '').toString().toLowerCase();
        if (val1 > val2) {
          return sortColumn.sort === 'desc' ? -1 : 1;
        } else if (val1 < val2) {
          return sortColumn.sort === 'asc' ? -1 : 1;
        }
        return 0;
      });
    }
  }

  setCellValue(
    key: string,
    value: string,
    contentPackage?: ContentModels.ContentPackage
  ): string {
    if (key === 'allowed_account_ids') {
      return this.setSelectedAccountDisplayValue(
        contentPackage.account_mode,
        contentPackage.allowed_account_ids || []
      );
    } else {
      return value;
    }
  }

  setSelectedAccountDisplayValue(
    accountMode: string,
    selectedIds: string[]
  ): string {
    if (accountMode === ContentConstants.CONTENT_ACCOUNT_MODE_ALL) {
      return 'All Accounts';
    }

    const accountProfileNames = this.findSelectedAccountNameById(selectedIds);
    return accountProfileNames.length > 7
      ? this.setAbbreviatedSelectedAccountNames(accountProfileNames)
      : accountProfileNames.join(', ');
  }

  setAbbreviatedSelectedAccountNames(accountProfileNames: string[]): string {
    const numberOfNamesToList = 7;
    const numberOfNamesNotListed =
      accountProfileNames.length - numberOfNamesToList;
    return (
      accountProfileNames.slice(0, numberOfNamesToList).join(', ') +
      ' ... +' +
      ` ${numberOfNamesNotListed}` +
      ' more'
    );
  }

  findSelectedAccountNameById(selectedIds: string[]): string[] {
    return this.accounts
      .filter(account => selectedIds.includes(account._id))
      .map(selectedAccount => selectedAccount.profile.account_name);
  }

  /**
   * doc_type checks
   */

  isLibraryFolder(item: ContentModels.ContentPackage): boolean {
    return item.doc_type === DocTypeConstants.TYPES.CONTENT.LIBRARY_FOLDER;
  }

  isImmutableStandardPackage(item: ContentModels.ContentPackage): boolean {
    return ContentConstants.NEVER_EDIT_CONTENT_IDS.includes(item._id);
  }

  /**
   * Events
   */

  emitAndClearChange() {
    this.emitChanges();
    this.clearChanges();
  }

  emitChanges() {
    console.log(this.changeDetails);
    this.changeEvent.emit(this.changeDetails);
  }

  clearChanges() {
    this.changeDetails = {};
    this.modalPackage = null;
    this.form = null;
  }

  /**
   * Add/Edit Package
   */
  showAddEditPackageModal(contentPackage?: ContentModels.ContentPackage) {
    this.modalPackage = contentPackage || new ContentModels.ContentPackage();
    this.setAllowedAccounts();

    this.form = this.fb.group({
      name: [this.modalPackage.name || '', Validators.required],
      abbreviation: [this.modalPackage.abbreviation || '', Validators.required],
      notes: [this.modalPackage.notes || ''],
      account_mode: [
        this.modalPackage.account_mode ||
          ContentConstants.CONTENT_ACCOUNT_MODE_ALL,
      ],
      allowed_account_ids: [this.modalPackage.allowed_account_ids || ''],
    });

    this.addEditPackageModal.show();
  }

  modalPackageNameIsUnique() {
    if (!this.form) {
      return true;
    }

    const name = this.form.controls['name'].value;
    if (!name) {
      return true;
    }

    const otherPackages =
      this.modalPackage && this.modalPackage._id
        ? this.packages.filter(item => item._id !== this.modalPackage._id)
        : this.packages;

    return !otherPackages.find(item => item.name === name);
  }

  modalPackageAbbreviationIsUnique() {
    if (!this.form) {
      return true;
    }

    const abbreviation = this.form.controls['abbreviation'].value;
    if (!abbreviation) {
      return true;
    }

    const otherPackages =
      this.modalPackage && this.modalPackage._id
        ? this.packages.filter(item => item._id !== this.modalPackage._id)
        : this.packages;

    return !otherPackages.find(item => item.abbreviation === abbreviation);
  }

  isIn2lAdmin(): boolean {
    return this.isUserAdmin;
  }

  setAllowedAccounts(): void {
    if (this.modalPackage.allowed_account_ids.length) {
      this.selectedAccountIds = [...this.modalPackage.allowed_account_ids];
      this.selectedAccountsList = [
        ...this.transformAccounts(this.selectedAccountIds),
      ];
    } else {
      this.selectedAccountIds = [];
    }
  }

  transformAccounts(
    selectedIds?: string[]
  ): Array<{ id: string; text: string }> {
    if (!this.accounts) {
      return;
    }
    const selectedAccounts = selectedIds
      ? this.accounts.filter(account => selectedIds.includes(account._id))
      : this.accounts;
    return selectedAccounts
      .map(account => ({
        id: account._id,
        text: `${account.profile.account_name}`,
      }))
      .sort((a, b) => a.text.trim().localeCompare(b.text.trim()));
  }

  filterAvailableAccounts(event) {
    const availableAccounts = this.transformAccounts();
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < availableAccounts.length; i++) {
      const account = availableAccounts[i];
      if (account.text.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(account);
      }
    }
    this.filteredAccounts = filtered;
  }

  handleAddAccount(select) {
    this.selectedAccountIds.push(select.id);
    this.form.get('allowed_account_ids').setValue(this.selectedAccountIds);
  }

  handleRemoveAccount(select) {
    this.selectedAccountIds = this.selectedAccountIds.filter(
      id => id !== select.id
    );
    this.form.get('allowed_account_ids').setValue(this.selectedAccountIds);
  }

  handleAccountModeChange(): void {
    if (
      this.form.get('account_mode').value ===
      ContentConstants.CONTENT_ACCOUNT_MODE_ALL
    ) {
      this.form.get('allowed_account_ids').setValue([]);
    } else {
      this.selectedAccountIds = [];
    }
  }

  getExcludedAccountNames(): string {
    if (!this.accountsLoaded) {
      return;
    }
    if (
      this.modalPackage.allowed_account_ids.length &&
      this.accounts.length !== 0
    ) {
      return this.accounts
        .filter(account =>
          this.modalPackage.allowed_account_ids.includes(account._id)
        )
        .map(account => account.profile.account_name)
        .sort((a, b) => a.localeCompare(b))
        .join(', ');
    } else {
      return '';
    }
  }

  submitDisabled() {
    return (
      !this.form.controls['name'].value ||
      !this.form.controls['abbreviation'].value ||
      !this.modalPackageNameIsUnique() ||
      !this.modalPackageAbbreviationIsUnique()
    );
  }

  isEditDeleteDisabled(): boolean {
    return this.isDataLoading; // input() variable
  }

  submitAddEditPackageModal() {
    if (
      !this.modalPackageNameIsUnique() ||
      !this.modalPackageAbbreviationIsUnique()
    ) {
      return;
    }
    this.isDataLoading = true; // set to false in parent container

    this.changeDetails = {
      savePackage: {
        package: Object.assign(
          this.modalPackage || new ContentModels.ContentPackage(),
          {
            name: this.form.controls['name'].value,
            abbreviation: this.form.controls['abbreviation'].value,
            notes: this.form.controls['notes'].value || '',
            account_mode: this.form.controls['account_mode'].value,
            allowed_account_ids:
              this.form.get('account_mode').value ===
              ContentConstants.CONTENT_ACCOUNT_MODE_ALL
                ? []
                : this.form.controls['allowed_account_ids'].value,
          }
        ),
      },
    };

    this.emitAndClearChange();
    this.closeAddEditPackageModal();
  }

  closeAddEditPackageModal() {
    this.form = null;
    this.modalPackage = null;
    this.addEditPackageModal.hide();
  }

  /**
   * Delete Package
   */
  showDeletePackageModal(contentPackage: ContentModels.ContentPackage) {
    this.modalPackage = contentPackage;

    this.deletePackageModal.show();
  }

  submitDelete() {
    this.changeDetails = {
      deletePackage: {
        package: this.modalPackage,
      },
    };

    this.emitAndClearChange();
    this.closeDeleteModal();
  }

  closeDeleteModal() {
    this.modalPackage = null;
    this.deletePackageModal.hide();
  }
}
