import * as _ from 'lodash';

import { DocTypeConstants } from '../../constants/doc-types';
import { ContentInterfaces } from '../../model/content/content.interfaces';
import { ContentModels } from '../../model/content/content.models';
import Container = ContentModels.Container;
import LinkTo = ContentModels.LinkTo;

export class ContentLayoutHelpers {
  static updateAncestorContainers(
    linkTo: LinkTo,
    itemMap: { [key: string]: Container | LinkTo }
  ): Container[] {
    const updateDocsById = {};
    let parent = <Container>itemMap[linkTo.parent_id];
    while (parent) {
      // Check if the parent container's active dates need to be updated
      // return whether the container needs updating along with the update
      const activeDatesResult = ContentLayoutHelpers.updateContainerActiveDates(
        parent,
        itemMap
      );

      // Add containers that were updated with new active dates to
      // the list of documents that needs to be saved
      if (activeDatesResult && activeDatesResult.activeDatesChanged) {
        updateDocsById[activeDatesResult.updatedContainer._id] =
          activeDatesResult.updatedContainer;
        itemMap[activeDatesResult.updatedContainer._id] =
          activeDatesResult.updatedContainer;
      }

      // Check if the parent container's packages need to be updated
      // return whether the container needs updating along with the update
      const packageResult = ContentLayoutHelpers.updateContainerPackages(
        parent,
        itemMap
      );

      // Add containers that were updated with new active dates to
      // the list of documents that needs to be saved
      if (packageResult && packageResult.packagesChanged) {
        updateDocsById[packageResult.updatedContainer._id] =
          packageResult.updatedContainer;
        itemMap[packageResult.updatedContainer._id] =
          packageResult.updatedContainer;
      }

      parent = <Container>itemMap[packageResult.updatedContainer.parent_id];
    }
    if (!updateDocsById) {
      return [];
    }

    return Object.keys(updateDocsById).map(id => updateDocsById[id]);
  }

  static updateContainerActiveDates(
    container: Container,
    itemMap: {
      [key: string]: Container | LinkTo;
    }
  ): {
    updatedContainer: Container;
    activeDatesChanged: boolean;
  } {
    if (!container || container._id.endsWith('layout-root')) {
      return null;
    }

    const originalActiveDates = container.active_dates;

    const children = container.children
      .map(id => itemMap[id])
      .filter(item => !!item);
    const childrenActiveDates = children.reduce((results, child) => {
      return [...results, ...(child.active_dates || [])];
    }, []);

    // Examine each active date, finding other active dates that overlap and merging them
    container.active_dates = childrenActiveDates.reduce((results, ad) => {
      if (!results.length) {
        return [ad];
      }

      // assume that result active date ranges do not overlap since they will have been merged
      const overlappingResultActiveDatesIndexes = results
        .map((item, index) => index)
        .filter(
          i =>
            ad.start_date <= results[i].end_date &&
            ad.end_date >= results[i].start_date
        );
      const overlappingResultActiveDates = overlappingResultActiveDatesIndexes
        .map(i => results[i])
        .concat(ad);

      const nonOverlappingActiveDates = results
        .map((item, i) => i)
        .filter(i => !overlappingResultActiveDatesIndexes.includes(i))
        .map(i => results[i]);

      const mergedActiveDate = {
        start_date: overlappingResultActiveDates
          .map(resultAd => resultAd.start_date)
          .sort()[0],
        end_date: overlappingResultActiveDates
          .map(resultAd => resultAd.end_date)
          .sort()[overlappingResultActiveDates.length - 1],
      };

      return nonOverlappingActiveDates.concat(mergedActiveDate);
    }, []);

    container.active_dates.sort((a, b) =>
      `${a.start_date}${a.end_date}`.localeCompare(
        `${b.start_date}${b.end_date}`
      )
    );

    return {
      updatedContainer: container,
      activeDatesChanged: !ContentLayoutHelpers.activeDatesMatch(
        originalActiveDates,
        container.active_dates
      ),
    };
  }

  static activeDatesMatch(
    activeDates1: ContentInterfaces.IActiveDateRange[],
    activeDates2: ContentInterfaces.IActiveDateRange[]
  ): boolean {
    if (activeDates1.length !== activeDates2.length) {
      return false;
    }

    const ranges1 = activeDates1
      .map(r => `${r.start_date}${r.end_date}`)
      .sort();
    const ranges2 = activeDates2
      .map(r => `${r.start_date}${r.end_date}`)
      .sort();
    return ranges1.every((r1, index) => r1 === ranges2[index]);
  }

  static updateContainerPackages(
    container: Container,
    itemMap: {
      [key: string]: Container | LinkTo;
    }
  ): {
    updatedContainer: Container;
    packagesChanged: boolean;
  } {
    if (!container || container._id.endsWith('layout-root')) {
      return null;
    }

    const originalPackagesWithModes = container.packages_with_modes;

    const children = container.children
      .map(id => itemMap[id])
      .filter(item => !!item);
    container.packages_with_modes = _.uniq(
      children.reduce((results, child) => {
        const childIsLinkTo =
          child.doc_type === DocTypeConstants.TYPES.CONTENT.LINK_TO;
        let childChannels: string[];
        if (childIsLinkTo) {
          const childLinkTo = <LinkTo>child;
          const linkToMode = childLinkTo.is_approved ? 'approved' : 'review';
          childChannels = (childLinkTo.packages || []).map(
            p => `${p}-${linkToMode}`
          );
        } else {
          const childContainer = <Container>child;
          childChannels = childContainer.packages_with_modes || [];
        }

        return [...results, ...childChannels];
      }, [])
    );

    container.packages_with_modes.sort();

    return {
      updatedContainer: container,
      packagesChanged: !ContentLayoutHelpers.packagesMatch(
        originalPackagesWithModes,
        container.packages_with_modes
      ),
    };
  }

  static packagesMatch(
    packagesWithModes1: string[],
    packagesWithModes2: string[]
  ): boolean {
    if (packagesWithModes1.length !== packagesWithModes2.length) {
      return false;
    }

    return (
      packagesWithModes1.sort().join('') === packagesWithModes2.sort().join('')
    );
  }
}
