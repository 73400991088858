import * as moment from 'moment';
import { CustomValidators } from 'ng2-validation';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ContentLayoutInterfaces } from '../../../../model/content/content-layout.interfaces';
import { ContentInterfaces } from '../../../../model/content/content.interfaces';
import { ContentModels } from '../../../../model/content/content.models';
import { DateUtils } from '../../../../util/date-utils';
import { AppValidator, BaseFormComponent } from '../../../../util/form-utils';

const ACTIVE_DATES = 'active_dates';
const IN2L_SUGGESTS_DATES = 'in2l_suggests_dates';
const RECENTLY_ADDED_DATES = 'recently_added_dates';

@Component({
  selector: 'app-view-idea-board',
  styles: [
    'tr.align-top > td { vertical-align: top; padding-top: 13px }',
    '.push-right { margin-left: 7px;}',
    '.title-cell { width: 160px }',
    '.date-cell { min-width: 265px }',
    '.action-cell { width: 250px }',
    '.col-head { padding: 4px 8px 12px 8px }',
    '.date-field { width: 120px }',
    '.date-subcolumn { min-width: 105px }',
  ],
  templateUrl: './view-idea-board.component.html',
})
export class ViewIdeaBoardComponent
  extends BaseFormComponent
  implements OnChanges {
  @Input()
  linkTos: ContentModels.LinkTo[];

  @Input()
  contentItems: ContentModels.ContentItem[];

  @Output()
  changeEvent: EventEmitter<ContentLayoutInterfaces.ILayoutChangeEvent> = new EventEmitter<ContentLayoutInterfaces.ILayoutChangeEvent>();

  changeDetails: ContentLayoutInterfaces.ILayoutChangeEvent = {};

  ideaBoardItems = [];

  dateRangeKeys = [ACTIVE_DATES, IN2L_SUGGESTS_DATES, RECENTLY_ADDED_DATES];

  editId = '';

  editForms: FormGroup;

  columnName: string;

  isColumnSortedAsc = {
    title: false,
    active_dates: false,
    in2l_suggests_dates: false,
    recently_added_dates: false,
  };

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnChanges() {
    if (this.showIdeaBoard()) {
      this.resetIdeaBoardItems();
    }
  }

  edit(id: string) {
    this.editId = id;

    const item = this.ideaBoardItems.find(value => value._id === id);
    this.editForms = this.fb.group({
      active_dates: this.buildDateRangeFormArray(item.active_dates),
      in2l_suggests_dates: this.buildDateRangeFormArray(
        item.in2l_suggests_dates
      ),
      recently_added_dates: this.buildDateRangeFormArray(
        item.recently_added_dates
      ),
    });
  }

  cancel() {
    this.resetIdeaBoardItems();
    this.editId = '';
  }

  save() {
    const linkTo = this.linkTos.find(lt => lt._id === this.editId);
    linkTo.active_dates = this.getFormRanges(ACTIVE_DATES);
    linkTo.in2l_suggests_dates = this.getFormRanges(IN2L_SUGGESTS_DATES);
    linkTo.recently_added_dates = this.getFormRanges(RECENTLY_ADDED_DATES);

    this.changeDetails = {
      saveLinkTo: {
        linkTo: linkTo,
      },
    };

    this.editId = '';
    this.editForms = null;

    this.emitAndClearChange();
  }

  clickLinkTo(id: string) {
    this.changeDetails = {
      editLinkTo: {
        linkToId: id,
      },
    };

    this.emitAndClearChange();
  }

  emitAndClearChange() {
    this.emitChanges();
    this.clearChanges();
  }

  emitChanges() {
    console.log(this.changeDetails);
    this.changeEvent.emit(this.changeDetails);
  }

  clearChanges() {
    this.changeDetails = {};
  }

  showIdeaBoard() {
    return (
      !!this.linkTos &&
      !!this.linkTos.length &&
      !!this.contentItems &&
      !!this.contentItems.length
    );
  }

  rangeIsInActiveRange(startDate: string, endDate: string) {
    if (!startDate || !endDate) {
      return true;
    }

    const rangeStartDate = moment(startDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
    const rangeEndDate = moment(endDate, 'MM/DD/YYYY').format('YYYY-MM-DD');

    const activeDates = this.editForms.get(ACTIVE_DATES);
    for (let i = 0; i < activeDates.value.length; i++) {
      const activeRange = activeDates.value[i];
      if (!activeRange.start_date || !activeRange.end_date) {
        continue;
      }

      const activeRangeStart = moment(
        activeRange.start_date,
        'MM/DD/YYYY'
      ).format('YYYY-MM-DD');
      const activeRangeEnd = moment(activeRange.end_date, 'MM/DD/YYYY').format(
        'YYYY-MM-DD'
      );

      if (
        activeRangeStart <= rangeStartDate &&
        activeRangeEnd >= rangeEndDate
      ) {
        return true;
      }
    }

    return false;
  }

  hasInvalidDateRange(rangeKey?: string): boolean {
    if (!this.editId || !this.editForms) {
      return false;
    }

    if (!rangeKey) {
      return (
        this.hasInvalidDateRange(IN2L_SUGGESTS_DATES) ||
        this.hasInvalidDateRange(RECENTLY_ADDED_DATES)
      );
    }

    const dateRanges = this.editForms.get(rangeKey) as FormArray;
    for (let i = 0; i < dateRanges.length; i++) {
      const range = dateRanges.value[i];
      if (
        range.start_date &&
        range.end_date &&
        !this.rangeIsInActiveRange(range.start_date, range.end_date)
      ) {
        return true;
      }
    }

    return false;
  }

  saveDisabled(): boolean {
    return (
      !this.editId ||
      !this.editForms.valid ||
      !this.hasActiveDates() ||
      this.hasInvalidDateRange()
    );
  }

  hasActiveDates(): boolean {
    if (!this.editForms) {
      return false;
    }

    const activeDates = this.editForms.get(ACTIVE_DATES) as FormArray;
    return activeDates.value.length > 0;
  }

  resetIdeaBoardItems() {
    this.ideaBoardItems = this.linkTos
      .filter(
        item =>
          (item.recently_added_dates || []).length > 0 ||
          (item.in2l_suggests_dates || []).length > 0
      )
      .map(linkTo => this.buildIdeaBoardItem(linkTo))
      .filter(item => !!item)
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  buildIdeaBoardItem(linkTo: ContentModels.LinkTo) {
    if (
      !this.arrayHasDates(linkTo.in2l_suggests_dates) &&
      !this.arrayHasDates(linkTo.recently_added_dates)
    ) {
      return null;
    }

    const contentItem = this.contentItems.find(
      ci => ci._id === linkTo.content_id
    );
    const itemTitle = linkTo.override_title
      ? linkTo.override_title
      : contentItem
      ? contentItem.title
      : '[missing content item]';
    const ideaBoardItem = {
      _id: linkTo._id,
      content_id: linkTo.content_id,
      title: itemTitle,
      active_dates: linkTo.getUtcActiveDateRanges(),
      in2l_suggests_dates: linkTo.getUtcIn2lSuggestsRanges(),
      recently_added_dates: linkTo.getUtcRecentlyAddedRanges(),
    };

    return ideaBoardItem;
  }

  arrayHasDates(dateArray: ContentInterfaces.IActiveDateRange[]): boolean {
    return (dateArray || []).length > 0;
  }

  getFormRanges(rangeKey: string): ContentInterfaces.IActiveDateRange[] {
    const rangeFormArray = this.editForms.get(rangeKey) as FormArray;
    const ranges: ContentInterfaces.IActiveDateRange[] = [];
    for (let i = 0; i < rangeFormArray.length; i++) {
      const rangeGroup = rangeFormArray.at(i) as FormGroup;
      const startDate = rangeGroup.get('start_date').value;
      const endDate = rangeGroup.get('end_date').value;
      if (!startDate || !endDate) {
        continue;
      }
      ranges.push({
        start_date: DateUtils.setSavedDateFormat(startDate),
        end_date: DateUtils.setSavedDateFormat(endDate),
      });
    }

    return ranges;
  }

  buildDateRangeFormArray(
    ranges: ContentInterfaces.IActiveDateRange[]
  ): FormArray {
    const rangeFormArray = new FormArray([]);

    ranges.forEach(range => {
      const rangeGroup = this.fb.group({
        start_date: [
          range.start_date || '',
          Validators.compose([
            Validators.required,
            CustomValidators.date,
            AppValidator.dateExists(),
          ]),
        ],
        end_date: [
          range.end_date || '',
          Validators.compose([
            Validators.required,
            CustomValidators.date,
            AppValidator.dateExists(),
          ]),
        ],
      });

      rangeFormArray.push(rangeGroup);
    });

    return rangeFormArray;
  }

  addEmptyRange(rangeKey: string) {
    const rangeGroup = this.fb.group({
      start_date: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.date,
          AppValidator.dateExists(),
        ]),
      ],
      end_date: [
        '',
        Validators.compose([
          Validators.required,
          CustomValidators.date,
          AppValidator.dateExists(),
        ]),
      ],
    });

    const rangeFormArray = this.editForms.get(rangeKey) as FormArray;
    rangeFormArray.push(rangeGroup);
  }

  removeRange(rangeKey: string, index: number) {
    const rangeFormArray = this.editForms.get(rangeKey) as FormArray;
    rangeFormArray.removeAt(index);
  }

  sortBoardItemsByColumnDates(columnName: string): void {
    this.columnName = columnName;
    const direction = this.isColumnSortedAsc[columnName] ? 1 : -1;
    this.ideaBoardItems.sort((a, b) => {
      const firstStartDate = moment(this.startDateValue(a[columnName]));
      const secondStartDate = moment(this.startDateValue(b[columnName]));
      return direction * (firstStartDate.isAfter(secondStartDate) ? 1 : -1);
    });
    this.isColumnSortedAsc[columnName] = !this.isColumnSortedAsc[columnName];
  }

  startDateValue(activeDates: ContentInterfaces.IActiveDateRange[]): string {
    if (activeDates.length === 0) {
      return '01/01/1900';
    }
    if (activeDates.length === 1) {
      return activeDates[0].start_date;
    }
    if (activeDates.length > 1) {
      return this.sortMultipleDateRanges(activeDates)[0].start_date;
    }
  }

  sortMultipleDateRanges(
    activeDates: ContentInterfaces.IActiveDateRange[]
  ): ContentInterfaces.IActiveDateRange[] {
    return activeDates.sort((a, b) => {
      const firstStartDate: number = moment(a.start_date).valueOf();
      const secondStartDate: number = moment(b.start_date).valueOf();
      return this.isColumnSortedAsc[this.columnName]
        ? firstStartDate - secondStartDate
        : secondStartDate - firstStartDate;
    });
  }

  sortBoardItemsByTitle(): void {
    const direction = this.isColumnSortedAsc.title ? 1 : -1;
    this.ideaBoardItems.sort(
      (a, b) => direction * a.title.localeCompare(b.title)
    );
    this.isColumnSortedAsc.title = !this.isColumnSortedAsc.title;
  }
}
