import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ContentLibraryInterfaces } from '../../model/content/content-library.interfaces';
import { MediaInterfaces } from '../../model/media/media-interfaces';
import { SyncGatewayAttachment } from '../../model/sync-gateway/sync-gateway-attachment';
import { SyncGatewayInterfaces } from '../../model/sync-gateway/sync-gateway.interfaces';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { MicroserviceApiBaseService } from '../micro-api/microservice-api-base.service';
import { StateManager } from '../state/state-manager';

@Injectable({
  providedIn: 'root',
})
export class MediaApiService extends MicroserviceApiBaseService {
  private readonly baseUrl = environment.mediaApi.url;
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    cacheService: CacheService,
    stateManager: StateManager
  ) {
    super(http, jwtService, stateManager);
  }

  forceUpload(uploadItem: ContentLibraryInterfaces.IChangeEvent) {
    const url =
      this.baseUrl + '/content/namespace/content/type/content-item/cdn-upload';
    return this.put<any>(url, uploadItem.uploadContentItem);
  }

  forceValidate(validateItem: ContentLibraryInterfaces.IChangeEvent) {
    const url =
      this.baseUrl +
      '/content/namespace/content/type/content-item/cdn-validate';
    return this.put<any>(url, validateItem.validateContentItem);
  }

  uploadMedia(
    docNamespace: string,
    docType: string,
    config: MediaInterfaces.IMediaInputs,
    imageBlob: Blob
  ): Observable<{ s3Key: MediaInterfaces.IMediaOutput; success: boolean }> {
    return imageBlob
      ? this.requestMediaUploadLink(docNamespace, docType, config).pipe(
          mergeMap(result => {
            return this.http
              .put<any>(result.signed_url, imageBlob, {
                headers: { 'Content-Type': config.mime_type },
                observe: 'response',
              })
              .pipe(
                map(imageUploadResult => {
                  return {
                    s3Key: result,
                    success: imageUploadResult.status === 200,
                  };
                })
              );
          })
        )
      : of({ s3Key: null, success: false });
  }

  requestMediaUploadLink(
    docNamespace: string,
    docType: string,
    config: MediaInterfaces.IMediaInputs
  ): Observable<MediaInterfaces.IMediaOutput> {
    const path = `${this.generateUrlPathFromParams(
      this.baseUrl,
      this.namespaceAndTypeToUrlParams(docNamespace, docType)
    )}/links`;
    return this.post<MediaInterfaces.IMediaOutput>(path, config);
  }

  requestMediaDownloadLink(
    docNamespace: string,
    docType: string,
    s3Key: string
  ): Observable<MediaInterfaces.IMediaOutput> {
    const path = `${this.generateUrlPathFromParams(
      this.baseUrl,
      this.namespaceAndTypeToUrlParams(docNamespace, docType)
    )}/links/${s3Key}`;
    return this.get<MediaInterfaces.IMediaOutput>(path);
  }

  private namespaceAndTypeToUrlParams(docNamespace: string, docType: string) {
    return ['namespace', docNamespace, 'type', docType];
  }

  getAttachmentByUrl(url: string): Observable<Blob> {
    url = `${this.baseUrl}/attachments/${url}`;
    return this.get(url, undefined, { responseType: 'blob' }, [404]);
  }

  getAttachment(id: string, attachmentName: string): Observable<Blob> {
    return this.getAttachmentByUrl(`${id}/${attachmentName}`);
  }

  updateAttachment(
    attachment: SyncGatewayAttachment,
    docId: string,
    rev: string
  ): Observable<SyncGatewayInterfaces.IUpdateResult> {
    const url =
      this.baseUrl +
      '/attachments/' +
      docId +
      '/' +
      attachment.name +
      '?rev=' +
      rev;
    return this.post<SyncGatewayInterfaces.IUpdateResult>(url, {
      deleted: attachment.deleted,
      fileContents: attachment.base64Data,
      key: { sourceDocId: docId, mediaType: attachment.name },
      mime_type: attachment.contentType,
    }).pipe(
      mergeMap((attachmentResult: SyncGatewayInterfaces.IUpdateResult) => {
        return of(attachmentResult);
      })
    );
  }
}
