<div class="panel panel-default inner-panel">
  <div class="panel-body">
    <div class="row">
      <div class="col-sm-12 panel-heading">
        Total Unique Content Items Accessed: {{ uniqueItemsAccessedCount }}
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th
            *ngFor="let column of contentTableColumns"
            (click)="changeContentTableSort(column)"
            style="cursor: pointer; width: 200px"
          >
            {{ column.title }}
            <i
              *ngIf="column.sort"
              class="fa"
              [ngClass]="{
                'fa-chevron-down': column.sort === 'desc',
                'fa-chevron-up': column.sort === 'asc'
              }"
            ></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let content of displayedList()">
          <td style="word-break: break-all">
            {{ verifyTitle(content) }}
          </td>
          <td>
            {{ content.display_type }}
          </td>
          <td>
            {{ content.accessed_count }}
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="text-center big"
      *ngIf="reportData?.length > shortContentListSize"
    >
      <div
        class="fa fa-chevron-down text-primary align-bottom"
        aria-hidden="true"
        *ngIf="!displayFullContentList"
        (click)="toggleContentListSize()"
      ></div>
      <div
        class="fa fa-chevron-up text-primary align-bottom"
        aria-hidden="true"
        *ngIf="displayFullContentList"
        (click)="toggleContentListSize()"
      ></div>
    </div>
    <div
      class="text-center text-muted"
      *ngIf="!reportData || reportData.length === 0"
    >
      Data unavailable
    </div>
  </div>
</div>
