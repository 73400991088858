<div class="wrapper">
  <div class="block-center mt-xl wd-xl">
    <!-- START panel-->
    <div class="panel panel-flat">
      <div class="panel-heading text-center">
        <a href="#">
          <img
            class="block-center login-logo-img"
            style="max-width: 45%"
            src="/assets/img/iN2L_powered_by_LifeLoop.png"
            alt="Image"
          />
        </a>
      </div>
      <div class="panel-body">
        <p class="text-center pv">PASSWORD RESET</p>
        <form
          [formGroup]="passwordForm"
          class="form-validate"
          role="form"
          name="resetForm"
          novalidate=""
          (submit)="submitForm($event)"
          *ngIf="!errorMessage && !loginInProgress"
        >
          <p class="text-center">Enter A New Password.</p>
          <div class="form-group has-feedback">
            <input
              class="form-control"
              type="password"
              name="password"
              placeholder="Enter password"
              autocomplete="off"
              formControlName="password"
            />
            <span class="fa fa-lock form-control-feedback text-muted"></span>
            <span
              class="text-danger"
              *ngIf="passwordForm.controls['password'].hasError('required')"
              >This field is required</span
            >
            <div class="password-feedback">
              Password must:
              <ul>
                <li
                  [ngClass]="
                    passwordForm.controls['password'].value.length === 0
                      ? ''
                      : conditionMet('minlength') && conditionMet('maxlength')
                      ? 'met'
                      : 'unmet'
                  "
                >
                  Be between {{ minPassLength }} and
                  {{ maxPassLength }} characters
                </li>
                <li
                  [ngClass]="
                    passwordForm.controls['password'].value.length === 0
                      ? ''
                      : conditionMet('containsupper')
                      ? 'met'
                      : 'unmet'
                  "
                >
                  Contain at least one uppercase letter
                </li>
                <li
                  [ngClass]="
                    passwordForm.controls['password'].value.length === 0
                      ? ''
                      : conditionMet('containslower')
                      ? 'met'
                      : 'unmet'
                  "
                >
                  Contain at least one lowercase letter
                </li>
                <li
                  [ngClass]="
                    passwordForm.controls['password'].value.length === 0
                      ? ''
                      : conditionMet('containsspecial')
                      ? 'met'
                      : 'unmet'
                  "
                >
                  Contain at least one special character
                </li>
                <li
                  [ngClass]="
                    passwordForm.controls['password'].value.length === 0
                      ? ''
                      : conditionMet('containsnum')
                      ? 'met'
                      : 'unmet'
                  "
                >
                  Contain at least one number
                </li>
                <li>
                  Not match any previous password used in the past
                  {{ passwordReuseLimit }} days
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group has-feedback">
            <input
              class="form-control"
              type="password"
              name="confirmPassword"
              placeholder="Retype password"
              autocomplete="off"
              formControlName="confirmPassword"
            />
            <span class="fa fa-lock form-control-feedback text-muted"></span>
            <span
              class="text-danger"
              *ngIf="passwordForm.get('confirmPassword').hasError('required')"
              >This field is required</span
            >
            <span
              class="text-danger"
              *ngIf="
                passwordForm.get('confirmPassword').value &&
                passwordForm.get('confirmPassword').errors?.equalTo
              "
              >Password does not match</span
            >
          </div>
          <button
            class="btn btn-danger btn-block"
            type="submit"
            [disabled]="disableResetPasswordButton"
          >
            Reset
          </button>
        </form>
        <button
          *ngIf="loginInProgress"
          class="btn btn-block btn-primary mt-lg"
          [disabled]="loginInProgress"
        >
          Reset in progess...
        </button>
        <div class="form-group text-center" *ngIf="errorMessage">
          <p class="text-danger mb-lg">{{ errorMessage }}</p>
          <div class="pt" *ngIf="showNavigationLinks">
            <a class="mr-xl" routerLink="/recover">Try reset again</a>
            <a routerLink="/login">Return to login</a>
          </div>
        </div>
      </div>
    </div>
    <!-- END panel-->
    <footer class="p-lg text-center">
      &copy; {{ settings.app.year }} - {{ settings.app.name }}<br />
      <a class="small mr" href="http://in2l.com/privacy-policy/" target="_blank"
        >Privacy Policy</a
      >
      <a class="small ml" href="http://in2l.com/terms-of-use/" target="_blank"
        >Terms of Use</a
      >
    </footer>
  </div>
</div>
