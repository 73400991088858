import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChange,
} from '@angular/core';

import { FileUtilityService } from '../../../core/file/file-utility.service';
import { MediaApiService } from '../../../core/media/media-api.service';
import { UnsubscribeOnDestroy } from '../../../util/unsubscribe-on-destroy';

@Directive({
  selector: '[appAttachmentSrc]',
})
export class AttachmentSrcDirective
  extends UnsubscribeOnDestroy
  implements OnChanges {
  @Input()
  appAttachmentSrc: string;
  @Input()
  defaultImagePath: string = null;

  constructor(
    private elementRef: ElementRef,
    private mediaApiService: MediaApiService
  ) {
    super();
  }

  ngOnChanges(changes): void {
    if (this.elementRef.nativeElement.nodeName !== 'IMG') {
      return;
    }

    if (changes['appAttachmentSrc']) {
      const change = <SimpleChange>changes['appAttachmentSrc'];

      if (change.currentValue) {
        this.loadImage(change.currentValue);
      }
    }
  }

  private loadImage(url: string): void {
    // if image starts with '/assets/', its a image path relative to the web app
    //   this is the case with fallback/default images
    //   otherwise, we assume its a sync gateway attachment
    if (/^\/assets\//.test(url) || url.startsWith('data:')) {
      this.elementRef.nativeElement.setAttribute('src', url);
    } else {
      this.subscriptionTracker.track = this.mediaApiService
        .getAttachmentByUrl(url)
        .pipe(
          mergeMap((imageBlob: Blob) => {
            // rec blob, convert to dataURI
            return imageBlob
              ? FileUtilityService.convertBlobToDataURI(imageBlob)
              : of(null);
          })
        )
        .subscribe(
          (imageDataURI: string) => {
            const uri = imageDataURI || this.defaultImagePath;

            if (uri) {
              this.elementRef.nativeElement.setAttribute('src', uri);
            }
          },
          error => {
            console.warn('Could not convert image to dataURI', error);
          }
        );
    }
  }
}
