import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const MAXPASSLENGTH = 30;
export const MINPASSLENGTH = 10;
export const PASSWORDREUSELIMIT = 180;
export const PINLENGTH = 5;

export function ContainsUppercaseValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const uppercaseRegex = /^.*[A-Z]+.*$/;
    return !uppercaseRegex.test(control.value)
      ? { containsupper: { value: control.value } }
      : null;
  };
}

export function ContainsLowercaseValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const lowercaseRegex = /^.*[a-z]+.*$/;
    return !lowercaseRegex.test(control.value)
      ? { containslower: { value: control.value } }
      : null;
  };
}

export function ContainsSpecialValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const specialRegex = /^.*[\!\(\)\-\.\?\[\]\_\`\~\;\:\@\#\$\%\^\&\*\+\=]+.*$/;
    return !specialRegex.test(control.value)
      ? { containsspecial: { value: control.value } }
      : null;
  };
}

export function ContainsNumValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const numRegex = /^.*[0-9]+.*$/;
    return !numRegex.test(control.value)
      ? { containsnum: { value: control.value } }
      : null;
  };
}

export function OnlyNumbersValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const numRegex = /^[0-9]*$/;
    return !numRegex.test(control.value)
      ? { onlynum: { value: control.value } }
      : null;
  };
}
