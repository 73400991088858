import { ContentConstants } from '../../constants/content.constants';
import { DocTypeConstants } from '../../constants/doc-types';
import { DateUtils } from '../../util/date-utils';
import { SyncGatewayModel } from '../sync-gateway/sync-gateway-model';
import { ContentInterfaces } from './content.interfaces';

export namespace ContentModels {
  class BaseContentModel
    extends SyncGatewayModel
    implements ContentInterfaces.IBaseContentModel {
    constructor(data: any = {}, namespace: string, type: string) {
      super(
        data._id,
        data._rev,
        data._attachments,
        data.created_by,
        data.created_date,
        namespace,
        type,
        data.modified_by,
        data.modified_date
      );
    }
  }
  export class LayoutRoot
    extends BaseContentModel
    implements ContentInterfaces.ILayoutRoot {
    children: string[];
    layout_root_id?: string;
    product?: string;
    title?: string;

    constructor(data: any = {}) {
      super(
        data,
        DocTypeConstants.NAMESPACES.CONTENT,
        DocTypeConstants.TYPES.CONTENT.LAYOUT_ROOT
      );

      this.children = data.children || [];
      this.layout_root_id = data.layout_root_id;
      this.product = data.product;
      this.title = data.title;
    }
  }

  export class Container
    extends BaseContentModel
    implements ContentInterfaces.IContainer {
    title: string;
    notes: string;
    parent_id?: string;
    layout_root_id?: string;
    children: string[] = [];
    active_dates: ContentInterfaces.IActiveDateRange[];
    packages_with_modes: string[];
    media?: ContentInterfaces.IMediaItem;

    constructor(data: any = {}) {
      super(
        data,
        DocTypeConstants.NAMESPACES.CONTENT,
        DocTypeConstants.TYPES.CONTENT.CONTAINER
      );

      if (data?.media?.tile_image) {
        this.media = {};
        this.media.tile_image = data.media.tile_image;
      }
      this.title = data.title;
      this.notes = data.notes;
      this.parent_id = data.parent_id;
      this.layout_root_id = data.layout_root_id;
      this.children = data.children || [];
      this.active_dates = data.active_dates || [];
      this.packages_with_modes = data.packages_with_modes || [];
    }
  }

  export class LinkTo
    extends BaseContentModel
    implements ContentInterfaces.ILinkTo {
    _id?: string;
    override_title?: string;
    parent_title?: string;
    parent_id: string;
    layout_root_id?: string;
    content_id: string;
    active_dates: ContentInterfaces.IActiveDateRange[];
    in2l_suggests_dates: ContentInterfaces.IActiveDateRange[];
    recently_added_dates: ContentInterfaces.IActiveDateRange[];
    packages: string[];
    is_approved: boolean;
    is_play_all: boolean;
    is_welcome_video: boolean;
    is_single_user_welcome_video: boolean;
    is_multi_user_welcome_video: boolean;
    notes: string;
    linked_interests?: string[];

    // Note: This property is not expected to come from the DB, but may be populated
    // afterward to provide a list of string-ified interests mapped to the linkTo
    mapped_interests?: string[];

    constructor(data: any = {}) {
      super(
        data,
        DocTypeConstants.NAMESPACES.CONTENT,
        DocTypeConstants.TYPES.CONTENT.LINK_TO
      );

      this._id = data.id || data._id;
      this.override_title = data.override_title;
      this.parent_title = data.parent_title || '';
      this.parent_id = data.parent_id;
      this.layout_root_id = data.layout_root_id;
      this.content_id = data.content_id;
      this.active_dates = data.active_dates || [];
      this.in2l_suggests_dates = data.in2l_suggests_dates || [];
      this.recently_added_dates = data.recently_added_dates || [];
      this.packages = data.packages || [];
      this.is_approved = data.is_approved;
      this.is_play_all = !!data.is_play_all;
      this.is_welcome_video = !!data.is_welcome_video;
      this.is_single_user_welcome_video = !!data.is_single_user_welcome_video;
      this.is_multi_user_welcome_video = !!data.is_multi_user_welcome_video;
      this.notes = data.notes;
      this.linked_interests = data.linked_interests || [];
    }

    getUtcActiveDateRanges(): ContentInterfaces.IActiveDateRange[] {
      return this.getUtcDateRanges(this.active_dates);
    }

    getUtcIn2lSuggestsRanges(): ContentInterfaces.IActiveDateRange[] {
      return this.getUtcDateRanges(this.in2l_suggests_dates);
    }

    getUtcRecentlyAddedRanges(): ContentInterfaces.IActiveDateRange[] {
      return this.getUtcDateRanges(this.recently_added_dates);
    }

    private getUtcDateRanges(
      dateRanges: ContentInterfaces.IActiveDateRange[]
    ): ContentInterfaces.IActiveDateRange[] {
      if (dateRanges && dateRanges.length > 0) {
        return dateRanges.map((range: ContentInterfaces.IActiveDateRange) => ({
          start_date: DateUtils.setDateDisplayFormat(range.start_date),
          end_date: DateUtils.setDateDisplayFormat(range.end_date),
        }));
      }

      return [];
    }
  }

  export class ContentItem
    extends BaseContentModel
    implements ContentInterfaces.IContentItem {
    archived?: boolean;
    link_to_paths?: string[];

    title: string;
    library_path: string;
    hosting_status?: string;
    content_type: string;
    content_status: string;
    display_type: string;
    excluded_account_ids: string[];
    us_only: boolean;
    interests: string[];
    pricing_tiers: string[];
    fileSize?: number;
    media?: ContentInterfaces.IMediaItem;

    platforms: {
      android: boolean;
      pc: boolean;
    } = { android: false, pc: false };

    products: {
      engage: boolean;
      focus: boolean;
      rehab: boolean;
    } = { engage: false, focus: false, rehab: false };
    enabled_platforms?: string[];
    keywords?: string[];
    accessibility: {
      hearing_impairment: boolean;
      physical_impairment: boolean;
      vision_impairment: boolean;
    } = {
      hearing_impairment: false,
      physical_impairment: false,
      vision_impairment: false,
    };

    usage_settings: {
      group_use: boolean;
      joint_use: boolean;
      solo_use: boolean;
    } = {
      group_use: false,
      joint_use: false,
      solo_use: false,
    };

    source?: string;
    license_expiration_date?: string;
    notes?: string;

    // AWS uploaded file info
    s3_etag?: string;
    s3_key?: string;

    // Happy neuron content type
    happy_neuron?: {
      game_id: string;
      lang: string;
    };

    // Video content type
    video_description?: string;

    // Integrated website content type
    content_vendor?: string;

    // Website content type
    content_url?: string;

    // Link to request a URL for playing a single song (Coro uses this in place of a single streaming link)
    new_song_request_link?: string;

    // Windows exe
    windows_exe_filename?: string;
    windows_exe_locations?: string[];

    // YouTube playlist video ids
    youtube_video_ids?: string[];

    constructor(data: any = {}) {
      super(
        data,
        DocTypeConstants.NAMESPACES.CONTENT,
        DocTypeConstants.TYPES.CONTENT.CONTENT_ITEM
      );

      this.archived = data.archived;

      this.title = data.title;
      this.library_path = data.library_path;
      this.hosting_status = data.hosting_status;
      this.display_type = data.display_type;
      this.content_type = data.content_type;
      this.content_status = data.content_status;
      this.interests = data.interests || [];
      this.pricing_tiers = data.pricing_tiers || [];
      this.excluded_account_ids = data.excluded_account_ids || [];
      this.us_only = !!data.us_only;
      if (!!data.fileSize) {
        this.fileSize = data.fileSize;
      }
      if (data?.media?.tile_image) {
        this.media = {};
        this.media.tile_image = data.media.tile_image;
      }
      data.platforms = data.platforms || {};
      this.platforms = {
        android: !!data.platforms.android,
        pc: !!data.platforms.pc,
      };

      data.products = data.products || {};
      this.products = {
        engage: !!data.products.engage,
        focus: !!data.products.focus,
        rehab: !!data.products.rehab,
      };

      this.enabled_platforms = data.enabled_platforms || [];
      this.keywords = data.keywords || [];

      data.accessibility = data.accessibility || {};
      this.accessibility = {
        hearing_impairment: !!data.accessibility.hearing_impairment,
        physical_impairment: !!data.accessibility.physical_impairment,
        vision_impairment: !!data.accessibility.vision_impairment,
      };

      data.usage_settings = data.usage_settings || {};
      this.usage_settings = {
        group_use: !!data.usage_settings.group_use,
        joint_use: !!data.usage_settings.joint_use,
        solo_use: !!data.usage_settings.solo_use,
      };

      this.source = data.source;
      this.license_expiration_date = data.license_expiration_date;
      this.notes = data.notes;

      // AWS uploaded file info
      this.s3_etag = data.s3_etag;
      this.s3_key = data.s3_key;

      // Happy neuron content type
      if (data.happy_neuron) {
        this.happy_neuron = {
          game_id: data.happy_neuron.game_id,
          lang: data.happy_neuron.lang,
        };
      }

      // Video content type
      this.video_description = data.video_description;

      // Website content type
      this.content_url = data.content_url;

      // Link to request a URL for playing a single song (Coro uses this in place of a single streaming link)
      this.new_song_request_link = data.new_song_request_link;

      // Integrated Website content type
      this.content_vendor = data.content_vendor;

      // Windows exe
      this.windows_exe_filename = data.windows_exe_filename || '';
      this.windows_exe_locations = data.windows_exe_locations || [];

      // YouTube playlist video ids
      this.youtube_video_ids = data.youtube_video_ids || [];
    }
  }

  export class ContentPackage
    extends BaseContentModel
    implements ContentInterfaces.IContentPackage {
    abbreviation: string;
    name: string;
    notes: string;
    account_mode: string;
    allowed_account_ids: string[] = [];

    constructor(data: any = {}) {
      super(
        data,
        DocTypeConstants.NAMESPACES.CONTENT,
        DocTypeConstants.TYPES.CONTENT.PACKAGE
      );

      this.abbreviation = data.abbreviation;
      this.name = data.name;
      this.notes = data.notes;
      this.account_mode =
        data.account_mode || ContentConstants.CONTENT_ACCOUNT_MODE_ALL;
      this.allowed_account_ids = data.allowed_account_ids || [];
    }
  }

  export class LibraryFolder
    extends BaseContentModel
    implements ContentInterfaces.ILibraryFolder {
    library_path?: string;

    constructor(data: any = {}) {
      super(
        data,
        DocTypeConstants.NAMESPACES.CONTENT,
        DocTypeConstants.TYPES.CONTENT.LIBRARY_FOLDER
      );

      this.library_path = data.library_path;
    }
  }
}
