import { DocTypeConstants } from '../../constants/doc-types';
import { SyncGatewayInterfaces } from '../sync-gateway/sync-gateway.interfaces';
import { User } from './user';

const RESIDENT_MODE_ALL = 'all';
const RESIDENT_MODE_SELECT = 'select';

export class FacilityUser extends User {
  constructor(
    data: {
      account_id?: string;
      email?: string;
      facility_ids?: string[];
      first_name?: string;
      has_temporary_pin?: boolean;
      last_name?: string;
      created_by?: string;
      created_date?: string;
      media?: {
        [key: string]: {
          content_type: string;
          etag: string;
          s3_key: string;
          status: string;
        };
      };
      pin?: string;
      phone?: string;
      resident_ids?: string[];
      resident_mode?: string;
      status?: string;
      title?: string;
      _id?: string;
      _rev?: string;
      _attachments?: { [key: string]: SyncGatewayInterfaces.IAttachment };
    } = {}
  ) {
    super({
      account_id: data.account_id,
      doc_type: DocTypeConstants.TYPES.USER.FACILITY_USER,
      email: data.email,
      facility_ids: data.facility_ids,
      first_name: data.first_name,
      has_temporary_pin: data.has_temporary_pin,
      last_name: data.last_name,
      created_by: data.created_by,
      created_date: data.created_date,
      media: data.media,
      phone: data.phone,
      pin: data.pin,
      resident_ids: data.resident_ids,
      resident_mode: data.resident_mode,
      status: data.status,
      title: data.title,
      _id: data._id,
      _rev: data._rev,
      _attachments: data._attachments,
    });
  }

  getResidentModeName() {
    switch (this.resident_mode) {
      case RESIDENT_MODE_ALL:
        return 'All Residents';
      case RESIDENT_MODE_SELECT:
        return 'Selected Residents';
    }

    return '';
  }
}
