import { Injectable } from '@angular/core';

import {
  Resident,
  RESIDENT_CONTACT_IMAGE_FILENAME_PREFIX,
  RESIDENT_STATUS_ACTIVE,
} from '../../model/resident/resident';
import { ResidentContactList } from '../../model/resident/resident-contact-list';
import { DateUtils } from '../../util/date-utils';
import { PhoneNumberUtils } from '../../util/phone-number-utils';

@Injectable()
export class ResidentFactoryService {
  constructor() {}
  createResidentFromFormValues(form): Resident {
    if (!form) {
      return new Resident();
    }

    form.status = RESIDENT_STATUS_ACTIVE;
    form.birthdate = DateUtils.setSavedDateFormat(form.birthdate);

    for (let i = 0; i < form.family.members.length; i++) {
      form.family.members[
        i
      ].phone = PhoneNumberUtils.setPhoneNumberToStandardSavedFormat(
        form.family.members[i].phone
      );
    }
    form.family = new ResidentContactList(form.family.members);

    return new Resident(form);
  }

  updateResidentFromFormValues(resident: Resident, form): Resident {
    resident.birthdate = DateUtils.setSavedDateFormat(form.birthdate);
    resident.interests = form.interests;
    resident.first_name = form.first_name;
    resident.last_name = form.last_name;
    resident.lifeloop_resident_id = form.lifeloop_resident_id || '';
    resident.pin = form.pin;
    resident.room_number = form.room_number;
    resident.family.members = form.family.members; // this.residentContactsFromForm(form.family.members);
    resident.recommended_search_terms = form.recommended_search_terms;
    resident.allow_family_interest_modification =
      form.allow_family_interest_modification;

    for (let i = 0; i < resident.family.members.length; i++) {
      const contact = resident.family.members[i];
      contact.phone = PhoneNumberUtils.setPhoneNumberToStandardSavedFormat(
        contact.phone
      );
      if (contact['remove_contact_image'] === 'true') {
        delete contact['remove_contact_image'];
        const phone = contact.phone.replace(/[^0-9]/g, '');
        const contact_image_name =
          RESIDENT_CONTACT_IMAGE_FILENAME_PREFIX + phone;
        delete resident._attachments[contact_image_name];
      }
    }

    return resident;
  }
}
