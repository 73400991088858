import * as moment from 'moment';
import { Subscription } from 'rxjs';

//  Subscription tracker that holds Observable subscriptions
//  until you call unsubscribe on it in ngOnDestroy.
export class SubscriptionTracker {
  _subs: Subscription[] = [];

  //    Subscription tracker that holds Observable subscriptions
  //    until you call unsubscribe on it in ngOnDestroy.
  constructor() {}

  // Add subscriptions to the tracked subscriptions
  // this.subs.add(observable$.subscribe(...));
  add(...subscriptions: Subscription[]) {
    this._subs = this._subs.concat(subscriptions);
  }

  //  Assign subscription to this tracker to add it to the tracked subscriptions
  //  this.subs.track = observable$.subscribe(...);
  set track(subscription: Subscription) {
    this._subs.push(subscription);
  }

  // Unsubscribe all subscriptions in ngOnDestroy()
  unsubscribe() {
    if (!this._subs.length) {
      return;
    }

    const startTime = moment();

    this._subs.forEach(sub => {
      sub && sub.unsubscribe();
    });
    this._subs = [];

    const duration = moment().diff(startTime, 'milliseconds');
    if (duration > 0) {
      console.log(
        `Unsubscribing from ${this._subs.length} tracked subscriptions took ${duration} ms`
      );
    }
  }
}
