import { Injectable } from '@angular/core';
import {
  PricingTier,
  PricingTierValues,
} from '../../model/pricing-tier/pricing-tiers';
import { from, Observable, of } from 'rxjs';

@Injectable()
export class PricingTierService {
  getPricingTiers(): Observable<PricingTier[]> {
    return of(PricingTierValues);
  }
}
