<div class="row" style="margin-left: 20px">
  <div class="col-sm-3">
    <h1>Portal</h1>
    <h3>Environment: {{ buildEnv }}</h3>
    <h3>Build Number: {{ buildNumber }}</h3>
    <h3>Jenkins Build Number: {{ jenkinsBuildNumber }}</h3>
    <h3 *ngIf="!!buildVersion">Version: {{ buildVersion }}</h3>
  </div>
</div>
<div class="row" style="margin-left: 20px">
  <div class="col-sm-12">
    <h1>APIs</h1>
  </div>
  <div class="row" style="margin-left: 20px">
    <div *ngFor="let api of sortedApiStatus" class="col-sm-3 bl bt">
      <h2>{{ api.name }}</h2>
      <ul>
        <li>API Environment: {{ api.status.env }}</li>
        <li>API Build Number: {{ api.status.buildNumber }}</li>
        <li>API Jenkins Build Number: {{ api.status.ciBuildNumber }}</li>
        <li *ngIf="!!api.status.buildVersion">
          API Version: {{ api.status.buildVersion }}
        </li>
      </ul>
    </div>
  </div>
</div>
