export namespace DocTypeConstants {
  export const NAMESPACES = {
    ACCOUNT: 'account',
    CONTENT: 'content',
    REFERENCE: 'reference',
    RESIDENT: 'resident',
    USER: 'user',
    SCRAPBOOK: 'scrapbook',
  };

  export const TYPES = {
    // Account Namespace
    ACCOUNT: {
      ACCOUNT: 'account',
      REGION: 'region',
      FACILITY: 'facility',
      DEVICE: 'device',
      ARCHIVED_DEVICE: 'archived-device',

      // Apollo device info
      SYSTEM_INFO: 'system-info',
    },

    // Content Namespace
    CONTENT: {
      // Library
      CONTENT_ITEM: 'content-item',
      LIBRARY_FOLDER: 'library-folder',

      // Packages
      PACKAGE: 'package',

      // Layout
      CONTAINER: 'container',
      LINK_TO: 'link-to',

      LAYOUT_ROOT: 'layout-root',
    },

    // Reference namespace
    REFERENCE: {
      INTERESTS: 'interests',
    },

    // Resident namespace
    RESIDENT: {
      RESIDENT: 'resident',
      ARCHIVED_RESIDENT: 'archived-resident',
    },

    // User namespace
    USER: {
      ACCOUNT_ADMIN: 'account-admin',
      FACILITY_ADMIN: 'facility-admin',
      FACILITY_USER: 'facility-user',
      IN2L_ADMIN: 'in2l-admin',
      IN2L_CONTENT: 'in2l-content',
      FAMILY_PORTAL_USER: 'family-portal-user',
      PORTAL_USER: 'portal-user',
    },

    SCRAPBOOK: {
      ENTRY: 'entry',
    },
  };

  export function isValidDocType(namespace: string, type: string): boolean {
    if (!namespace || !type) {
      return false;
    }

    const namespaceKey = (namespace || '').toUpperCase().replace('-', '_');
    const typeKey = (type || '').toUpperCase().replace('-', '_');
    return (
      NAMESPACES[namespaceKey] === namespace &&
      TYPES[namespaceKey][typeKey] === type
    );
  }
}
