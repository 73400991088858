import { Router } from '@angular/router';

import { DocTypeConstants } from '../constants/doc-types';
import { StateManager } from '../core/state/state-manager';
import { IAuthState } from '../model/state/auth-state';
import { User } from '../model/user/user';

function getUserHomeUrl(
  userSignedIn: boolean,
  userDocType: string,
  userDefaultAccountId: string,
  userDefaultFacilityId: string,
  dashboardsEnabled: boolean
): string {
  if (!userSignedIn) {
    return '/login';
  }

  if (
    [
      DocTypeConstants.TYPES.USER.IN2L_ADMIN,
      DocTypeConstants.TYPES.USER.IN2L_CONTENT,
    ].includes(userDocType)
  ) {
    return '/home';
  }

  let url = `/account/${userDefaultAccountId}`;

  if (userDocType === DocTypeConstants.TYPES.USER.FACILITY_ADMIN) {
    url += `/facility/${userDefaultFacilityId}`;
  }

  if (dashboardsEnabled) {
    url += '/dashboard';
  }

  return url;
}

function getSelectionUrl(
  authState: IAuthState,
  accountId?: string,
  facilityId?: string
): string {
  if (!authState.userSignedIn) {
    return '/login';
  }

  const isIn2lAdminOrContent = [
    DocTypeConstants.TYPES.USER.IN2L_ADMIN,
    DocTypeConstants.TYPES.USER.IN2L_CONTENT,
  ].includes(authState.userDocType);
  if (isIn2lAdminOrContent && !accountId && !facilityId) {
    return '/home';
  }

  let url = '';
  if (accountId) {
    url = `/account/${accountId}`;
  }

  if (accountId && facilityId) {
    url += `/facility/${facilityId}`;
  }

  if (authState.dashboardsEnabled) {
    url += '/dashboard';
  }

  return url;
}

function getIdsFromUrl(url: string): { accountId: string; facilityId: string } {
  if (!url) {
    return { accountId: '', facilityId: '' };
  }

  const accountMatch = url.match(/\/account\/([^\/]+)/i);
  const facilityMatch = url.match(/\/facility\/([^\/]+)/i);

  const accountId =
    accountMatch && ![':account_id', 'add'].includes(accountMatch[1])
      ? accountMatch[1]
      : '';
  const facilityId =
    facilityMatch && ![':facility_id', 'add'].includes(facilityMatch[1])
      ? facilityMatch[1]
      : '';

  return { accountId, facilityId };
}

function pageRequiresAuthentication(url: string): boolean {
  if (url === null) {
    return false;
  }

  if (['', '/'].includes(url)) {
    return true;
  }

  const urlBaseMatch = url.match(/^\/[a-zA-Z]+/);
  return (
    !!urlBaseMatch &&
    [
      '/home',
      '/account',
      '/facility',
      '/device',
      '/content',
      '/invite',
      '/admin',
      '/denied',
    ].includes(urlBaseMatch[0].toLowerCase())
  );
}

function pageSignsUserIn(url: string): boolean {
  if (!url) {
    return false;
  }

  const urlBaseMatch = url.match(/^\/[a-zA-Z\-]+/);
  return (
    !!urlBaseMatch &&
    ['/login', '/complete-invite', '/password-reset'].includes(urlBaseMatch[0])
  );
}

/**
 * The following navigation functions use setTimeout in order to
 * allow navigation from within state subscriptions
 */
function navigateToLogin(
  router: Router,
  state: StateManager,
  redirectUrl?: string
) {
  if (redirectUrl) {
    state.setRedirectUrl(redirectUrl);
  }
  router.navigateByUrl('/login');
}

function navigateToHomeUrl(router: Router) {
  router.navigateByUrl('/home');
}

function navigateToUserDefaultHomeUrl(
  router: Router,
  currentUser: User,
  stateManager: StateManager,
  dashboardsEnabled: boolean
) {
  const userDocType = (currentUser || {}).doc_type || '';
  const userDefaultAccountId = currentUser
    ? stateManager.getDefaultAccountId(currentUser)
    : '';
  const userDefaultFacilityId = currentUser
    ? stateManager.getDefaultFacilityId(currentUser)
    : '';

  // const authState = stateManager.getAuthState();
  const defaultUrl = getUserHomeUrl(
    !!currentUser,
    userDocType,
    userDefaultAccountId,
    userDefaultFacilityId,
    dashboardsEnabled
  );

  router.navigateByUrl(defaultUrl);
}

function navigateToSelectionUrl(
  router: Router,
  authState: IAuthState,
  accountId?: string,
  facilityId?: string
) {
  if (!accountId && !facilityId) {
    return;
  }

  const url = getSelectionUrl(authState, accountId, facilityId);
  router.navigateByUrl(url);
}

export {
  getIdsFromUrl,
  getSelectionUrl,
  getUserHomeUrl,
  pageRequiresAuthentication,
  pageSignsUserIn,
  navigateToLogin,
  navigateToHomeUrl,
  navigateToUserDefaultHomeUrl,
  navigateToSelectionUrl,
};
