import { UUID } from 'angular2-uuid';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ContentConstants } from '../../../../constants/content.constants';
import { FileUtilityService } from '../../../../core/file/file-utility.service';
import { MediaApiService } from '../../../../core/media/media-api.service';
import { ContentLayoutInterfaces } from '../../../../model/content/content-layout.interfaces';
import { ContentInterfaces } from '../../../../model/content/content.interfaces';
import { ContentModels } from '../../../../model/content/content.models';
import { ImageCropControlComponent } from '../../../../shared/components/image-crop-control/image-crop-control.component';
import { BaseFormComponent } from '../../../../util/form-utils';

@Component({
  selector: 'app-layout-container-form',
  styleUrls: ['./layout-container-form.component.scss'],
  templateUrl: './layout-container-form.component.html',
})
export class LayoutContainerFormComponent
  extends BaseFormComponent
  implements OnChanges {
  @ViewChild('cropControl')
  cropControl: ImageCropControlComponent;
  @ViewChild('deleteContainerModal')
  deleteContainerModal: ModalDirective;

  @Input()
  container: ContentModels.Container;

  @Output()
  changeEvent: EventEmitter<ContentLayoutInterfaces.ILayoutChangeEvent> = new EventEmitter<ContentLayoutInterfaces.ILayoutChangeEvent>();

  changeDetails: ContentLayoutInterfaces.ILayoutChangeEvent = {};
  deleteSubmitted = false;
  errorMessage: string;
  editMode: boolean;
  form: FormGroup;

  tileImageSrc = '';

  formSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private mediaApiService: MediaApiService
  ) {
    super();
  }

  ngOnChanges() {
    if (!this.container) {
      return;
    }

    if (this.container._id) {
      this.subscriptionTracker.track = this.mediaApiService
        .getAttachment(this.container._id, this.container.tileImageKey())
        .pipe(
          mergeMap((imageBlob: Blob) => {
            // rec blob, convert to dataURI
            return imageBlob
              ? FileUtilityService.convertBlobToDataURI(imageBlob)
              : of(null);
          })
        )
        .subscribe(
          (imageDataURI: string) => {
            const uri = imageDataURI || null;

            if (uri) {
              this.tileImageSrc = uri;
            }
          },
          error => {
            console.warn('Could not convert image to dataURI', error);
          }
        );
    } else {
      this.tileImageSrc = null;
    }

    this.form = this.fb.group({
      title: [this.container.title || '', Validators.required],
      notes: [this.container.notes || ''],
    });

    this.formSubmitted = false;
    this.editMode = !!this.container._id;
  }

  getUtcActiveDates(): ContentInterfaces.IActiveDateRange[] {
    if (this.container.active_dates && this.container.active_dates.length > 0) {
      return this.container.active_dates.map(
        (range: ContentInterfaces.IActiveDateRange) => ({
          start_date: moment(range.start_date).utc().format('MM/DD/YYYY'),
          end_date: moment(range.end_date).utc().format('MM/DD/YYYY'),
        })
      );
    }

    return [{ start_date: '', end_date: '' }];
  }

  deleteDisabled(): boolean {
    return (
      ContentConstants.NEVER_DELETE_CONTENT_IDS.includes(this.container._id) ||
      !this.editMode
    );
  }

  /**
   * Tile image
   */

  removeTileImage() {
    this.tileImageSrc = '';
  }

  /**
   * Delete Modal
   */
  showDeleteModal() {
    this.deleteSubmitted = false;
    this.deleteContainerModal.show();
  }

  closeDeleteModal() {
    this.deleteContainerModal.hide();
  }

  /**
   * Events
   */

  deleteContainer() {
    if (!this.container || !this.container._id) {
      return;
    }

    this.changeDetails = {
      deleteContainer: {
        containerId: this.container._id,
      },
    };

    this.deleteSubmitted = true;
    this.emitAndClearChange();
  }

  saveContainer() {
    if (!this.form.valid) {
      return;
    }

    if (!this.tileImageSrc && !this.cropControl.getCroppedImageDataUrl()) {
      this.container.removeTileImage();
    }

    this.changeDetails = {
      saveContainer: {
        imageSrc:
          this.tileImageSrc || this.cropControl.getCroppedImageDataUrl(),
        container: Object.assign(
          new ContentModels.Container(),
          this.container,
          {
            _id: this.container._id || UUID.UUID(),
            parent_id:
              this.container.parent_id || ContentConstants.LAYOUT_ROOT_ID,
            title: this.form.controls['title'].value || '',
            notes: this.form.controls['notes'].value || '',
            layout_root_id: this.container.parent_id.endsWith(
              ContentConstants.LAYOUT_ROOT_ID
            )
              ? this.container.parent_id
              : this.container.layout_root_id,
          }
        ),
        tileImageDataUrl:
          this.cropControl.getCroppedImageDataUrl() || undefined,
      },
    };

    this.formSubmitted = true;

    this.emitAndClearChange();
  }

  cancel() {
    this.changeDetails = {
      cancel: {},
    };
    this.emitAndClearChange();
  }

  emitAndClearChange() {
    this.emitChanges();
    this.clearChanges();
  }

  emitChanges() {
    this.changeEvent.emit(this.changeDetails);
  }

  clearChanges() {
    this.changeDetails = {};
  }
}
