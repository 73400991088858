import { ModalDirective } from 'ngx-bootstrap/modal';
import { of, Subject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IActionMapping, ITreeState } from '@circlon/angular-tree-component';

import { ContentConstants } from '../../../../constants/content.constants';
import { DocTypeConstants } from '../../../../constants/doc-types';
import { ContentLayoutService } from '../../../../core/content-layout/content-layout.service';
import { ContentLayoutInterfaces } from '../../../../model/content/content-layout.interfaces';
import { ContentModels } from '../../../../model/content/content.models';
import { DEVICE_PRODUCTS_DISPLAY } from '../../../../model/device/device';

enum CopyPhase {
  SelectSource = 'selectSource',
  SelectDest = 'selectDest',
  Confirm = 'confirm',
  DoCopy = 'doCopy',
  Done = 'done',
}

interface INode {
  id: string;
  name: string;
  children: INode[];
  isFolder: boolean;
  isSelected: boolean;
}

@Component({
  selector: 'app-layout-copy-modal',
  styleUrls: ['./layout-copy-modal.component.scss'],
  templateUrl: './layout-copy-modal.component.html',
})
export class LayoutCopyModal implements OnInit {
  @ViewChild('layoutCopyModal')
  layoutCopyModal: ModalDirective;

  @Input()
  sourceRoot?: ContentModels.LayoutRoot;

  @Input()
  layoutRoots: ContentModels.LayoutRoot[];

  @Input()
  itemMap: { [id: string]: any }; // map of all containers, link-tos, content-items, and packages

  copyCompleteSubject: Subject<
    ContentLayoutInterfaces.IContainerCopyResult | undefined
  >;
  copyResult?: ContentLayoutInterfaces.IContainerCopyResult;
  containersCopied: number;
  linkTosCopied: number;
  copyError?: string;
  isCopying: boolean;

  modalTitle: string;

  currentPhase: CopyPhase;
  prevPhase: CopyPhase;
  nextPhase: CopyPhase;

  selectedDestProduct: string = '';
  selectedDestRootId: string = '';
  filteredDestLayoutRoots: ContentModels.LayoutRoot[] = [];
  // products: string[] = Object.keys(DEVICE_PRODUCTS_DISPLAY);
  products: string[] = ['engage', 'lifeloop'];
  nextBtnEnabled: boolean;
  nextBtnText: string;

  selectedNode?: INode;
  selectedSourceNode?: INode;
  selectedDestNode?: INode;

  nodes: INode[];
  treeState: ITreeState;
  treeActionMapping: IActionMapping = {
    mouse: {
      click: (_, node, __) => {
        if (this.selectedNode) {
          this.selectedNode.isSelected = false;
        }

        this.selectedNode = <INode>node.data;
        this.selectedNode.isSelected = true;

        if (this.currentPhase === CopyPhase.SelectSource) {
          this.selectedSourceNode = this.selectedNode;
          this.nextBtnEnabled = true;
        }

        if (this.currentPhase === CopyPhase.SelectDest) {
          this.selectedDestNode = this.selectedNode;
        }
      },
    },
  };
  treeOptions = {
    idField: 'id',
    childrenField: 'children',
    actionMapping: this.treeActionMapping,
    allowDrag: false,
    allowDrop: false,
  };

  // allow only copy to lifeloop
  constructor(private contentLayoutService: ContentLayoutService) {
    this.copyCompleteSubject = new Subject<
      ContentLayoutInterfaces.IContainerCopyResult | undefined
    >();
  }

  switchToPhase(phase: CopyPhase) {
    switch (phase) {
      case CopyPhase.SelectSource:
        this.nextBtnEnabled = false;
        this.currentPhase = CopyPhase.SelectSource;
        this.nextPhase = CopyPhase.SelectDest;

        if (!this.sourceRoot) {
          throw new Error('sourceRoot is required');
        }

        this.nextBtnText = 'Next';
        this.modalTitle =
          `Copy container from ${this.getProductDisplay(
            this.sourceRoot.product ?? ''
          )} > ` + this.getLayoutTitle(this.sourceRoot);

        this.refreshNodes(this.sourceRoot);
        break;
      case CopyPhase.SelectDest:
        this.nextBtnEnabled = false;
        this.selectedDestNode = undefined;
        this.currentPhase = CopyPhase.SelectDest;
        this.prevPhase = CopyPhase.SelectSource;
        this.nextPhase = CopyPhase.Confirm;

        this.selectedDestProduct = 'lifeloop';
        this.switchDestProduct();

        this.nextBtnText = 'Next';
        this.modalTitle = 'Select destination container';
        break;
      case CopyPhase.Confirm:
        this.nextBtnEnabled = true;
        this.currentPhase = CopyPhase.Confirm;
        this.prevPhase = CopyPhase.SelectDest;
        this.nextPhase = CopyPhase.DoCopy;

        this.modalTitle = 'Confirm container copy';
        this.nextBtnText = 'Start Copy';
        break;
      case CopyPhase.DoCopy:
        this.nextBtnEnabled = false;
        this.isCopying = true;
        this.currentPhase = CopyPhase.DoCopy;
        this.prevPhase = CopyPhase.Confirm;
        this.nextPhase = CopyPhase.Done;

        this.modalTitle = 'Copy In Progress';
        this.nextBtnText = 'Done';

        this.doCopy();
        break;
      case CopyPhase.Done:
        this.currentPhase = CopyPhase.Done;
        this.prevPhase = CopyPhase.Done;
        this.nextPhase = CopyPhase.Done;

        this.hide();
        this.copyCompleteSubject.next(this.copyResult);
        break;
      default:
        break;
    }
  }

  doCopy() {
    const destRoot = this.getSelectedDestRoot();
    if (this.selectedSourceNode && destRoot) {
      const dest = (this.selectedDestNode?.id || destRoot._id) ?? '';

      // no reason to track observable, take(1) will complete observable when it fires the one time.
      this.contentLayoutService
        .copyContainer(this.selectedSourceNode.id, dest)
        .pipe(
          take(1),
          catchError(val => {
            console.log('Copy operation failed. ' + val);
            return of(undefined);
          })
        )
        .subscribe(copyResult => {
          this.isCopying = false;
          this.copyResult = copyResult;
          this.nextBtnEnabled = true;

          if (copyResult) {
            this.modalTitle = 'Copy complete';
            this.copyError = undefined;
            this.linkTosCopied = copyResult.linkTosCopied;
            this.containersCopied = copyResult.containersCopied;
          } else {
            this.modalTitle = 'Copy failed';
            this.copyError = 'Copy failed. Please try again later.';
          }
        });
    }
  }

  switchDestProduct() {
    if (this.selectedDestProduct) {
      this.filteredDestLayoutRoots = this.layoutRoots.filter(
        l => l.product === this.selectedDestProduct
      );

      if (this.filteredDestLayoutRoots.length == 1) {
        this.selectedDestRootId = this.filteredDestLayoutRoots[0]._id ?? '';
        this.switchDestRoot();
        return;
      } else if (this.filteredDestLayoutRoots.length > 1) {
        this.selectedDestRootId =
          this.filteredDestLayoutRoots.find(l =>
            ContentConstants.PREFERRED_LAYOUT_ROOT_IDS.includes(l._id ?? '')
          )?._id ?? '';

        this.switchDestRoot();
        return;
      }
    }

    this.selectedDestRootId = '';
    this.switchDestRoot();
  }

  switchDestRoot() {
    if (this.selectedDestRootId) {
      this.refreshNodes(
        this.layoutRoots.find(l => l._id === this.selectedDestRootId)
      );
      this.nextBtnEnabled = true;
    } else {
      this.nextBtnEnabled = false;
      this.refreshNodes();
    }

    this.selectedDestNode = undefined;
  }

  ngOnInit() {}

  hide() {
    this.layoutCopyModal.hide();
  }

  back() {
    this.switchToPhase(this.prevPhase);
  }

  next() {
    this.switchToPhase(this.nextPhase);
  }

  show() {
    this.switchToPhase(CopyPhase.SelectSource);
    this.layoutCopyModal.show();
  }

  getProductDisplay(product: string) {
    return DEVICE_PRODUCTS_DISPLAY[product];
  }

  getLayoutTitle(layout: ContentModels.LayoutRoot) {
    return layout.title || `Untitled - ${layout._id}`;
  }

  getSourceDescription(): string {
    if (this.selectedSourceNode && this.sourceRoot) {
      const doc = this.itemMap[this.selectedSourceNode.id];
      return this.getAncestorNames(this.sourceRoot, doc).reverse().join(' > ');
    }

    throw new Error('Invalid state. Could not get source description.');
  }

  getDestDescription(): string {
    const destLayoutRoot = this.getSelectedDestRoot();

    if (destLayoutRoot) {
      if (this.selectedDestNode) {
        const doc = this.itemMap[this.selectedDestNode.id];
        return this.getAncestorNames(destLayoutRoot, doc).reverse().join(' > ');
      } else {
        return this.getLayoutTitle(destLayoutRoot);
      }
    }

    throw new Error('Invalid state. Could not get dest description.');
  }

  getSelectedDestRoot(): ContentModels.LayoutRoot | undefined {
    return this.layoutRoots.find(l => l._id === this.selectedDestRootId);
  }

  getAncestorNames(
    layoutRoot: ContentModels.LayoutRoot,
    doc: any,
    names: string[] | undefined = undefined
  ): string[] {
    // From Jake - TODO: If containers get ancestors property - maybe use that instead.
    // From Jake - TODO: If this logic is needed elsewhere
    // - consolidate into a service, and type off content models directly

    if (!names) {
      names = [];
    }

    names.push(doc.title);

    if (doc.parent_id && doc.parent_id !== layoutRoot._id) {
      const parentDoc = this.itemMap[doc.parent_id];

      if (parentDoc) {
        this.getAncestorNames(layoutRoot, parentDoc, names);
      }
    } else {
      names.push(this.getLayoutTitle(layoutRoot));
    }

    return names;
  }

  refreshNodes(root: ContentModels.LayoutRoot | undefined = undefined) {
    if (root) {
      if (!this.itemMap) {
        throw new Error('itemMap is required');
      }

      this.nodes = <INode[]>(
        root.children.map(id => this.buildNode(id)).filter(i => !!i)
      );
    } else {
      this.nodes = [];
    }

    this.selectedNode = undefined;
  }

  getNodeClass(node: INode) {
    return node.isSelected ? 'selectedNode' : '';
  }

  buildNode(id: string): INode | undefined {
    if (!this.itemMap[id]) {
      return undefined;
    }

    const doc = this.itemMap[id];

    if (doc.doc_type === DocTypeConstants.TYPES.CONTENT.CONTAINER) {
      const node: INode = {
        id: id,
        children: [],
        isFolder: true,
        name: doc.title,
        isSelected: false,
      };

      node.children.push(
        ...(doc.children || []).map(i => this.buildNode(i)).filter(c => !!c)
      );

      return node;
    }

    return undefined;
  }
}
