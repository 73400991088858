import { Subscription } from 'rxjs';

import { Injectable } from '@angular/core';

import { CacheService } from '../cache/cache.service';
import { AuthStateService } from './auth-state.service';
import { SidebarStateService } from './sidebar-state.service';
import { StateManager } from './state-manager';

@Injectable({
  providedIn: 'root',
})
export class StateSubscriptionManager {
  private constructorSubscribes: Subscription[] = [];
  private onInitSubscribes: Subscription[] = [];

  constructor(
    private authStateService: AuthStateService,
    private cacheService: CacheService,
    private sidebarStateService: SidebarStateService,
    private stateManager: StateManager
  ) {}

  constructorSubscribe(): Subscription[] {
    this.constructorSubscribes = [];

    return this.constructorSubscribes;
  }

  onInitSubscribe(): Subscription[] {
    this.stateManager.initialize(this.cacheService);
    this.onInitSubscribes = [
      this.authStateService.authStateUpdaterSubscription(),
      this.authStateService.errorEventSubscription(),
      this.sidebarStateService.sidebarAccountSelectionChangeSubscription(),
      this.sidebarStateService.sidebarFacilitySelectionChangeSubscription(),
    ];

    return this.onInitSubscribes;
  }
}
