import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DocTypeConstants } from '../../constants/doc-types';
import { ContentModels } from '../../model/content/content.models';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { DatabaseApiService } from '../database-api/database-api.service';
import { StateManager } from '../state/state-manager';

@Injectable()
export class ContentLibraryDbService extends DatabaseApiService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    cacheService: CacheService,
    stateManager: StateManager
  ) {
    super(http, jwtService, stateManager, cacheService);
  }

  getActiveLinkTos(): Observable<ContentModels.LinkTo[]> {
    return this.partialsRequest<ContentModels.LinkTo[]>(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.LINK_TO,
      { preset: 'current-and-future-active' }
    ).pipe(map(linkTos => linkTos.map(l => new ContentModels.LinkTo(l))));
  }

  getContentTitles(
    contentIds: string[]
  ): Observable<ContentModels.ContentItem[]> {
    if (contentIds && contentIds.length) {
      return this.partialsRequest<ContentModels.ContentItem[]>(
        DocTypeConstants.NAMESPACES.CONTENT,
        DocTypeConstants.TYPES.CONTENT.CONTENT_ITEM,
        {
          ids: contentIds,
          returnProperties: ['title', '_id'],
        }
      );
    } else {
      return of([]);
    }
  }

  getLinkTosForInterest(
    interestId: string
  ): Observable<ContentModels.LinkTo[]> {
    if (interestId) {
      return this.partialsRequest<ContentModels.LinkTo[]>(
        DocTypeConstants.NAMESPACES.CONTENT,
        DocTypeConstants.TYPES.CONTENT.LINK_TO,
        {
          queryProperties: [
            {
              propertyName: 'linked_interests',
              comparator: 'contains',
              value: interestId,
            },
          ],
          returnProperties: [
            '_id',
            'active_dates',
            'content_id',
            'is_approved',
            'layout_root_id',
            'linked_interests',
            'override_title',
            'packages',
            'parent_id',
          ],
        }
      );
    } else {
      return of([]);
    }
  }

  getLinkToParentContainers(
    parentContainerIds: string[]
  ): Observable<ContentModels.Container[]> {
    if (parentContainerIds) {
      return this.partialsRequest<ContentModels.Container[]>(
        DocTypeConstants.NAMESPACES.CONTENT,
        DocTypeConstants.TYPES.CONTENT.CONTAINER,
        {
          ids: parentContainerIds,
          returnProperties: ['_id', 'title'],
        }
      );
    } else {
      return of([]);
    }
  }

  getSingleLinkToById(linkToId: string): Observable<ContentModels.LinkTo> {
    if (linkToId) {
      return this.partialsRequest<ContentModels.LinkTo[]>(
        DocTypeConstants.NAMESPACES.CONTENT,
        DocTypeConstants.TYPES.CONTENT.LINK_TO,
        {
          ids: [linkToId],
          returnProperties: [
            '_id',
            'active_dates',
            'is_approved',
            'layout_root_id',
            'linked_interests',
            'override_title',
            'packages',
            'parent_id',
          ],
        }
      ).pipe(
        map(linkToList =>
          linkToList && linkToList.length === 1 ? linkToList.shift() : undefined
        )
      );
    } else {
      return of(undefined);
    }
  }

  patchLinkToInterests(
    linkToId: string,
    oldLinkedInterests: string[],
    newLinkedInterests: string[]
  ): Observable<ContentModels.LinkTo> {
    if (oldLinkedInterests && newLinkedInterests) {
      return this.patchProperty<ContentModels.LinkTo>(
        {
          doc_namespace: DocTypeConstants.NAMESPACES.CONTENT,
          doc_type: DocTypeConstants.TYPES.CONTENT.LINK_TO,
          _id: linkToId,
        },
        'linked-interests',
        { oldLinkedInterests, newLinkedInterests }
      );
    } else {
      return of(null);
    }
  }

  // Override handleError function for this service so that we can prevent the 409 errors from getting suppressed by generic handlers
  handleError<T>(error: any): Observable<T> {
    if (error.status === 401) {
      this.stateManager.handleErrorEvent({
        type: 'UNAUTHORIZED',
      });
    } else if (error.status === 409) {
      // Error status 409 is used by the Patch/linked-interests endpoint to indicate an editing conflict
      // By passing it up the call stack we can handle the error and resubmit the request
      throw error;
    } else {
      this.stateManager.handleErrorEvent({
        type: 'UNKNOWN_ERROR',
      });
    }

    return of(null);
  }
}
