import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DocTypeConstants } from '../../constants/doc-types';
import { ContentModels } from '../../model/content/content.models';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { ContentApiService } from '../content-api/content-api.service';
import { MediaApiService } from '../media/media-api.service';
import { StateManager } from '../state/state-manager';

import ContentPackage = ContentModels.ContentPackage;
@Injectable()
export class ContentPackageService extends ContentApiService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    cacheService: CacheService,
    mediaApiService: MediaApiService,
    stateManager: StateManager
  ) {
    super(http, jwtService, mediaApiService, stateManager, cacheService);
  }

  getAllContentPackages(): Observable<ContentPackage[]> {
    return this.getAllByNamespaceType<ContentPackage>(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.PACKAGE,
      ContentPackage
    );
  }

  getPartialsPackages(ids: string[]): Observable<ContentPackage[]> {
    return this.partialsRequest(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.PACKAGE,
      {
        ids: ids,
      }
    );
  }

  getContentPackage(id: string): Observable<ContentPackage> {
    return this.getById<ContentPackage>(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.PACKAGE,
      id,
      ContentPackage
    );
  }

  upsertPackage(contentPackage: ContentPackage): Observable<ContentPackage> {
    return this.upsertSinglePackage(contentPackage).pipe(
      mergeMap((updatedPackage: ContentPackage) => {
        this.cacheService.updateSingleDoc(updatedPackage);
        return of(updatedPackage);
      })
    );
  }

  deletePackage(contentPackage: ContentPackage): Observable<boolean> {
    return this.deleteDoc(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.PACKAGE,
      contentPackage._id
    );
  }
}
