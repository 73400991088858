<div class="wrapper">
  <div class="block-center mt-xl wd-xl">
    <!-- START panel-->
    <div class="panel panel-flat">
      <div class="panel-heading text-center">
        <a href="#">
          <img
            class="block-center login-logo-img"
            style="max-width: 45%"
            src="/assets/img/iN2L_powered_by_LifeLoop.png"
            alt="Image"
          />
        </a>
      </div>
      <div class="panel-body">
        <p class="text-center pv">PASSWORD RESET</p>
        <form
          [formGroup]="valForm"
          class="form-validate"
          role="form"
          name="recoverForm"
          novalidate=""
          (submit)="submitForm($event)"
          *ngIf="!successMessage && !errorMessage"
        >
          <p class="mb-lg">
            Enter your email to receive instructions on how to reset your
            password.
          </p>
          <div class="form-group has-feedback">
            <label class="text-muted">Email address</label>
            <input
              class="form-control"
              type="email"
              name="email"
              placeholder="Enter email"
              autocomplete="off"
              formControlName="email"
            />
            <span
              class="fa fa-envelope form-control-feedback text-muted"
            ></span>
            <span
              class="text-danger"
              *ngIf="valForm.controls['email'].hasError('required')"
              >This field is required</span
            >
            <span
              class="text-danger"
              *ngIf="
                valForm.controls['email'].hasError('email') &&
                (valForm.controls['email'].dirty ||
                  valForm.controls['email'].touched)
              "
              >This field must be a valid email address</span
            >
          </div>
          <div class="clearfix">
            <div class="pull-right">
              <a class="text-muted" routerLink="/login">Looking to sign in?</a>
            </div>
          </div>
          <button
            class="btn btn-primary btn-block mt-lg"
            type="submit"
            [disabled]="disableButton || !valForm.valid"
          >
            Reset Password
          </button>
        </form>
        <p class="text-center" *ngIf="successMessage">{{ successMessage }}</p>
        <div class="form-group text-center" *ngIf="errorMessage">
          <p class="text-danger mb-lg" [innerHTML]="errorMessage"></p>
          <a href="#" (click)="tryAgain()">Try again</a>
        </div>
      </div>
    </div>
    <!-- END panel-->
    <footer class="p-lg text-center">
      &copy; {{ settings.app.year }} - {{ settings.app.name }}<br />
      <a class="small mr" href="http://in2l.com/privacy-policy/" target="_blank"
        >Privacy Policy</a
      >
      <a class="small ml" href="http://in2l.com/terms-of-use/" target="_blank"
        >Terms of Use</a
      >
    </footer>
  </div>
</div>
