<div class="wrapper">
  <!-- top navbar-->
  <app-header class="topnavbar-wrapper"></app-header>
  <!-- sidebar-->
  <app-sidebar class="aside show-sidebar-over-navbar"></app-sidebar>
  <!-- offsidebar-->
  <app-offsidebar class="offsidebar"></app-offsidebar>
  <!-- Main section-->
  <section>
    <!-- Page content-->
    <div class="content-wrapper">
      <!-- Breadcrumb -->
      <app-breadcrumb class="freeze-breadcrumb-on-scroll"></app-breadcrumb>
      <div class="show-active-component-beneath-frozen-breadcrumb">
        <router-outlet></router-outlet>
      </div>
    </div>
  </section>
  <!-- Page footer-->
  <footer app-footer class="text-center"></footer>
  <app-layout-notification></app-layout-notification>
</div>
