import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { navigateToLogin, pageSignsUserIn } from '../../util/navigation-utils';
import { StateManager } from '../state/state-manager';

/**
 *  AUTH GUARD
 *  To Use: import AuthGuard and set 'canActivate: [ AuthGaurd ]'
 *
 *  This should be a default guard for all route requiring user authentication. Simply
 *  prevents access to pages that require authentications users. When a request is made
 *  and a user is not authenticated, this guard will redirect the uer to the login page.
 */

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private stateManager: StateManager) {}

  /**
   * CanActivate Interface Functions
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const currentUser = this.stateManager.getCurrentUser();
    const authState = this.stateManager.getAuthState();
    if (!currentUser && pageSignsUserIn(authState.currentUrl)) {
      return of(true);
    }

    if (!currentUser) {
      navigateToLogin(this.router, this.stateManager, state.url);
      return of(false);
    }

    return of(true);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
