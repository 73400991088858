<!-- START Sidebar (left)-->
<div class="aside-inner">
  <nav
    class="sidebar"
    sidebar-anyclick-close=""
    [class.show-scrollbar]="settings.layout.asideScrollbar"
  >
    <ul class="nav sidebar-logo-container">
      <img
        class="sidebar-logo-img"
        src="/assets/img/iN2L_powered_by_LifeLoop.png"
        alt="Image"
      />
    </ul>

    <!-- START sidebar nav-->
    <ul
      class="nav sidebar-inner-wrapper-height"
      [ngStyle]="{ visibility: isSidebarCollapsed() ? 'hidden' : 'visible' }"
    >
      <!-- START user info-->
      <li class="has-user-block"><app-userblock></app-userblock></li>
      <!-- END user info-->

      <li>
        <app-sidebar-group
          name="Accounts & Communities"
          [main]="true"
          [alwaysShow]="true"
          *ngIf="!isIn2lContentUser()"
        >
          <app-sidebar-accounts-group
            [clearParams]="['facilityId']"
            param="accountId"
            displayKey="profile.account_name"
            placeholder="Select Account"
            [defaultRoute]="
              isDashboardEnabled
                ? '/account/:account_id/dashboard'
                : '/account/:account_id'
            "
            *ngIf="!isIn2lContentUser()"
            [disabled]="accountsDropdownDisabled"
          >
            <app-sidebar-link
              *ngIf="isDashboardEnabled"
              href="/account/:account_id/dashboard"
              text="Dashboard"
              [role]="roles.ROLE_VIEW_ACCOUNT"
            >
            </app-sidebar-link>

            <app-sidebar-link
              href="/account/:account_id/"
              text="Account Profile"
              [role]="roles.ROLE_VIEW_ACCOUNT"
            >
            </app-sidebar-link>

            <app-sidebar-link
              href="/account/:account_id/regions"
              text="Regions"
              [role]="roles.ROLE_EDIT_REGIONS"
            >
            </app-sidebar-link>

            <app-sidebar-group name="Communities" [alwaysShow]="true">
              <app-sidebar-facilities-group
                param="facilityId"
                paramDependency="accountId"
                displayKey="profile.name"
                placeholder="Select Community"
                [defaultRoute]="
                  isDashboardEnabled
                    ? '/account/:account_id/facility/:facility_id/dashboard'
                    : '/account/:account_id/facility/:facility_id'
                "
              >
                <app-sidebar-link
                  *ngIf="isDashboardEnabled"
                  href="/account/:account_id/facility/:facility_id/dashboard"
                  text="Dashboard"
                  [role]="roles.ROLE_VIEW_FACILITY"
                >
                </app-sidebar-link>

                <app-sidebar-link
                  href="/account/:account_id/facility/:facility_id"
                  text="Community Profile"
                  [role]="roles.ROLE_VIEW_FACILITY"
                >
                </app-sidebar-link>

                <app-sidebar-group
                  name="Residents"
                  defaultRoute="/account/:account_id/facility/:facility_id/resident"
                >
                  <app-sidebar-link
                    href="/account/:account_id/facility/:facility_id/resident"
                    text="Resident List"
                    [role]="roles.ROLE_VIEW_RESIDENT"
                  >
                  </app-sidebar-link>
                  <app-sidebar-link
                    href="/account/:account_id/facility/:facility_id/resident/add"
                    text="Add Resident"
                    [role]="roles.ROLE_ADD_RESIDENT"
                  >
                  </app-sidebar-link>
                </app-sidebar-group>

                <app-sidebar-group
                  name="Devices"
                  defaultRoute="/account/:account_id/facility/:facility_id/devices"
                >
                  <app-sidebar-link
                    href="/account/:account_id/facility/:facility_id/devices"
                    text="Device List"
                    [role]="roles.ROLE_VIEW_DEVICE"
                  >
                  </app-sidebar-link>
                  <app-sidebar-link
                    href="/account/:account_id/facility/:facility_id/devices/add"
                    text="Add Device"
                    [role]="roles.ROLE_ADD_DEVICE"
                  >
                  </app-sidebar-link>
                </app-sidebar-group>

                <app-sidebar-group
                  name="Staff"
                  defaultRoute="/account/:account_id/facility/:facility_id/staff"
                >
                  <app-sidebar-link
                    href="/account/:account_id/facility/:facility_id/staff"
                    text="Staff List"
                    [role]="roles.ROLE_VIEW_FACILITY_STAFF"
                  >
                  </app-sidebar-link>
                  <app-sidebar-link
                    href="/account/:account_id/facility/:facility_id/staff/invite"
                    text="Invite Staff"
                    [role]="roles.ROLE_VIEW_FACILITY_STAFF"
                  >
                  </app-sidebar-link>
                </app-sidebar-group>
              </app-sidebar-facilities-group>

              <app-sidebar-link
                href="/account/:account_id/facility/add"
                text="Add Community"
                [role]="roles.ROLE_ADD_FACILITY"
              >
              </app-sidebar-link>
            </app-sidebar-group>
          </app-sidebar-accounts-group>

          <app-sidebar-link
            href="/account/add"
            text="Create an Account"
            [role]="roles.ROLE_ADD_ACCOUNT"
          >
          </app-sidebar-link>
        </app-sidebar-group>
      </li>

      <li>
        <app-sidebar-group name="Devices" [main]="true" [alwaysShow]="true">
          <app-sidebar-link
            href="/device/status"
            text="Device Status List"
            [role]="roles.ROLE_VIEW_DEVICE_STATUS"
          >
          </app-sidebar-link>
        </app-sidebar-group>
      </li>

      <li>
        <app-sidebar-group name="Content" [main]="true" [alwaysShow]="true">
          <app-sidebar-link
            href="/content/library"
            text="Library"
            [role]="roles.ROLE_VIEW_CONTENT"
          >
          </app-sidebar-link>
          <app-sidebar-link
            href="/content/packages"
            text="Packages"
            [role]="roles.ROLE_VIEW_CONTENT"
          >
          </app-sidebar-link>
          <app-sidebar-link
            href="/content/layout"
            text="Layout"
            [role]="roles.ROLE_VIEW_CONTENT"
          >
          </app-sidebar-link>
          <app-sidebar-link
            href="/content/ideaboard"
            text="Idea Board"
            [role]="roles.ROLE_VIEW_CONTENT"
          >
        </app-sidebar-link>
          <app-sidebar-link
          href="/content/iconnect-recommendations"
          text="iConnect Recommendations"
          [role]="roles.ROLE_EDIT_CONTENT"
        >
          </app-sidebar-link>
        </app-sidebar-group>
      </li>

      <li>
        <app-sidebar-group name="Admin" [main]="true" [alwaysShow]="true">
          <app-sidebar-link
            href="/invite/list"
            text="Open Invites"
            [role]="roles.ROLE_ADMIN_MANAGE_INVITES"
          >
          </app-sidebar-link>
          <app-sidebar-link
            href="/admin/user/list"
            text="Users"
            [role]="roles.ROLE_ADMIN_USERS"
          >
          </app-sidebar-link>
          <app-sidebar-link
            href="/admin/configuration"
            text="Portal Configuration"
            [role]="roles.ROLE_ADMIN_USERS"
          >
          </app-sidebar-link>
        </app-sidebar-group>
      </li>
    </ul>
    <!-- END sidebar nav-->
  </nav>
</div>
<!-- END Sidebar (left)-->
