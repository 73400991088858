import * as _ from 'lodash';
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { DocTypeConstants } from '../../constants/doc-types';
import { Account } from '../../model/account/account';
import { IDeviceTypes } from '../../model/device-types/device-types';
import { Device } from '../../model/device/device';
import { Facility } from '../../model/facility/facility';
import { Region } from '../../model/regions/regions';
import {
  Resident,
  RESIDENT_STATUS_ACTIVE,
} from '../../model/resident/resident';
import { SummaryInterfaces } from '../../model/summary/summary.interfaces';
import { TooltipInterfaces } from '../../model/tooltips/tooltip.interfaces';
import { ISortableReport } from '../../shared/components/sortable-report/sortable-report.component';
import { AccountService } from '../account/account.service';
import { DeviceTypesService } from '../configuration/device-types.service';
import {
  DashboardApiService,
  IChartData,
  IContentReport,
  IHasProducts,
  IPieChartData,
  ISelectedRange,
  SELECTED_RANGE_FOR_NAMES,
} from '../dashboard-api/dashboard-api.service';
import { DeviceService } from '../device/device.service';
import { FacilityService } from '../facility/facility.service';
import { RegionsService } from '../regions/regions.service';
import { ResidentService } from '../resident/resident.service';

import IDashboardSummary = SummaryInterfaces.IDashboardSummary;
import ISummaryCommunitiesReport = SummaryInterfaces.ISummaryCommunitiesReport;
import ISummaryDrillDownChart = SummaryInterfaces.ISummaryDrillDownChart;
import ISummaryDisplayTypeAccessedCharts = SummaryInterfaces.ISummaryDisplayTypeAccessedCharts;
import ISummaryResidentsReport = SummaryInterfaces.ISummaryResidentsReport;
import IDeviceStays = SummaryInterfaces.IDeviceStays;
import IResidentStays = SummaryInterfaces.IResidentStays;
import ISummaryRegionsReport = SummaryInterfaces.ISummaryRegionsReport;

export interface IAccountDashboard {
  accountName: string;
  firstYear: number;
  products: IHasProducts;
  drillDownChart: IChartData;
  facilitiesReport: ISortableReport[];
  regionsReport: ISortableReport[];
  contentPieChart: IPieChartData;
  contentAccessedReport: IContentReport;
  accountToolTips: TooltipInterfaces.IDrillDownChart[];
}

export interface IFacilityDashboard {
  accountName: string;
  facilityName: string;
  firstYear: number;
  products?: IHasProducts;
  drillDownChart?: IChartData;
  residentCount?: number;
  residentsReport?: ISortableReport[];
  deviceCount?: number;
  devicesReport?: ISortableReport[];
  contentPieChart?: IPieChartData;
  contentAccessedReport?: IContentReport;
  facilityToolTips?: TooltipInterfaces.IDrillDownChart[];
}

export interface IDeviceDashboard {
  deviceSummary: SummaryInterfaces.ISummaryDevicesReport;
  firstYear: number;
  products: IHasProducts;
  drillDownChart?: IChartData;
  contentPieChart?: IPieChartData;
  contentAccessedReport?: IContentReport;
}

export interface IResidentDashboard {
  resident: Resident;
  firstYear: number;
  products?: IHasProducts;
  drillDownChart?: IChartData;
  contentPieChart?: IPieChartData;
  contentAccessedReport?: IContentReport;
}

export interface IRegionsDashboard {
  regionName: string;
  firstYear: number;
  products: IHasProducts;
  drillDownChart: IChartData;
  facilitiesReport: ISortableReport[];
  contentPieChart: IPieChartData;
  contentAccessedReport: IContentReport;
  regionToolTips: TooltipInterfaces.IDrillDownChart[];
}

export interface IDeviceReport {
  id: string;
  averageUsage: number;
  totalUsage: number;
  deviceName: string;
  deviceNameLink: string[];
  deviceType: string;
  serialNumber: string;
  serialNumberLink?: string[];
}

const REPORT_TYPES = {
  DEVICE: 'device',
  RESIDENT: 'resident',
  COMMUNITY: 'community',
  REGION: 'region',
};

@Injectable()
export class DashboardService {
  accountDashboards: IDashboardSummary[];
  deviceDashboards: IDashboardSummary[];
  facilityDashboards: IDashboardSummary[];
  regionsDashboards: IDashboardSummary[];
  residentDashboards: IDashboardSummary[];
  account: Account;
  accountFacilities: Facility[];
  device: Device;
  facility: Facility;
  region: Region;
  resident: Resident;
  currentDashboard: IDashboardSummary | undefined;
  deviceTypes: IDeviceTypes;
  constructor(
    private accountService: AccountService,
    private dashboardApiService: DashboardApiService,
    private deviceService: DeviceService,
    private deviceTypeService: DeviceTypesService,
    private facilityService: FacilityService,
    private regionService: RegionsService,
    private residentService: ResidentService
  ) {}

  static readonly emptyDashboardSummary: IDashboardSummary = {
    created_date: '',
    account_id: '',
    device_stays: [],
    device_update_date: '',
    doc_type: '',
    resident_stays: [],
    resident_update_date: '',
    modified_date: '',
    product: '',
    year: '',
    analytics_start_date: '',
    year_view: { drill_down_chart: [] },
    month_view: {},
  };

  static readonly emptyFacilityDashboard: IFacilityDashboard = {
    accountName: '',
    facilityName: '',
    firstYear: moment().year(),
    products: {
      hasApolloData: false,
      hasEngageData: false,
      hasFocusData: false,
      hasRehabData: false,
    },
    drillDownChart: { data: [] },
    residentCount: 0,
    residentsReport: [],
    deviceCount: 0,
    devicesReport: [],
    contentPieChart: { data: [] },
    contentAccessedReport: { data: [] },
    facilityToolTips: [],
  };

  getAccountDashboard(
    accountId: string,
    selectedRange: ISelectedRange
  ): Observable<IAccountDashboard> {
    const accountChanged = !this.account || this.account._id !== accountId;
    return forkJoin({
      account: accountChanged
        ? this.accountService.getAccount(accountId)
        : of(this.account),
      facilities: accountChanged
        ? this.facilityService.getAccountFacilities(accountId)
        : of(this.accountFacilities),
      dashboards:
        !this.accountDashboards || accountChanged
          ? this.dashboardApiService.getAccountDashboards(accountId)
          : of(this.accountDashboards),
    }).pipe(
      mergeMap(({ account, facilities, dashboards }) => {
        this.accountDashboards = dashboards;
        this.account = account;
        this.accountFacilities = facilities || [];
        this.currentDashboard = this.getCurrentDashboards(
          dashboards,
          selectedRange
        );

        this.currentDashboard =
          this.currentDashboard || DashboardService.emptyDashboardSummary;

        let viewDocs = this.currentDashboard?.year_view;
        if (selectedRange.for === SELECTED_RANGE_FOR_NAMES.MONTH) {
          viewDocs = this.currentDashboard?.month_view[
            (selectedRange.date.month() + 1).toString().padStart(2, '0')
          ];
        }
        const accountName = account.profile.account_name;
        let regionsReport = [];
        const regionsReportSummary: ISummaryRegionsReport[] = viewDocs
          ? viewDocs.regions_report
          : [];
        regionsReport = regionsReportSummary
          ? regionsReportSummary
              .map(
                (rr: ISummaryRegionsReport): ISortableReport => {
                  const { startDate, endDate } = this.getTimeframe(
                    selectedRange
                  );
                  // Filter down to facilities with region_assignments within range of the view
                  const regionFacilities = this.accountFacilities.filter(
                    af =>
                      af.profile &&
                      af.profile.region_assignment &&
                      af.profile.region_assignment.find(ra => {
                        // We don't care about the time; a partial day counts as a full day
                        const start = moment(startDate).utc().format();
                        const end = moment(endDate).utc().format();

                        const regionFound =
                          ra.region_id === rr.region_id &&
                          ra.start_date < end &&
                          (!ra.end_date || ra.end_date > start);
                        return regionFound;
                      })
                  );
                  const regionFacilityIds = regionFacilities.map(rf => rf._id);
                  const deviceCount = this.getDeviceCount(
                    this.currentDashboard.device_stays,
                    selectedRange,
                    regionFacilityIds
                  );
                  const deviceStaySum = this.getDeviceStaySum(
                    this.currentDashboard.device_stays,
                    selectedRange,
                    regionFacilityIds
                  );
                  const report: ISortableReport = {
                    id: rr.region_id,
                    name: rr.region_name,
                    nameLink: [
                      '/account',
                      account._id,
                      'region',
                      rr.region_id,
                      'dashboard',
                    ],
                    residentCount: this.getResidentCount(
                      this.currentDashboard.resident_stays,
                      selectedRange,
                      regionFacilityIds
                    ),
                    residentsWithUsageCount: rr.residents_with_usage,
                    deviceCount: deviceCount,
                    devicesWithUsageCount: rr.devices_with_usage,
                    averageUsage: this.calculateDeviceOrResidentAverageUsage(
                      rr.total_ms,
                      rr.average_hours,
                      deviceStaySum,
                      REPORT_TYPES.REGION
                    ),
                  };
                  return report;
                }
              )
              .sort((a, b) => b.averageUsage - a.averageUsage)
          : [];

        const communitiesReport: ISummaryCommunitiesReport[] = viewDocs
          ? viewDocs.communities_report
          : [];
        const facilitiesReport = communitiesReport
          ? communitiesReport
              .map(
                (fr: ISummaryCommunitiesReport): ISortableReport => {
                  const deviceCount = this.getDeviceCount(
                    this.currentDashboard.device_stays,
                    selectedRange,
                    [fr.facility_id]
                  );
                  const deviceStaySum = this.getDeviceStaySum(
                    this.currentDashboard.device_stays,
                    selectedRange,
                    [fr.facility_id]
                  );
                  const report: ISortableReport = {
                    id: fr.facility_id,
                    name: fr.facility_name,
                    nameLink: [
                      '/account',
                      account._id,
                      'facility',
                      fr.facility_id,
                      'dashboard',
                    ],
                    residentCount: this.getResidentCount(
                      this.currentDashboard.resident_stays,
                      selectedRange,
                      [fr.facility_id]
                    ),
                    residentsWithUsageCount: fr.residents_with_usage,
                    deviceCount: deviceCount,
                    devicesWithUsageCount: fr.devices_with_usage,
                    averageUsage: this.calculateDeviceOrResidentAverageUsage(
                      fr.total_ms,
                      fr.average_hours,
                      deviceStaySum,
                      REPORT_TYPES.COMMUNITY
                    ),
                  };
                  return report;
                }
              )
              .sort((a, b) => b.averageUsage - a.averageUsage)
          : [];

        const drillDownChart: IChartData = this.getDeviceDrillDownChart(
          viewDocs ? viewDocs.drill_down_chart : [],
          this.currentDashboard ? this.currentDashboard.device_stays : [],
          selectedRange
        );
        const accountToolTips = this.getDrillDownToolTips(
          viewDocs ? viewDocs.drill_down_chart : [],
          selectedRange,
          this.currentDashboard ? this.currentDashboard.device_stays : []
        );
        const contentPieChart: IPieChartData = this.getPieChart(
          viewDocs ? viewDocs.display_type_accessed_chart : []
        );
        const contentAccessedReport: IContentReport = {
          data: viewDocs ? viewDocs.content_accessed_report : [],
        };
        return of({
          accountName,
          firstYear: +dashboards.reduce(
            (prevYear, dashboard) =>
              prevYear < dashboard ? prevYear : dashboard.year,
            _.get(dashboards, '[0].year', moment().year().toString())
          ),
          products: {
            hasApolloData: false,
            hasEngageData: !!this.accountDashboards.find(
              d => d.product === 'engage'
            ),
            hasFocusData: !!this.accountDashboards.find(
              d => d.product === 'focus'
            ),
            hasRehabData: false,
          },
          drillDownChart,
          regionsReport,
          facilitiesReport,
          contentPieChart: contentPieChart,
          contentAccessedReport: contentAccessedReport,
          accountToolTips,
        });
      })
    );
  }

  getFacilityDashboard(
    accountId: string,
    facilityId: string,
    selectedRange: ISelectedRange
  ): Observable<IFacilityDashboard> {
    const accountChanged = !this.account || this.account._id !== accountId;
    const facilityChanged = !this.facility || this.facility._id !== facilityId;
    return forkJoin({
      account: accountChanged
        ? this.accountService.getAccount(accountId)
        : of(this.account),
      facility: facilityChanged
        ? this.facilityService.getFacility(facilityId)
        : of(this.facility),
      dashboards:
        !this.facilityDashboards || facilityChanged
          ? this.dashboardApiService.getCommunityDashboards(facilityId)
          : of(this.facilityDashboards),
    }).pipe(
      mergeMap(({ account, facility, dashboards }) => {
        this.facilityDashboards = dashboards;
        this.account = account;
        this.facility = facility;
        const accountName = account.profile.account_name;
        const facilityName = facility.profile.name;
        const firstYear = +dashboards.reduce(
          (prevYear, dashboard) =>
            prevYear < dashboard ? prevYear : dashboard.year,
          _.get(dashboards, '[0].year', moment().year().toString())
        );
        const products = {
          hasApolloData: false,
          hasEngageData: !!this.facilityDashboards.find(
            d => d.product === 'engage'
          ),
          hasFocusData: !!this.facilityDashboards.find(
            d => d.product === 'focus'
          ),
          hasRehabData: false,
        };

        // Try to select the current dashboard and view
        this.currentDashboard = this.getCurrentDashboards(
          dashboards,
          selectedRange
        );

        let viewDocs = this.currentDashboard?.year_view;
        if (selectedRange.for === SELECTED_RANGE_FOR_NAMES.MONTH) {
          viewDocs = this.currentDashboard?.month_view[
            (selectedRange.date.month() + 1).toString().padStart(2, '0')
          ];
        }

        // If either of these is non-existant, we can return now since everything else will pretty much fail
        if (!this.currentDashboard || !viewDocs) {
          return of({
            accountName,
            facilityName,
            firstYear,
            products,
          });
        }

        const drillDownChart = this.getDeviceDrillDownChart(
          viewDocs.drill_down_chart,
          this.currentDashboard.device_stays,
          selectedRange
        );
        const facilityToolTips = this.getDrillDownToolTips(
          viewDocs.drill_down_chart,
          selectedRange,
          this.currentDashboard.device_stays
        );
        const contentPieChart = this.getPieChart(
          viewDocs.display_type_accessed_chart || []
        );
        const contentAccessedReport: IContentReport = {
          data: viewDocs.content_accessed_report || [],
        };

        const devicesReport: IDeviceReport[] =
          viewDocs.devices_report
            ?.map(device => {
              const deviceStaySum = this.getDeviceStaySum(
                this.currentDashboard?.device_stays.filter(
                  d => d.device_id === device.device_id
                ) || [],
                selectedRange
              );
              return {
                id: device.device_id,
                averageUsage: this.calculateDeviceOrResidentAverageUsage(
                  device.total_ms,
                  device.average_hours,
                  deviceStaySum,
                  REPORT_TYPES.DEVICE
                ),
                totalUsage: device.total_ms / 60 / 60 / 1000,
                deviceName: device.device_name,
                deviceNameLink: [
                  '/account',
                  accountId,
                  'facility',
                  facilityId,
                  'devices',
                  device.device_id,
                  'dashboard',
                ],
                deviceType: device.device_type,
                serialNumber: device.serial_number,
                serialNumberLink:
                  device.serial_number.includes('Other') ||
                  (!!device.is_archived ? device.is_archived : false)
                    ? undefined
                    : [
                        '/account',
                        accountId,
                        'facility',
                        facilityId,
                        'devices',
                        device.device_id,
                      ],
              };
            })
            .sort((a, b) => b.averageUsage - a.averageUsage) || [];

        const deviceCount = devicesReport.length || 0;

        const residentsReportRaw: ISummaryResidentsReport[] | undefined =
          viewDocs?.residents_report;
        const residentsReport = residentsReportRaw
          ?.map(
            (fr: ISummaryResidentsReport): ISortableReport => {
              const residentStaySum = this.getResidentStaySum(
                this.currentDashboard?.resident_stays || [],
                selectedRange,
                fr.resident_id
              );

              let statusStyle = '';

              if (fr.resident_id) {
                if (fr.status === RESIDENT_STATUS_ACTIVE) {
                  statusStyle = 'label label-success';
                } else {
                  statusStyle = 'label label-warning';
                }
              }

              const report: ISortableReport = {
                id: fr.resident_id,
                name: fr.resident_name,
                status: fr.status,
                statusStyle: statusStyle,
                roomNumber: fr.room_number,
                nameLink: fr.resident_id
                  ? [
                      '/account',
                      account._id,
                      'facility',
                      facilityId,
                      'resident',
                      fr.resident_id,
                      'dashboard',
                    ]
                  : null,
                averageUsage: this.calculateDeviceOrResidentAverageUsage(
                  fr.total_ms,
                  fr.average_hours,
                  residentStaySum,
                  REPORT_TYPES.RESIDENT
                ),
              };
              return report;
            }
          )
          .sort((a, b) => b.averageUsage - a.averageUsage);

        const residentCount =
          residentsReport?.filter(resident => resident.id).length || 0;

        return of({
          accountName,
          facilityName,
          firstYear,
          products,
          drillDownChart,
          residentCount,
          residentsReport,
          deviceCount,
          devicesReport,
          contentPieChart,
          contentAccessedReport,
          facilityToolTips,
        });
      })
    );
  }

  getResidentDashboard(
    residentId: string,
    selectedRange: ISelectedRange
  ): Observable<IResidentDashboard> {
    const residentChanged = !this.resident || this.resident._id !== residentId;
    return forkJoin({
      resident: residentChanged
        ? this.residentService.getResident(residentId)
        : of(this.resident),
      dashboards:
        !this.residentDashboards || residentChanged
          ? this.dashboardApiService.getResidentDashboards(residentId)
          : of(this.residentDashboards),
    }).pipe(
      mergeMap(({ resident, dashboards }) => {
        this.residentDashboards = dashboards;
        this.resident = resident;
        const firstYear = +dashboards.reduce(
          (prevYear, dashboard) =>
            prevYear < dashboard ? prevYear : dashboard.year,
          _.get(dashboards, '[0].year', moment().year().toString())
        );
        const products = {
          hasApolloData: false,
          hasEngageData: !!this.residentDashboards.find(
            d => d.product === 'engage'
          ),
          hasFocusData: !!this.residentDashboards.find(
            d => d.product === 'focus'
          ),
          hasRehabData: false,
        };

        // Try to select the current dashboard and view
        this.currentDashboard = this.getCurrentDashboards(
          dashboards,
          selectedRange
        );

        let viewDocs = this.currentDashboard?.year_view;
        if (selectedRange.for === SELECTED_RANGE_FOR_NAMES.MONTH) {
          viewDocs = this.currentDashboard?.month_view[
            (selectedRange.date.month() + 1).toString().padStart(2, '0')
          ];
        }

        // If either of these is non-existant, we can return now since everything else will pretty much fail
        if (!this.currentDashboard || !viewDocs) {
          return of({
            resident,
            firstYear,
            products,
          });
        }

        const drillDownChart: IChartData = this.getResidentDrillDownChart(
          viewDocs.drill_down_chart,
          this.currentDashboard.resident_stays,
          selectedRange,
          residentId
        );
        const contentPieChart: IPieChartData = this.getPieChart(
          viewDocs.display_type_accessed_chart || []
        );
        const contentAccessedReport: IContentReport = {
          data: viewDocs.content_accessed_report || [],
        };

        return of({
          resident,
          firstYear,
          products,
          drillDownChart,
          contentPieChart: contentPieChart,
          contentAccessedReport: contentAccessedReport,
        });
      })
    );
  }

  getDeviceDashboard(
    deviceId: string,
    selectedRange: ISelectedRange
  ): Observable<IDeviceDashboard> {
    const deviceChanged = !this.device || this.device._id !== deviceId;
    return forkJoin({
      device: deviceChanged
        ? this.deviceService.getDevice(deviceId)
        : of(this.device),
      dashboards:
        !this.deviceDashboards || deviceChanged
          ? this.dashboardApiService.getDeviceDashboards(deviceId)
          : of(this.deviceDashboards),
      deviceTypes: this.deviceTypes
        ? of(this.deviceTypes)
        : this.deviceTypeService.getDeviceTypes(),
    }).pipe(
      mergeMap(({ device, dashboards, deviceTypes }) => {
        this.deviceDashboards = dashboards;
        this.device = device;
        this.deviceTypes = deviceTypes;
        const deviceSummary: SummaryInterfaces.ISummaryDevicesReport = <
          SummaryInterfaces.ISummaryDevicesReport
        >{};
        deviceSummary.device_id = device._id || '';
        deviceSummary.device_name = device.nickname;
        const deviceType = this.deviceTypes.types.find(
          type => type.type_id === device.device_type
        );
        deviceSummary.device_type = deviceType ? deviceType.type_name : '';
        deviceSummary.is_archived =
          device.doc_type === DocTypeConstants.TYPES.ACCOUNT.ARCHIVED_DEVICE;
        deviceSummary.product = device.product;
        deviceSummary.serial_number = device.serial_number;

        const firstYear = +dashboards.reduce(
          (prevYear, dashboard) =>
            prevYear < dashboard ? prevYear : dashboard.year,
          _.get(dashboards, '[0].year', moment().year().toString())
        );
        const products = {
          hasApolloData: false,
          hasEngageData: deviceSummary.product === 'engage',
          hasFocusData: deviceSummary.product === 'focus',
          hasRehabData: false,
        };

        // Try to select the current dashboard and view
        this.currentDashboard = this.getCurrentDashboards(
          dashboards,
          selectedRange
        );

        let viewDocs = this.currentDashboard?.year_view;
        if (selectedRange.for === SELECTED_RANGE_FOR_NAMES.MONTH) {
          viewDocs = this.currentDashboard?.month_view[
            (selectedRange.date.month() + 1).toString().padStart(2, '0')
          ];
        }

        // If either of these is non-existant, we can return now since everything else will pretty much fail
        if (!this.currentDashboard || !viewDocs) {
          return of({
            deviceSummary,
            firstYear,
            products,
          });
        }

        const drillDownChart: IChartData = this.getDeviceDrillDownChart(
          viewDocs.drill_down_chart,
          this.currentDashboard.device_stays,
          selectedRange
        );
        const contentPieChart: IPieChartData = this.getPieChart(
          viewDocs.display_type_accessed_chart || []
        );
        const contentAccessedReport: IContentReport = {
          data: viewDocs.content_accessed_report || [],
        };

        if (viewDocs.devices_report && viewDocs.devices_report.length) {
          const devicesReport = viewDocs.devices_report[0];
          deviceSummary.average_hours = devicesReport.average_hours;
          deviceSummary.total_ms = devicesReport.total_ms;
        }

        return of({
          deviceSummary,
          firstYear,
          drillDownChart,
          contentPieChart: contentPieChart,
          contentAccessedReport: contentAccessedReport,
          products: {
            hasApolloData: false,
            hasEngageData: deviceSummary.product === 'engage',
            hasFocusData: deviceSummary.product === 'focus',
            hasRehabData: false,
          },
        });
      })
    );
  }

  getRegionsDashboard(
    accountId: string,
    regionId: string,
    selectedRange: ISelectedRange
  ): Observable<IRegionsDashboard> {
    const accountChanged = !this.account || this.account._id !== accountId;
    const regionChanged = !this.region || this.region.region_id !== regionId;
    return forkJoin({
      region: regionChanged
        ? this.regionService.getRegion(accountId, regionId)
        : of(this.region),
      account: accountChanged
        ? this.accountService.getAccount(accountId)
        : of(this.account),
      dashboards:
        !this.regionsDashboards || regionChanged
          ? this.dashboardApiService.getRegionsDashboards(accountId, regionId)
          : of(this.regionsDashboards),
    }).pipe(
      mergeMap(({ region, account, dashboards }) => {
        this.regionsDashboards = dashboards;
        this.account = account;
        this.region = region;
        this.currentDashboard = this.getCurrentDashboards(
          dashboards,
          selectedRange
        );

        if (!this.currentDashboard || !this.currentDashboard.year_view) {
          const regionDashboard = <IRegionsDashboard>{};
          regionDashboard.regionName = region.region_name;
          return of(regionDashboard);
        }

        let viewDocs = this.currentDashboard?.year_view;

        if (selectedRange.for === SELECTED_RANGE_FOR_NAMES.MONTH) {
          viewDocs = this.currentDashboard?.month_view[
            (selectedRange.date.month() + 1).toString().padStart(2, '0')
          ];
        }
        const regionName = region.region_name;

        const communitiesReport: ISummaryCommunitiesReport[] =
          viewDocs.communities_report;
        const facilitiesReport = communitiesReport
          ? communitiesReport
              .map(
                (fr: ISummaryCommunitiesReport): ISortableReport => {
                  const deviceCount = this.getDeviceCount(
                    this.currentDashboard.device_stays,
                    selectedRange,
                    [fr.facility_id]
                  );
                  const deviceStaySum = this.getDeviceStaySum(
                    this.currentDashboard.device_stays,
                    selectedRange,
                    [fr.facility_id]
                  );
                  const report: ISortableReport = {
                    id: fr.facility_id,
                    name: fr.facility_name,
                    nameLink: [
                      '/account',
                      account._id,
                      'facility',
                      fr.facility_id,
                      'dashboard',
                    ],
                    residentCount: this.getResidentCount(
                      this.currentDashboard.resident_stays,
                      selectedRange,
                      [fr.facility_id]
                    ),
                    residentsWithUsageCount: fr.residents_with_usage,
                    deviceCount: deviceCount,
                    devicesWithUsageCount: fr.devices_with_usage,
                    averageUsage: this.calculateDeviceOrResidentAverageUsage(
                      fr.total_ms,
                      fr.average_hours,
                      deviceStaySum,
                      REPORT_TYPES.COMMUNITY
                    ),
                  };
                  return report;
                }
              )
              .sort((a, b) => b.averageUsage - a.averageUsage)
          : [];

        const drillDownChart: IChartData = this.getDeviceDrillDownChart(
          viewDocs.drill_down_chart,
          this.currentDashboard.device_stays,
          selectedRange
        );
        const regionToolTips = this.getDrillDownToolTips(
          viewDocs.drill_down_chart,
          selectedRange,
          this.currentDashboard.device_stays
        );
        const contentPieChart: IPieChartData = this.getPieChart(
          viewDocs.display_type_accessed_chart
        );
        const contentAccessedReport: IContentReport = {
          data: viewDocs.content_accessed_report,
        };
        return of({
          regionName,
          firstYear: +dashboards.reduce(
            (prevYear, dashboard) =>
              prevYear < dashboard ? prevYear : dashboard.year,
            _.get(dashboards, '[0].year', moment().year().toString())
          ),
          products: {
            hasApolloData: false,
            hasEngageData: !!this.regionsDashboards.find(
              d => d.product === 'engage'
            ),
            hasFocusData: !!this.regionsDashboards.find(
              d => d.product === 'focus'
            ),
            hasRehabData: false,
          },
          drillDownChart,
          facilitiesReport,
          contentPieChart: contentPieChart,
          contentAccessedReport: contentAccessedReport,
          regionToolTips,
        });
      })
    );
  }

  getCurrentDashboards(
    dashboards: IDashboardSummary[],
    selectedRange: ISelectedRange
  ): IDashboardSummary | undefined {
    const dashboardsByYear = dashboards.filter(
      doc => +doc.year === selectedRange.date.year()
    );
    if (dashboardsByYear.length === 1) {
      selectedRange.productFilter = dashboardsByYear[0].product;
      return dashboardsByYear[0];
    }

    return dashboardsByYear.find(
      doc => doc.product === (selectedRange.productFilter || 'engage')
    );
  }

  getDeviceDrillDownChart(
    summaryDrillDownChart: ISummaryDrillDownChart[],
    deviceStays: IDeviceStays[],
    rangeSelected: ISelectedRange
  ): IChartData {
    // Calculate average hours here with new summary schema and device stays
    return {
      data: summaryDrillDownChart.map(chartItem => {
        const calculate = this.shouldCalculateAverage(
          chartItem.label,
          rangeSelected
        );
        let deviceStaySum = null;
        if (calculate) {
          deviceStaySum = this.getDeviceStaySum(
            deviceStays,
            rangeSelected,
            null,
            chartItem.label
          );
        }
        return [
          chartItem.label,
          rangeSelected.useAverages
            ? this.calculateAverageUsageDrillDown(chartItem, deviceStaySum)
            : chartItem.total_hours,
        ];
      }),
    };
  }

  getDeviceStaySum(
    deviceStays: IDeviceStays[],
    rangeSelected?: ISelectedRange,
    facilityIds?: string[],
    dateLabel?: string
  ): number {
    deviceStays =
      deviceStays && facilityIds
        ? deviceStays.filter(stay => facilityIds.includes(stay.facility_id))
        : deviceStays;
    // Calculate average hours here with new summary schema and device stays
    let deviceStaySum = 0;
    for (const deviceStay of deviceStays) {
      const sum = this.getTotalStays(deviceStay, rangeSelected, dateLabel);
      deviceStaySum += sum;
    }
    return deviceStaySum;
  }

  getResidentDrillDownChart(
    summaryDrillDownChart: ISummaryDrillDownChart[],
    residentStays: IResidentStays[],
    rangeSelected: ISelectedRange,
    residentId?: string
  ): IChartData {
    return {
      data: summaryDrillDownChart.map(chartItem => {
        const calculate = this.shouldCalculateAverage(
          chartItem.label,
          rangeSelected
        );
        let residentStaySum = null;
        if (calculate) {
          // Calculate average hours here with new summary schema and device stays
          residentStaySum = this.getResidentStaySum(
            residentStays,
            rangeSelected,
            residentId,
            null,
            chartItem.label
          );
        }

        return [
          chartItem.label,
          this.calculateAverageUsageDrillDown(chartItem, residentStaySum),
        ];
      }),
    };
  }

  getTimeframe(
    rangeSelected: ISelectedRange
  ): { startDate: Date; endDate: Date } {
    let startDate = new Date(rangeSelected.date.year(), 0, 1, 0, 0, 0, 0);
    let endDate = new Date(rangeSelected.date.year() + 1, 0, 1, 0, 0, 0, 0);
    switch (rangeSelected.for) {
      case SELECTED_RANGE_FOR_NAMES.MONTH:
        startDate = new Date(
          rangeSelected.date.year(),
          rangeSelected.date.month(),
          1,
          0,
          0,
          0,
          0
        );
        endDate = new Date(startDate.getTime());
        endDate.setMonth(endDate.getMonth() + 1);
        break;
      case SELECTED_RANGE_FOR_NAMES.YEAR:
      default:
        break;
    }
    return { startDate, endDate };
  }

  getResidentCount(
    stays: IResidentStays[],
    rangeSelected: ISelectedRange,
    facilityIds?: string[]
  ): number {
    let count = 0;
    stays =
      stays && facilityIds
        ? stays.filter(stay => facilityIds.includes(stay.facility_id))
        : stays;
    const { startDate, endDate } = this.getTimeframe(rangeSelected);
    for (const resident of stays) {
      for (const stay of resident.stays) {
        // we don't care about the time; a partial day counts as a full day
        const stayStart = new Date(stay.start_date);
        stayStart.setHours(0, 0, 0, 0);
        let stayEnd = null;
        if (stay.end_date) {
          stayEnd = new Date(stay.end_date);
          stayEnd.setHours(0, 0, 0, 0);
        }
        if (!stayEnd || (stayStart < endDate && stayEnd >= startDate)) {
          count++;
          break;
        }
      }
    }
    return count;
  }

  getDeviceCount(
    stays: IDeviceStays[],
    rangeSelected: ISelectedRange,
    facilityIds?: string[]
  ): number {
    let count = 0;
    stays =
      stays && facilityIds
        ? stays.filter(stay => facilityIds.includes(stay.facility_id))
        : stays;
    const { startDate, endDate } = this.getTimeframe(rangeSelected);
    for (const stay of stays) {
      // we don't care about the time; a partial day counts as a full day
      const stayStart = new Date(stay.start_date);
      stayStart.setHours(0, 0, 0, 0);
      const stayEnd = new Date(stay.end_date);
      stayEnd.setHours(0, 0, 0, 0);
      if (stayStart < endDate && (!stay.end_date || stayEnd >= startDate)) {
        count++;
      }
    }
    return count;
  }

  getResidentStaySum(
    residentStays: IResidentStays[],
    rangeSelected: ISelectedRange,
    residentId: string,
    facilityId?: string,
    dateLabel?: string
  ): number {
    residentStays =
      residentStays && facilityId
        ? residentStays.filter(stay => stay.facility_id === facilityId)
        : residentStays;

    let residentStaySum = 0;

    const residentStay = residentId
      ? residentStays.find(res => res.resident_id === residentId)
      : null;
    if (residentStay) {
      for (const stay of residentStay.stays) {
        residentStaySum += this.getTotalStays(stay, rangeSelected, dateLabel);
      }
    } else {
      if (residentStays && residentStays.length) {
        for (const resStay of residentStays) {
          for (const stay of resStay.stays) {
            residentStaySum += this.getTotalStays(
              stay,
              rangeSelected,
              dateLabel
            );
          }
        }
      }
    }

    return residentStaySum;
  }

  getTotalStays(
    stay: { start_date: string; end_date: string },
    rangeSelected: ISelectedRange,
    dateLabel: string
  ): number {
    if (!stay.start_date) {
      return 0;
    }
    const dateRange = this.getDateRange(
      stay.start_date,
      stay.end_date,
      rangeSelected,
      dateLabel
    );
    const diffDates =
      new Date(dateRange.endDate).getTime() -
      new Date(dateRange.startDate).getTime();
    return diffDates / (1000 * 3600 * 24);
  }

  getDrillDownToolTips(
    summaryDrillDownChart: ISummaryDrillDownChart[],
    rangeSelected: ISelectedRange,
    deviceStays?: IDeviceStays[],
    residentStays?: IResidentStays[],
    residentId?: string
  ): TooltipInterfaces.IDrillDownChart[] {
    return summaryDrillDownChart.map(chartItem => {
      const calculate = this.shouldCalculateAverage(
        chartItem.label,
        rangeSelected
      );
      let staySum = null;
      if (calculate) {
        // Calculate average hours and active device count here with new summary schema
        if (deviceStays) {
          staySum = this.getDeviceStaySum(
            deviceStays,
            rangeSelected,
            null,
            chartItem.label
          );
        } else {
          staySum = this.getResidentStaySum(
            residentStays,
            rangeSelected,
            residentId,
            null,
            chartItem.label
          );
        }
      }

      return {
        label: chartItem.label,
        usage: this.calculateAverageUsageDrillDown(chartItem, staySum),
        totalDevices: chartItem.active_device_count,
        activeDevices: chartItem.with_usage_count,
      };
    });
  }

  getPieChart(
    displayTypeAccessedChart: ISummaryDisplayTypeAccessedCharts[]
  ): IPieChartData {
    return {
      data: displayTypeAccessedChart?.map(content => {
        return { label: content.display_type, data: content.accessed_count };
      }),
    };
  }

  calculateAverageUsageDrillDown(
    item: SummaryInterfaces.ISummaryDrillDownChart,
    sum: number
  ): number {
    if (sum > 0 && item.total_ms > 0) {
      return item.total_ms / (sum * 60 * 60 * 1000);
    }
    return item.average_hours || 0;
  }

  calculateDeviceOrResidentAverageUsage(
    total_ms: number,
    average_hours: number,
    sum: number,
    type: string
  ): number {
    let daysSinceUpdate = 0;
    if (
      type === REPORT_TYPES.DEVICE &&
      this.currentDashboard.device_update_date
    ) {
      daysSinceUpdate =
        (new Date().getTime() -
          new Date(this.currentDashboard.device_update_date).getTime()) /
        (1000 * 3600 * 24);
    } else if (
      type === REPORT_TYPES.RESIDENT &&
      this.currentDashboard.resident_update_date
    ) {
      daysSinceUpdate =
        (new Date().getTime() -
          new Date(this.currentDashboard.resident_update_date).getTime()) /
        (1000 * 3600 * 24);
    }

    if (daysSinceUpdate > 1 && sum > 0 && total_ms > 0) {
      return total_ms / (sum * 60 * 60 * 1000);
    }
    return average_hours;
  }

  getDateRange(
    stayStartDate: string,
    stayEndDate: string,
    rangeSelected: ISelectedRange,
    dateLabel: string
  ) {
    const range = this.getTimeframe(rangeSelected);
    const rangeStartDate = moment(range.startDate).utc().format();
    const rangeEndDate = moment(range.endDate).utc().format();

    let startDate =
      stayStartDate > rangeStartDate ? stayStartDate : rangeStartDate;
    let endDate = stayEndDate || rangeEndDate;
    if (this.shouldCalculateAverage(dateLabel, rangeSelected)) {
      const currentDate = new Date();
      const formattedEndDate = moment(currentDate).utc().format();
      // Year view
      if (!dateLabel.includes('/')) {
        startDate = `${currentDate.getFullYear()}-${
          currentDate.getMonth() + 1
        }-01T00:00:00.000Z`;
        endDate = this.getCalcEndDate(startDate, stayEndDate, formattedEndDate);
        // Month view
      } else {
        startDate = `${currentDate.getFullYear()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getDate()}T00:00:00.000Z`;
        endDate = this.getCalcEndDate(startDate, stayEndDate, formattedEndDate);
      }
    }
    return { startDate: startDate, endDate: endDate };
  }

  // Current month and date should be calculated on this end. All others should default to the summary average
  shouldCalculateAverage(dateLabel: string, rangeSelected: ISelectedRange) {
    const currentDateObj = new Date();
    currentDateObj.setMonth(currentDateObj.getMonth() + 1);
    const currentDate =
      rangeSelected.for === SELECTED_RANGE_FOR_NAMES.YEAR
        ? new Date().toLocaleString('default', { month: 'long' })
        : _.padStart('' + currentDateObj.getMonth(), 2, '0') +
          '/' +
          _.padStart('' + currentDateObj.getDate(), 2, '0');
    return currentDate && currentDate === dateLabel;
  }

  getCalcEndDate(
    startDate: string,
    stayEndDate: string,
    formattedEndDate: string
  ) {
    // Use the stayEndDate if it is between the current date and the beginning of the month
    // Use the currentDate as the end date if there is no stayEndDate
    // Use the startDate if the stayEndDate falls outside the current month
    return stayEndDate && stayEndDate < formattedEndDate
      ? stayEndDate > startDate
        ? stayEndDate
        : startDate
      : formattedEndDate;
  }
}
