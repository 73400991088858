<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
  <div class="loading-indicator-container-top" *ngIf="loading">
    <i class="fas fa-circle-notch fa-spin"></i> Loading
  </div>

  <!-- START navbar header-->
  <div class="navbar-header">
    <a class="navbar-brand" routerLink="">
      <div class="brand-logo"></div>
    </a>
  </div>
  <!-- END navbar header-->
  <!-- START Nav wrapper-->
  <div class="nav-wrapper">
    <!-- START Right Navbar-->
    <ul class="nav navbar-nav navbar-right">
      <li>
        <a title="Logout" (click)="logout()">
          <i class="fas fa-sign-out-alt" style="margin-right: 5px"></i>Sign Out
        </a>
      </li>
      <li>
        <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
        <a
          class="visible-xs sidebar-toggle"
          (click)="settings.layout.asideToggled = !settings.layout.asideToggled"
        >
          <em class="fa fa-bars"></em>
        </a>
      </li>
    </ul>
    <!-- END Right Navbar-->
  </div>
  <!-- END Nav wrapper-->
</nav>
<div class="loading-indicator-container-bottom" *ngIf="loading"></div>
<!-- END Top Navbar-->
