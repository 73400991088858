<div class="panel panel-default outer-panel">
  <div class="panel-body">
    <div class="panel-body">
      <div class="row mb-lg">
        <div class="col-sm-6"></div>
        <div class="col-sm-6">
          <button
            class="btn btn-primary btn-outline pull-right"
            (click)="showAddEditPackageModal()"
          >
            Add Package
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default inner-panel">
            <div class="panel-body">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th
                      *ngFor="let column of columns"
                      (click)="changeSort(column)"
                      style="cursor: pointer;"
                    >
                      {{ column.title }}
                      <i
                        *ngIf="column.sort"
                        class="fa"
                        [ngClass]="{
                          'fa-chevron-down': column.sort === 'desc',
                          'fa-chevron-up': column.sort === 'asc'
                        }"
                      ></i>
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of packages">
                    <td *ngFor="let column of columns; let i = index">
                      {{ setCellValue(column.key, item[column.key], item) }}
                    </td>
                    <td class="button-pair-min-width">
                      <button
                        *ngIf="!isImmutableStandardPackage(item)"
                        class="btn btn-danger btn-outline pull-right ml-sm"
                        [disabled]="isEditDeleteDisabled()"
                        (click)="showDeletePackageModal(item)"
                      >
                        Delete
                      </button>
                      <button
                        *ngIf="!isImmutableStandardPackage(item)"
                        [disabled]="isEditDeleteDisabled()"
                        class="btn btn-primary btn-outline pull-right"
                        (click)="showAddEditPackageModal(item)"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Add/Edit Package Modal -->
<div
  class="modal fade"
  bsModal
  #addEditPackageModal="bs-modal"
  [config]="{
    animated: true,
    keyboard: false,
    backdrop: false,
    ignoreBackdropClick: false
  }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addEditPackageModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          {{ modalPackage && modalPackage._id ? 'Edit a' : 'Add a new' }} package
        </h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="closeAddEditPackageModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="form">
        <form
          [formGroup]="form"
          class="form-validate"
          role="form"
          name="packageForm"
          novalidate=""
        >
          <div class="form-group">
            <label class="control-label">Name</label>
            <input
              class="form-control"
              placeholder="Package name"
              type="text"
              formControlName="name"
              tabindex="0"
              aria-invalid="true"
            />
            <span class="text-danger" *ngIf="required(form.controls.name)"
              >This field is required</span
            >
            <span
              class="text-danger"
              *ngIf="
                !required(form.controls.name) && !modalPackageNameIsUnique()
              "
              >Package name must be unique</span
            >
          </div>

          <div class="form-group">
            <label class="control-label">Abbreviation</label>
            <input
              class="form-control"
              placeholder="Package name abbreviation"
              type="text"
              formControlName="abbreviation"
              tabindex="0"
              aria-invalid="true"
            />
            <span
              class="text-danger"
              *ngIf="required(form.controls.abbreviation)"
              >This field is required</span
            >
            <span
              class="text-danger"
              *ngIf="
                !required(form.controls.abbreviation) &&
                !modalPackageAbbreviationIsUnique()
              "
              >Package abbreviation must be unique</span
            >
          </div>

          <div class="form-group" *ngIf="isIn2lAdmin()">
            <label class="control-label">Account Mode</label>
            <div class="radio c-radio">
              <label>
                <input
                  type="radio"
                  formControlName="account_mode"
                  (change)="handleAccountModeChange()"
                  value="all"
                />
                <span class="fa fa-circle"></span>
                All Accounts
              </label>
            </div>
            <div class="radio c-radio">
              <label>
                <input
                  type="radio"
                  formControlName="account_mode"
                  (change)="handleAccountModeChange()"
                  value="select"
                />
                <span class="fa fa-circle"></span>
                Select Accounts
              </label>
            </div>

            <div
              id="#p-autocomplete-form-group"
              *ngIf="form.get('account_mode').value === 'select'"
              style="width: 100%;"
            >
              <p-autoComplete
                [(ngModel)]="selectedAccountsList"
                [ngModelOptions]="{ standalone: true }"
                (completeMethod)="filterAvailableAccounts($event)"
                [suggestions]="filteredAccounts"
                (onSelect)="handleAddAccount($event)"
                (onUnselect)="handleRemoveAccount($event)"
                [multiple]="true"
                field="text"
                [dropdown]="true"
                [style]="{ width: '100%' }"
                [inputStyle]="{ width: '100%' }"
                class="p-autocomplete"
                name="accountSelectDropdown"
                placeholder="No accounts selected"
              >
                <ng-template let-account pTemplate="item">
                  <div>
                    <div>{{ account.text }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>

          <div class="form-group">
            <label class="control-label">Notes</label>
            <textarea
              class="form-control"
              placeholder="Notes"
              formControlName="notes"
              rows="4"
              aria-multiline="true"
              tabindex="0"
              aria-invalid="false"
            ></textarea>
          </div>
        </form>
        <div class="row mt-lg">
          <div class="col-sm-12">
            <button
              type="button"
              class="pull-left btn"
              aria-label="Cancel"
              (click)="closeAddEditPackageModal()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="pull-right btn btn-primary"
              aria-label="Add or edit package"
              (click)="submitAddEditPackageModal()"
              [disabled]="submitDisabled()"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Delete LinkTo Modal -->
<div
  class="modal fade"
  bsModal
  #deletePackageModal="bs-modal"
  [config]="{
    animated: true,
    keyboard: false,
    backdrop: false,
    ignoreBackdropClick: true
  }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deletePackageModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          Delete Package
          {{ modalPackage && modalPackage.name ? modalPackage.name : '' }}
        </h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="closeDeleteModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mt-lg">
          <div class="col-sm-12">
            <button
              type="button"
              class="pull-left btn"
              aria-label="Cancel"
              (click)="closeDeleteModal()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="pull-right btn btn-primary"
              aria-label="Delete"
              (click)="submitDelete()"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
