import * as _ from 'lodash';

import { Component, Input, OnChanges } from '@angular/core';

interface ISortColumn {
  title: string;
  name: string;
  sort: string;
  sortType: string;
}

interface IContentItemReport {
  title: string;
  display_type: string;
  accessed_count: number;
}

@Component({
  selector: 'app-content-usage-report',
  styleUrls: ['./content-usage-report.component.scss'],
  templateUrl: './content-usage-report.component.html',
})
export class ContentUsageReportComponent implements OnChanges {
  @Input()
  reportData: IContentItemReport[] = [];
  @Input()
  uniqueItemsAccessedCount = -1;

  displayFullContentList = false;
  shortContentListSize = 10;
  contentTableColumns: ISortColumn[] = [
    {
      title: 'Content Title',
      name: 'title',
      sort: '',
      sortType: 'string',
    },
    {
      title: 'Content Type',
      name: 'display_type',
      sort: '',
      sortType: 'string',
    },
    {
      title: 'Times Accessed',
      name: 'accessed_count',
      sort: 'desc',
      sortType: 'number',
    },
  ];
  contentTableConfig = {
    sorting: { columns: this.contentTableColumns },
  };
  displayedContentList = [];

  constructor() {}

  ngOnChanges() {
    this.update();
  }

  update() {
    this.uniqueItemsAccessedCount = this.reportData?.length;

    // Default to false unless there are fewer than {{ this.shortContentListSize }} content
    this.displayFullContentList =
      this.reportData?.length <= this.shortContentListSize;
    this.changeContentTableSort(
      this.contentTableColumns.find(c => c.name === 'accessed_count'),
      true
    );
  }

  sortData(sortColumn?: ISortColumn) {
    // apply sorting
    if (sortColumn) {
      this.reportData?.sort((a, b) => {
        const val1 =
          sortColumn.sortType === 'string' && a[sortColumn.name]
            ? (a[sortColumn.name] || '').toLowerCase()
            : Number(a[sortColumn.name] || 0);
        const val2 =
          sortColumn.sortType === 'string' && b[sortColumn.name]
            ? (b[sortColumn.name] || '').toLowerCase()
            : Number(b[sortColumn.name] || 0);
        if (val1 > val2) {
          return sortColumn.sort === 'desc' ? -1 : 1;
        } else if (val1 < val2) {
          return sortColumn.sort === 'asc' ? -1 : 1;
        }
        return 0;
      });
    }
  }

  displayedList() {
    return this.displayFullContentList
      ? this.reportData
      : this.reportData?.slice(0, this.shortContentListSize);
  }

  toggleContentListSize() {
    this.displayFullContentList = !this.displayFullContentList;
  }

  changeContentTableSort(sortColumn: ISortColumn, reset?: boolean) {
    this.contentTableColumns.forEach(c => {
      // Reset sort to usageCount when the page changes
      if (reset && c.name === 'accessed_count') {
        c.sort = 'desc';
      } else if (c.name === sortColumn.name) {
        c.sort = c.sort === 'asc' ? 'desc' : 'asc';
      } else {
        c.sort = '';
      }
    });
    this.sortData(sortColumn);
  }

  verifyTitle(item: IContentItemReport) {
    return item.title || _.capitalize(item.display_type);
  }
}
