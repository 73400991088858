import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { JwtService } from '../authentication/jwt.service';
import { MicroserviceApiBaseService } from '../micro-api/microservice-api-base.service';
import { StateManager } from '../state/state-manager';

const CONTENT_VENDORS_URL =
  environment.contentApi.url + '/type/content-item/vendors';

const VENDOR_PREVIEW_URL =
  environment.contentApi.url + '/type/content-item/vendor-url';

interface IPreviewUrlResult {
  url: string;
}

@Injectable()
export class ContentVendorService extends MicroserviceApiBaseService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    stateManager: StateManager
  ) {
    super(http, jwtService, stateManager);
  }

  // The first subscriber triggers a network call.
  // All later subscribers get a cached value.
  getContentVendors = this.get<string[]>(CONTENT_VENDORS_URL).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  getPreviewURL(id: string): Observable<string> {
    return this.post<IPreviewUrlResult>(VENDOR_PREVIEW_URL, {
      contentId: id,
      data: {
        facilityId: 'sampleFacilityId',
      },
    }).pipe(map(res => res.url));
  }
}
