import { CustomValidators } from 'ng2-validation';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { UnsubscribeOnDestroy } from '../../../util/unsubscribe-on-destroy';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends UnsubscribeOnDestroy implements OnInit {
  valForm: FormGroup;
  userEmail: string;
  errorMessage: string;
  responseCode: number;

  loginInProgress = false;
  siteIsUnderMaintenance = false;

  constructor(
    private authenticationService: AuthenticationService,
    public fb: FormBuilder,
    private router: Router,
    public settings: SettingsService
  ) {
    super();
  }

  ngOnInit() {
    this.loginInProgress = false;

    this.valForm = this.fb.group({
      email: [
        '',
        Validators.compose([Validators.required, CustomValidators.email]),
      ],
      password: ['', Validators.required],
    });
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    this.errorMessage = '';
    this.loginInProgress = true;

    for (const c in this.valForm.controls) {
      if (this.valForm.controls.hasOwnProperty(c)) {
        this.valForm.controls[c].markAsTouched();
      }
    }

    if (this.valForm.valid) {
      this.subscriptionTracker.track = this.authenticationService
        .signIn(value)
        .subscribe(
          user => {
            if (!user) {
              this.errorMessage = 'Unauthorized';
              this.loginInProgress = false;

              return;
            }

            this.router.navigateByUrl('/home');
          },
          error => {
            this.errorMessage =
              'Login Unsuccessful. Please check your username and password and try again.';
            this.loginInProgress = false;
          }
        );
    }
  }
}
