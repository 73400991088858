import * as moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { IJwtAuthResponse } from '../../model/state/auth-state';
import { StateManager } from '../state/state-manager';

const TOKEN_EXPIRATION_MIN = environment.authApi.tokenExpirationMin;
const AUTH_HOST = environment.authApi.url;

@Injectable()
export class JwtService {
  constructor(private http: HttpClient, private stateManager: StateManager) {}

  getCurrentAuth(): IJwtAuthResponse | null {
    return this.stateManager.getJwtAuth();
  }

  authenticate(
    username: string,
    password: string
  ): Observable<IJwtAuthResponse> {
    return this.postAuthLogin(username, password).pipe(
      map(authResult => {
        this.saveAuth(authResult);
        return authResult;
      })
    );
  }

  getToken(): Observable<string> {
    const currentAuth = this.getCurrentAuth();

    if (!currentAuth) {
      return throwError(new Error('User authentication required.'));
    }

    if (this.isTokenExpired(currentAuth)) {
      return this.postAuthToken(currentAuth.refresh_token).pipe(
        map(authResult => {
          if (!authResult || !authResult.token) {
            this.stateManager.handleErrorEvent({
              type: 'UNAUTHORIZED',
            });
            return '';
          }

          this.saveAuth(authResult);
          return authResult.token;
        }),
        catchError(tokenRefreshError => {
          console.log(`Token refresh request failed`);
          console.log(tokenRefreshError);
          this.stateManager.handleErrorEvent({
            type: 'UNAUTHORIZED',
          });
          return '';
        })
      );
    } else {
      return of(currentAuth.token);
    }
  }

  private isTokenExpired(auth: IJwtAuthResponse): boolean {
    return moment().diff(auth.created_time, 'minutes') > TOKEN_EXPIRATION_MIN;
  }

  private postAuthLogin(
    username: string,
    password: string
  ): Observable<IJwtAuthResponse> {
    return this.http
      .post<IJwtAuthResponse>(AUTH_HOST + '/auth/login?source=portal', {
        username,
        password,
      })
      .pipe(
        map(result => {
          return {
            ...(<any>result),
            created_time: moment(),
          };
        })
      );
  }

  private postAuthToken(refreshToken: string): Observable<IJwtAuthResponse> {
    return this.http
      .post<IJwtAuthResponse>(AUTH_HOST + '/auth/token', {
        refresh_token: refreshToken,
      })
      .pipe(
        map(result => {
          return {
            ...result,
            created_time: moment(),
          };
        })
      );
  }

  private saveAuth(auth: IJwtAuthResponse) {
    this.stateManager.setJwtAuth(auth);
  }
}
