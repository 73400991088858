import { AngularTokenService } from 'angular-token';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Md5 } from 'ts-md5';

import { Injectable } from '@angular/core';

import { DocTypeConstants } from '../../constants/doc-types';
import { UserConstants } from '../../constants/user.constants';
import { Account } from '../../model/account/account';
import { User } from '../../model/user/user';
import { AccountService } from '../account/account.service';
import { AuthApiService } from '../auth-api/auth-api.service';
import { FileUtilityService } from '../file/file-utility.service';
import { PortalCompositeApiService } from '../portal-composite-api/portal-composite-api.service';
import { StateManager } from '../state/state-manager';
import { UserService } from '../user/user.service';
import { JwtService } from './jwt.service';

@Injectable()
export class AuthenticationService {
  constructor(
    private accountService: AccountService,
    private authApiService: AuthApiService,
    private tokenService: AngularTokenService,
    private portalCompositeApiService: PortalCompositeApiService,
    private jwtService: JwtService,
    private stateManager: StateManager,
    private userService: UserService
  ) {}

  validateUser(): Observable<string> {
    return this.jwtService
      .getToken()
      .pipe(map(() => (this.jwtService.getCurrentAuth() || {}).username || ''));
  }

  signOut(): Observable<boolean> {
    // If user was not logged in,
    // don't bother signing out of rails auth
    const currentUser = this.stateManager.getCurrentUser();
    if (!currentUser) {
      return of(true);
    }

    return this.tokenService.signOut().pipe(
      mergeMap(() => of(true)),
      catchError(() => of(true))
    );
  }

  signIn(userData: {
    email: string;
    password: string;
  }): Observable<User | Observable<never>> {
    userData.email = (userData.email || '').toLowerCase();

    // The JWT service authenticate function sets the auth token
    // Which is needed by the User API service to retrieve current user
    // So we can't call forkJoin on all three at the same time
    return this.jwtService.authenticate(userData.email, userData.password).pipe(
      mergeMap(jwtLoginResult => {
        return this.userService.getCurrentUser().pipe(
          mergeMap((userResponse: User) => {
            const user = <User>userResponse;
            if (
              !user ||
              !jwtLoginResult ||
              user.email !== jwtLoginResult.username
            ) {
              return throwError(
                new Error(
                  'We were unable to find the user profile for this user.'
                )
              );
            }

            if (user && user.status !== UserConstants.USER_STATUS_ACTIVE) {
              return this.signOut().pipe(
                mergeMap(() => {
                  return throwError(new Error('Your account is not active.'));
                })
              );
            }

            // Logged in; set auth state
            this.stateManager.setLocalStorageItem('uid', user.email || '');
            this.stateManager.setLocalStorageItem('client', 'IN2LPortal');
            this.stateManager.setLocalStorageItem('tokenType', 'Bearer');

            const defaultAccountObservable = this.stateManager.userIsAccountOrFacilityAdmin(
              user.doc_type || ''
            )
              ? this.accountService.getAccount(user.account_id || '')
              : of(<Account>{
                  dashboards_enabled:
                    user.doc_type === DocTypeConstants.TYPES.USER.IN2L_ADMIN,
                });
            return forkJoin([of(user), defaultAccountObservable]);
          }),
          mergeMap(([user, account]: [User, Account]) => {
            // Account dashboardsEnabled is required to determine the user's home page in AuthStateService
            this.stateManager.updateDashboardsEnabled(
              !!account.dashboards_enabled
            );
            this.stateManager.updateCurrentUser(user);

            return of(user);
          }),
          catchError(error => {
            return this.handleError(error);
          })
        );
      })
    );
  }

  completeAccount(
    email: string,
    token: string,
    pin: string,
    password: string,
    confirm_password: string,
    firstName: string,
    lastName: string,
    profileImageFile?: File
  ) {
    return this.portalCompositeApiService
      .createUser({
        email: email,
        token: token,
        password,
        confirm_password,
        pin: pin || '',
        firstName,
        lastName,
      })
      .pipe(
        mergeMap(result => {
          if (!result) {
            return of(null);
          }

          return this.signIn({ email, password });
        }),
        mergeMap((user: User | Observable<never> | null) => {
          if (
            !user ||
            (<any>user).doc_namespace !== DocTypeConstants.NAMESPACES.USER
          ) {
            return of(null);
          }

          // if user_profile is updated successfully, check to see if we need to
          // update their profile image
          // result will be a user object returned from updateUser()
          if (profileImageFile) {
            return FileUtilityService.convertBlobToDataURI(
              profileImageFile
            ).pipe(
              mergeMap((dataUri: string) => {
                return this.userService.updateUser(<User>user, {
                  name: UserConstants.USER_PROFILE_IMAGE_KEY,
                  imageBlob: profileImageFile,
                  contentType: FileUtilityService.getDataUriMimeType(dataUri),
                  etag: Md5.hashStr(dataUri),
                });
              })
            );
          }

          // if no file to upload, just pass along user
          return of(user);
        }),
        mergeMap(user => {
          if (
            !user ||
            (<any>user).doc_namespace !== DocTypeConstants.NAMESPACES.USER
          ) {
            return throwError(
              new Error('There was an error with account registration')
            );
          }

          const defaultAccountObservable = this.stateManager.userIsAccountOrFacilityAdmin(
            (<User>user).doc_type || ''
          )
            ? this.accountService.getAccount((<User>user).account_id || '')
            : of(<Account>{
                dashboards_enabled:
                  (<User>user).doc_type ===
                  DocTypeConstants.TYPES.USER.IN2L_ADMIN,
              });
          return forkJoin([of(<User>user), defaultAccountObservable]);
        }),
        mergeMap(([user, account]: [User, Account]) => {
          // Account dashboardsEnabled is required to determine the user's home page in AuthStateService
          this.stateManager.updateDashboardsEnabled(
            !!(<Account>account).dashboards_enabled
          );
          this.stateManager.updateCurrentUser(user);

          return of(user);
        }),
        catchError(error => {
          return this.handleError(error);
        })
      );
  }

  resetPassword(email: string) {
    return this.authApiService.resetPassword(email).pipe(
      mergeMap(response => {
        return of(response);
      }),
      catchError(error => {
        if (
          error &&
          error.status === 404 &&
          error.errors &&
          Array.isArray(error.errors)
        ) {
          return throwError(new Error(error.errors[0]));
        }

        return throwError(new Error(JSON.stringify(error)));
      })
    );
  }

  processResetPasswordError(error): string {
    const genericErrorMsg =
      'An error has occurred.  Please contact iN2L Support for assistance: <a href="https://in2l.com/support">iN2L Support - iN2L</a>';
    if (error && error.message) {
      try {
        // If the message is a valid JSON object, we don't want to display it to the user due to bad UX
        // Return a generic error message instead
        return JSON.parse(error.message) ? genericErrorMsg : error.message;
      } catch {
        return error.message;
      }
    }

    if (error && error.error) {
      return error.error;
    }

    console.log('Reset Password Error');
    console.log(error);

    return typeof error === 'string' ? error : genericErrorMsg;
  }

  // use this updatePassword method when a user has to verify their current password
  // and supply a new password (with confirmation)
  updatePassword(
    newPassword: string,
    newPasswordConfirmation: string,
    currentPassword: string
  ) {
    return this.authApiService
      .updatePassword(currentPassword, newPassword)
      .pipe(
        map(res => {
          return res && res.body ? res.body : {};
        }),
        catchError(error => {
          return this.handleError(error);
        })
      );
  }

  // use this updatePasswordWithResetToken when a user is working through the reset password
  // flow. You should have a valid reset token and the new password for the user
  updatePasswordWithResetToken(
    newPassword: string,
    resetPasswordToken: string
  ) {
    return this.authApiService
      .updatePasswordWithResetToken(newPassword, resetPasswordToken)
      .pipe(
        map(res => {
          return res && res.body ? res.body : {};
        }),
        catchError(error => {
          return this.handleError(error);
        })
      );
  }

  // --- update couchbase document username helper ---

  getCurrentUserDocumentChangedByValue() {
    const currentUser = this.stateManager.getCurrentUser();
    if (currentUser && currentUser.email) {
      return currentUser.email;
    }

    return '';
  }

  getTokenService(): AngularTokenService {
    return this.tokenService;
  }

  private handleError(response: any): Observable<never> {
    let errMsg: string;

    if (response && response.status) {
      switch (response.status) {
        case 401:
          errMsg = 'Unauthorized';
          break;
        case 404:
          errMsg = 'Not Found';
          break;
        case 409:
          errMsg = response.error.message;
          break;
        case 422:
          console.log(response.error);
          errMsg = JSON.stringify(response.error.errors);
          break;
        case 500:
          const exception = response.error
            ? response.error.exception || ''
            : '';
          switch (true) {
            case /key already exists in the server/.test(exception):
              errMsg =
                'Email address already exists. Please try a different one.';
              break;
            default:
              errMsg =
                'We could not complete this action because of a server error.';
              break;
          }
          break;
        default:
          errMsg =
            'We could not complete this action because of an unknown error.';
          break;
      }
    } else {
      errMsg = response.message ? response.message : response.toString();
    }

    return throwError(new Error(errMsg));
  }
}
