<div class="panel panel-default inner-panel">
  <div class="panel-body">
    <div class="row">
      <div class="col-sm-12 panel-heading">
        Content Types Accessed
      </div>
    </div>
    <div
      *ngIf="contentPieChart.dataset && contentPieChart.dataset.length > 0"
      flot
      [dataset]="contentPieChart.dataset"
      [options]="contentPieChart.options"
      height="482"
    ></div>
    <div class="text-center text-muted" *ngIf="!contentPieChart.dataset || contentPieChart.dataset.length === 0">
      Data unavailable
    </div>
</div>
</div>
