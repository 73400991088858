import * as _ from 'lodash';
import { CustomValidators } from 'ng2-validation';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { DocTypeConstants } from '../../../constants/doc-types';
import { UserConstants } from '../../../constants/user.constants';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { StateManager } from '../../../core/state/state-manager';
import { UserService } from '../../../core/user/user.service';
import { User } from '../../../model/user/user';
import {
  ContainsLowercaseValidator,
  ContainsNumValidator,
  ContainsSpecialValidator,
  ContainsUppercaseValidator,
  MAXPASSLENGTH,
  MINPASSLENGTH,
  OnlyNumbersValidator,
  PINLENGTH,
} from '../../../util/password';
import { UnsubscribeOnDestroy } from '../../../util/unsubscribe-on-destroy';

@Component({
  selector: 'app-complete-invite',
  styleUrls: ['./complete-invite.component.scss'],
  templateUrl: './complete-invite.component.html',
})
export class CompleteInviteComponent
  extends UnsubscribeOnDestroy
  implements OnInit {
  completeInviteForm: FormGroup;
  disableCompleteAccountButton = false;
  loginInProgress = false;
  errorMessage = '';
  profileImageFile: File;
  invitePageError = '';
  inviteEmailAddress = '';
  inviteToken = '';
  inviteUserType = '';
  successfulAccountCreation = false;
  minPassLength = MINPASSLENGTH;
  maxPassLength = MAXPASSLENGTH;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private settings: SettingsService,
    private stateManager: StateManager,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptionTracker.track = this.route.params
      .pipe(
        mergeMap(params => {
          this.inviteEmailAddress = this.route.snapshot.queryParams[
            'email'
          ].replace(/ /g, '+');
          this.inviteUserType = this.route.snapshot.queryParams['type'];
          this.inviteToken = this.route.snapshot.queryParams['token'];

          // logout any active users
          return of(params);
        }),
        mergeMap(params => {
          // current user has been logged out, or skipped if no one was logged in
          // now get the requested user's status through a specific api endpoint
          // that only returns status to non-logged in anonymous users
          return this.userService.getPublicUserStatus(params.user_id);
        })
      )
      .subscribe(
        (userStatus: string) => {
          if (userStatus !== UserConstants.USER_STATUS_INVITED) {
            this.invitePageError =
              'We could not find an open invitation for this request.';
          }
        },
        error => {
          this.invitePageError =
            'There was an error locating this invitiation. Please try again later.';
        }
      );

    // --- form setup ---

    this.completeInviteForm = this.formBuilder.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      password: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(MAXPASSLENGTH),
          Validators.minLength(MINPASSLENGTH),
          ContainsUppercaseValidator(),
          ContainsLowercaseValidator(),
          ContainsNumValidator(),
          ContainsSpecialValidator(),
        ]),
      ],
      confirm_password: [null],
      pin: [null],
    });

    if (this.hasPin()) {
      this.completeInviteForm
        .get('pin')
        .setValidators(
          Validators.compose([
            Validators.required,
            Validators.minLength(PINLENGTH),
            Validators.maxLength(PINLENGTH),
            OnlyNumbersValidator(),
          ])
        );
    }

    // need to have password control defined before we can set equalTo validator
    this.completeInviteForm
      .get('confirm_password')
      .setValidators(
        Validators.compose([
          Validators.required,
          CustomValidators.equalTo(this.completeInviteForm.get('password')),
        ])
      );
  }

  setFile($ev) {
    this.profileImageFile = $ev.target.files[0];
  }

  hasPin(): boolean {
    return [
      DocTypeConstants.TYPES.USER.FACILITY_ADMIN,
      DocTypeConstants.TYPES.USER.FACILITY_USER,
    ].includes(this.inviteUserType);
  }

  getCopyRight(): string {
    return `${_.get(this.settings, 'app.year', '')} - ${_.get(
      this.settings,
      'settings.app.name',
      ''
    )}`;
  }

  submitForm($ev) {
    $ev.preventDefault();

    for (const c in this.completeInviteForm.controls) {
      if (this.completeInviteForm.controls.hasOwnProperty(c)) {
        this.completeInviteForm.controls[c].markAsTouched();
      }
    }

    if (this.completeInviteForm.valid) {
      this.disableCompleteAccountButton = true;
      this.loginInProgress = true;
      this.subscriptionTracker.track = this.authenticationService
        .completeAccount(
          this.inviteEmailAddress,
          this.inviteToken,
          this.completeInviteForm.get('pin').value,
          this.completeInviteForm.get('password').value,
          this.completeInviteForm.get('confirm_password').value,
          this.completeInviteForm.get('first_name').value,
          this.completeInviteForm.get('last_name').value,
          this.profileImageFile
        )
        .subscribe(
          (user: User) => {
            this.disableCompleteAccountButton = false;
            if (!user) {
              this.errorMessage = 'There was an error updating this account.';
              return;
            }

            this.successfulAccountCreation = true;

            this.router.navigateByUrl('/home');
          },
          error => {
            if (!this.successfulAccountCreation) {
              this.errorMessage =
                'There was an error updating this account. ' + error;
            }

            this.disableCompleteAccountButton = false;
            this.loginInProgress = false;
          }
        );
    }
  }

  conditionMet(controlName: string, conditionName: string) {
    return !this.completeInviteForm.controls[controlName].errors
      ? true
      : !Object.keys(
          this.completeInviteForm.controls[controlName].errors
        ).includes(conditionName);
  }
}
