<div [formGroup]="parentForm" *ngIf="viewReady">
  <!-- ROLE TYPE SELECTOR (aka, Account Type) -->
  <div class="form-group has-feedback">
    <label class="control-label">Account Type</label>
    <select
      class="form-control"
      formControlName="userType"
      *ngIf="userTypeNames.length > 1"
      (change)="onUserTypeChange($event.target.value)"
    >
      <option value="">Please select an account type</option>
      <option
        *ngFor="let type of userTypeNames"
        [value]="type.id"
        [selected]="type.id === selectedUserType"
      >
        {{ type.text }}
      </option>
    </select>
    <div *ngIf="userTypeNames.length === 1">
      {{ userTypeNames[0].text }}
      <input
        type="hidden"
        formControlName="userType"
        [value]="userTypeNames[0].text"
      />
    </div>
    <span
      class="text-danger"
      *ngIf="parentForm.controls['userType'].hasError('required')"
      >This field is required</span
    >
  </div>

  <!-- ACCOUNT SELECTOR -->
  <div class="form-group has-feedback" *ngIf="showAccountDropdown()">
    <label *ngIf="accountNames.length > 1" class="control-label">Account</label>
    <select
      class="form-control"
      formControlName="accountId"
      *ngIf="accountNames.length > 1"
      (change)="onAccountChange($event.target.value)"
    >
      <option value="">Please select an account</option>
      <option
        *ngFor="let account of accountNames"
        [value]="account.id"
        [selected]="account.id === selectedAccountId"
      >
        {{ account.text }}
      </option>
    </select>
    <div *ngIf="accountNames.length === 1">
      {{ accountNames[0].text }}
      <input
        type="hidden"
        formControlName="accountId"
        [value]="selectedAccountId"
      />
    </div>
    <span
      class="text-danger"
      *ngIf="parentForm.controls['accountId'].hasError('required')"
      >This field is required</span
    >
  </div>

  <!-- FACILITY SELECTOR -->
  <div
    class="form-group has-feedback"
    id="p-autocomplete-form-group"
    *ngIf="showFacilityDropdown()"
    style="width: 100%"
  >
    <label class="control-label">{{
      availableFacilities.length > 1 ? 'Communities' : 'Community'
    }}</label>

    <div *ngIf="availableFacilities.length > 1">
      <p-autoComplete
        [(ngModel)]="selectedFacilities"
        [ngModelOptions]="{ standalone: true }"
        (completeMethod)="filterFacilitiesForQuery($event)"
        [suggestions]="filteredFacilities"
        (ngModelChange)="selectedFacilitiesChanged()"
        field="profile.name"
        [multiple]="true"
        [dropdown]="true"
        [type]="'search'"
        [style]="{ width: '100%' }"
        [inputStyle]="{ width: '100%' }"
        class="p-autocomplete"
      >
        <ng-template let-facility pTemplate="item">
          <div>
            <div>{{ facility.profile.name }}</div>
          </div>
        </ng-template>
      </p-autoComplete>
      <span class="text-danger" *ngIf="!selectedFacilities.length"
        >This field is required</span
      >
    </div>
    <div *ngIf="availableFacilities.length === 1">
      {{ availableFacilities[0].profile.name }}
    </div>
    <div *ngIf="!availableFacilities.length">
      No facilities exists for this account. You will not be able to use this
      account type for the selected account until a facility is available.
    </div>
  </div>

  <!-- Community User Account/Community -->
  <div class="form-group" *ngIf="showFacilityUserForm()">
    <label class="control-label">Account</label>
    <div class="mb-lg">
      {{ accountName() }}
      <input
        type="hidden"
        formControlName="accountId"
        [value]="defaultAccountId"
      />
    </div>
    <label class="control-label">Community</label>
    <div class="mb-lg">
      {{ facilityName() }}
      <input
        type="hidden"
        formControlName="facilityIds"
        [value]="defaultFacilityIds"
      />
    </div>
  </div>

  <!-- EMAIL & FIRST NAME -->
  <div class="form-group" *ngIf="showEmailFirstName()">
    <div class="form-group">
      <label class="control-label">Enter An Email Address</label>
      <input
        class="form-control"
        type="text"
        placeholder="Email Address"
        formControlName="email"
      />
      <span
        class="text-danger"
        *ngIf="parentForm.controls['email'].hasError('required')"
        >This field is required</span
      >
      <span
        class="text-danger"
        *ngIf="parentForm.controls['email'].hasError('email')"
      >
        This field must be a valid email address
      </span>
    </div>
    <div class="form-group">
      <label class="control-label">First Name</label>
      <input
        class="form-control"
        placeholder="First Name"
        type="text"
        name="firstName"
        formControlName="firstName"
        tabindex="0"
        aria-invalid="true"
      />
      <span
        class="text-danger"
        *ngIf="parentForm.controls['firstName'].hasError('required')"
        >This field is required</span
      >
    </div>
  </div>

  <!-- FACILITY USER FORM -->
  <div class="form-group" *ngIf="facilityUserFormLoading">
    Loading Community User form...
  </div>
  <div class="form-group has-feedback" *ngIf="showFacilityUserForm()">
    <div class="form-group">
      <label class="control-label">Last Name</label>
      <input
        class="form-control"
        placeholder="Last Name"
        type="text"
        name="lastName"
        formControlName="lastName"
        tabindex="0"
        aria-invalid="true"
      />
      <span
        class="text-danger"
        *ngIf="parentForm.controls['lastName'].hasError('required')"
        >This field is required</span
      >
    </div>

    <div class="form-group">
      <label class="control-label">Title/Position</label>
      <input
        class="form-control"
        placeholder="Title/Position"
        type="text"
        name="title"
        formControlName="title"
        tabindex="0"
        aria-invalid="true"
      />
    </div>
    <div class="form-group">
      <label class="control-label">Phone</label>
      <span
        class="text-danger"
        *ngIf="parentForm.controls.phone.hasError('pattern')"
      >
        This field has an invalid phone number
      </span>
      <input
        class="form-control"
        placeholder="Phone"
        type="text"
        name="phone"
        formControlName="phone"
        tabindex="0"
        aria-invalid="true"
        [textMask]="{ mask: phoneMask }"
      />
    </div>
    <div class="form-group">
      <label class="control-label">PIN</label>
      <div class="mb-lg">
        {{ randomPin }}
        <input type="hidden" formControlName="pin" [value]="randomPin" />
      </div>
    </div>

    <div class="form-group">
      <label class="control-label">Residents</label>
      <div class="radio c-radio">
        <label>
          <input
            type="radio"
            formControlName="residentMode"
            [checked]="!showResidentSelector()"
            value="all"
            (click)="setResidentModeAll()"
          />
          <span class="fa fa-circle"></span>
          Show All Residents
        </label>
      </div>
      <div class="radio c-radio">
        <label>
          <input
            type="radio"
            formControlName="residentMode"
            [checked]="showResidentSelector()"
            value="select"
            (click)="setResidentModeSelect()"
          />
          <span class="fa fa-circle"></span>
          Show Selected Residents
        </label>
      </div>
      <div
        class="select-residents-container"
        *ngIf="showResidentSelector()"
        style="width: 100%"
      >
        <div *ngIf="defaults.facilityIds.length >= 1" style="width: 100%">
          <p-autoComplete
            [(ngModel)]="selectedResidentsDropdown"
            [ngModelOptions]="{ standalone: true }"
            (completeMethod)="filterResidents($event)"
            [suggestions]="filteredResidents"
            (onSelect)="handleAddResident($event)"
            (onUnselect)="handleRemoveResident($event)"
            field="text"
            [multiple]="true"
            [dropdown]="true"
            [type]="'search'"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
            class="p-autocomplete"
          >
            <ng-template let-resident pTemplate="item">
              <div>
                <div>{{ resident.text }}</div>
              </div>
            </ng-template>
          </p-autoComplete>
          <span
          class="text-danger"
          *ngIf="!selectedResidentIds.length"
          >This field is required</span
        >
        </div>
      </div>
    </div>
  </div>
</div>
