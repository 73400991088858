import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { JwtService } from '../authentication/jwt.service';
import { MicroserviceApiBaseService } from '../micro-api/microservice-api-base.service';
import { StateManager } from '../state/state-manager';

@Injectable()
export class PortalCompositeApiService extends MicroserviceApiBaseService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    stateManager: StateManager
  ) {
    super(http, jwtService, stateManager);
  }

  createUser<T>(body: {
    email: string;
    token: string;
    pin: string;
    password: string;
    confirm_password: string;
    firstName: string;
    lastName: string;
  }) {
    return this.postWithoutToken<T>(
      this.portalCompositeApiUrl + '/users/create',
      body
    ).pipe(
      map(user => {
        return user;
      })
    );
  }
}
