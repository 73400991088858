import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { DocTypeConstants } from '../../constants/doc-types';
import { FamilyPortalUser } from '../../model/family-portal-user/family-portal-user';
import { FamilyPortalUserList } from '../../model/family-portal-user/family-portal-user-list';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { DatabaseApiService } from '../database-api/database-api.service';
import { StateManager } from '../state/state-manager';

@Injectable()
export class FamilyPortalUserDbService extends DatabaseApiService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    cacheService: CacheService,
    stateManager: StateManager,
    private dbApi: DatabaseApiService
  ) {
    super(http, jwtService, stateManager, cacheService);
  }

  removeFamilyPortalConnection(
    familyPortalUser: FamilyPortalUser,
    residentId: string
  ): Observable<unknown> {
    familyPortalUser.associated_residents = familyPortalUser.associated_residents.filter(
      resident => resident._id !== residentId
    );

    return this.putFamilyPortalUser(familyPortalUser);
  }

  putFamilyPortalUser(
    familyPortalUser: FamilyPortalUser
  ): Observable<FamilyPortalUser> {
    return this.put<FamilyPortalUser>(
      `${environment.databaseApi.url}/namespace/${DocTypeConstants.NAMESPACES.USER}/type/${DocTypeConstants.TYPES.USER.FAMILY_PORTAL_USER}/doc/${familyPortalUser._id}`,
      familyPortalUser
    );
  }

  getFamilyPortalUsersByEmail(
    emails: string[]
  ): Observable<FamilyPortalUserList> {
    const emailStr = encodeURIComponent(emails.join());
    return this.getAllWithCustomQueryParams<FamilyPortalUserList>(
      DocTypeConstants.NAMESPACES.USER,
      DocTypeConstants.TYPES.USER.FAMILY_PORTAL_USER,
      { emails: emailStr },
      FamilyPortalUserList,
      null,
      true
    );
  }
}
