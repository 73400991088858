<div *ngIf="nodes && selectedRoot" class="mb-sm">
  <div>{{ getContainerCount() }} Containers</div>
  <div>{{ getLinkToCount() }} Link Tos</div>
</div>
<tree-root
  *ngIf="selectedRoot"
  [nodes]="nodes"
  [(state)]="treeState"
  [focused]="true"
  [options]="options"
>
  <ng-template #treeNodeTemplate let-node="node" let-index="index">
    <span
      *ngIf="node.data.isLinkTo || node.data.isContainer"
      [class]="getNodeStatusCssClass(node.data.status)"
    ></span>
    <span [ngClass]="{ 'text-bold': node.data.isBold }">{{
      node.data.name
    }}</span>
    <span class="text-warning">{{ node.data.packageAbbreviationsText }}</span>
  </ng-template>
</tree-root>

<app-layout-copy-modal
  #layoutCopyModal
  [layoutRoots]="layoutRoots"
  [itemMap]="itemMap"
  [sourceRoot]="selectedRoot"
></app-layout-copy-modal>
