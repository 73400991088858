import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Interest } from '../../model/interest/interests';
import { JwtService } from '../authentication/jwt.service';
import { DatabaseApiService } from '../database-api/database-api.service';
import { CacheService } from '../cache/cache.service';
import { StateManager } from '../state/state-manager';

@Injectable()
export class InterestsService extends DatabaseApiService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    cacheService: CacheService,
    stateManager: StateManager
  ) {
    super(http, jwtService, stateManager, cacheService);
  }

  getInterestTypes(): Observable<Interest> {
    return this.getInterests();
  }
}
