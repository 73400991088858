import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { RoleService } from '../role/role.service';
import { StateManager } from '../state/state-manager';
import { AuthGuard } from './auth-guard';

/**
 *  ROLE GUARD
 *  To Use: import RoleGuard class and set 'canActivate: [ RoleGuard ]'
 *
 *  If the route does not need to be restricted to a specific account or facility,
 *  but it should be restricted to certain roles, use this guard. Add a 'roles' array
 *  property to the routes data object and define the required roles. You can add multiple
 *  roles. Role constants are defined in 'model/role/role.ts'. Roles are set for each user
 *  type (also in role.ts). All roles in the 'roles' list must exist on the user type to
 *  allow access.
 *
 *  When a user attempts to access a route they do not have roles defined by, the user
 *  will be redirected to the /denied page.
 */

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private authGuard: AuthGuard,
    private roleService: RoleService,
    private router: Router,
    private stateManager: StateManager
  ) {}

  /**
   * CanActivate Interface Functions
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const routeRoles = route.data['roles'];

    // run auth guard can activate first, then check role logic
    return this.authGuard.canActivate(route, state).pipe(
      mergeMap(authResult => {
        if (!authResult) {
          return of(false);
        }

        const hasRolesForRoute = this.roleService.currentUserHasRoles(
          routeRoles
        );

        if (!hasRolesForRoute) {
          this.router.navigateByUrl('/denied');
        }

        return of(hasRolesForRoute);
      })
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
