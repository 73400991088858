import * as moment from 'moment';

import { DocTypeConstants } from '../../constants/doc-types';
import { IDatabaseDoc } from '../database-api/database-doc';
import { SyncGatewayModel } from '../sync-gateway/sync-gateway-model';
import { DeviceInterfaces } from './device-interfaces';
import * as _ from 'lodash';

export const DEVICE_STATUS_ACTIVE = 'active';

export const DEVICE_RESIDENT_MODE_ALL = 'all';
export const DEVICE_RESIDENT_MODE_SELECT = 'select';
export const DEVICE_RESIDENT_MODE_SINGLE = 'single';

export const DEVICE_PRODUCTS = {
  ENGAGE: 'engage',
  FOCUS: 'focus',
};

const GENERIC_DEVICE_IMAGE = '/assets/img/device/generic_{0}.svg';

export const DEVICE_PRODUCTS_DISPLAY = {
  engage: 'Group System',
  focus: 'Tablet',
  lifeloop: 'LifeLoop',
};

export const DEVICE_PRODUCTS_DISPLAY_UPPER = {
  engage: 'GROUP',
  focus: 'TABLET',
  lifeloop: 'LIFELOOP',
};

export class Device
  extends SyncGatewayModel
  implements DeviceInterfaces.IDevice, IDatabaseDoc {
  account_id: string;
  facility_id: string;
  show_content_in_review: boolean;
  external_nickname: string;
  nickname: string;
  device_type: string;
  product: string;
  resident_ids: string[];
  packages: string[];
  resident_mode: string;
  serial_number: string;
  status: string;
  voice_commands_enabled: boolean;
  refresh_data: boolean;
  stay: {
    start_date?: string;
    end_date?: string;
  };
  terms_of_use_agreement: {
    created_date: string;
    acceptance_date?: string;
    account_id: string;
    facility_id: string;
  }[];
  use_s3_proxy: boolean;

  constructor(data: DeviceInterfaces.IDevice = {}) {
    super(
      data._id,
      data._rev,
      undefined,
      data.created_by,
      data.created_date,
      DocTypeConstants.NAMESPACES.ACCOUNT,
      DocTypeConstants.TYPES.ACCOUNT.DEVICE,
      data.modified_by,
      data.modified_date,
      data.apollo_id,
      data.apollo_import
    );

    this.account_id = data.account_id || '';
    this.facility_id = data.facility_id || '';
    this.show_content_in_review = !!data.show_content_in_review;
    this.external_nickname = data.external_nickname || '';
    this.nickname = data.nickname || '';
    this.device_type = data.device_type || '';
    this.product = data.product || '';
    this.packages = data.packages || [];
    this.resident_ids = data.resident_ids || [];
    this.resident_mode = data.resident_mode || '';
    this.serial_number = data.serial_number || '';
    this.status = data.status || '';
    this.voice_commands_enabled = !!data.voice_commands_enabled;
    this.refresh_data = !!data.refresh_data;
    this.stay = data.stay || { start_date: '', end_date: '' };
    this.terms_of_use_agreement = data.terms_of_use_agreement || [];
    this.use_s3_proxy =
      typeof data.use_s3_proxy === 'boolean' ? data.use_s3_proxy : true;
    this._deleted = data._deleted;
  }

  getContentModeName() {
    return this.show_content_in_review
      ? 'Content Review Mode'
      : 'Approved Content Mode';
  }

  getUserModeName(): string {
    return this.resident_mode === DEVICE_RESIDENT_MODE_SINGLE
      ? 'Single User'
      : 'Multi User';
  }

  getResidentModeName() {
    switch (this.resident_mode) {
      case DEVICE_RESIDENT_MODE_ALL:
        return 'All Residents';
      case DEVICE_RESIDENT_MODE_SELECT:
        return 'Selected Residents';
      case DEVICE_RESIDENT_MODE_SINGLE:
        return 'Single User Mode';
    }

    return '';
  }

  isInAllResidentMode(): boolean {
    return this.resident_mode === DEVICE_RESIDENT_MODE_ALL;
  }

  isInSingleResidentMode(): boolean {
    return this.resident_mode === DEVICE_RESIDENT_MODE_SINGLE;
  }

  isActive() {
    return this.status === DEVICE_STATUS_ACTIVE;
  }

  getTermsOfUseRecord(accountId, facilityId) {
    const accountFacilityTerms = this.terms_of_use_agreement.filter(
      tos => tos.account_id === accountId && tos.facility_id === facilityId
    );
    // Now that we have the account/facility specific terms, we need to find the most recent one.
    return _.sortBy(accountFacilityTerms, ['created_date'])[
      accountFacilityTerms.length - 1
    ];
  }

  addTermsOfUseSigned(accountId, facilityId) {
    const existingEntry = this.getTermsOfUseRecord(accountId, facilityId);

    const created_date = moment.utc().format();

    // if nothing exists, just append it directly
    if (!existingEntry) {
      this.terms_of_use_agreement.push({
        created_date,
        account_id: accountId,
        facility_id: facilityId,
      });
    } else {
      // remove property
      delete existingEntry.acceptance_date;
      // if exists, refresh created date
      existingEntry.created_date = created_date;
    }
  }

  static getDeviceImagePath(product): string {
    return GENERIC_DEVICE_IMAGE.replace('{0}', product || 'focus');
  }
}

export class DeviceFactory {
  static create(
    account_id: string,
    facility_id: string,
    serial_number: string,
    show_content_in_review: boolean,
    nickname: string = '',
    product: string,
    external_nickname: string = '',
    resident_ids: string[] = [],
    resident_mode: string = '',
    status: string,
    use_s3_proxy: boolean
  ): Device {
    return new Device({
      account_id: account_id,
      facility_id: facility_id,
      serial_number,
      show_content_in_review,
      nickname,
      external_nickname,
      product,
      resident_ids,
      resident_mode,
      status,
      use_s3_proxy,
    });
  }

  static createFromDevice(device: Device): Device {
    const newDevice = new Device(device);
    newDevice._rev = device._rev;
    return newDevice;
  }

  static createFromForm(form: any): Device {
    return DeviceFactory.create(
      form.account_id,
      form.facility_id,
      form.serial_number,
      form.show_content_in_review,
      form.nickname || '',
      form.product,
      form.external_nickname || '',
      form.resident_ids,
      form.resident_mode,
      form.status,
      form.use_s3_proxy
    );
  }

  static updateFromForm(device: Device, form: any): Device {
    device.show_content_in_review = !!form.show_content_in_review;
    device.nickname = form.nickname || '';
    device.external_nickname = form.external_nickname || '';
    device.product = form.product;
    device.resident_ids = form.resident_ids || [];
    device.resident_mode = form.resident_mode || DEVICE_RESIDENT_MODE_ALL;
    device.serial_number = form.serial_number;
    device.status = form.status;
    device.voice_commands_enabled = form.voice_commands_enabled;
    device.refresh_data = form.refresh_data;
    device.use_s3_proxy = form.use_s3_proxy;

    return this.createFromDevice(device);
  }
}

export interface IDeviceStatus {
  account_id: string;
  account_name: string;
  device_id: string;
  doc_type: string; // device-status
  download_status_completed: number;
  download_status_date: string;
  download_status_errored: number;
  download_status_in_progress: number;
  download_status_queued: number;
  download_status_total: number;
  download_status_unzipping: number;
  device_external_nickname: string;
  facility_id: string;
  facility_name: string;
  hockey_build_number: string; // only numbers
  jenkins_build_number: string; // only numbers
  last_usage: string; // UTC time in the format "2019-10-21T17:09:50Z"
  device_nickname: string;
  online_at: string; // UTC time in the format "2019-10-21T17:09:50Z"
  product: string; // ENGAGE or FOCUS
  release_version_number: string; // empty in QA, otherwise MAJOR.MINOR.PATCH version (e.g., "1.54.0")
  serial_number: string;
}

export interface IDeviceStatusResponse {
  device_status: IDeviceStatus[];
  count: number;
  limit: number;
  offset: number;
}
