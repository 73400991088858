import { Component, Input, OnInit } from '@angular/core';

import { RoleService } from '../../../core/role/role.service';
import { StateManager } from '../../../core/state/state-manager';

@Component({
  selector: 'app-sidebar-link',
  templateUrl: './sidebar-link.component.html',
  styleUrls: ['./sidebar-link.component.scss'],
})
export class SidebarLinkComponent implements OnInit {
  @Input() href = '';
  @Input() text = '';
  @Input() role = '';

  computedHref: string;
  visible = true;

  constructor(
    protected roleService: RoleService,
    private stateManager: StateManager
  ) {}

  ngOnInit() {
    // listen to param changes if this is parameterized URLs
    this.computedHref = this.href;
    if (this.href.indexOf(':account_id')) {
      this.stateManager
        .getSidebarAccountIdSelectionSubject()
        .subscribe(accountId => {
          this.computedHref = this.generateHref(this.href, accountId);
        });
    }

    if (this.href.indexOf(':facility_id')) {
      this.stateManager
        .getSidebarFacilityIdSelectionSubject()
        .subscribe(facilityId => {
          const accountId = this.stateManager.getSidebarAccountIdSelection();
          this.computedHref = this.generateHref(
            this.href,
            accountId,
            facilityId
          );
        });
    }

    // determine if link should be visible
    // we cannot use router configuarations because the router does not contain
    // any data for routes in lazy loaded modules
    //
    // this means will need to maintain a set of rules for link visibility separate
    // from the route guards :(
    //
    // a [role] attribute will need to be assigned to app-sidebar-link components. That
    // role is then checked against the current users allowed roles. Accounts and
    // facilities should be limited in the sidebar group resource resolve functions for
    // account and facility users

    if (this.role) {
      this.visible = this.roleService.currentUserHasRoles(this.role);
    }
  }

  generateHref(url: string, accountId?: string, facilityId?: string) {
    if (url.includes(':account_id') && accountId) {
      url = url.replace(':account_id', accountId);
    }

    if (url.includes(':facility_id') && facilityId) {
      url = url.replace(':facility_id', facilityId);
    }

    return url;
  }
}
