import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DocTypeConstants } from '../../constants/doc-types';
import { Account } from '../../model/account/account';
import { JwtService } from '../authentication/jwt.service';
import { MediaApiService } from '../media/media-api.service';
import { MicroserviceApiBaseService } from '../micro-api/microservice-api-base.service';
import { StateManager } from '../state/state-manager';

const accountLogoMediaName: string = 'account_logo';

@Injectable()
export class AccountService extends MicroserviceApiBaseService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    stateManager: StateManager,
    private mediaService: MediaApiService
  ) {
    super(http, jwtService, stateManager);
  }

  getAccount(accountId: string): Observable<Account> {
    const url = this.determineDbApiDocPath(
      DocTypeConstants.NAMESPACES.ACCOUNT,
      {
        type: DocTypeConstants.TYPES.ACCOUNT.ACCOUNT,
        id: accountId,
      }
    );
    return this.get<Account>(url, {});
  }

  getAccounts(): Observable<Account[]> {
    return this.get<Account[]>(
      this.determineDbApiDocPath('account', { type: 'account' }),
      {}
    );
  }

  createAccount(
    account: Account,
    accountLogo?: {
      contentType: string;
      imageBlob: Blob;
    }
  ): Observable<Account> {
    return this.post<Account>(
      this.determineDbApiDocPath('account', {
        type: 'account',
      }),
      account
    ).pipe(
      mergeMap(createdAccount => {
        if (!accountLogo) {
          return of(createdAccount);
        }

        // Use update to upload the logo, add media details to the account, and update cache
        return this.updateAccount(createdAccount, accountLogo);
      })
    );
  }

  updateAccount(
    account: Account,
    accountLogo?: {
      contentType: string;
      imageBlob: Blob;
    }
  ): Observable<Account> {
    return this.uploadAccountMediaFile(account, accountLogo).pipe(
      mergeMap(accountWithLogo =>
        this.put<Account>(
          this.determineDbApiDocPath('account', {
            type: 'account',
            id: account._id,
          }),
          accountWithLogo
        )
      ),
      tap(updatedAccount => this.refreshAccountCache(updatedAccount))
    );
  }

  getAccountLogoImage(account: Account): Observable<string> {
    if (
      account.media &&
      account.media[accountLogoMediaName] &&
      account.media[accountLogoMediaName].status === 'Completed'
    ) {
      return this.mediaService
        .requestMediaDownloadLink(
          DocTypeConstants.NAMESPACES.ACCOUNT,
          DocTypeConstants.TYPES.ACCOUNT.ACCOUNT,
          account.media[accountLogoMediaName].s3_key
        )
        .pipe(map(media => media.signed_url));
    }

    return of('');
  }

  removeAccountLogo(account: Account) {
    // TODO [Jake]: Need to actually delete from S3 as well
    if (account.media && account.media[accountLogoMediaName]) {
      delete account.media[accountLogoMediaName];
    }
  }

  private uploadAccountMediaFile(
    account: Account,
    accountLogo?: {
      contentType: string;
      imageBlob: Blob;
    }
  ): Observable<Account> {
    if (!accountLogo) {
      return of(account);
    }

    return this.mediaService
      .uploadMedia(
        DocTypeConstants.NAMESPACES.ACCOUNT,
        DocTypeConstants.TYPES.ACCOUNT.ACCOUNT,
        {
          mime_type: accountLogo.contentType,
          key: {
            sourceDocId: account._id ?? '',
            mediaType: accountLogoMediaName,
          },
        },
        accountLogo.imageBlob
      )
      .pipe(
        map(result => {
          if (accountLogo && result && result.s3Key && result.s3Key.media_key) {
            account.media = account.media || {};
            account.media[accountLogoMediaName] = {
              content_type: accountLogo.contentType,
              etag: 'none', // TODO: eTags needs to be worked out
              s3_key: result.s3Key.media_key,
              status: 'Completed',
            };
          }

          return account;
        })
      );
  }

  private refreshAccountCache(account: Account) {
    const selectedAccountId = this.stateManager.getSidebarAccountIdSelection();
    const selectedFacilityId = this.stateManager.getSidebarFacilityIdSelection();

    this.stateManager.updateAccountDoc(account);

    this.stateManager.updateSidebarAccountIdSelection(selectedAccountId);
    this.stateManager.updateSidebarFacilityIdSelection(selectedFacilityId);
  }

  typeOptions() {
    return [
      { id: null, name: 'Please Select' },
      { value: 'customer', name: 'Customer' },
      { value: 'demo', name: 'Demo' },
      { value: 'test', name: 'Test' },
    ];
  }

  corporateNames() {
    return [
      { id: null, name: 'Please Select' },
      { id: 1, name: 'Corporation 1' },
      { id: 2, name: 'Corporation 2' },
      { id: 3, name: 'Corporation 3' },
    ];
  }

  countryOptions() {
    return [
      { value: null, name: 'Please Select' },
      { value: 'United States', name: 'United States' },
      { value: 'Canada', name: 'Canada' },
    ];
  }

  stateOptions() {
    return [
      { name: 'Please Select', abbreviation: null },
      { name: 'Alabama', abbreviation: 'AL' },
      { name: 'Alaska', abbreviation: 'AK' },
      { name: 'Arizona', abbreviation: 'AZ' },
      { name: 'Arkansas', abbreviation: 'AR' },
      { name: 'California', abbreviation: 'CA' },
      { name: 'Colorado', abbreviation: 'CO' },
      { name: 'Connecticut', abbreviation: 'CT' },
      { name: 'Delaware', abbreviation: 'DE' },
      { name: 'District of Columbia', abbreviation: 'DC' },
      { name: 'Florida', abbreviation: 'FL' },
      { name: 'Georgia', abbreviation: 'GA' },
      { name: 'Hawaii', abbreviation: 'HI' },
      { name: 'Idaho', abbreviation: 'ID' },
      { name: 'Illinois', abbreviation: 'IL' },
      { name: 'Indiana', abbreviation: 'IN' },
      { name: 'Iowa', abbreviation: 'IA' },
      { name: 'Kansas', abbreviation: 'KS' },
      { name: 'Kentucky', abbreviation: 'KY' },
      { name: 'Louisiana', abbreviation: 'LA' },
      { name: 'Maine', abbreviation: 'ME' },
      { name: 'Maryland', abbreviation: 'MD' },
      { name: 'Massachusetts', abbreviation: 'MA' },
      { name: 'Michigan', abbreviation: 'MI' },
      { name: 'Minnesota', abbreviation: 'MN' },
      { name: 'Mississippi', abbreviation: 'MS' },
      { name: 'Missouri', abbreviation: 'MO' },
      { name: 'Montana', abbreviation: 'MT' },
      { name: 'Nebraska', abbreviation: 'NE' },
      { name: 'Nevada', abbreviation: 'NV' },
      { name: 'New Hampshire', abbreviation: 'NH' },
      { name: 'New Jersey', abbreviation: 'NJ' },
      { name: 'New Mexico', abbreviation: 'NM' },
      { name: 'New York', abbreviation: 'NY' },
      { name: 'North Carolina', abbreviation: 'NC' },
      { name: 'North Dakota', abbreviation: 'ND' },
      { name: 'Ohio', abbreviation: 'OH' },
      { name: 'Oklahoma', abbreviation: 'OK' },
      { name: 'Oregon', abbreviation: 'OR' },
      { name: 'Pennsylvania', abbreviation: 'PA' },
      { name: 'Rhode Island', abbreviation: 'RI' },
      { name: 'South Carolina', abbreviation: 'SC' },
      { name: 'South Dakota', abbreviation: 'SD' },
      { name: 'Tennessee', abbreviation: 'TN' },
      { name: 'Texas', abbreviation: 'TX' },
      { name: 'Utah', abbreviation: 'UT' },
      { name: 'Vermont', abbreviation: 'VT' },
      { name: 'Virginia', abbreviation: 'VA' },
      { name: 'Washington', abbreviation: 'WA' },
      { name: 'West Virginia', abbreviation: 'WV' },
      { name: 'Wisconsin', abbreviation: 'WI' },
      { name: 'Wyoming', abbreviation: 'WY' },
    ];
  }

  canadianProvinces() {
    return [
      { abbreviation: null, name: 'Please Select' },
      { abbreviation: 'AB', name: 'Alberta' },
      { abbreviation: 'BC', name: 'British Columbia' },
      { abbreviation: 'MB', name: 'Manitoba' },
      { abbreviation: 'NB', name: 'New Brunswick', country: 'CA' },
      { abbreviation: 'NL', name: 'Newfoundland and Labrador' },
      { abbreviation: 'NS', name: 'Nova Scotia' },
      { abbreviation: 'NU', name: 'Nunavut' },
      { abbreviation: 'NT', name: 'Northwest Territories' },
      { abbreviation: 'ON', name: 'Ontario' },
      { abbreviation: 'PE', name: 'Prince Edward Island' },
      { abbreviation: 'QC', name: 'Quebec' },
      { abbreviation: 'SK', name: 'Saskatchewan' },
      { abbreviation: 'YT', name: 'Yukon' },
    ];
  }

  // Billing Dropdown Values
  billingStatusOptions() {
    return [
      { value: 'active', name: 'Active' },
      { value: 'inactive', name: 'Inactive' },
    ];
  }

  billingFrequencyOptions() {
    return [
      { value: null, name: 'Please Select' },
      { value: 'Monthly', name: 'Monthly' },
      { value: 'Quarterly', name: 'Quarterly' },
      { value: 'Semi-Annually', name: 'Semi-Annually' },
      { value: 'Annually', name: 'Annually' },
      { value: '2-Year', name: '2-Year' },
    ];
  }
}
