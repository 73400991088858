<div class="wrapper">
  <div *ngIf="siteIsUnderMaintenance" class="imgbox">
    <img class="center-fit" src="/assets/img/maintenance.jpg" />
  </div>
  <div *ngIf="!siteIsUnderMaintenance" class="block-center mt-xl wd-xl">
    <!-- START panel-->
    <div class="panel panel-flat">
      <div class="panel-heading text-center">
        <a href="#">
          <img
            class="block-center login-logo-img"
            src="/assets/img/iN2L_powered_by_LifeLoop.png"
            alt="Image"
          />
        </a>
      </div>
      <div class="panel-body">
        <p *ngIf="!loginInProgress" class="text-center pv">
          SIGN IN TO CONTINUE.
        </p>
        <p *ngIf="loginInProgress" class="text-center pv">
          Welcome to iN2L Portal!
        </p>
        <form
          [formGroup]="valForm"
          class="form-validate mb-lg"
          role="form"
          name="loginForm"
          novalidate=""
          (submit)="submitForm($event, valForm.value)"
        >
          <div class="form-group" *ngIf="errorMessage">
            <p class="text-danger">{{ errorMessage }}</p>
          </div>
          <div *ngIf="!loginInProgress" class="form-group has-feedback">
            <input
              class="form-control"
              id="userEmail"
              type="email"
              name="email"
              placeholder="Enter email"
              autocomplete="off"
              formControlName="email"
              required=""
            />
            <span
              class="fa fa-envelope form-control-feedback text-muted"
            ></span>
            <span
              class="text-danger"
              *ngIf="valForm.controls['email'].hasError('required')"
              >This field is required</span
            >
            <span
              class="text-danger"
              *ngIf="
                valForm.controls['email'].hasError('email') &&
                (valForm.controls['email'].dirty ||
                  valForm.controls['email'].touched)
              "
              >This field must be a valid email address</span
            >
          </div>
          <div *ngIf="!loginInProgress" class="form-group has-feedback">
            <input
              class="form-control"
              id="password"
              type="password"
              name="password"
              placeholder="Password"
              formControlName="password"
              required=""
            />
            <span class="fa fa-lock form-control-feedback text-muted"></span>
            <span
              class="text-danger"
              *ngIf="valForm.controls['password'].hasError('required')"
              >This field is required</span
            >
          </div>
          <div class="clearfix" *ngIf="!loginInProgress">
            <div class="pull-right">
              <a class="text-muted" [routerLink]="'/recover'"
                >Forgot your password?</a
              >
            </div>
          </div>
          <button
            *ngIf="loginInProgress"
            class="btn btn-block btn-primary mt-lg"
            [disabled]="loginInProgress"
          >
            Loading...
          </button>
          <button
            *ngIf="!loginInProgress"
            class="btn btn-block btn-primary mt-lg"
            type="submit"
            [disabled]="loginInProgress"
          >
            Login
          </button>
        </form>
      </div>
    </div>
    <!-- END panel-->
    <h5 class="text-center trademark">It's Never 2 Late <sup>TM</sup></h5>
    <footer class="p-lg text-center">
      &copy; {{ settings.app.year }} - {{ settings.app.name }}<br />
      <a class="small mr" href="http://in2l.com/privacy-policy/" target="_blank"
        >Privacy Policy</a
      >
      <a class="small ml" href="http://in2l.com/terms-of-use/" target="_blank"
        >Terms of Use</a
      >
    </footer>
  </div>
</div>
