import * as _ from 'lodash';

import { IUiEventMessage, UiEventMessage } from './ui-event-service';

export interface IToasterMessage extends IUiEventMessage {
  body: string;
  title?: string;
  type: 'error' | 'info' | 'success' | 'wait' | 'warning';
  options?: {
    mouseoverTimerStop?: boolean;
    timeout?: {
      error?: number;
      info?: number;
      success?: number;
      wait?: number;
      warning?: number;
    };
    showCloseButton: boolean;
  };
}

export class ToasterMessage extends UiEventMessage implements IToasterMessage {
  body;
  title;
  type;
  options;

  constructor(message: IToasterMessage) {
    super();

    const messageBody = _.get(message, 'body', '');
    if (typeof messageBody === 'string') {
      this.body = messageBody;
    } else if (messageBody['status'] === 401) {
      this.body = 'Unauthorized. Please refresh the page and try again.';
    } else if (messageBody['status'] === 404) {
      this.body = 'Not Found';
    } else if (messageBody['message']) {
      this.body = messageBody['message'];
    } else {
      console.log(JSON.stringify(messageBody));
      this.body = 'Unknown Error';
    }

    this.type = _.get(message, 'type', 'info');
    this.options = _.get(message, 'options', null);

    // default title to formatted type if not set
    this.title = _.get(
      message,
      'title',
      this.type.substr(0, 1).toUpperCase() + this.type.substr(1)
    );
  }
}
