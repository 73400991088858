import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { FeatureFlagService } from '../../../core/configuration/feature-flag.service';

@Directive({
  selector: '[featureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  @Input() featureFlag: string;

  features: string[] = [];

  constructor(
    private featureFlagService: FeatureFlagService,
    private tpl: TemplateRef<any>,
    private vcr: ViewContainerRef
  ) {}

  ngOnInit() {
    this.featureFlagService.getFeatureFlags().subscribe(f => {
      this.features = f;

      const isEnabled = this.features.includes(this.featureFlag);

      if (isEnabled) {
        this.vcr.createEmbeddedView(this.tpl);
      }
    });
  }
}
