import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { SettingsService } from '../../core/settings/settings.service';
import { StateManager } from '../../core/state/state-manager';
import { UiEventService } from '../../core/ui-event-service/ui-event-service';
import { LoadingMessage } from '../../core/ui-event-service/ui-loading';
import { navigateToLogin } from '../../util/navigation-utils';

const screenfull = require('screenfull');
const browser = require('jquery.browser');
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, AfterViewInit {
  menuItems = []; // for horizontal layout
  loading = false;

  @ViewChild('fsbutton') fsbutton; // the fullscreen button

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    public settings: SettingsService,
    private stateManager: StateManager,
    private uiEventService: UiEventService
  ) {}

  ngOnInit() {
    if (browser.msie) {
      // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }

    this.uiEventService.subscribe(LoadingMessage, loading => {
      this.loading = loading.enabled;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  toggleCollapsedSideabar() {
    this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
  }

  isCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  logout() {
    // this.router.navigateByUrl('/login');
    navigateToLogin(this.router, this.stateManager);
  }
}
