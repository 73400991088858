import * as moment from 'moment';

import { Injectable } from '@angular/core';

@Injectable()
export class DateUtils {
  static DISPLAY_DATE_FORMAT: string = 'MM\\/DD\\/YYYY';
  static SAVED_DATE_FORMAT: string = 'YYYY-MM-DD';

  //PORTAL STANDARD: All dates in portal should display as: MM/DD/YYYY
  // input masking is applied via input service
  static setDateDisplayFormat(dateToFormat?: string): string {
    if (!dateToFormat) {
      return '';
    }
    return moment(dateToFormat).format(DateUtils.DISPLAY_DATE_FORMAT);
  }

  //PORTAL STANDARD: All dates in portal should be stored as: YYYY-MM-DD
  static setSavedDateFormat(dateToFormat: string): string {
    if (!dateToFormat) {
      return '';
    }

    const momentDate = moment(dateToFormat, 'MM/DD/YYYY').isValid()
      ? moment(dateToFormat, 'MM/DD/YYYY')
      : moment(dateToFormat);
    return momentDate.format(DateUtils.SAVED_DATE_FORMAT);
  }
}
