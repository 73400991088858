import { FamilyPortalUser } from './family-portal-user';

export class FamilyPortalUserList {
  members: Array<FamilyPortalUser>;

  constructor(data: any = {}) {
    this.members = [];
    if (data && Array.isArray(data)) {
      data.forEach(connection => {
        this.members.push(new FamilyPortalUser(connection));
      });
    }
  }
}
