import { Observable } from 'rxjs';

import { Injectable, Injector } from '@angular/core';

import { Facility } from '../../model/facility/facility';
import { Resident } from '../../model/resident/resident';
import { PortalAPIService } from '../portal-api/portal-api.service';

// twilio status docs: https://www.twilio.com/docs/api/messaging/message#message-status-values
@Injectable()
export class TextMessageService {
  // formatting helper function
  // text messages use https://en.wikipedia.org/wiki/E.164 phone number formatting
  static formatPhoneNumber(phone_number: string) {
    return '+' + phone_number.replace(/[^0-9]+/g, '');
  }

  constructor(
    private injector: Injector,
    private portalAPIService: PortalAPIService
  ) {}

  sendWelcome(
    contact_phone: string,
    facility: Facility,
    resident: Resident
  ): Observable<boolean> {
    const messageData = {
      contact_phone: TextMessageService.formatPhoneNumber(contact_phone),
      facility_name: facility.profile.name,
      resident_name: resident.first_name,
    };

    // could check a couple things here for success, chose to check for queued status
    // could also check for an error_code > 0 if multiple status are returned
    return this.portalAPIService.sendWelcomeTextMessage(messageData);
  }
}
