import { CustomValidators } from 'ng2-validation';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormUtils } from '../../../util/form-utils';
import {
  ContainsLowercaseValidator,
  ContainsNumValidator,
  ContainsSpecialValidator,
  ContainsUppercaseValidator,
  MAXPASSLENGTH,
  MINPASSLENGTH,
  PASSWORDREUSELIMIT,
} from '../../../util/password';
import { UnsubscribeOnDestroy } from '../../../util/unsubscribe-on-destroy';

@Component({
  selector: 'app-password-reset',
  styleUrls: ['./password-reset.component.scss'],
  templateUrl: './password-reset.component.html',
})
export class PasswordResetComponent
  extends UnsubscribeOnDestroy
  implements OnInit {
  email: string;
  errorMessage: string;
  passwordForm: FormGroup;
  resetToken: string;
  showNavigationLinks = false;
  loginInProgress = false;
  minPassLength = MINPASSLENGTH;
  maxPassLength = MAXPASSLENGTH;
  passwordReuseLimit = PASSWORDREUSELIMIT;

  constructor(
    public settings: SettingsService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptionTracker.track = this.activatedRoute.queryParams.subscribe(
      (param: Params) => {
        this.email = param['uid'];
        this.resetToken = param['token'];
        this.setErrorByToken(param['err']);
      }
    );

    this.passwordForm = this.fb.group({
      password: [''], // validators set below
      confirmPassword: [''], // validators set below
    });

    this.passwordForm
      .get('password')
      .setValidators(
        Validators.compose([
          Validators.required,
          Validators.maxLength(MAXPASSLENGTH),
          Validators.minLength(MINPASSLENGTH),
          ContainsUppercaseValidator(),
          ContainsLowercaseValidator(),
          ContainsNumValidator(),
          ContainsSpecialValidator(),
        ])
      );

    // need to have password control defined before we can set equalTo validator
    this.passwordForm
      .get('confirmPassword')
      .setValidators(
        Validators.compose([
          Validators.required,
          CustomValidators.equalTo(this.passwordForm.get('password')),
        ])
      );
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();

    FormUtils.markAllAsTouched(this.passwordForm);

    if (this.passwordForm.valid) {
      this.loginInProgress = true;
      this.subscriptionTracker.track = this.authenticationService
        .updatePasswordWithResetToken(
          this.passwordForm.get('password').value,
          this.resetToken
        )
        .pipe(
          mergeMap(result => {
            if (result) {
              this.router.navigateByUrl('/login');
            }

            return of(null);
          })
        )
        .subscribe(
          user => {
            if (!user) {
              this.errorMessage =
                'We were unable to update your password because of an unknown error.';
              return;
            }

            this.loginInProgress = false;

            this.router.navigateByUrl('/home');
          },
          error => {
            this.errorMessage = error['error'] ? error.error : error;

            this.loginInProgress = false;
          }
        );
    }
  }

  setErrorByToken(err: string) {
    if (err === 'user') {
      this.errorMessage =
        'This reset link is no longer valid or an unexpected error was found.';
      this.showNavigationLinks = true;
    }
  }

  conditionMet(conditionName: string) {
    return !this.passwordForm.controls['password'].errors
      ? true
      : !Object.keys(this.passwordForm.controls['password'].errors).includes(
          conditionName
        );
  }
}
