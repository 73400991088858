import { UUID } from 'angular2-uuid';
import * as _ from 'lodash';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { concatMap, map, mergeMap, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DocTypeConstants } from '../../constants/doc-types';
import { ContentModels } from '../../model/content/content.models';
import { IDatabaseDoc } from '../../model/database-api/database-doc';
import { Device } from '../../model/device/device';
import { Interest } from '../../model/interest/interests';
import { SyncGatewayAttachment } from '../../model/sync-gateway/sync-gateway-attachment';
import { SyncGatewayInterfaces } from '../../model/sync-gateway/sync-gateway.interfaces';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { MediaApiService } from '../media/media-api.service';
import { MicroserviceApiBaseService } from '../micro-api/microservice-api-base.service';
import { StateManager } from '../state/state-manager';

import Container = ContentModels.Container;
import LayoutRoot = ContentModels.LayoutRoot;
import LinkTo = ContentModels.LinkTo;
const MULTIPLE_UPDATE_LIMIT = 10;

@Injectable()
export class DatabaseApiService extends MicroserviceApiBaseService {
  protected portalApiUrl: string;
  protected mediaApiService: MediaApiService;

  constructor(
    http: HttpClient,
    jwtService: JwtService,
    stateManager: StateManager,
    protected cacheService: CacheService
  ) {
    super(http, jwtService, stateManager);
    this.mediaApiService = new MediaApiService(
      http,
      jwtService,
      cacheService,
      stateManager
    );
  }

  getDoc<T extends IDatabaseDoc>(
    namespace: string,
    type: string,
    id: string,
    parameters?: Record<string, string>
  ) {
    const cachedData = this.cacheService.getCachedDoc(id);

    if (cachedData) {
      return of<T>(cachedData);
    } else {
      return this.get<T>(
        this.determineDbApiDocPath(namespace, { type, id }),
        parameters
      ).pipe(tap(result => this.cacheService.updateSingleDoc(result)));
    }
  }

  getAllByNamespace<T>(namespace: string): Observable<T[]> {
    const cachedData = this.cacheService.getCache(namespace);
    let latestDate = null;

    // Check the current cache for any records that are newer than the most recent one
    if (cachedData && cachedData.length > 0) {
      latestDate = cachedData.reduce(
        (result, value) =>
          result > value.modified_date ? result : value.modified_date,
        ''
      );
    }
    const path = this.generateUrl(
      this.determineDbApiDocPath(namespace),
      latestDate ? { since: latestDate } : null
    );

    const apiResponseObservable =
      cachedData && !latestDate ? of(<T[]>cachedData) : this.get<T[]>(path);

    return apiResponseObservable.pipe(
      map((results: T[]) => {
        const resultsToSet = results;
        // Combine the current cache and any recent records found to add to the cache
        if (cachedData && latestDate) {
          if (results && results.length > 0) {
            const syncGatewayChanges = results.map(result => {
              return { id: result['_id'], doc: result, seq: 0 };
            });
            this.cacheService.updateChangedDocs(syncGatewayChanges);
          }
        } else {
          // If no new records, just use the original cache
          this.cacheService.setCache(resultsToSet, namespace);
        }

        const cacheToReturn = this.cacheService.getCache(namespace);

        return cacheToReturn ? cacheToReturn : [];
      })
    );
  }

  getAllByNamespaceType<T>(
    namespace: string,
    type: string,
    docClass: new (data?: any) => T,
    filterByAccountIds: string[] = [],
    filterByFacilityIds: string[] = [],
    ignoreCache = false
  ): Observable<T[]> {
    const accountIds = filterByAccountIds.filter(id => !!id);
    const facilityIds = filterByFacilityIds.filter(id => !!id);
    const isTypeRequest = !accountIds.length && !facilityIds.length;
    const isMultipleAccountsRequest =
      accountIds.length > 1 && !facilityIds.length;
    const isMultipleFacilitiesRequest =
      accountIds.length === 1 && facilityIds.length > 1;
    const isSingleAccountRequest =
      accountIds.length === 1 && !facilityIds.length;
    const isSingleFacilityRequest =
      accountIds.length === 1 && facilityIds.length === 1;

    if (
      !isTypeRequest &&
      !isMultipleAccountsRequest &&
      !isMultipleFacilitiesRequest &&
      !isSingleAccountRequest &&
      !isSingleFacilityRequest
    ) {
      return throwError(new Error('Invalid document request'));
    }

    if (isMultipleAccountsRequest) {
      return this.getAllByNamespaceType<T>(namespace, type, docClass).pipe(
        map(docs =>
          docs.filter(
            doc =>
              accountIds.includes(doc['_id']) ||
              accountIds.includes(doc['account_id'])
          )
        )
      );
    }

    if (isMultipleFacilitiesRequest) {
      return this.getAllByNamespaceType<T>(
        namespace,
        type,
        docClass,
        accountIds
      ).pipe(
        map(docs => {
          return docs.filter(
            doc =>
              facilityIds.includes(doc['_id']) ||
              facilityIds.includes(
                doc['facility_id'] ||
                  !!_.intersection(
                    facilityIds,
                    (doc['facility_ids'] || []).length
                  )
              )
          );
        })
      );
    }

    const accountId = accountIds.length ? accountIds[0] : undefined;
    const facilityId = facilityIds.length ? facilityIds[0] : undefined;

    let cachedData = null;
    let latestDate = null;
    if (!ignoreCache) {
      cachedData = this.cacheService.getCache(
        namespace,
        type,
        accountId,
        facilityId
      );

      // Check the current cache for any records that are newer than the most recent one
      if (cachedData && cachedData.length > 0) {
        latestDate = cachedData.reduce(
          (result, value) =>
            result > value.modified_date ? result : value.modified_date,
          ''
        );
      }
    }

    const accountQueryString =
      accountIds && !!accountIds.length ? `account_id=${accountIds[0]}` : '';
    const facilityQueryString =
      accountQueryString && facilityIds && !!facilityIds.length
        ? `&facility_id=${facilityIds[0]}`
        : '';
    let queryString = accountQueryString
      ? `?${accountQueryString}${facilityQueryString}`
      : '';
    // If we found a latest date in the current cache, then add it to the query to gather recent records
    queryString += latestDate
      ? (queryString.startsWith('?') ? '&' : '?') + `since=${latestDate}`
      : '';

    const pathWithQueryString = `${this.determineDbApiDocPath(namespace, {
      type,
    })}${queryString}`;

    const apiResponseObservable =
      cachedData && !latestDate
        ? of(<T[]>cachedData)
        : this.get<T[]>(pathWithQueryString);

    return apiResponseObservable.pipe(
      map((results: T[]) => {
        const resultsToSet = results;
        // Combine the current cache and any recent records found to add to the cache
        if (cachedData && latestDate) {
          if (results && results.length > 0) {
            const syncGatewayChanges = results.map(result => {
              return { id: result['_id'], doc: result, seq: 0 };
            });
            this.cacheService.updateChangedDocs(syncGatewayChanges);
          }
        } else {
          // If no new records, just use the original cache
          this.cacheService.setCache(
            resultsToSet,
            namespace,
            type,
            accountId,
            facilityId
          );
        }

        const cacheToReturn = this.cacheService.getCache(
          namespace,
          type,
          accountId,
          facilityId
        );

        return cacheToReturn
          ? cacheToReturn.map(result => new docClass(result))
          : [];
      })
    );
  }

  protected getById<T>(
    namespace: string,
    type: string,
    id: string,
    docClass: new (data?: any) => T,
    ignoreCache = false
  ): Observable<T> {
    const cachedData = !!ignoreCache
      ? null
      : this.cacheService.getCachedDoc(id);

    const url = this.determineDbApiDocPath(namespace, { type, id });

    const apiResponseObservable = cachedData
      ? of(<T>cachedData)
      : this.get<T>(url);

    return apiResponseObservable.pipe(
      map(result => {
        if (!cachedData) {
          this.cacheService.updateSingleDoc(result);
        }

        const newDoc = new docClass(result);

        return newDoc;
      })
    );
  }

  getAllWithCustomQueryParams<T>(
    namespace: string,
    type: string,
    paramValueDict: Record<string, string>,
    docClass: new (data?: any) => T,
    id: string,
    ignoreCache = false,
    useIdToCorrelateCacheData = false
  ): Observable<T> {
    const cachedData = !!ignoreCache
      ? null
      : this.cacheService.getCache(namespace, type, id);

    const customQueryParamsurl = this.generateUrl(
      this.determineDbApiDocPath(namespace, { type }),
      paramValueDict
    );

    const apiResponseObservable = ((cachedData as unknown) as T)
      ? of((cachedData as unknown) as T)
      : this.get<T>(customQueryParamsurl);

    return apiResponseObservable.pipe(
      map(result => {
        if (!cachedData && ((result as unknown) as object[])) {
          const resultArr = (result as unknown) as object[];
          if (useIdToCorrelateCacheData) {
            resultArr.forEach(
              resultItem => (resultItem['correlation_account_id'] = id)
            );
          }
          this.cacheService.setCache(
            (result as unknown) as object[],
            namespace,
            type,
            id
          );
        } else if (!cachedData) {
          this.cacheService.setCache([result], namespace, type, id);
        }

        return new docClass(result);
      })
    );
  }

  partialsRequest<T>(
    namespace: string,
    type: string,
    body: object
  ): Observable<T> {
    const partialsUrl = this.determineDbApiDocPath(namespace, {
      type,
      isPartials: true,
    });

    return this.post<T>(partialsUrl, body);
  }

  deleteDoc(
    namespace: string,
    type: string,
    id: string,
    parameters?: Record<string, string>
  ): Observable<boolean> {
    return this.delete(
      this.determineDbApiDocPath(namespace, { type, id }),
      parameters
    ).pipe(
      tap(() => this.cacheService.updateSingleDoc({ _id: id, _deleted: true }))
    );
  }

  createDoc<T extends IDatabaseDoc>(
    doc: T,
    isSingleResource: boolean = false,
    parameters?: Record<string, string>
  ): Observable<T> {
    if (!doc.doc_namespace) {
      throwError(new Error('doc_namespace is required.'));
    }

    if (!doc.doc_type) {
      throwError(new Error('doc_type is required.'));
    }

    const pathParams = doc._id
      ? { type: doc.doc_type, id: doc._id, isSingleResource }
      : { type: doc.doc_type, isSingleResource };
    return this.post<T>(
      this.determineDbApiDocPath(doc.doc_namespace || '', pathParams),
      doc,
      parameters
    ).pipe(tap(updatedDoc => this.cacheService.updateSingleDoc(updatedDoc)));
  }

  updateDoc<T extends IDatabaseDoc>(
    doc: T,
    isSingleResource: boolean = false,
    parameters?: Record<string, string>,
    overrideAddId: boolean = false
  ): Observable<T> {
    if (!doc._id) {
      throwError(Error('_id is required when updating a document.'));
    }

    if (!doc.doc_namespace) {
      throwError(new Error('doc_namespace is required.'));
    }

    if (!doc.doc_type) {
      throwError(new Error('doc_type is required.'));
    }

    return this.put<T>(
      this.determineDbApiDocPath(doc.doc_namespace, {
        type: doc.doc_type,
        id: doc._id,
        isSingleResource,
        overrideAddId,
      }),
      doc,
      parameters
    ).pipe(tap(updatedDoc => this.cacheService.updateSingleDoc(updatedDoc)));
  }

  patchProperty<T>(
    doc: IDatabaseDoc,
    propertyName: string,
    body: unknown,
    isSingleResource: boolean = false,
    parameters?: Record<string, string>
  ): Observable<T> {
    if (!doc._id) {
      throwError(
        new Error('_id is required when patching a property on a document.')
      );
    }

    if (!doc.doc_namespace) {
      throwError(new Error('doc_namespace is required.'));
    }

    if (!doc.doc_type) {
      throwError(new Error('doc_type is required.'));
    }

    return this.patch<T>(
      `${this.determineDbApiDocPath(doc.doc_namespace, {
        type: doc.doc_type,
        id: doc._id,
        isSingleResource,
      })}/${propertyName}`,
      body,
      parameters
    );
  }

  protected upsert<T extends SyncGatewayInterfaces.ISyncGatewayModel>(
    namespace: string,
    type: string,
    id: string,
    docClass: new (data?: any) => T,
    doc: T,
    attachment?: SyncGatewayAttachment
  ): Observable<T> {
    if (doc._id && doc._id !== id) {
      return throwError(new Error('Cannot save document due to ID mismatch'));
    }

    if (doc.doc_namespace !== namespace || doc.doc_type !== type) {
      return throwError(
        new Error(
          'Namespace and type does not match update namespace and type.'
        )
      );
    }

    const docId = id || UUID.UUID();
    doc._id = docId;

    // clean attachments in case there is a blob_ and non-blob_ version of the same attachment type
    if (attachment && doc._attachments) {
      const blobName = `blob_/${attachment.name}`;
      delete doc._attachments[blobName];
      delete doc._attachments[attachment.name];
      delete doc[attachment.name];
    }

    const url = this.determineDbApiDocPath(namespace, { type, id: docId });

    return this.put<T>(url, doc).pipe(
      mergeMap(result => {
        if (typeof result === 'string') {
          return throwError(new Error(result));
        }
        return this.getById<T>(namespace, type, doc._id, docClass, true);
      }),
      mergeMap((updatedItem: T) => {
        if (attachment) {
          return this.mediaApiService
            .updateAttachment(attachment, docId, '')
            .pipe(
              mergeMap((result: SyncGatewayInterfaces.IUpdateResult) => {
                if (result.ok) {
                  return this.getById<T>(
                    namespace,
                    type,
                    docId,
                    docClass,
                    true
                  );
                }
                return throwError(
                  new Error(
                    'Update failed. Please refresh the page and try again.'
                  )
                );
              })
            );
        }
        return this.getById<T>(namespace, type, docId, docClass, true);
      })
    );
  }

  // Only allow for devices and content containers
  // TODO: content containers implementation
  protected bulkUpdate<T extends SyncGatewayInterfaces.ISyncGatewayModel>(
    namespace: string,
    type: string,
    docs: T[]
  ): Observable<SyncGatewayInterfaces.IBulkUpdateResult[]> {
    if (!docs.length) {
      return of([]);
    }

    const isContentLayoutType = namespace ===
      DocTypeConstants.NAMESPACES.CONTENT && [
      DocTypeConstants.TYPES.CONTENT.LAYOUT_ROOT,
      DocTypeConstants.TYPES.CONTENT.CONTAINER,
      DocTypeConstants.TYPES.CONTENT.LINK_TO,
    ];
    const isDeviceType =
      namespace === DocTypeConstants.NAMESPACES.ACCOUNT &&
      type === DocTypeConstants.TYPES.ACCOUNT.DEVICE;

    if (!isContentLayoutType && !isDeviceType) {
      return throwError(new Error('Invalid bulk update type'));
    }

    if (
      !docs.every(
        doc => doc.doc_namespace === namespace && doc.doc_type === type
      )
    ) {
      return throwError(
        new Error(
          'Namespace and type does not match bulk update namespace and type for all documents.'
        )
      );
    }

    docs.forEach(doc => {
      doc._id = doc._id || UUID.UUID();
    });

    // Devices update access in Sync Gateway sync functions
    // This code is a workaround for a Sync Gateway bug that causes
    // the access update to fail resulting in missing docs on devices
    if (
      namespace === DocTypeConstants.NAMESPACES.ACCOUNT &&
      type === DocTypeConstants.TYPES.ACCOUNT.DEVICE
    ) {
      if (docs.length > MULTIPLE_UPDATE_LIMIT) {
        const docs1 = docs.slice(0, MULTIPLE_UPDATE_LIMIT);
        const docs2 = docs.slice(MULTIPLE_UPDATE_LIMIT);
        return this.bulkUpdate<T>(namespace, type, docs1).pipe(
          concatMap(results1 => {
            return this.bulkUpdate<T>(namespace, type, docs2).pipe(
              map(results2 => {
                return [...results1, ...results2];
              })
            );
          })
        );
      }

      return forkJoin(
        docs.map(doc =>
          this.upsert<Device>(
            namespace,
            type,
            doc._id,
            Device,
            new Device(doc)
          ).pipe(
            map((result: Device) => {
              const updateSuccessful =
                !!result && !!result._id && !!result._rev;
              const updatedDevice = updateSuccessful
                ? new Device(result)
                : { _id: '', _rev: '' };

              const bulkUpdateResult: SyncGatewayInterfaces.IBulkUpdateResult = {
                id: updatedDevice._id,
                rev: updatedDevice._rev,
                ok: updateSuccessful,
                error: !updateSuccessful ? 'Update failed' : '',
                reason: !updateSuccessful ? 'Update failed' : '',
              };

              return bulkUpdateResult;
            })
          )
        )
      );
    }

    const url = this.databaseApiUrl + '/bulk';
    return this.post<SyncGatewayInterfaces.IBulkUpdateResult[]>(url, docs).pipe(
      mergeMap(
        (bulkUpdateResults: SyncGatewayInterfaces.IBulkUpdateResult[]) => {
          let refreshCacheObservable;

          switch (type) {
            case DocTypeConstants.TYPES.ACCOUNT.DEVICE:
              refreshCacheObservable = this.getAllByNamespaceType<Device>(
                DocTypeConstants.NAMESPACES.ACCOUNT,
                DocTypeConstants.TYPES.ACCOUNT.DEVICE,
                Device,
                [docs[0]['account_id']],
                [docs[0]['facility_id']],
                true
              );
              break;

            // TODO: Future switch over of content
            case DocTypeConstants.TYPES.CONTENT.LAYOUT_ROOT:
              refreshCacheObservable = this.getAllByNamespaceType<LayoutRoot>(
                DocTypeConstants.NAMESPACES.CONTENT,
                DocTypeConstants.TYPES.CONTENT.LAYOUT_ROOT,
                LayoutRoot,
                [],
                [],
                true
              );
              break;
            case DocTypeConstants.TYPES.CONTENT.CONTAINER:
              refreshCacheObservable = this.getAllByNamespaceType<Container>(
                DocTypeConstants.NAMESPACES.CONTENT,
                DocTypeConstants.TYPES.CONTENT.CONTAINER,
                Container,
                [],
                [],
                true
              );
              break;
            case DocTypeConstants.TYPES.CONTENT.LINK_TO:
              refreshCacheObservable = this.getAllByNamespaceType<LinkTo>(
                DocTypeConstants.NAMESPACES.CONTENT,
                DocTypeConstants.TYPES.CONTENT.LINK_TO,
                LinkTo,
                [],
                [],
                true
              );
              break;
            default:
              return of([]);
          }

          return forkJoin([of(bulkUpdateResults), refreshCacheObservable]);
        }
      ),
      mergeMap(bulkUpdateResults => {
        return of(bulkUpdateResults);
      })
    );
  }

  getAttachmentFromDataUri(
    attachmentName: string,
    dataUri: string
  ): SyncGatewayAttachment {
    if (!dataUri) {
      return null;
    }

    const match = dataUri.match(/^data:([^;]+);base64,(.*)/);
    if (!match) {
      return null;
    }

    const contentType = match[1];
    const base64Data = match[2];
    return {
      name: attachmentName,
      base64Data,
      contentType,
    };
  }

  protected getAllUsersBySearchPhrase<T>(
    namespace: string,
    type: string,
    searchPhrase: string
  ): Observable<T[]> {
    const cachedData = this.cacheService.getCache(namespace);
    const pathWithQueryString = this.generateUrl(
      this.determineDbApiDocPath(namespace, { type }),
      { search: searchPhrase }
    );

    return this.get<T[]>(pathWithQueryString).pipe(
      map((results: T[]) => {
        // If there is cache add the result returned to it for safe keeping
        if (cachedData) {
          if (results && results.length > 0) {
            const syncGatewayChanges = results.map(result => {
              return { id: result['_id'], doc: result, seq: 0 };
            });
            this.cacheService.updateChangedDocs(syncGatewayChanges);
          }
        }

        return results ? results : [];
      })
    );
  }

  getInterests(): Observable<Interest> {
    const cachedData = this.cacheService.getCache(
      DocTypeConstants.NAMESPACES.REFERENCE
    );
    const pathWithQueryString =
      this.databaseApiUrl +
      '/namespace/' +
      DocTypeConstants.NAMESPACES.REFERENCE +
      '/type/' +
      DocTypeConstants.TYPES.REFERENCE.INTERESTS +
      '/doc';

    return this.get<Interest>(pathWithQueryString).pipe(
      map((result: Interest) => {
        // If there is cache add the result returned to it for safe keeping
        if (cachedData) {
          if (result) {
            const syncGatewayChanges = {
              id: result['_id'],
              doc: result,
              seq: 0,
            };
            this.cacheService.updateChangedDocs([syncGatewayChanges]);
          }
        }
        return result ? result : new Interest();
      })
    );
  }
}
