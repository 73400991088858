export interface IInterestTypes {
  _id?: string;
  doc_namespace: string;
  doc_type: string;
  types: IInterestType[];
}

export interface IInterestType {
  interest_id: string;
  interest_name: string;
  parent_id: string;
  linked_content_ids: string[];
}

export interface IInterestChangeEvent {
  selectedInterest: IInterestType;
}

export class Interest implements IInterestTypes {
  _id?: string;
  doc_namespace: string;
  doc_type: string;
  types: IInterestType[];
}
