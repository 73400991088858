<div class="wrapper">
  <div class="block-center mt-xl wd-xl">
    <!-- START panel-->
    <div class="panel panel-flat">
      <div class="panel-heading text-center">
        <a href="#">
          <img
            class="block-center login-logo-img"
            style="max-width: 45%"
            src="/assets/img/iN2L_powered_by_LifeLoop.png"
            alt="Image"
          />
        </a>
      </div>
      <div class="panel-body">
        <div *ngIf="!invitePageError && !loginInProgress">
          <h4 class="text-center">Welcome to the iN2L Portal</h4>
          <p class="text-center pb">
            Please finish your account registration by completing the profile
            information below
          </p>

          <form
            [formGroup]="completeInviteForm"
            class="form-validate mb-lg"
            role="form"
            name="registerForm"
            novalidate=""
            (submit)="submitForm($event, completeInviteForm.value)"
          >
            <div class="form-group has-feedback" *ngIf="inviteEmailAddress">
              <label class="text-muted">Email Address</label>
              <div class="text-muted">{{ inviteEmailAddress }}</div>
            </div>

            <div class="form-group has-feedback">
              <label class="text-muted">First Name</label>
              <input
                class="form-control"
                type="text"
                name="first_name"
                autocomplete="off"
                formControlName="first_name"
                required=""
              />
              <span
                class="text-danger"
                *ngIf="
                  completeInviteForm.controls['first_name'].hasError('required')
                "
                >This field is required</span
              >
            </div>

            <div class="form-group has-feedback">
              <label class="text-muted">Last Name</label>
              <input
                class="form-control"
                type="text"
                name="last_name"
                autocomplete="off"
                formControlName="last_name"
                required=""
              />
              <span
                class="text-danger"
                *ngIf="
                  completeInviteForm.controls['last_name'].hasError('required')
                "
                >This field is required</span
              >
            </div>
            <div *ngIf="hasPin()" class="form-group has-feedback">
              <label class="text-muted">Pin Number</label>
              <input
                class="form-control"
                id="id-pin"
                type="text"
                name="pin"
                formControlName="pin"
                [formControl]="completeInviteForm.controls['pin']"
              />
              <span class="fa fa-lock form-control-feedback text-muted"></span>
              <span
                class="text-danger"
                *ngIf="completeInviteForm.controls['pin'].hasError('required')"
                >This field is required</span
              >
              <div class="password-feedback">
                Pin Number must:
                <ul>
                  <li
                    [ngClass]="
                      !completeInviteForm.controls['pin'] ||
                      !completeInviteForm.controls['pin'].value ||
                      completeInviteForm.controls['pin'].value.length === 0
                        ? ''
                        : conditionMet('pin', 'minlength') &&
                          conditionMet('pin', 'maxlength') &&
                          conditionMet('pin', 'onlynum')
                        ? 'met'
                        : 'unmet'
                    "
                  >
                    Be 5 digits
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group has-feedback">
              <label class="text-muted">Password</label>
              <input
                class="form-control"
                id="id-password"
                type="password"
                name="password"
                formControlName="password"
                [formControl]="completeInviteForm.controls['password']"
              />
              <span class="fa fa-lock form-control-feedback text-muted"></span>
              <span
                class="text-danger"
                *ngIf="
                  completeInviteForm.controls['password'].hasError('required')
                "
                >This field is required</span
              >
              <div class="password-feedback">
                Password must:
                <ul>
                  <li
                    [ngClass]="
                      !completeInviteForm.controls['password'] ||
                      !completeInviteForm.controls['password'].value ||
                      completeInviteForm.controls['password'].value.length === 0
                        ? ''
                        : conditionMet('password', 'minlength') &&
                          conditionMet('password', 'maxlength')
                        ? 'met'
                        : 'unmet'
                    "
                  >
                    Be between 10 and
                    {{ maxPassLength }} characters
                  </li>
                  <li
                    [ngClass]="
                      !completeInviteForm.controls['password'] ||
                      !completeInviteForm.controls['password'].value ||
                      completeInviteForm.controls['password'].value.length === 0
                        ? ''
                        : conditionMet('password', 'containsupper')
                        ? 'met'
                        : 'unmet'
                    "
                  >
                    Contain at least one uppercase letter
                  </li>
                  <li
                    [ngClass]="
                      !completeInviteForm.controls['password'] ||
                      !completeInviteForm.controls['password'].value ||
                      completeInviteForm.controls['password'].value.length === 0
                        ? ''
                        : conditionMet('password', 'containslower')
                        ? 'met'
                        : 'unmet'
                    "
                  >
                    Contain at least one lowercase letter
                  </li>
                  <li
                    [ngClass]="
                      !completeInviteForm.controls['password'] ||
                      !completeInviteForm.controls['password'].value ||
                      completeInviteForm.controls['password'].value.length === 0
                        ? ''
                        : conditionMet('password', 'containsspecial')
                        ? 'met'
                        : 'unmet'
                    "
                  >
                    Contain at least one special character
                  </li>
                  <li
                    [ngClass]="
                      !completeInviteForm.controls['password'] ||
                      !completeInviteForm.controls['password'].value ||
                      completeInviteForm.controls['password'].value.length === 0
                        ? ''
                        : conditionMet('password', 'containsnum')
                        ? 'met'
                        : 'unmet'
                    "
                  >
                    Contain at least one number
                  </li>
                </ul>
              </div>
            </div>

            <div class="form-group has-feedback">
              <label class="text-muted">Retype Password</label>
              <input
                class="form-control"
                type="password"
                name="confirm_password"
                formControlName="confirm_password"
              />
              <span class="fa fa-lock form-control-feedback text-muted"></span>
              <span
                class="text-danger"
                *ngIf="
                  completeInviteForm.controls['confirm_password'].hasError(
                    'required'
                  )
                "
                >This field is required</span
              >
              <span
                class="text-danger"
                *ngIf="
                  completeInviteForm.get('confirm_password').value &&
                  completeInviteForm.controls['confirm_password'].errors
                    ?.equalTo
                "
                >Password does not match</span
              >
            </div>
            <div class="form-group has-feedback">
              <label class="text-muted">Profile Image</label>
              <input
                accept=".png,.jpg,.jpeg"
                type="file"
                (change)="setFile($event)"
              />
            </div>

            <alert type="danger" *ngIf="errorMessage">
              <i class="fa fa-exclamation-circle"></i>
              {{ errorMessage }}
            </alert>

            <button
              class="btn btn-block btn-primary mt-xl"
              type="submit"
              [disabled]="disableCompleteAccountButton"
            >
              Complete Account
            </button>
          </form>
        </div>

        <div *ngIf="loginInProgress">
          <p *ngIf="loginInProgress" class="text-center pv">Welcome to iN2L!</p>
          <div class="text-center mt-lg">
            <button
              class="btn btn-block btn-primary mt-lg"
              [disabled]="loginInProgress"
            >
              Loading...
            </button>
          </div>
        </div>
        <div *ngIf="invitePageError">
          <p class="text-danger">{{ invitePageError }}</p>
          <div class="text-center mt-lg">
            <h4>Already have an account?</h4>
            <button
              class="btn btn-large btn-primary mt mb-lg"
              routerLink="/login"
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END panel-->

    <footer class="p-lg text-center">
      &copy; {{ getCopyRight() }}<br />
      <a class="small mr" href="http://in2l.com/privacy-policy/" target="_blank"
        >Privacy Policy</a
      >
      <a class="small ml" href="http://in2l.com/terms-of-use/" target="_blank"
        >Terms of Use</a
      >
    </footer>
  </div>
</div>
