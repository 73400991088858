import * as _ from 'lodash';
import * as moment from 'moment';
import { CustomValidators } from 'ng2-validation';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { ContentConstants } from '../../../../constants/content.constants';
import { ContentVendorService } from '../../../../core/content-api/content-vendor.service';
import { FileUtilityService } from '../../../../core/file/file-utility.service';
import { MediaApiService } from '../../../../core/media/media-api.service';
import { Account } from '../../../../model/account/account';
import { AccountProfile } from '../../../../model/account/account-profile';
import { ContentLibraryInterfaces } from '../../../../model/content/content-library.interfaces';
import { ContentModels } from '../../../../model/content/content.models';
import { IInterestType } from '../../../../model/interest/interests';
import { PricingTier } from '../../../../model/pricing-tier/pricing-tiers';
import {
  ImageCropControlComponent,
} from '../../../../shared/components/image-crop-control/image-crop-control.component';
import { DateUtils } from '../../../../util/date-utils';
import { AppValidator } from '../../../../util/form-utils';
import {
  ContentLibraryBaseComponent,
} from '../../content-library-base/content-library-base.component';

const DISABLED_LIFELOOP_CONTENT_TYPES = [
  ContentConstants.CONTENT_TYPE.HAPPYNEURON,
  ContentConstants.CONTENT_TYPE.PRELOADEDEXE,
  ContentConstants.CONTENT_TYPE.DISTRIBUTEDEXE,
];

@Component({
  selector: 'app-content-library-item-form',
  templateUrl: './content-library-item-form.component.html',
  styleUrls: ['./content-library-item-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContentLibraryItemFormComponent
  extends ContentLibraryBaseComponent
  implements OnInit {
  @Input()
  libraryPath: string;
  @Input()
  contentItem: ContentModels.ContentItem;
  @Input()
  accounts: Account[];
  @Input()
  interests: IInterestType[];
  @Input()
  pricingTiers: PricingTier[];
  @Input()
  isUserAdmin: boolean;
  @Output()
  changeEvent: EventEmitter<ContentLibraryInterfaces.IChangeEvent> = new EventEmitter<ContentLibraryInterfaces.IChangeEvent>();

  @ViewChild('cropControl')
  cropControl: ImageCropControlComponent;

  prevContentType = '';
  contentTypeDropdownList = [
    { label: 'Select Content Type', value: '' },
    ...Object.keys(ContentConstants.CONTENT_TYPE_NAMES).map(key => ({
      label: ContentConstants.CONTENT_TYPE_NAMES[key],
      value: key,
    })),
  ];

  vendorDropdownList: Observable<{ label: string; value: string }[]>;
  previewUrl = of('(Save content item to see preview)');

  displayTypeDropdownList = [
    { label: 'Select Display Type', value: '' },
    ...Object.keys(ContentConstants.DISPLAY_TYPE_NAMES).map(key => ({
      label: ContentConstants.DISPLAY_TYPE_NAMES[key],
      value: key,
    })),
  ];

  availableAccountProfiles: AccountProfile[];
  selectedAccountIds: string[] = [];
  selectedAccountsList: Array<{ id: string; text: string }> = [];
  selectedInterests: Array<{ id: string; text: string }> = [];
  selectedPricingTiers: Array<string> = [];
  suggestedPricingTiers: Array<string> = [];
  filteredAccounts: Array<{ id: string; text: string }> = [];
  form: FormGroup;
  tileImagePath: string | null = null;
  tileImageRemoved = false;

  contentFile: File;
  replaceFile = false;
  fileValidationMessage = '';

  happyNeuronLangOptions = [
    { label: 'English', value: ContentConstants.HAPPY_NEURON_LANG.ENGLISH },
    { label: 'Spanish', value: ContentConstants.HAPPY_NEURON_LANG.SPANISH },
  ];

  keywordErrorMessageMap = {
    pattern:
      'Invalid characters, you may only use alphanumeric characters and spaces',
  };
  keywordValidators: ValidatorFn[] = [this.validateKeywordPattern];
  saveButtonClicked = false;
  editMode = true;

  constructor(
    private fb: FormBuilder,
    private mediaApiService: MediaApiService,
    private contentVendorService: ContentVendorService
  ) {
    super();

    this.vendorDropdownList = this.contentVendorService.getContentVendors.pipe(
      map(vendors => {
        const vendorItems = vendors.map(vendor => {
          return { label: vendor, value: vendor };
        });
        return [{ label: 'Select Vendor', value: '' }, ...vendorItems];
      })
    );
  }

  ngOnInit() {
    if (!this.contentItem) {
      this.contentItem = new ContentModels.ContentItem();
      this.editMode = false;
    }
    this.setExcludedAccounts();
    this.setPricingTiers();
    this.setPreviewUrl();
    this.setTitleImagePath();
    this.configureForm();

    if (!this.editMode) {
      this.setDefaults();
    }
  }

  setDefaults(): void {
    this.form.get('platforms')?.patchValue({ pc: true });
    this.form.get('products')?.patchValue({ engage: true });

    if (!this.isEnabledPlatformsFieldDisabled()) {
      this.form
        .get('enabled_platforms')
        ?.setValue([
          'web',
          'ios_mobile',
          'ios_tablet',
          'android_mobile',
          'android_tablet',
        ]);
    }
  }

  isEnabledPlatformsFieldDisabled(): boolean {
    return _.includes(
      DISABLED_LIFELOOP_CONTENT_TYPES,
      this.form.get('content_type')?.value
    );
  }

  toggleEnabledPlatforms(value): void {
    if (this.isEnabledPlatformsFieldDisabled()) {
      return;
    }
    let enabled_platforms = this.form.get('enabled_platforms')?.value || [];
    if (_.includes(enabled_platforms, value)) {
      enabled_platforms = enabled_platforms?.filter(
        platform => platform !== value
      );
    } else {
      enabled_platforms = [...enabled_platforms, value];
    }
    this.form.get('enabled_platforms')?.setValue(enabled_platforms);
  }

  getEnabledPlatformOptions(): any[] {
    const isDisabled = this.isEnabledPlatformsFieldDisabled();
    const enabledPlatforms = this.form.get('enabled_platforms')?.value;
    const _createOption = (value, label) => ({
      label,
      value,
      isDisabled,
      isChecked: _.includes(enabledPlatforms, value),
    });
    return [
      _createOption('web', 'Web'),
      _createOption('ios_mobile', 'iOS Mobile'),
      _createOption('ios_tablet', 'iOS Tablet'),
      _createOption('android_mobile', 'Android Mobile'),
      _createOption('android_tablet', 'Android Tablet'),
    ];
  }

  setTitleImagePath(): void {
    if (this.contentItem._id) {
      this.subscriptionTracker.track = this.mediaApiService
        .getAttachment(this.contentItem._id, this.contentItem.tileImageKey())
        .pipe(
          mergeMap((imageBlob: Blob) => {
            // rec blob, convert to dataURI
            return imageBlob
              ? FileUtilityService.convertBlobToDataURI(imageBlob)
              : of(null);
          })
        )
        .subscribe(
          (imageDataURI: string | null) => {
            if (imageDataURI) {
              this.tileImagePath = imageDataURI;
            }
          },
          error => {
            console.warn('Could not convert image to dataURI', error);
          }
        );
    } else {
      this.tileImagePath = null;
    }
  }

  configureForm(): void {
    this.form = this.fb.group({
      title: [this.contentItem.title || '', Validators.required],
      library_path: [this.libraryPath || '/', Validators.required],
      display_type: [this.contentItem.display_type || '', Validators.required],
      excluded_account_ids: [this.contentItem.excluded_account_ids || ''],
      us_only: new FormControl(!!this.contentItem.us_only),
      content_type: [this.contentItem.content_type || '', Validators.required],
      platforms: new FormGroup({
        android: new FormControl(!!this.contentItem.platforms.android),
        pc: new FormControl(!!this.contentItem.platforms.pc),
      }),
      enabled_platforms: [this.contentItem.enabled_platforms],
      products: new FormGroup({
        engage: new FormControl(!!this.contentItem.products.engage),
        focus: new FormControl(!!this.contentItem.products.focus),
        rehab: new FormControl(!!this.contentItem.products.rehab),
      }),
      keywords: [
        (this.contentItem.keywords || []).map(keyword =>
          this.normalizeKeyword(keyword)
        ),
      ],
      accessibility: new FormGroup({
        hearing_impairment: new FormControl(
          !!this.contentItem.accessibility.hearing_impairment
        ),
        physical_impairment: new FormControl(
          !!this.contentItem.accessibility.physical_impairment
        ),
        vision_impairment: new FormControl(
          !!this.contentItem.accessibility.vision_impairment
        ),
      }),
      usage_settings: new FormGroup({
        group_use: new FormControl(!!this.contentItem.usage_settings.group_use),
        joint_use: new FormControl(!!this.contentItem.usage_settings.joint_use),
        solo_use: new FormControl(!!this.contentItem.usage_settings.solo_use),
      }),
      source: [this.contentItem.source || ''],
      license_expiration_date: [
        DateUtils.setDateDisplayFormat(
          this.contentItem.license_expiration_date
        ) || '',
        Validators.compose([CustomValidators.date, AppValidator.dateExists()]),
      ],
      notes: [this.contentItem.notes || ''],

      // AWS uploaded file info
      s3_etag: [this.contentItem.s3_etag || ''],
      s3_key: [this.contentItem.s3_key || ''],

      // Happy neuron content type
      happy_neuron: this.fb.group({
        game_id: [_.get(this, 'contentItem.happy_neuron.game_id', '')],
        lang: [_.get(this, 'contentItem.happy_neuron.lang', '')],
      }),

      // Video content type
      video_description: [this.contentItem.video_description || ''],

      // Integrated website content type
      content_vendor: [this.contentItem.content_vendor || ''],

      // Website content type
      content_url: [this.contentItem.content_url || ''],

      // Link to request a URL for playing a single song (Coro uses this in place of a single streaming link)
      new_song_request_link: [this.contentItem.new_song_request_link || ''],

      // Windows exe
      windows_exe_filename: [this.contentItem.windows_exe_filename || ''],
      windows_exe_locations: [
        (this.contentItem.windows_exe_locations || []).join('\n'),
      ],

      // YouTube playlist video ids
      youtube_video_ids: [
        (this.contentItem.youtube_video_ids || []).join('\n'),
      ],
    });
  }

  setActiveValidatorsByType(
    subjectContentType,
    targetContentType,
    control,
    activeValidators
  ) {
    this.replaceFile = false;
    if (subjectContentType === targetContentType) {
      control.setValidators(activeValidators);
    } else {
      control.setValidators(null);
    }

    control.updateValueAndValidity();
  }

  onContentTypeChange() {
    const contentType = this.form.controls['content_type'].value;
    if (contentType === this.prevContentType) {
      return;
    }

    this.prevContentType = contentType;

    if (_.includes(DISABLED_LIFELOOP_CONTENT_TYPES, contentType)) {
      this.form.get('enabled_platforms')?.setValue([]);
    }

    // toggle URL validators
    this.setActiveValidatorsByType(
      ContentConstants.CONTENT_TYPE.WEBSITE,
      contentType,
      this.form.controls['content_url'],
      [Validators.required, CustomValidators.url]
    );
    if (contentType != ContentConstants.CONTENT_TYPE.WEBSITE) {
      this.setActiveValidatorsByType(
        ContentConstants.CONTENT_TYPE.INTEGRATEDWEBSITE,
        contentType,
        this.form.controls['content_url'],
        [Validators.required, CustomValidators.url]
      );
    }

    // toggle New Song URL validators
    this.setActiveValidatorsByType(
      ContentConstants.CONTENT_TYPE.WEBSITE,
      contentType,
      this.form.controls['new_song_request_link'],
      [CustomValidators.url]
    );

    // toggle vendor validator
    this.setActiveValidatorsByType(
      ContentConstants.CONTENT_TYPE.INTEGRATEDWEBSITE,
      contentType,
      this.form.controls['content_vendor'],
      [Validators.required]
    );

    // toggle windows exe filename validator
    this.setActiveValidatorsByType(
      ContentConstants.CONTENT_TYPE.DISTRIBUTEDEXE,
      contentType,
      this.form.controls['windows_exe_filename'],
      [Validators.required]
    );

    // toggle windows exe locations validator
    this.setActiveValidatorsByType(
      ContentConstants.CONTENT_TYPE.PRELOADEDEXE,
      contentType,
      this.form.controls['windows_exe_locations'],
      [Validators.required]
    );

    // toggle youtube video ids validator
    this.setActiveValidatorsByType(
      ContentConstants.CONTENT_TYPE.YOUTUBEPLAYLIST,
      contentType,
      this.form.controls['youtube_video_ids'],
      [Validators.required]
    );

    // toggle happy neuron validators
    this.setActiveValidatorsByType(
      ContentConstants.CONTENT_TYPE.HAPPYNEURON,
      contentType,
      this.form.get('happy_neuron.game_id'),
      [Validators.required, CustomValidators.digits]
    );

    this.setActiveValidatorsByType(
      ContentConstants.CONTENT_TYPE.HAPPYNEURON,
      contentType,
      this.form.get('happy_neuron.lang'),
      Validators.required
    );

    this.setFileValidationMessage(contentType, this.contentItem.s3_key);
  }

  fileUploadRequired(): boolean {
    if (!this.form.controls['content_type']) {
      return false;
    }
    const typeRequiredFile = ![
      ContentConstants.CONTENT_TYPE.PRELOADEDEXE,
      ContentConstants.CONTENT_TYPE.HAPPYNEURON,
      ContentConstants.CONTENT_TYPE.WEBSITE,
      ContentConstants.CONTENT_TYPE.INTEGRATEDWEBSITE,
      ContentConstants.CONTENT_TYPE.YOUTUBEPLAYLIST,
    ].includes(this.form.controls['content_type'].value);
    return typeRequiredFile;
  }

  fileIsValid(): boolean {
    return !this.fileUploadRequired() || this.isFileUploadAttemptSuccessful();
  }

  isFileUploadAttemptSuccessful(): boolean {
    return this.fileValidationMessage.length === 0;
  }

  setFileValidationMessage(
    contentType: string,
    filename?: string,
    fileSize?: number
  ) {
    this.fileValidationMessage = '';

    if (!contentType) {
      this.fileValidationMessage =
        'Select a content type before uploading a file';
      return;
    }

    // validate formats
    if (!this.fileUploadRequired() || !filename) {
      return;
    }

    if (/[^a-zA-Z0-9\-_\.]/.test(filename)) {
      this.fileValidationMessage =
        'Invalid file name characters. File names can only contain letters, numbers, underscores, dashes, and periods.';
      return;
    }

    const ext = filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();

    if (contentType === ContentConstants.CONTENT_TYPE.PDF && ext !== 'pdf') {
      this.fileValidationMessage =
        'Choose a valid PDF file (.PDF), or change the Content Type above.';
      return;
    }

    if (
      contentType === ContentConstants.CONTENT_TYPE.VIDEO &&
      ext !== 'webm' &&
      ext !== 'mp4'
    ) {
      this.fileValidationMessage =
        'Choose a valid Video file (.MP4 or .WEBM), or change the Content Type above.';
      return;
    }

    if (contentType === ContentConstants.CONTENT_TYPE.WEBAPP && ext !== 'zip') {
      this.fileValidationMessage =
        'Choose a valid Web App file (.ZIP), or change the Content Type above.';
      return;
    }

    if (
      contentType === ContentConstants.CONTENT_TYPE.DISTRIBUTEDEXE &&
      ext !== 'zip'
    ) {
      this.fileValidationMessage =
        'Choose a valid zipped Windows EXE file (.ZIP), or change the Content Type above.';
      return;
    }

    if (fileSize && fileSize > ContentConstants.MAX_FILE_SIZE) {
      this.fileValidationMessage = 'Max file size is 1 GB';
      return;
    }
  }

  fileChangeListener(event: ContentLibraryInterfaces.FileChangeEvent) {
    if (!this.fileUploadRequired()) {
      event.target.value = '';
      return;
    }

    const file: File = event.target.files[0];
    this.setFileValidationMessage(
      this.form.controls['content_type'].value,
      file.name,
      file.size
    );

    if (this.fileValidationMessage) {
      event.target.value = '';
      return;
    }
    this.replaceFile = this.fileValidationMessage.length === 0;
    this.contentFile = file;

    if (this.shouldRecordFileSize()) {
      // raw value is used to find total size of each layout: Engage and Focus in Content Layout Tree
      this.contentItem.fileSize = this.contentFile.size;
    }
  }

  isIn2lAdmin(): boolean {
    return this.isUserAdmin;
  }

  setExcludedAccounts(): void {
    if (this.contentItem.excluded_account_ids.length) {
      this.selectedAccountIds = [...this.contentItem.excluded_account_ids];
      this.selectedAccountsList = [
        ...this.transformAccounts(this.selectedAccountIds),
      ];
    }
  }

  setPricingTiers() {
    this.selectedPricingTiers = this.contentItem.pricing_tiers.map(
      pt => pt[0].toUpperCase() + pt.slice(1).toLowerCase()
    );
  }

  setPreviewUrl() {
    if (
      this.contentItem._id &&
      this.contentItem.content_type ===
        ContentConstants.CONTENT_TYPE.INTEGRATEDWEBSITE &&
      this.contentItem.content_url &&
      this.contentItem.content_vendor
    ) {
      this.previewUrl = this.contentVendorService.getPreviewURL(
        this.contentItem._id
      );
    }
  }

  transformAccounts(
    selectedIds?: string[]
  ): Array<{ id: string; text: string }> {
    if (!this.accounts) {
      return [];
    }
    const selectedAccounts = selectedIds
      ? this.accounts.filter(
          account => account._id && selectedIds.includes(account._id)
        )
      : this.accounts;

    return selectedAccounts
      .map(account => ({
        id: account._id,
        text: `${account.profile.account_name}`,
      }))
      .sort((a, b) => a.text.trim().localeCompare(b.text.trim()))
      .filter((account): account is { id: string; text: string } =>
        Boolean(account.id)
      );
  }

  filterAvailableAccounts(event) {
    const availableAccounts = this.transformAccounts();
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < availableAccounts.length; i++) {
      const account = availableAccounts[i];
      if (account.text.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(account);
      }
    }
    this.filteredAccounts = filtered;
  }

  filterPricingTiers(event) {
    this.suggestedPricingTiers = this.pricingTiers.filter(i =>
      i.toLowerCase().includes(event.query?.toLowerCase())
    );
  }

  handleAddAccount(select) {
    this.selectedAccountIds.push(select.id);
    this.form.get('excluded_account_ids')?.setValue(this.selectedAccountIds);
  }

  handleRemoveAccount(select) {
    this.selectedAccountIds = this.selectedAccountIds.filter(
      id => id !== select.id
    );
    this.form.get('excluded_account_ids')?.setValue(this.selectedAccountIds);
  }

  shouldRecordFileSize(): boolean {
    // only record file size for the following content types
    const validContentTypes = [
      ContentConstants.CONTENT_TYPE.VIDEO,
      ContentConstants.CONTENT_TYPE.PDF,
      ContentConstants.CONTENT_TYPE.WEBAPP,
      ContentConstants.CONTENT_TYPE.DISTRIBUTEDEXE,
    ];
    return validContentTypes.includes(this.form.controls['content_type'].value);
  }

  submit(formData: ContentLibraryInterfaces.IContentItemForm) {
    this.saveButtonClicked = !this.saveButtonClicked;
    if (!this.form.valid || !this.fileIsValid()) {
      this.changeEvent.emit({
        error: {
          message:
            'Content item cannot be saved. Please add all required fields before submitting.',
        },
      });
      this.saveButtonClicked = !this.saveButtonClicked;
      return;
    }

    const fileRequired = this.fileUploadRequired();

    const additionalValues = {
      library_path: this.libraryPath,
      license_expiration_date:
        DateUtils.setSavedDateFormat(formData.license_expiration_date) ||
        undefined,
      interests: this.selectedInterests.map(i => i.id),
      pricing_tiers: this.selectedPricingTiers.map(pt => pt.toUpperCase()),
      s3_etag: fileRequired && this.replaceFile ? '' : this.contentItem.s3_etag,
      s3_key: fileRequired && this.replaceFile ? '' : this.contentItem.s3_key,
      windows_exe_filename: formData.windows_exe_filename || '',
      windows_exe_locations: formData.windows_exe_locations
        ? formData.windows_exe_locations.split('\n')
        : undefined,
      youtube_video_ids: formData.youtube_video_ids
        ? formData.youtube_video_ids.split('\n')
        : undefined,
    };

    const item = Object.assign(
      new ContentModels.ContentItem(),
      this.contentItem,
      formData,
      additionalValues
    );

    if (this.tileImageRemoved) {
      item.removeTileImage();
    }

    delete item.hosting_status;

    this.changeEvent.emit({
      saveContentItem: {
        imageSrc:
          this.tileImagePath || this.cropControl.getCroppedImageDataUrl(),
        contentItem: item,
        file: fileRequired ? this.contentFile : undefined,
        tileImageDataUrl:
          this.cropControl.getCroppedImageDataUrl() || undefined,
      },
    });
  }

  submitDisabled(): boolean {
    const invalidForm = !this.form.valid;
    const invalidFile = !this.fileIsValid();
    return invalidForm || invalidFile;
  }

  submitButtonClicked(): boolean {
    return this.saveButtonClicked;
  }

  cancel() {
    this.changeEvent.emit({
      saveContentItem: {
        cancelled: true,
        imageSrc: '',
      },
    });
  }

  upload() {
    if (this.contentItem.s3_key && this.contentItem._id)
      this.changeEvent.emit({
        uploadContentItem: {
          s3Key: this.contentItem.s3_key,
          rootDocId: this.contentItem._id,
          priority: 'interactive',
        },
      });
  }

  validate() {
    if (this.contentItem.s3_key && this.contentItem._id)
      this.changeEvent.emit({
        validateContentItem: {
          s3Key: this.contentItem.s3_key,
          rootDocId: this.contentItem._id,
          priority: 'interactive',
        },
      });
  }

  showWindowsExeFilename(contentType: string): boolean {
    return contentType === ContentConstants.CONTENT_TYPE.DISTRIBUTEDEXE;
  }

  showWindowsExeLocations(contentType: string): boolean {
    return contentType === ContentConstants.CONTENT_TYPE.PRELOADEDEXE;
  }

  showYouTubePlaylist(contentType: string): boolean {
    return contentType === ContentConstants.CONTENT_TYPE.YOUTUBEPLAYLIST;
  }

  showVendor(contentType: string): boolean {
    return contentType === ContentConstants.CONTENT_TYPE.INTEGRATEDWEBSITE;
  }

  showContentUrl(contentType: string): boolean {
    return (
      contentType === ContentConstants.CONTENT_TYPE.WEBSITE ||
      contentType === ContentConstants.CONTENT_TYPE.INTEGRATEDWEBSITE
    );
  }

  showNewSongRequestLink(contentType: string, display_type: string): boolean {
    return (
      contentType === ContentConstants.CONTENT_TYPE.WEBSITE &&
      display_type === ContentConstants.DISPLAY_TYPE.AUDIO
    );
  }

  showPreviewUrl(contentType: string): boolean {
    return contentType === ContentConstants.CONTENT_TYPE.INTEGRATEDWEBSITE;
  }

  validateKeywordPattern(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (!/^[A-Za-z0-9\s]+$/.test(control.value)) {
      return { pattern: true };
    }

    return null;
  }

  normalizeKeyword(keyword: string): string {
    return keyword
      .toLowerCase()
      .replace(/\s{2,}/g, '')
      .trim();
  }

  isValidDate(formDate: string, format: string): boolean {
    return moment(formDate, format).isValid();
  }

  removeTileImage() {
    this.tileImagePath = null;
    this.tileImageRemoved = true;
  }
}
