import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { GuardsCheckStart, Router } from '@angular/router';

import { IRouterEventState } from '../../model/state/url-state';
import {
  getIdsFromUrl,
  navigateToLogin,
  pageSignsUserIn,
} from '../../util/navigation-utils';
import { StateManager } from './state-manager';

@Injectable()
export class UrlStateService {
  constructor(private stateManager: StateManager, private router: Router) {}

  /**
   * This is not an actual subscription but provides modified event stream for the AuthState subscription
   * Monitors for Angular Router.events stream for GuardsCheckStart events
   * - GuardsCheckStart events occur before the AuthGuard checks
   * - GuardsCheckStart is used instead of NavigationEnd because it happens before the routing access check (see auth-guard.ts)
   * - urlAfterRedirects is used to determine the current URL with url used as the backup if urlAfterRedirects is not set
   * Tasks:
   * - Updates the current URL and previous URL
   * - Clears state if the page being loaded signs the user in (login, complete-invite, and password-reset)
   * - Returns the current URL, previous URL, and the account and community IDs in the URL if present
   */
  routerEventStream(): Observable<IRouterEventState> {
    return this.router.events.pipe(
      filter(event => event instanceof GuardsCheckStart),
      map(
        event =>
          (<GuardsCheckStart>event).urlAfterRedirects ||
          (<GuardsCheckStart>event).url ||
          null
      ),
      filter(url => url !== null),
      map(currentUrl => {
        const previousUrl = this.stateManager.getCurrentUrl();

        // Update url subjects
        if (currentUrl !== previousUrl) {
          this.stateManager.updatePreviousUrl(previousUrl);
        }
        this.stateManager.updateCurrentUrl(currentUrl);

        // Verify that the current user BehaviorSubject value
        // matches what is in localStorage
        // If a user logs in as a new user in a different browser tab,
        // then the user in this tab needs to be cleared
        const currentUser = this.stateManager.getCurrentUser();
        const localStorageUser = this.stateManager.getLocalStorageUser();
        const usersMatch =
          (!currentUser && !localStorageUser) ||
          (!!currentUser &&
            !!localStorageUser &&
            _.isEqual(currentUser, localStorageUser));

        if (!usersMatch) {
          this.stateManager.updateCurrentUser(null);
          navigateToLogin(this.router, this.stateManager, this.router.url);
          return null;
        }

        const urlIds = getIdsFromUrl(currentUrl);

        if (pageSignsUserIn(currentUrl)) {
          this.stateManager.resetAuthState();
        }

        return { previousUrl, currentUrl, urlIds };
      })
    );
  }
}
