import * as _ from 'lodash';
import { Observable, of } from 'rxjs';

import { Component } from '@angular/core';

import { SidebarStateService } from '../../../core/state/sidebar-state.service';
import { StateManager } from '../../../core/state/state-manager';
import { Facility } from '../../../model/facility/facility';
import { SidebarResourceGroupComponent } from '../sidebar-resource-group/sidebar-resource-group.component';

@Component({
  selector: 'app-sidebar-facilities-group',
  templateUrl:
    '../sidebar-resource-group/sidebar-resource-group.component.html',
  styleUrls: [
    '../sidebar-resource-group/sidebar-resource-group.component.scss',
  ],
})
export class SidebarFacilitiesGroupComponent extends SidebarResourceGroupComponent {
  constructor(
    protected stateManager: StateManager,
    protected sidebarStateService: SidebarStateService
  ) {
    super(stateManager, sidebarStateService);
  }

  resolveResource(facilities: Facility[]): Observable<Facility[]> {
    const sortedFacilities = (facilities || []).sort(
      (a: Facility, b: Facility) => {
        const aName: string = _.get(a, 'profile.name', '').trim().toLowerCase();
        const bName: string = _.get(b, 'profile.name', '').trim().toLowerCase();
        return aName.localeCompare(bName);
      }
    );

    return of(sortedFacilities);
  }
}
