import { map, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Resident } from '../../model/resident/resident';
import { UnsubscribeOnDestroy } from '../../util/unsubscribe-on-destroy';
import { DeviceService } from '../device/device.service';
import { OldDeviceService } from '../device/old-device.service';
import { UiEventService } from '../ui-event-service/ui-event-service';
import { ToasterMessage } from '../ui-event-service/ui-toaster-message';
import { ResidentService } from './resident.service';

@Injectable()
export class ResidentStatusService extends UnsubscribeOnDestroy {
  constructor(
    private deviceService: DeviceService,
    private oldDeviceService: OldDeviceService,
    private residentService: ResidentService,
    private uiEventService: UiEventService
  ) {
    super();
  }

  activateResident(resident: Resident): void {
    this.subscriptionTracker.track = this.residentService
      .activateResident(resident)
      .pipe(
        mergeMap(updatedResident => {
          return this.oldDeviceService
            .addResidentToFacilityDevices(resident)
            .pipe(map(() => updatedResident));
        })
      )
      .subscribe(
        (updatedResident: Resident) => {
          resident = updatedResident;
          this.showSuccessMessage(
            `${resident.first_name} ${resident.last_name} has been activated`
          );
        },
        error => {
          this.showErrorMessage(error);
        }
      );
  }

  disableResident(resident: Resident): void {
    this.subscriptionTracker.track = this.residentService
      .disableResident(resident)
      .pipe(
        mergeMap(updatedResident => {
          return this.oldDeviceService
            .removeResidentFromFacilityDevices(
              resident,
              resident.account_id,
              resident.facility_id
            )
            .pipe(map(() => updatedResident));
        })
      )
      .subscribe(
        (updatedResident: Resident) => {
          resident = updatedResident;
          this.showSuccessMessage(
            `${resident.first_name} ${resident.last_name} has been disabled`
          );
        },
        error => {
          this.showErrorMessage(error);
        }
      );
  }

  private showErrorMessage(message): void {
    this.uiEventService.dispatch(
      new ToasterMessage({ body: message, type: 'error' })
    );
  }

  private showSuccessMessage(message): void {
    this.uiEventService.dispatch(
      new ToasterMessage({ body: message, type: 'success' })
    );
  }
}
