<div class="panel panel-default outer-panel">
  <div class="panel-body">
    <div class="panel-body">
      <table class="table table-striped">
        <thead>
        <tr>
          <th class="col-head title-cell">
            <a (click)="sortBoardItemsByTitle()"
              >Title
              <span class="order-icon" *ngIf="isColumnSortedAsc.title"
                >&uarr;</span
              >
              <span class="order-icon" *ngIf="!isColumnSortedAsc.title"
                >&darr;</span
              ></a
            >
          </th>
          <th class="col-head date-cell">
            <a (click)="sortBoardItemsByColumnDates('active_dates')"
              >Active Dates
              <span class="order-icon" *ngIf="isColumnSortedAsc.active_dates"
                >&uarr;</span
              >
              <span class="order-icon" *ngIf="!isColumnSortedAsc.active_dates"
                >&darr;</span
              >
            </a>
          </th>
          <th class="col-head date-cell">
            <a (click)="sortBoardItemsByColumnDates('in2l_suggests_dates')"
              >iN2L Suggests Dates
              <span
                class="order-icon"
                *ngIf="isColumnSortedAsc.in2l_suggests_dates"
                >&uarr;</span
              >
              <span
                class="order-icon"
                *ngIf="!isColumnSortedAsc.in2l_suggests_dates"
                >&darr;</span
              >
            </a>
          </th>
          <th class="col-head date-cell">
            <a (click)="sortBoardItemsByColumnDates('recently_added_dates')"
              >Recently Added Dates
              <span
                class="order-icon"
                *ngIf="isColumnSortedAsc.recently_added_dates"
                >&uarr;</span
              >
              <span
                class="order-icon"
                *ngIf="!isColumnSortedAsc.recently_added_dates"
                >&darr;</span
              >
            </a>
          </th>
          <th class="col-head action-cell text-center">Action</th>
        </tr>
        </thead>
        <tbody>
          <tr class="align-top" *ngFor="let linkTo of ideaBoardItems">
            <td>
              <button class="btn btn-link" (click)="clickLinkTo(linkTo._id)">
                {{ linkTo.title }}
              </button>
            </td>
            <td *ngFor="let rangeKey of dateRangeKeys">
              <!-- View LinkTo dates -->
              <div *ngIf="editId !== linkTo._id">
                <div *ngFor="let range of linkTo[rangeKey]">
                  {{ range.start_date }} - {{ range.end_date }}
                </div>
              </div>

              <!-- Edit LinkTo dates -->
              <div *ngIf="editId === linkTo._id">
                <div
                  class="row"
                  *ngFor="
                    let range of editForms.controls[rangeKey].controls;
                    let i = index
                  "
                >
                  <div class="col-sm-3 date-subcolumn form-group">
                    <input
                      bsDatepicker
                      placement="bottom left"
                      [bsConfig]="{ containerClass: 'theme-blue' }"
                      [compareToEndControl]="range.controls.end_date"
                      class="form-control date-field"
                      placeholder="Start"
                      type="text"
                      [formControl]="range.controls.start_date"
                    />
                    <span
                      class="text-danger"
                      *ngIf="hasDateError(range.controls.start_date)"
                      >Invalid date</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="range.controls.start_date.hasError('endDate')"
                      >Date must be before the end date</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="required(range.controls.start_date)"
                      >This field is required</span
                    >
                  </div>
                  <div class="col-sm-3 date-subcolumn form-group">
                    <input
                      bsDatepicker
                      placement="bottom left"
                      [bsConfig]="{ containerClass: 'theme-blue' }"
                      [compareToStartControl]="range.controls.start_date"
                      class="form-control date-field"
                      placeholder="End"
                      type="text"
                      [formControl]="range.controls.end_date"
                    />
                    <span
                      class="text-danger"
                      *ngIf="hasDateError(range.controls.end_date)"
                      >Invalid date</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="range.controls.end_date.hasError('startDate')"
                      >Date must be after the start date</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="required(range.controls.end_date)"
                      >This field is required</span
                    >
                  </div>
                  <div class="col-sm-1 push-right">
                    <a
                      class="btn btn-link pull-left"
                      (click)="removeRange(rangeKey, i)"
                      ><i class="fa fa-times text-danger"></i
                    ></a>
                  </div>
                  <div class="col-sm-5"></div>
                </div>
                <div
                  class="row"
                  *ngIf="rangeKey === 'active_dates' && !hasActiveDates()"
                >
                  <div class="col-sm-12">
                    <span class="text-danger"
                      >At least one active date is required</span
                    >
                  </div>
                </div>
                <div class="row" *ngIf="hasInvalidDateRange(rangeKey)">
                  <div class="col-sm-12">
                    <span class="text-danger"
                      >Dates must be within active ranges</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <button
                      class="btn btn-link"
                      (click)="addEmptyRange(rangeKey)"
                    >
                      + Add Dates
                    </button>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">
              <button
                class="btn btn-warning btn-outline btn-sm ml"
                (click)="edit(linkTo._id)"
                *ngIf="editId !== linkTo._id"
              >
                Edit
              </button>
              <button
                class="btn btn-info btn-outline btn-sm ml"
                (click)="save()"
                [disabled]="saveDisabled()"
                *ngIf="editId === linkTo._id"
              >
                Save
              </button>
              <button
                class="btn btn-danger btn-outline btn-sm ml"
                (click)="cancel()"
                *ngIf="editId === linkTo._id"
              >
                Cancel
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
