<div class="container-fluid">
  <app-page-title title="Access Restricted"></app-page-title>

  <div class="panel panel-default bb0">
    <div class="panel-body bb bt pb0 pb-xl">
      <h4>Restricted Page</h4>

      <p class="mt-lg mb-xl">
        You were linked to or attempted to access a page that needs different
        account permissions. Access to this page has been restricted.
      </p>

      <a href="#" class="mt-xl mb-xl mr-xl" (click)="goBack()"
        >Go Back to Previous Page</a
      >
      <a href="#" class="mt-xl mb-xl" routerLink="/">Go to Account Home</a>
    </div>
  </div>
</div>
