import { Injectable } from '@angular/core';

import { UiEventService } from '../ui-event-service/ui-event-service';
import { LoadingMessage } from '../ui-event-service/ui-loading';

@Injectable()
export class LoaderService {
  numberCall = 0;

  constructor(protected uiEventService: UiEventService) {}

  showLoader() {
    this.numberCall++;
    this.uiEventService.dispatch(new LoadingMessage({ enabled: true }));
  }

  hideLoader() {
    this.numberCall--;
    if (this.numberCall < 1) {
      this.uiEventService.dispatch(new LoadingMessage({ enabled: false }));
    }
  }
}
