import * as _ from 'lodash';
import { Observable, of } from 'rxjs';

import { Component } from '@angular/core';

import { DocTypeConstants } from '../../../constants/doc-types';
import { SidebarStateService } from '../../../core/state/sidebar-state.service';
import { StateManager } from '../../../core/state/state-manager';
import { Account } from '../../../model/account/account';
import { SidebarResourceGroupComponent } from '../sidebar-resource-group/sidebar-resource-group.component';

@Component({
  selector: 'app-sidebar-accounts-group',
  templateUrl:
    '../sidebar-resource-group/sidebar-resource-group.component.html',
  styleUrls: [
    '../sidebar-resource-group/sidebar-resource-group.component.scss',
  ],
})
export class SidebarAccountsGroupComponent extends SidebarResourceGroupComponent {
  singleAccountUserTypes = [
    DocTypeConstants.TYPES.USER.ACCOUNT_ADMIN,
    DocTypeConstants.TYPES.USER.FACILITY_ADMIN,
  ];

  constructor(
    protected stateManager: StateManager,
    protected sidebarStateService: SidebarStateService
  ) {
    super(stateManager, sidebarStateService);
  }

  resolveResource(accounts: Account[]): Observable<Account[]> {
    const sortedAccounts = (accounts || []).sort((a: Account, b: Account) => {
      if (a.billing.status !== b.billing.status) {
        return a.billing.status.localeCompare(b.billing.status);
      }

      if (a.account_type !== b.account_type) {
        return a.account_type.localeCompare(b.account_type);
      }

      const aName: string = _.get(a, 'profile.account_name', '')
        .trim()
        .toLowerCase();
      const bName: string = _.get(b, 'profile.account_name', '')
        .trim()
        .toLowerCase();
      return aName.localeCompare(bName);
    });

    return of(sortedAccounts);
  }
}
