import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, share, tap } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { DocTypeConstants } from '../../constants/doc-types';
import { JwtService } from '../authentication/jwt.service';
import { StateManager } from '../state/state-manager';

@Injectable()
export class MicroserviceApiBaseService {
  private httpObservableCache = {};
  protected readonly contentApiUrl: string;
  protected readonly databaseApiUrl: string;
  protected readonly deviceApiUrl: string;
  protected readonly mediaApiUrl: string;
  protected readonly portalCompositeApiUrl: string;
  protected readonly userApiUrl: string;

  constructor(
    protected http: HttpClient,
    protected jwtService: JwtService,
    protected stateManager: StateManager
  ) {
    this.contentApiUrl = environment.contentApi.url;
    this.databaseApiUrl = environment.databaseApi.url;
    this.deviceApiUrl = environment.deviceApi.url;
    this.mediaApiUrl = environment.mediaApi.url;
    this.portalCompositeApiUrl = environment.portalCompositeApi.url;
    this.userApiUrl = environment.userApi.url;
  }

  protected get<T>(
    path: string,
    parameters?: Record<string, string>,
    requestOptions: {} = {},
    ignoredErrors: number[] = []
  ): Observable<T> {
    return this.jwtService.getToken().pipe(
      mergeMap(token => {
        return this.sharedGetRequest<T>(
          this.generateUrl(path, parameters),
          token,
          requestOptions,
          ignoredErrors
        );
      })
    );
  }

  protected getWithoutToken<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }

  protected post<T>(
    path: string,
    body: unknown,
    parameters?: Record<string, string>
  ): Observable<T> {
    return this.jwtService.getToken().pipe(
      mergeMap(token =>
        this.http.post<T>(this.generateUrl(path, parameters), body, {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + token,
          }),
        })
      ),
      catchError(error => {
        this.handleError(error);
        return throwError(error);
      })
    );
  }

  protected postWithoutToken<T>(
    path: string,
    body: unknown,
    parameters?: Record<string, string>
  ): Observable<HttpResponse<T>> {
    return this.http.post<T>(this.generateUrl(path, parameters), body, {
      observe: 'response',
    });
  }

  protected patch<T>(
    path: string,
    body: unknown,
    parameters?: Record<string, string>
  ): Observable<T> {
    return this.jwtService.getToken().pipe(
      mergeMap(token =>
        this.http.patch<T>(this.generateUrl(path, parameters), body, {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + token,
          }),
        })
      ),
      catchError(error => {
        this.handleError(error);
        return throwError(error);
      })
    );
  }

  protected put<T>(
    path: string,
    body: T,
    parameters?: Record<string, string>
  ): Observable<T> {
    return this.jwtService.getToken().pipe(
      mergeMap(token =>
        this.http.put<T>(this.generateUrl(path, parameters), body, {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + token,
          }),
        })
      ),
      catchError(error => {
        this.handleError(error);
        return throwError(error);
      })
    );
  }

  protected delete(
    path: string,
    parameters?: Record<string, string>
  ): Observable<boolean> {
    return this.jwtService.getToken().pipe(
      mergeMap(token =>
        this.http.delete(this.generateUrl(path, parameters), {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + token,
          }),
        })
      ),
      map(() => true),
      catchError(error => {
        if (error.status !== 404) {
          this.handleError(error);
          return of(false);
        }

        return of(true);
      })
    );
  }

  protected sharedGetRequest<T>(
    url: string,
    token: string,
    requestOptions: {} = {},
    ignoredErrors: number[] = []
  ): Observable<T> {
    if (this.httpObservableCache[url]) {
      return this.httpObservableCache[url];
    }
    const options = Object.assign({}, requestOptions, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    });
    this.httpObservableCache[url] = this.http.get<T>(url, options).pipe(
      tap(() => {
        this.httpObservableCache[url] = null;
      }),
      catchError(error => {
        if (!ignoredErrors.includes(error.status)) {
          this.handleError(error);
        }

        return of(null);
      }),
      share()
    );

    return this.httpObservableCache[url];
  }

  protected handleError(error: any) {
    if (error.status === 401) {
      this.stateManager.handleErrorEvent({
        type: 'UNAUTHORIZED',
      });
    } else {
      this.stateManager.handleErrorEvent({
        type: 'UNKNOWN_ERROR',
      });
    }
  }

  protected determineDbApiDocPath(
    namespace: string,
    optionalParams: {
      type?: string;
      id?: string;
      isSingleResource?: boolean;
      overrideAddId?: boolean;
      isPartials?: boolean;
    } = {}
  ): string {
    let url = `${this.databaseApiUrl}/`;
    url += `namespace/${namespace}/`;

    if (optionalParams.type) {
      url += `type/${optionalParams.type}/`;
    }
    // Separating out microservices from Database API
    if (
      namespace === DocTypeConstants.NAMESPACES.ACCOUNT &&
      optionalParams.type === DocTypeConstants.TYPES.ACCOUNT.DEVICE
    ) {
      url = `${this.deviceApiUrl}/`;
    } else if (
      namespace === DocTypeConstants.NAMESPACES.CONTENT &&
      Object.values(DocTypeConstants.TYPES.CONTENT).find(
        type => type === optionalParams.type
      )
    ) {
      url = `${this.contentApiUrl}/`;

      if (optionalParams.type) {
        url += `type/${optionalParams.type}/`;
      }
    }

    if (optionalParams.isPartials) {
      url += 'partials';
    } else if (
      optionalParams.isSingleResource &&
      optionalParams.overrideAddId
    ) {
      url += `doc/${optionalParams.id}`;
    } else if (optionalParams.isSingleResource) {
      url += 'doc';
    } else if (optionalParams.id) {
      url += `docs/${optionalParams.id}`;
    } else {
      url += 'docs';
    }

    return url;
  }

  protected generateUrl(
    path: string,
    parameters?: Record<string, string>
  ): string {
    if (!parameters || Object.keys(parameters).length < 1) {
      return path;
    }

    let result = '?';
    let first = true;

    for (const key of Object.keys(parameters)) {
      if (first) {
        first = false;
      } else {
        result += '&';
      }

      result += `${key}=${encodeURIComponent(parameters[key])}`;
    }

    return path + result;
  }

  protected generateUrlPathFromParams(
    path: string,
    urlParameters: string[] = []
  ): string {
    if (urlParameters && urlParameters.length) {
      path = path + '/' + urlParameters.join('/');
    }
    return path;
  }

  getApiStatus<T>(url: string): Observable<T> {
    return this.http.get<T>(`${url}/api/detailed-status`);
  }
}
