<div class="content-heading">
  <ul class="breadcrumb pull-right" style="margin-right: 100px">
    <li><app-upload-progress></app-upload-progress></li>
  </ul>
  <ul class="breadcrumb">
    <li><a routerLink="">Home</a></li>
    <li
      *ngFor="let breadcrumb of breadcrumbs | async; let last = last"
      [ngClass]="{ active: last }"
    >
      <a *ngIf="!last" [routerLink]="[breadcrumb.url]">{{
        breadcrumb.label
      }}</a>
      <span *ngIf="last">{{ breadcrumb.label }}</span>
    </li>
  </ul>
</div>
