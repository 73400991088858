import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { BuildNumberComponent } from './build-number/build-number.component';
import { CompleteInviteComponent } from './complete-invite/complete-invite.component';
import { DeniedComponent } from './denied/denied.component';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';
import { LoginComponent } from './login/login.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { RecoverComponent } from './recover/recover.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    CompleteInviteComponent,
    DeniedComponent,
    LoginComponent,
    RecoverComponent,
    Error404Component,
    Error500Component,
    PasswordResetComponent,
    BuildNumberComponent,
  ],
  exports: [
    CompleteInviteComponent,
    DeniedComponent,
    RouterModule,
    LoginComponent,
    RecoverComponent,
    Error404Component,
    Error500Component,
    PasswordResetComponent,
    BuildNumberComponent,
  ],
})
export class PagesModule {}
