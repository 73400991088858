import * as $ from 'jquery';
import { filter } from 'rxjs/operators';

import { Component, HostBinding, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { environment } from '../environments/environment';
import { SettingsService } from './core/settings/settings.service';
import { StateSubscriptionManager } from './core/state/state-subscription-manager';

declare var ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('class.layout-fixed')
  get isFixed() {
    return this.settings.layout.isFixed;
  }
  @HostBinding('class.aside-collapsed')
  get isCollapsed() {
    return this.settings.layout.isCollapsed;
  }
  @HostBinding('class.layout-boxed')
  get isBoxed() {
    return this.settings.layout.isBoxed;
  }
  @HostBinding('class.layout-fs')
  get useFullLayout() {
    return this.settings.layout.useFullLayout;
  }
  @HostBinding('class.hidden-footer')
  get hiddenFooter() {
    return this.settings.layout.hiddenFooter;
  }
  @HostBinding('class.layout-h')
  get horizontal() {
    return this.settings.layout.horizontal;
  }
  @HostBinding('class.aside-float')
  get isFloat() {
    return this.settings.layout.isFloat;
  }
  @HostBinding('class.offsidebar-open')
  get offsidebarOpen() {
    return this.settings.layout.offsidebarOpen;
  }
  @HostBinding('class.aside-toggled')
  get asideToggled() {
    return this.settings.layout.asideToggled;
  }
  @HostBinding('class.aside-collapsed-text')
  get isCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  firstUrlSet = false;

  constructor(
    public router: Router,
    public settings: SettingsService,
    private stateSubscriptionManager: StateSubscriptionManager
  ) {
    // set google analyics ua id based on environment
    ga('create', environment.google.analytics.ua, 'auto');

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          ga('send', 'pageview', event['url']);
        }
      });
  }

  ngOnInit() {
    $(document).on('click', '[href="#"]', e => e.preventDefault());

    this.stateSubscriptionManager.onInitSubscribe();
  }
}
