import * as moment from 'moment';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SummaryInterfaces } from '../../model/summary/summary.interfaces';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { DatabaseApiService } from '../database-api/database-api.service';
import { StateManager } from '../state/state-manager';

import IDashboardSummary = SummaryInterfaces.IDashboardSummary;
export interface IHasProducts {
  hasApolloData: boolean;
  hasEngageData: boolean;
  hasFocusData: boolean;
  hasRehabData: boolean;
}

export const SELECTED_RANGE_FOR_NAMES = {
  YEAR: 'year',
  MONTH: 'month',
  DAY: 'day',
  HOUR: 'hour',
};

export interface ISelectedRange {
  for: string;
  date: moment.Moment;
  productFilter: string;
  useAverages: boolean;
}

export interface IChartData {
  data: [string, number][];
}

export interface IPieChartData {
  data: { label: string; data: number }[];
}

export interface IAccountFacilitiesWithUsage {
  [facilityId: string]: {
    resident_count: number;
    devices_with_usage: string[];
    residents_with_usage: string[];
  };
}

export interface IContentReport {
  data: {
    title: string;
    display_type: string;
    accessed_count: number;
  }[];
}

@Injectable()
export class DashboardApiService extends DatabaseApiService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    cacheService: CacheService,
    stateManager: StateManager
  ) {
    super(http, jwtService, stateManager, cacheService);
  }

  /**
   * Analytics Routes
   */
  getAccountDashboards(accountId: string): Observable<IDashboardSummary[]> {
    return this.get<IDashboardSummary[]>(
      `${this.databaseApiUrl}/namespace/summary/type/account-dashboard/docs?accountId=${accountId}`
    );
  }

  getRegionsDashboards(
    accountId: string,
    regionId: string
  ): Observable<IDashboardSummary[]> {
    return this.get<IDashboardSummary[]>(
      `${this.databaseApiUrl}/namespace/summary/type/regions-dashboard/docs?accountId=${accountId}&regionId=${regionId}`
    );
  }

  getCommunityDashboards(facilityId: string): Observable<IDashboardSummary[]> {
    return this.get<IDashboardSummary[]>(
      `${this.databaseApiUrl}/namespace/summary/type/community-dashboard/docs?facilityId=${facilityId}`
    );
  }

  getResidentDashboards(residentId: string): Observable<IDashboardSummary[]> {
    return this.get<IDashboardSummary[]>(
      `${this.databaseApiUrl}/namespace/summary/type/resident-dashboard/docs?residentId=${residentId}`
    );
  }

  getDeviceDashboards(deviceId: string): Observable<IDashboardSummary[]> {
    return this.get<IDashboardSummary[]>(
      `${this.databaseApiUrl}/namespace/summary/type/device-dashboard/docs?deviceId=${deviceId}`
    );
  }
}
