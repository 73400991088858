import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Facility } from '../../model/facility/facility';
import { IRegion, IRegions } from '../../model/regions/regions';
import {
  IRegionOption,
  IRegionsDisplay,
} from '../../model/regions/regions-display';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { DatabaseApiService } from '../database-api/database-api.service';
import { FacilityService } from '../facility/facility.service';
import { StateManager } from '../state/state-manager';

const REGION_URL =
  environment.databaseApi.url + '/namespace/account/type/regions/doc';
const REGIONS_URL = REGION_URL + 's';

@Injectable()
export class RegionsService extends DatabaseApiService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    stateManager: StateManager,
    cacheService: CacheService,
    private facilityService: FacilityService
  ) {
    super(http, jwtService, stateManager, cacheService);
  }

  getRegion(accountId: string, regionId: string): Observable<IRegion> {
    return this.get<IRegion>(
      REGION_URL + `?accountId=${accountId}&regionId=${regionId}`
    );
  }

  getRegionsDocument(accountId: string): Observable<IRegions> {
    return this.get<IRegions>(REGIONS_URL + `?accountId=${accountId}`).pipe(
      map(region => {
        return region && region._id ? region : null;
      })
    );
  }

  updateRegions(regions: IRegions): Observable<IRegions> {
    return this.put<IRegions>(REGION_URL, regions);
  }

  getRegionOptions(accountId: string): Observable<IRegionOption[]> {
    return this.getRegionsDocument(accountId).pipe(
      map(regionsDoc => {
        if (!regionsDoc) {
          return [];
        }
        return regionsDoc.regions
          .filter(r => !r.archived)
          .map(r => {
            return {
              region_id: r.region_id,
              region_name: r.region_name,
            };
          })
          .sort((a, b) => a.region_name.localeCompare(b.region_name));
      })
    );
  }

  getCurrentRegionId(facility: Facility): string {
    return (
      (facility.profile.region_assignment || []).find(f => !f.end_date)
        ?.region_id || ''
    );
  }

  setCurrentRegion(facility: Facility, regionId: string) {
    const assignments = facility.profile.region_assignment || [];
    if (!assignments.length && !regionId) {
      return;
    }

    if (assignments.length > 0) {
      const lastest = assignments[assignments.length - 1];

      if (lastest.region_id === regionId) {
        return;
      }

      lastest.end_date = moment.utc().format();
    }

    assignments.push({
      start_date: moment.utc().format(),
      region_id: regionId,
    });

    facility.profile.region_assignment = assignments;
  }

  getRegionsDisplayFromRegionsDoc(
    accountId: string,
    regionsDoc: IRegions
  ): Observable<IRegionsDisplay> {
    return this.facilityService.getAccountFacilities(accountId).pipe(
      map(facilities => {
        const regionsDisplay: IRegionsDisplay = {
          regions: [],
          regionDoc: regionsDoc,
        };

        regionsDoc.regions
          .filter(r => !r.archived)
          .forEach(region => {
            const regionDisplay = {
              region_id: region.region_id,
              region_name: region.region_name,
              communities: facilities
                .filter(f =>
                  (f.profile.region_assignment || []).some(
                    r => r.region_id === region.region_id && !r.end_date
                  )
                )
                .map(f => f.profile.name),
            };

            if (regionDisplay.communities.length === 0) {
              regionDisplay.communities.push('None');
            }

            regionsDisplay.regions.push(regionDisplay);
          });

        regionsDisplay.regions = regionsDisplay.regions.sort((a, b) =>
          a.region_name.localeCompare(b.region_name)
        );

        return regionsDisplay;
      })
    );
  }

  getRegionsDisplay(accountId: string): Observable<IRegionsDisplay> {
    return this.getRegionsDocument(accountId).pipe(
      mergeMap(regionsDoc => {
        if (!regionsDoc) {
          return [];
        }
        return this.getRegionsDisplayFromRegionsDoc(accountId, regionsDoc);
      })
    );
  }
}
