import { forkJoin, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
} from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserConstants } from '../../constants/user.constants';
import { Account } from '../../model/account/account';
import { Facility } from '../../model/facility/facility';
import { EMPTY_AUTH_STATE, IAuthState } from '../../model/state/auth-state';
import { IRouterEventState } from '../../model/state/url-state';
import { User } from '../../model/user/user';
import { navigateToUserDefaultHomeUrl } from '../../util/navigation-utils';
import { AccountService } from '../account/account.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { FacilityService } from '../facility/facility.service';
import { UiEventService } from '../ui-event-service/ui-event-service';
import { ToasterMessage } from '../ui-event-service/ui-toaster-message';
import { UnauthorizedMessage } from '../ui-event-service/ui-unauthorized';
import { StateManager } from './state-manager';
import { UrlStateService } from './url-state.service';

@Injectable()
export class AuthStateService {
  constructor(
    private accountService: AccountService,
    private authenticationService: AuthenticationService,
    private facilityService: FacilityService,
    private router: Router,
    private stateManager: StateManager,
    private uiEventService: UiEventService,
    private urlStateService: UrlStateService
  ) {}

  /**
   * Monitor URL changes only
   * - The AuthenticationService is responsible for setting or clearing
   *   the currentUser BehaviorSubject.
   * - The page signing in or signing out the user is responsible
   *   for navigating to the next page.
   * Tasks:
   * - Update the auth state to reflect all changes
   * - Update sidebar selected account and community based on IDs in the URL
   * - Allow the sidebar account and community selector subscriptions handle navigation
   *   to default URLs when needed
   */
  authStateUpdaterSubscription(): Subscription {
    return this.urlStateService
      .routerEventStream()
      .pipe(
        filter((routerEventState: IRouterEventState) => {
          return routerEventState !== null;
        }),
        mergeMap((routerEventState: IRouterEventState) => {
          const currentUser = this.stateManager.getCurrentUser();
          if (!currentUser) {
            return forkJoin([
              of(routerEventState),
              of(currentUser),
              of(''),
              of([]),
              of([]),
            ]);
          }

          return forkJoin([
            of(routerEventState),
            of(currentUser),
            this.authenticationService.validateUser(),
            this.getAccountList(currentUser),
            this.getDefaultFacilityList(
              currentUser,
              routerEventState.urlIds.accountId
            ),
          ]);
        }),
        catchError(e => {
          const httpError = e as HttpErrorResponse;

          if (httpError && httpError.error && httpError.error.status === 401) {
            this.stateManager.handleErrorEvent({ type: 'UNAUTHORIZED' });
          } else {
            this.stateManager.handleErrorEvent({
              type: 'UNKNOWN_ERROR',
            });
          }

          return of(null);
        })
      )
      .subscribe(
        ([routerEventState, currentUser, username, accountList, facilityList]: [
          IRouterEventState,
          User,
          string,
          Account[],
          Facility[]
        ]) => {
          // Build a new authState to reflect the current url and user
          const authState = this.buildAuthState(
            routerEventState,
            currentUser,
            accountList
          );

          if (!username || username !== currentUser.email) {
            this.stateManager.handleErrorEvent({ type: 'UNAUTHORIZED' });
            return;
          }

          // Update the accounts and facility lists
          // This will only change if the user signs out (set to empty array)
          // or back in again as a different user
          this.stateManager.updateSidebarAccountList(accountList);
          this.stateManager.updateSidebarFacilityList(facilityList);

          // Update the authState BehaviorSubject
          // Monitored by SidebarComponent, SidebarGroup, and UserBlockComponent
          this.stateManager.setAuthState(authState);
          const authorizedFacility = facilityList.find(
            facility => facility._id === authState.urlIds.facilityId
          );
          // Send account and facility admins to default user home page if needed
          if (
            !routerEventState.currentUrl.includes(
              UserConstants.USER_PROFILE_PAGE_URL
            ) &&
            this.stateManager.userIsAccountOrFacilityAdmin(
              authState.userDocType
            ) &&
            (authState.urlIds.accountId !== authState.userDefaultAccountId ||
              (authState.urlIds.facilityId !== '' &&
                facilityList.length > 0 &&
                !authorizedFacility))
          ) {
            // Pause other subscriptions to give the navigate time to settle
            this.stateManager.pauseSubscriptions();

            navigateToUserDefaultHomeUrl(
              this.router,
              currentUser,
              this.stateManager,
              authState.dashboardsEnabled
            );
            return;
          }

          // Pause sidebar state management subscriptions in order to override their behavior
          // Update sidebar selections based on the url but don't run the subscriptions
          // since the user is already on a valid page
          this.stateManager.pauseSubscriptions();
          this.stateManager.updateSidebarAccountIdSelection(
            authState.urlIds.accountId
          );
          this.stateManager.updateSidebarFacilityIdSelection(
            authState.urlIds.facilityId
          );
          this.stateManager.resumeSubscriptions();

          // Redirect; This has to be done here since our actual login process
          //   isn't tied to our authState :facepalm:
          const redirectUrl = this.stateManager.getRedirectUrl();
          if (redirectUrl) {
            this.router.navigateByUrl(redirectUrl);
            this.stateManager.setRedirectUrl('');
          }
        }
      );
  }

  /**
   * Monitor for unauthorized errors
   * Do one, both, or neither of the following based on the submitted error behavior
   * - Dispatch an toaster message error, custom or default, to the user
   * - Clear state on unauthorized errors so the user will have to sign-in again after refreshing the page
   */
  errorEventSubscription(): Subscription {
    return this.stateManager
      .getErrorEventSubject()
      .pipe(debounceTime(200))
      .subscribe(errorEvent => {
        if (errorEvent.type === 'UNKNOWN_ERROR') {
          this.uiEventService.dispatch(
            new ToasterMessage({
              title: 'Error',
              body:
                errorEvent.message ||
                'Unknown server error. Please refresh the page and try again.',
              type: 'error',
            })
          );
        } else if (errorEvent.type === 'UNAUTHORIZED') {
          this.uiEventService.dispatch(new UnauthorizedMessage());
          this.stateManager.resetAuthState();
        }
      });
  }

  private getAccountList(currentUser: User): Observable<Account[]> {
    if (!currentUser) {
      return of([]);
    }

    // in2l-admins get all accounts, account and community admins only get their own
    const accountList = this.stateManager.getSidebarAccountList() || [];
    let accountListObservable: Observable<Account[]>;
    if (this.stateManager.userIsIn2lAdmin(currentUser.doc_type || '')) {
      accountListObservable =
        accountList.length > 1
          ? of(accountList)
          : this.accountService.getAccounts();
    } else if (
      this.stateManager.userIsIn2lContent(currentUser.doc_type || '')
    ) {
      accountListObservable = of([]);
    } else {
      accountListObservable =
        accountList.length === 1 &&
        accountList[0]._id === currentUser.account_id
          ? of(accountList)
          : this.accountService.getAccount(currentUser.account_id || '').pipe(
              map(account => {
                return [account];
              })
            );
    }

    return accountListObservable;
  }

  private getDefaultFacilityList(
    currentUser: User,
    urlAccountId: string
  ): Observable<Facility[]> {
    const accountId = urlAccountId || currentUser.account_id || '';
    if (
      !accountId ||
      this.stateManager.userIsIn2lContent(currentUser.doc_type)
    ) {
      return of([]);
    }

    return this.facilityService.getAccountFacilities(accountId).pipe(
      map(facilities => {
        return this.stateManager.userIsFacilityAdmin(currentUser.doc_type)
          ? facilities.filter(f =>
              (currentUser.facility_ids || []).includes(f._id)
            )
          : facilities;
      })
    );
  }

  private buildAuthState(
    routerEventState: IRouterEventState,
    currentUser: User,
    accountList: Account[]
  ): IAuthState {
    if (!currentUser) {
      return <IAuthState>Object.assign({}, EMPTY_AUTH_STATE, routerEventState);
    }

    const account = this.stateManager.userIsAccountOrFacilityAdmin(
      currentUser.doc_type
    )
      ? accountList.find(a => a._id === currentUser.account_id)
      : null;

    const isIn2lAdmin = this.stateManager.userIsIn2lAdmin(
      (currentUser || {}).doc_type
    );

    const username = (currentUser || {}).email || '';
    const dashboardsEnabled =
      isIn2lAdmin || !!(account || {}).dashboards_enabled;
    const userDocType = (currentUser || {}).doc_type || '';
    const userDefaultAccountId = currentUser
      ? this.stateManager.getDefaultAccountId(currentUser)
      : '';
    const userDefaultFacilityId = currentUser
      ? this.stateManager.getDefaultFacilityId(currentUser)
      : '';
    const userFacilityIds = (currentUser || {}).facility_ids || [];
    const newState: IAuthState = {
      ...routerEventState,
      username,
      userSignedIn: !!userDocType,
      dashboardsEnabled,
      userDocType,
      userDefaultAccountId,
      userDefaultFacilityId,
      userFacilityIds,
    };

    return newState;
  }
}
