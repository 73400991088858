import { CustomValidators } from 'ng2-validation';

import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { FamilyPortalUser } from '../../model/family-portal-user/family-portal-user';
import { FamilyPortalUserList } from '../../model/family-portal-user/family-portal-user-list';
import { Resident } from '../../model/resident/resident';
import { ResidentContact } from '../../model/resident/resident-contact';
import { DateUtils } from '../../util/date-utils';
import { AppValidator } from '../../util/form-utils';
import { PhoneNumberUtils } from '../../util/phone-number-utils';
import { InputService } from '../input/input.service';

@Injectable()
export class ResidentFormService {
  constructor(private fb: FormBuilder) {}
  buildContactsFormArray(resident: Resident): FormArray {
    const formArray = this.fb.array([]);

    resident.family.members.forEach((contact: ResidentContact) => {
      formArray.push(this.formGroupFromContact(contact));
    });

    return formArray;
  }

  buildFamilyPortalConnectionsArray(
    familyPortalConnectionsList: FamilyPortalUserList,
    residentId?: string
  ): FormArray {
    const formArray = this.fb.array([]);

    familyPortalConnectionsList.members.forEach(
      (familyPortalUser: FamilyPortalUser) => {
        formArray.push(
          this.formGroupFromFamilyPortalUser(familyPortalUser, residentId)
        );
      }
    );

    return formArray;
  }

  buildResidentForm(
    resident: Resident,
    editMode: boolean,
    contactsForm: FormArray
  ): FormGroup {
    return this.fb.group({
      birthdate: [
        editMode ? DateUtils.setDateDisplayFormat(resident.birthdate) : '',
        Validators.compose([CustomValidators.date, AppValidator.dateExists()]),
      ],
      customer_resident_id: [
        { value: resident.customer_resident_id || '', disabled: true },
      ],
      lifeloop_resident_id: [editMode ? resident.lifeloop_resident_id : null],
      interests: [editMode ? resident.interests : []],
      allow_family_interest_modification: [
        resident.allow_family_interest_modification,
      ],
      first_name: [editMode ? resident.first_name : null, Validators.required],
      last_name: [editMode ? resident.last_name : null, Validators.required],
      move_in_date: [
        {
          value: DateUtils.setDateDisplayFormat(resident.move_in_date) || '',
          disabled: true,
        },
      ],
      move_out_date: [
        {
          value: DateUtils.setDateDisplayFormat(resident.move_out_date) || '',
          disabled: true,
        },
      ],
      pin: [
        editMode ? resident.pin : null,
        Validators.compose([
          Validators.required,
          Validators.pattern(InputService.PIN_VALIDATION_PATTERN),
        ]),
      ],
      room_number: [editMode ? resident.room_number : ''],
      nickname: [{ value: resident.nickname || '', disabled: true }],
      recommended_search_terms: [
        editMode ? resident.recommended_search_terms : '',
      ],
      family: this.fb.group({
        members: contactsForm,
      }),
    });
  }

  newContactFormGroup(contactsFormArray: FormArray): FormGroup {
    return this.formGroupFromContact(new ResidentContact());
  }

  formGroupFromContact(contact: ResidentContact): FormGroup {
    return this.fb.group({
      name: [contact.name || ''],
      first_name: [
        contact.first_name || contact.name || '',
        Validators.compose([Validators.required, Validators.maxLength(18)]),
      ],
      last_name: [
        contact.last_name || '',
        Validators.compose([Validators.maxLength(18)]),
      ],
      relation: [
        contact.relation || '',
        Validators.compose([Validators.maxLength(100)]),
      ],
      description: [
        contact.description || '',
        Validators.compose([Validators.maxLength(200)]),
      ],
      email: [contact.email || '', CustomValidators.email],
      phone: [
        PhoneNumberUtils.setPhoneNumberToStandardDisplayFormat(contact.phone) ||
          '',
        Validators.compose([Validators.required]),
      ],
      twilio_blacklist: [contact.twilio_blacklist || 'false'],
      twilio_blacklist_date: [contact.twilio_blacklist_date || ''],
      contact_image_data: [''],
      is_duplicate_phone: [
        false,
        Validators.compose([
          (formControl: AbstractControl): { [key: string]: any } => {
            return formControl.value
              ? { notUnique: { value: formControl.value } }
              : null;
          },
        ]),
      ],
      remove_contact_image: ['false'],
    });
  }

  newFamilyPortalConnectionFormGroup(
    familyPortalConnectionsArray: FormArray
  ): FormGroup {
    return this.formGroupFromFamilyPortalUser(new FamilyPortalUser());
  }

  formGroupFromFamilyPortalUser(
    fpUser: FamilyPortalUser,
    residentId?: string
  ): FormGroup {
    return this.fb.group({
      email: [
        fpUser.email || '',
        Validators.compose([Validators.required, CustomValidators.email]),
      ],
      userName: [
        fpUser.first_name
          ? fpUser.last_name
            ? `${fpUser.first_name} ${fpUser.last_name}`
            : fpUser.first_name
          : '',
      ],
      status: this.fpUserStatusMask(fpUser, residentId),
    });
  }

  fpUserStatusMask(fpUser: FamilyPortalUser, residentId?: string): string {
    if (
      fpUser.status !== 'active' ||
      !residentId ||
      !fpUser.associated_residents.map(r => r._id).includes(residentId)
    ) {
      return fpUser.status || '';
    } else {
      return fpUser.associated_residents.find(r => r._id === residentId).status;
    }
  }
}
