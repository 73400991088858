import { ModalDirective } from 'ngx-bootstrap/modal';

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-image-picker-control',
  templateUrl: './image-picker-control.component.html',
  styleUrls: ['./image-picker-control.component.scss'],
})
export class ImagePickerControlComponent implements OnInit {
  @Input() existingImageSrc: string;
  @Input() width: number = 300;
  @Input() height: number = 300;
  @Input() label: string = 'Image';

  @ViewChild('removeImageModal')
  removeImageModal: ModalDirective;

  @Output() onImageChanged: EventEmitter<any> = new EventEmitter();
  @Output() onRemoveImage: EventEmitter<any> = new EventEmitter();

  imageSrc: string;
  loading = false;
  showUpload: boolean;
  uploadedFile: File | undefined;
  uploadError = '';
  allowedFilesRegex: RegExp;

  constructor() {}

  ngOnInit() {
    this.allowedFilesRegex = new RegExp(/\.(jpe?g|png)$/, 'i');
  }

  fileChangeListener($event) {
    this.uploadedFile = $event.target.files[0];

    if (!this.uploadedFile) {
      this.showUpload = false;
      return;
    }

    const fileReader = new FileReader();
    fileReader.onloadend = _ => {
      this.imageSrc = fileReader.result as string;
      this.showUpload = true;
    };

    let uploadError = '';

    if (!this.allowedFilesRegex.test(this.uploadedFile.name)) {
      uploadError = 'Please select a jpg or png image for upload.';
    } else if (this.uploadedFile.size / 1000000 > 2) {
      uploadError = 'File size must not be greater than 2 megabytes';
    }

    if (uploadError === '') {
      fileReader.readAsDataURL(this.uploadedFile);
    } else {
      this.showUpload = false;
    }

    this.uploadError = uploadError;
  }

  getUploadedFile(): { contentType: string; imageBlob: Blob } | undefined {
    if (this.uploadedFile) {
      return {
        contentType: this.uploadedFile.type,
        imageBlob: this.uploadedFile,
      };
    } else {
      return undefined;
    }
  }

  removeImage($event) {
    if ($event.preventDefault) {
      $event.preventDefault();
    }

    this.removeImageModal.show();
  }

  removeImageConfirm() {
    this.uploadedFile = undefined;
    this.onRemoveImage.emit();
    this.removeImageModal.hide();
  }

  cancelConfirm() {
    this.uploadedFile = undefined;
    this.removeImageModal.hide();
  }

  cancelUpload() {
    this.showUpload = false;
  }
}
