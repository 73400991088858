import { DocTypeConstants } from '../../constants/doc-types';
import { UserConstants } from '../../constants/user.constants';
import { MediaInterfaces } from '../media/media-interfaces';
import { SyncGatewayModel } from '../sync-gateway/sync-gateway-model';
import { SyncGatewayInterfaces } from '../sync-gateway/sync-gateway.interfaces';
import { AccountAdminUser } from './account-admin-user';
import { FacilityAdminUser } from './facility-admin-user';
import { FacilityUser } from './facility-user';
import { In2lAdminUser } from './in2l-admin-user';
import { In2lContentUser } from './in2l-content-user';
import { UserInterfaces } from './user.interfaces';

// prettier-ignore
type In2lUserType = AccountAdminUser
  | FacilityAdminUser
  | FacilityUser
  | In2lAdminUser
  | In2lContentUser;

class User extends SyncGatewayModel implements UserInterfaces.IUser {
  account_id?: string;
  email?: string;
  facility_ids?: string[];
  first_name?: string;
  has_temporary_pin?: boolean;
  last_name?: string;
  created_by?: string;
  created_date?: string;
  media?: MediaInterfaces.IMediaEntries;
  pin?: string;
  phone?: string;
  resident_ids?: string[];
  resident_mode?: string;
  status?: string;
  title?: string;

  constructor(
    data: {
      account_id?: string;
      email?: string;
      facility_ids?: string[];
      first_name?: string;
      has_temporary_pin?: boolean;
      last_name?: string;
      pin?: string;
      phone?: string;
      resident_ids?: string[];
      resident_mode?: string;
      status?: string;
      title?: string;
      _id?: string;
      _rev?: string;
      _attachments?: { [key: string]: SyncGatewayInterfaces.IAttachment };
      created_by?: string;
      created_date?: string;
      doc_type?: string;
      modified_by?: string;
      modified_date?: string;
      apollo_id?: number;
      apollo_import?: boolean;
      media?: MediaInterfaces.IMediaEntries;
    } = {}
  ) {
    super(
      data._id,
      data._rev,
      data._attachments,
      data.created_by,
      data.created_date,
      DocTypeConstants.NAMESPACES.USER,
      data.doc_type,
      data.modified_by,
      data.modified_date,
      data.apollo_id,
      data.apollo_import
    );

    this.account_id = data.account_id;
    this.email = data.email;
    this.facility_ids = data.facility_ids;
    this.first_name = data.first_name;
    this.has_temporary_pin = data.has_temporary_pin;
    this.last_name = data.last_name;
    this.created_by = data.created_by;
    this.created_date = data.created_date;
    this.media = data.media;
    this.pin = data.pin;
    this.phone = data.phone;
    this.resident_ids = data.resident_ids;
    this.resident_mode = data.resident_mode;
    this.status = data.status;
    this.title = data.title;
  }

  // in2l-admin and in2l users do not collect phone numbers, but the rest of user types
  // do, can use this method to determine if a phone should be collected for any user type
  shouldCollectPhoneNumber(): boolean {
    return (
      this.doc_type !== DocTypeConstants.TYPES.USER.IN2L_ADMIN &&
      this.doc_type !== DocTypeConstants.TYPES.USER.IN2L_CONTENT
    );
  }

  accountTypeName(): string {
    const userType = UserConstants.USER_TYPES_LIST.find(
      type => type.type === this.doc_type
    );
    return userType.name;
  }

  isIn2lAdmin(): boolean {
    return this.doc_type === DocTypeConstants.TYPES.USER.IN2L_ADMIN;
  }

  hasProfileImage(): boolean {
    return !!(
      this.media &&
      this.media[UserConstants.USER_PROFILE_IMAGE_KEY] &&
      this.media[UserConstants.USER_PROFILE_IMAGE_KEY].s3_key
    );
  }

  profileImagePath(): string {
    if (this.hasProfileImage()) {
      const path = this.attachmentImagePath(this.profileImageKey());
      return path;
    }

    return '/assets/img/user/generic.png';
  }
}

export { In2lUserType, User };
