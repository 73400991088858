import { DocTypeConstants } from './doc-types';

export namespace UserConstants {
  export const USER_STATUS_INVITED = 'invited';
  export const USER_STATUS_ACTIVE = 'active';
  export const USER_STATUS_INACTIVE = 'inactive';
  export const USER_STATUS_ALL = 'all';

  export const USER_RESIDENT_MODE_ALL = 'all';
  export const USER_RESIDENT_MODE_SELECT = 'select';

  export const USER_TYPES_LIST = [
    { name: 'iN2L Admin User', type: DocTypeConstants.TYPES.USER.IN2L_ADMIN },
    {
      name: 'iN2L Content User',
      type: DocTypeConstants.TYPES.USER.IN2L_CONTENT,
    },
    {
      name: 'Account Admin User',
      type: DocTypeConstants.TYPES.USER.ACCOUNT_ADMIN,
    },
    {
      name: 'Community Admin User',
      type: DocTypeConstants.TYPES.USER.FACILITY_ADMIN,
    },
    { name: 'Community User', type: DocTypeConstants.TYPES.USER.FACILITY_USER },
    {
      name: 'Family Portal User',
      type: DocTypeConstants.TYPES.USER.FAMILY_PORTAL_USER,
    },
  ];

  export const USER_PROFILE_IMAGE_KEY = 'profile_image';
  export const USER_PROFILE_PAGE_URL = '/profile';
}
