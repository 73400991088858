import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { UiEventService } from '../ui-event-service/ui-event-service';
import { UploadFailedMessage } from '../ui-event-service/ui-queue-upload';
import { ProgressService } from '../upload/progress.service';

const signedPolicy = environment.bucketPolicy.fields;
export const S3_HOST = environment.bucketPolicy.host;

export interface S3HeaderResponse {
  key: string;
  etag: string;
}

@Injectable()
export class AwsService {
  constructor(
    private http: HttpClient,
    protected progressService: ProgressService,
    private uiEventService: UiEventService
  ) {}

  getFormInfo(key: string, file: File) {
    const data = new FormData();

    data.append('acl', signedPolicy.acl);
    data.append('Policy', signedPolicy.policy);
    data.append('X-Amz-Signature', signedPolicy['x-amz-signature']);
    data.append('key', key);
    data.append('X-Amz-Credential', signedPolicy['x-amz-credential']);
    data.append('X-Amz-Algorithm', signedPolicy['x-amz-algorithm']);
    data.append('X-Amz-Date', signedPolicy['x-amz-date']);
    data.append('Content-Type', file.type);

    data.append('file', file);

    return data;
  }

  uploadNewPublicFile(key: string, file: File): Observable<S3HeaderResponse> {
    const requestOptions = new HttpRequest(
      'POST',
      S3_HOST,
      this.getFormInfo(key, file)
    );

    return this.http
      .post(S3_HOST, this.getFormInfo(key, file), {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map(
          (response: any): S3HeaderResponse => {
            if (response.type === HttpEventType.UploadProgress) {
              this.progressService.uploadProgress.next(response);
            }
            return {
              key,
              etag: !!response.headers
                ? response.headers.get('ETag')
                : undefined,
            };
          }
        ),
        catchError((error: any) => {
          this.uiEventService.dispatch(new UploadFailedMessage(key));
          return throwError(new Error(error || 'Server error'));
        })
      );
  }
}
