import { Injectable, OnDestroy } from '@angular/core';

import { SubscriptionTracker } from './subscription-tracker';

/**
 * A class that automatically unsubscribes all observables when the object gets destroyed
 */
@Injectable()
export class UnsubscribeOnDestroy implements OnDestroy {
  /**
   * The subscriptions object that stores all subscriptions
   */
  subscriptionTracker = new SubscriptionTracker();

  /**
   * The lifecycle hook that unsubscribes all subscriptions when the component / object gets destroyed
   */
  ngOnDestroy(): void {
    this.subscriptionTracker.unsubscribe();
  }
}
