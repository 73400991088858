export const ADDRESS_COUNTRY_US = 'United States';
export const ADDRESS_COUNTRY_CANADA = 'Canada';

export interface IAddress {
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  province?: string;
  postal_code?: string;
  state?: string;
  zip_code?: string;

  // Other names for fields that need to be converted
  state_province?: string;
  zip_postal_code?: string;
}

export class Address implements IAddress {
  address1: string;
  address2: string;
  city: string;
  country: string;
  province: string;
  postal_code: string;
  state: string;
  zip_code: string;

  state_province: string = '';
  zip_postal_code: string = '';

  constructor(data: IAddress = {}) {
    this.address1 = data.address1 || '';
    this.address2 = data.address2 || '';
    this.city = data.city || '';
    this.country = data.country || '';
    this.state =
      (this.country === ADDRESS_COUNTRY_US && data.state_province
        ? data.state_province
        : data.state) || '';
    this.province =
      (this.country === ADDRESS_COUNTRY_CANADA && data.state_province
        ? data.state_province
        : data.province) || '';
    this.zip_code =
      (this.country === ADDRESS_COUNTRY_US && data.zip_postal_code
        ? data.zip_postal_code
        : data.zip_code) || '';
    this.postal_code =
      (this.country === ADDRESS_COUNTRY_CANADA && data.zip_postal_code
        ? data.zip_postal_code
        : data.postal_code) || '';
  }

  getStateProvince() {
    if (this.country === ADDRESS_COUNTRY_CANADA) {
      return this.province;
    }

    return this.state;
  }

  getPostalZip() {
    if (this.country === ADDRESS_COUNTRY_CANADA) {
      return this.postal_code;
    }

    return this.zip_code;
  }

  setStateOrProvinceForCountry(
    country: string,
    state: string = '',
    province: string = ''
  ) {
    if (country === ADDRESS_COUNTRY_CANADA) {
      this.state = '';
      this.province = province;
    } else {
      this.state = state;
      this.province = '';
    }
  }

  setZipOrPostalCodeForCountry(
    country: string,
    zip: string = '',
    postal: string = ''
  ) {
    if (country === ADDRESS_COUNTRY_CANADA) {
      this.zip_code = '';
      this.postal_code = postal;
    } else {
      this.zip_code = zip;
      this.postal_code = '';
    }
  }
}
