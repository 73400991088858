import { FamilyPortalUserInterfaces } from './family-portal-user.interfaces';

export class FamilyPortalUser {
  _id?: string;
  _rev?: string;
  created_by?: string;
  created_date?: string;
  doc_namespace?: string;
  doc_type?: string;
  phone?: string;
  modified_by?: string;
  modified_date?: string;
  invitation_code?: string;

  user_type: string;
  email: string;
  first_name?: string;
  last_name?: string;
  status?: string;
  associated_residents: FamilyPortalUserInterfaces.IAssociatedResident[];

  constructor(data?: FamilyPortalUserInterfaces.IFamilyPortalUser) {
    data = data || ({} as FamilyPortalUserInterfaces.IFamilyPortalUser);

    this._id = data._id || '';
    this._rev = data._rev || '';
    this.created_by = data.created_by || '';
    this.created_date = data.created_date || '';
    this.doc_namespace = 'user';
    this.doc_type = 'family-portal-user';
    this.phone = data.phone || '';
    this.modified_by = data.modified_by || '';
    this.modified_date = data.modified_date || '';
    this.invitation_code = data.invitation_code || '';

    this.user_type = data.user_type || '';
    this.email = data.email || '';
    this.first_name = data.first_name || '';
    this.last_name = data.last_name || '';
    this.status = data.status || '';
    this.associated_residents = data.associated_residents || [];
  }
}
