import { DocTypeConstants } from '../../constants/doc-types';
import { SyncGatewayModel } from '../sync-gateway/sync-gateway-model';
import { SyncGatewayInterfaces } from '../sync-gateway/sync-gateway.interfaces';
import { FacilityBillingProfile } from './facility-billing-profile';
import { FacilityProfile } from './facility-profile';

export interface IFacility extends SyncGatewayInterfaces.ISyncGatewayModel {
  account_id?: string;
  facility_type?: 'customer' | 'demo' | 'test' | '';
  billing?: FacilityBillingProfile;
  created_by?: string;
  created_date?: string;
  disable_passcode_change?: boolean;
  modified_by?: string;
  modified_date?: string;
  profile?: FacilityProfile;
  require_pin_for_admin?: boolean;
  tablet_enable_inbound_messaging?: boolean;
  enable_lifeloop?: boolean;
  enable_lifeloop_data_sync?: boolean;
  lifeloop_price_tier?: string;
  lifeloop_facilities?: {
    lifeloop_facility_id: string;
    lifeloop_price_tier: string;
  }[];
  tablet_enable_lifeloop?: boolean;
  iconnect?: {
    portal: boolean;
    devices: boolean;
    disable_device_media_upload: boolean;
  };
  status?: { name: string };
  coro_content?: boolean;
  high_impact_content?: boolean;
}

export class Facility extends SyncGatewayModel implements IFacility {
  account_id: string;
  facility_type: 'customer' | 'demo' | 'test' | '';
  billing: FacilityBillingProfile;
  created_by: string;
  created_date: string;
  disable_passcode_change: boolean;
  modified_by: string;
  modified_date: string;
  profile: FacilityProfile;
  require_pin_for_admin: boolean;
  tablet_enable_inbound_messaging: boolean;
  enable_lifeloop: boolean;
  enable_lifeloop_data_sync: boolean;
  lifeloop_price_tier: string;
  lifeloop_facilities: {
    lifeloop_facility_id: string;
    lifeloop_price_tier: string;
  }[];
  tablet_enable_lifeloop: boolean;
  iconnect: {
    portal: boolean;
    devices: boolean;
    disable_device_media_upload: boolean;
  };
  status: { name: string };
  coro_content: boolean;
  high_impact_content: boolean;

  constructor(data: IFacility = {}) {
    super(
      data._id,
      data._rev,
      undefined,
      data.created_by,
      data.created_date,
      DocTypeConstants.NAMESPACES.ACCOUNT,
      DocTypeConstants.TYPES.ACCOUNT.FACILITY,
      data.modified_by,
      data.modified_date,
      data.apollo_id,
      data.apollo_import
    );

    this.account_id = data.account_id || '';
    this.facility_type = data.facility_type || '';
    this.billing = new FacilityBillingProfile(data.billing);
    this.created_by = data.created_by || '';
    this.created_date = data.created_date || '';
    this.disable_passcode_change = !!data.disable_passcode_change;
    this.iconnect = {
      portal: data.iconnect?.portal || false,
      devices: data.iconnect?.devices || false,
      disable_device_media_upload:
        data.iconnect?.disable_device_media_upload || false,
    };
    this.modified_by = data.modified_by || '';
    this.modified_date = data.modified_date || '';
    this.profile = new FacilityProfile(data.profile);
    this.require_pin_for_admin = !!data.require_pin_for_admin;
    this.tablet_enable_inbound_messaging = !!data.tablet_enable_inbound_messaging;
    this.enable_lifeloop_data_sync = !!data.enable_lifeloop_data_sync;
    this.lifeloop_price_tier = data.lifeloop_price_tier || '';
    this.lifeloop_facilities = data.lifeloop_facilities || [];
    this.enable_lifeloop = !!data.enable_lifeloop;
    this.tablet_enable_lifeloop = !!data.tablet_enable_lifeloop;
    this.coro_content = !!data.coro_content;
    this.high_impact_content = !!data.high_impact_content;
  }
}

export class FacilityFactory {
  static createFacility(data: IFacility = {}): Facility {
    return new Facility(data);
  }
}
