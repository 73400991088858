<div *ngIf="!!container">
    <div class="row">
        <div class="col-xs-12 mb-lg text-right">
          <button
            class="btn btn-danger btn-outline"
            (click)="showDeleteModal()"
            [disabled]="deleteDisabled(container._id)"
          >
            Delete
          </button>
        </div>
      </div>

  <form
    [formGroup]="form"
    class="form-validate"
    role="form"
    name="containerForm"
    novalidate=""
    (submit)="saveContainer()"
  >
    <div class="panel bb0">
      <div class="panel-body bb bt">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label class="control-label">Container Image</label>
              <app-image-crop-control
                #cropControl
                canvasWidth="200"
                canvasHeight="150"
                width="200"
                height="150"
                croppedWidth="300"
                croppedHeight="200"
                label="Image"
                [keepAspect]="false"
                [existingImageSrc]="tileImageSrc"
                (onRemoveImage)="removeTileImage()"
              >
              </app-image-crop-control>
            </div>

            <div class="form-group">
              <label class="control-label">Title</label>
              <input
                class="form-control"
                placeholder="Title"
                type="text"
                formControlName="title"
                tabindex="0"
                aria-invalid="true"
              />
              <span class="text-danger" *ngIf="required(form.controls.title)"
                >This field is required</span
              >
            </div>
            <div class="form-group">
              <div class="text-bold">Active Date Ranges:</div>
              <div>
                <div *ngFor="let range of getUtcActiveDates()">
                  {{ range.start_date }} - {{ range.end_date }}
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="control-label">Notes</label>
              <textarea
                class="form-control"
                placeholder="Notes"
                formControlName="notes"
                rows="4"
                aria-multiline="true"
                tabindex="0"
                aria-invalid="false"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="panel-body bg-gray-lighter">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <button
              class="btn btn-labeled btn-info"
              type="submit"
              [disabled]="!form.valid || formSubmitted"
            >
              <span class="btn-label"><i class="fa fa-save"></i></span>
              Save Container
            </button>
          </div>
          <div class="col-sm-12 col-md-4 mt-xl" *ngIf="errorMessage">
            <p class="text-danger">{{ errorMessage }}</p>
          </div>
          <div class="col-sm-12 col-md-4 pull-right">
            <button
              (click)="cancel()"
              class="btn btn-danger pull-right"
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
   <!-- Delete Container Modal -->
   <div
   style="margin-top: 250px"
   class="modal fade"
   bsModal
   #deleteContainerModal="bs-modal"
   [config]="{
     animated: true,
     keyboard: false,
     backdrop: false,
     ignoreBackdropClick: false
   }"
   tabindex="-1"
   role="dialog"
   aria-labelledby="deleteContainerModal"
   aria-hidden="true"
 >
   <div class="modal-dialog modal-sm">
     <div class="modal-content">
       <div class="modal-header">
         <h4 class="modal-title pull-left">
           Delete Container {{ container.title }}
         </h4>
         <button
           type="button"
           class="close pull-right"
           aria-label="Close"
           (click)="closeDeleteModal()"
         >
           <span aria-hidden="true">&times;</span>
         </button>
       </div>
       <div class="modal-body">
         <div class="row mt-lg">
           <div class="col-sm-12">
             <button
               type="button"
               class="pull-left btn"
               aria-label="Cancel"
               (click)="closeDeleteModal()"
             >
               Cancel
             </button>
             <button
               type="button"
               class="pull-right btn btn-primary"
               aria-label="Delete"
               (click)="deleteContainer()"
               [disabled]="deleteSubmitted"
             >
               Confirm
             </button>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
</div>
<div class="row">
  <div class="col-xs-12">
    <app-document-changed-by [document]="container"></app-document-changed-by>
  </div>
</div>
