import * as moment from 'moment';

import { Directive, EventEmitter, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';

import { UnsubscribeOnDestroy } from './unsubscribe-on-destroy';

export class FormUtils {
  static markAllAsTouched(fb: FormGroup) {
    Object.keys(fb.controls || {}).forEach(childFb => {
      const node = fb.controls[childFb];

      if (node instanceof FormControl) {
        node.markAsTouched();
      }

      if (node instanceof FormArray) {
        for (let i = 0; i < node.length; ++i) {
          FormUtils.markAllAsTouched(<FormGroup>node.at(i));
        }
      }

      if (node instanceof FormGroup) {
        FormUtils.markAllAsTouched(node);
      }
    });
  }
}

@Directive()
export abstract class BaseFormComponent extends UnsubscribeOnDestroy {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  errorMessage: string;
  successMessage: string;
  form: FormGroup;

  required(formControl: AbstractControl): boolean {
    return this.hasError(formControl, 'required');
  }

  hasError(formControl, errorProperty: string): boolean {
    return formControl.hasError(errorProperty);
  }

  // date error check, looks for both 'date' and 'dateExists' errors
  hasDateError(formControl: FormControl | AbstractControl): boolean {
    return (
      this.modified(formControl) &&
      (formControl.hasError('date') || formControl.hasError('dateNonExistent'))
    );
  }

  modified(formControl: FormControl | AbstractControl): boolean {
    return formControl.dirty || formControl.touched;
  }

  resetMessages() {
    this.errorMessage = '';
    this.successMessage = '';
  }
}

// custom validators for the in2l focus app
export class AppValidator {
  static dateExists = (dateFormat: string = 'MM/DD/YYYY'): ValidatorFn => {
    return (control: FormControl | AbstractControl) => {
      // if valid, validatorFn must return null
      // otherwise, return an error object ex: { validator_key: 'message' }
      const dateValue = control.value;

      if (dateValue && !moment(dateValue, dateFormat, true).isValid()) {
        return { dateNonExistent: 'This date does not exist' };
      }

      return null;
    };
  };
}
