<div class="panel panel-default">
  <div class="panel-body pt0 pb0">
    <div class="row">
      <div class="col-xs-12 bg-gray-lighter pt pb">
        <div class="row">
          <div class="col-xs-10 col-md-5">
            <div class="input-group">
              <div class="input-group-addon bg-primary">
                <i class="fas fa-search"></i>
              </div>
              <input
                type="text"
                placeholder="Search users"
                [(ngModel)]="search"
                (keyup.enter)="filterUsers()"
                class="form-control"
              />
            </div>
          </div>
          <div
            class="col-xs-2 col-md-offset-5"
            style="text-align: center; padding: 0.1em"
          >
            <a
              *ngIf="showUserExport()"
              class="btn btn-primary"
              (click)="exportCsv()"
            >
              Export User List</a
            >
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th colspan="2">Name</th>
              <th class="account-cell" *ngIf="showAccountColumn">Account</th>
              <th class="facility-cell" *ngIf="showFacilityColumn">
                Community
              </th>
              <th class="email-cell" *ngIf="showEmailColumn">Email</th>
              <th class="type-cell">User Type</th>
              <th class="status-cell" *ngIf="showAccountStatusColumn">
                Status
              </th>
              <th class="action-cell text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of filteredUsers">
              <td class="profile-img-cell">
                <img
                  class="mr-lg"
                  [src]="profileImageForUser(user, showProfileImages)"
                  width="40"
                  height="40"
                />
              </td>
              <td>
                <a
                  href="#"
                  (click)="handleViewUserClick($event, user)"
                  *ngIf="showViewButton"
                >
                  <strong>{{ user.last_name }}, {{ user.first_name }}</strong>
                </a>
                <strong *ngIf="!showViewButton"
                  >{{ user.last_name }}, {{ user.first_name }}</strong
                >
              </td>
              <td *ngIf="showAccountColumn">{{ getAccountName(user) }}</td>
              <td *ngIf="showFacilityColumn">{{ getFacilityNames(user) }}</td>
              <td *ngIf="showEmailColumn">{{ getEmailForUser(user) }}</td>
              <td>{{ userTypeName(user) }}</td>
              <td *ngIf="showAccountStatusColumn">
                <div class="label label-success" *ngIf="userIsActive(user)">
                  Active
                </div>
                <div class="label label-warning" *ngIf="userIsInactive(user)">
                  Inactive
                </div>
                <div class="label label-info" *ngIf="userIsInvited(user)">
                  Invited
                </div>
              </td>
              <td class="text-center" style="min-width: 150px">
                <a
                  class="btn btn-primary btn-outline btn-sm"
                  (click)="handleViewUserClick($event, user)"
                  *ngIf="showViewButton"
                >
                  View
                </a>
                <a
                  class="btn btn-warning btn-outline btn-sm ml"
                  (click)="handleEditUserClick($event, user)"
                  *ngIf="showEditButton"
                >
                  Edit
                </a>
              </td>
            </tr>
          </tbody>
        </table>

        <p class="p-lg" *ngIf="!filteredUsers.length">
          No users available.
          <a
            class="btn"
            href="#"
            (click)="search = ''; filterUsers()"
            *ngIf="search.length"
            >Clear search</a
          >
        </p>
      </div>
    </div>
  </div>
</div>
