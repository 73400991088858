<div class="panel panel-default outer-panel">
  <div class="panel-body">
    <div class="panel-body">
      <div class="row mb-sm" *ngIf="!!contentItem">
        <div class="col-sm-12">
          <div class="panel panel-default inner-panel">
            <div class="panel-heading">
              {{ contentItem.title }}
              <button
                class="btn btn-danger btn-outline pull-right ml-sm"
                (click)="showDeleteModal()"
              >
                Delete
              </button>
              <button
                class="btn btn-primary btn-outline pull-right ml-sm"
                (click)="editContent()"
              >
                Edit
              </button>
            </div>
            <div class="panel-body" *ngIf="!!contentItem">
              <div class="row mb-sm">
                <div class="col-sm-5">
                  <div class="section-title">PRODUCTS</div>
                  <button
                    class="btn btn-success"
                    *ngIf="contentItem.products.engage"
                  >
                    ENGAGE
                  </button>
                  <button
                    class="btn btn-info ml-sm"
                    *ngIf="contentItem.products.focus"
                  >
                    FOCUS
                  </button>
                  <button
                    class="btn btn-warning ml-sm"
                    *ngIf="contentItem.products.rehab"
                  >
                    REHAB
                  </button>
                </div>
                <div class="col-sm-5">
                  <div class="section-title">PLATFORMS</div>
                  <button
                    class="btn btn-info"
                    *ngIf="contentItem.platforms.android"
                  >
                    ANDROID
                  </button>
                  <button
                    class="btn btn-success ml-sm"
                    *ngIf="contentItem.platforms.pc"
                  >
                    PC
                  </button>
                </div>
              </div>

              <div class="row content-details-group">
                <div class="col-sm-6">
                  <div class="row mb-sm">
                    <div class="col-sm-5">
                      <span class="text-bold">Title:</span>
                    </div>
                    <div class="col-sm-7">
                      {{ contentItem.title }}
                    </div>
                  </div>
                  <div class="row mb-sm">
                    <div class="col-sm-5">
                      <span class="text-bold">Content Type:</span>
                    </div>
                    <div class="col-sm-7">
                      {{ contentTypeNames[contentItem.content_type] }}
                    </div>
                  </div>
                  <div class="row mb-sm">
                    <div class="col-sm-5">
                      <span class="text-bold">Display Type:</span>
                    </div>
                    <div class="col-sm-7">
                      {{ displayTypeNames[contentItem.display_type] }}
                    </div>
                  </div>
                  <div class="row mb-sm">
                    <div class="col-sm-5">
                      <span class="text-bold">Filename:</span>
                    </div>
                    <div class="col-sm-7">
                      {{ contentItem.s3_key }}
                    </div>
                  </div>
                  <div class="row mb-sm">
                    <div class="col-sm-5">
                      <span class="text-bold">Search Keywords:</span>
                    </div>
                    <div class="col-sm-7">
                      {{ getKeywords() }}
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <img
                    *ngIf="contentItem.hasTileImage()"
                    [appAttachmentSrc]="contentItem.tileImagePath()"
                    class="content-image pull-left"
                  />
                </div>
              </div>

              <div class="row mb-sm">
                <div class="col-sm-5">
                  <div class="section-title">SETTINGS</div>
                </div>
              </div>
              <div class="row settings-details">
                <div class="col-sm-6">
                  <div class="row mb-sm" *ngIf="isIn2lAdmin()">
                    <div class="col-sm-5">
                      <span class="text-bold">Excluded Accounts: </span>
                    </div>
                    <div class="col-sm-7">
                      {{ getExcludedAccountNames() }}
                    </div>
                  </div>
                  <div class="row mb-sm">
                    <div class="col-sm-5">
                      <span class="text-bold">Usage Settings:</span>
                    </div>
                    <div class="col-sm-7">
                      {{ getUsageSettingsLabels() }}
                    </div>
                  </div>
                  <div class="row mb-sm">
                    <div class="col-sm-5">
                      <span class="text-bold">Accessibility:</span>
                    </div>
                    <div class="col-sm-7">
                      {{ getAccessibilityLabels() }}
                    </div>
                  </div>
                  <div class="row mb-sm">
                    <div class="col-sm-5">
                      <span class="text-bold">Source:</span>
                    </div>
                    <div class="col-sm-7">
                      {{ contentItem.source }}
                    </div>
                  </div>
                  <div class="row mb-sm">
                    <div class="col-sm-5">
                      <span class="text-bold">Notes:</span>
                    </div>
                    <div class="col-sm-7">
                      {{ contentItem.notes }}
                    </div>
                  </div>
                  <div class="row mb-sm">
                    <div class="col-sm-5">
                      <span class="text-bold">License Expiration Date:</span>
                    </div>
                    <div class="col-sm-7">
                      {{
                        setDateDisplayFormat(
                          contentItem.license_expiration_date
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Delete Item Modal -->
<div
  class="modal fade"
  bsModal
  #deleteModal="bs-modal"
  [config]="{ backdrop: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteContentLibraryItemModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Delete {{ contentItem.title }}?</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="cancelModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mt-lg">
          <div class="col-sm-12">
            <button
              type="button"
              class="pull-left btn"
              aria-label="Cancel"
              (click)="cancelModal()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="pull-right btn btn-primary"
              aria-label="Save"
              (click)="submitDelete()"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Delete Not Allowed Modal -->
<div
  class="modal fade"
  bsModal
  #deleteNotAllowedModal="bs-modal"
  [config]="{ backdrop: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteNotAllowedModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          The following content items cannot be deleted because they are linked
          to in the layouts.
        </h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="cancelModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row list-separator">
          <div class="col-sm-3">
            <span class="text-bold">Content Item Title</span>
            <hr />
          </div>
          <div class="col-sm-9">
            <span class="text-bold">Layout paths were item is linked</span>
            <hr />
          </div>
        </div>
        <div class="row list-separator">
          <div class="col-sm-3">
            {{ contentItem.title }}
          </div>
          <div class="col-sm-8=9">
            <div *ngFor="let path of contentItem.link_to_paths">
              {{ path }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
