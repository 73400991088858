import { FamilyPortalUserInterfaces } from './family-portal-user.interfaces';

export class NewFamilyPortalUserRequest {
  user_type: string;
  email: string;
  associated_residents: FamilyPortalUserInterfaces.IAssociatedResident[];

  constructor(data?: FamilyPortalUserInterfaces.IFamilyPortalUser) {
    data = data || ({} as FamilyPortalUserInterfaces.IFamilyPortalUser);

    this.user_type = data.user_type || '';
    this.email = data.email || '';
    this.associated_residents = data.associated_residents || [];
  }
}
