import { concat, forkJoin, Observable, of, throwError } from 'rxjs';
import { last, map, mergeMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DocTypeConstants } from '../../constants/doc-types';
import { FamilyPortalUser } from '../../model/family-portal-user/family-portal-user';
import { FamilyPortalUserList } from '../../model/family-portal-user/family-portal-user-list';
import {
  Resident,
  RESIDENT_PROFILE_IMAGE_FILENAME,
  RESIDENT_STATUS_ACTIVE,
  RESIDENT_STATUS_INACTIVE,
} from '../../model/resident/resident';
import { ScrapbookEntry } from '../../model/scrapbook/scrapbook-entry';
import { ScrapbookEntryList } from '../../model/scrapbook/scrapbook-entry-list';
import { SyncGatewayAttachment } from '../../model/sync-gateway/sync-gateway-attachment';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { DatabaseApiService } from '../database-api/database-api.service';
import { StateManager } from '../state/state-manager';

const GENERIC_PROFILE_IMAGE = '/assets/img/user/generic.png';

@Injectable()
export class ResidentService extends DatabaseApiService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    cacheService: CacheService,
    stateManager: StateManager,
    private databaseApi: DatabaseApiService
  ) {
    super(http, jwtService, stateManager, cacheService);
  }

  getAllResidentsForFacility(
    accountId: string,
    facilityId: string
  ): Observable<Resident[]> {
    if (!accountId || !facilityId) {
      return throwError(
        new Error(
          'Unable to retrieve residents due to invalid account or facility ID'
        )
      );
    }

    return this.getAllByNamespaceType<Resident>(
      DocTypeConstants.NAMESPACES.RESIDENT,
      DocTypeConstants.TYPES.RESIDENT.RESIDENT,
      Resident,
      [accountId],
      [facilityId]
    );
  }

  getAllResidentsForFacilities(
    accountId: string,
    facilityIds: string[]
  ): Observable<Resident[]> {
    if (!accountId || !facilityIds || !facilityIds.length) {
      return throwError(
        new Error(
          'Unable to retrieve residents due to invalid account or facility ID'
        )
      );
    }

    return this.getAllByNamespaceType<Resident>(
      DocTypeConstants.NAMESPACES.RESIDENT,
      DocTypeConstants.TYPES.RESIDENT.RESIDENT,
      Resident,
      [accountId],
      facilityIds
    );
  }

  getResident(residentId: string): Observable<Resident> {
    return this.getById<Resident>(
      DocTypeConstants.NAMESPACES.RESIDENT,
      DocTypeConstants.TYPES.RESIDENT.RESIDENT,
      residentId,
      Resident,
      true
    );
  }

  getResidentFamilyPortalConnections(
    residentId: string
  ): Observable<FamilyPortalUserList> {
    return this.databaseApi.getAllWithCustomQueryParams<FamilyPortalUserList>(
      DocTypeConstants.NAMESPACES.USER,
      DocTypeConstants.TYPES.USER.FAMILY_PORTAL_USER,
      { residentId },
      FamilyPortalUserList,
      residentId,
      true
    );
  }

  getResidentScrapbookEntries(
    residentId: string
  ): Observable<ScrapbookEntryList> {
    return this.databaseApi.getAllWithCustomQueryParams<ScrapbookEntryList>(
      DocTypeConstants.NAMESPACES.SCRAPBOOK,
      DocTypeConstants.TYPES.SCRAPBOOK.ENTRY,
      { resident_id: residentId },
      ScrapbookEntryList,
      residentId
    );
  }

  createResident(
    resident: Resident,
    attachment?: SyncGatewayAttachment
  ): Observable<Resident> {
    return this.createDoc(resident).pipe(
      mergeMap(updatedResident =>
        this.updateResident(updatedResident, attachment)
      )
    );
  }

  updateResident(
    resident: Resident,
    attachment?: SyncGatewayAttachment
  ): Observable<Resident> {
    if (!resident.status) {
      resident.status = RESIDENT_STATUS_ACTIVE;
    }

    // TODO [Ben]: Once we've switched over to S3, we'll want to go back to DB API
    return this.upsert<Resident>(
      DocTypeConstants.NAMESPACES.RESIDENT,
      DocTypeConstants.TYPES.RESIDENT.RESIDENT,
      resident._id || '',
      Resident,
      resident,
      attachment
    );
  }

  updateResidentRemoveProfileImage(resident: Resident): Observable<Resident> {
    this.deleteProfileImageKeys(resident);
    return this.updateResident(resident);
  }

  moveResident(
    residentId: string,
    newAccountId: string,
    newFacilityId: string
  ): Observable<Resident> {
    return this.getResident(residentId).pipe(
      mergeMap((resident: Resident) => {
        const newResident = new Resident(resident);
        delete newResident._id;
        delete newResident._rev;
        newResident.account_id = newAccountId;
        newResident.facility_id = newFacilityId;

        return forkJoin([this.createResident(newResident), of(resident)]);
      }),
      mergeMap(([newResident, prevResident]) => {
        return concat(
          this.moveFamilyPortalConnections(
            prevResident._id || '',
            newResident._id || ''
          ),
          this.moveScrapbookEntries(
            prevResident._id || '',
            newResident._id || ''
          ),
          this.removeResident(prevResident)
        ).pipe(
          last(),
          map(() => newResident)
        );
      })
    );
  }

  // --- Disable / Re-Activate Resident ---

  activateResident(resident: Resident): Observable<Resident> {
    resident.status = RESIDENT_STATUS_ACTIVE;
    return this.updateResident(resident);
  }

  disableResident(resident: Resident): Observable<Resident> {
    resident.status = RESIDENT_STATUS_INACTIVE;
    return this.updateResident(resident);
  }

  // --- Remove and mark resident doc_type as archived-resident ---
  removeResident(resident: Resident): Observable<Resident> {
    return this.databaseApi
      .deleteDoc(
        DocTypeConstants.NAMESPACES.RESIDENT,
        DocTypeConstants.TYPES.RESIDENT.RESIDENT,
        resident._id || ''
      )
      .pipe(
        mergeMap(() => {
          resident.doc_type = DocTypeConstants.TYPES.RESIDENT.ARCHIVED_RESIDENT;
          return of(resident);
        })
      );
  }

  // --- Profile Images ---

  updateProfileImage(
    resident: Resident,
    dataUri: string
  ): Observable<Resident> {
    const attachment = this.getAttachmentFromDataUri(
      resident.profileImageKey(),
      dataUri
    );
    return this.updateResident(resident, attachment);
  }

  // --- Contact Image ---

  getContactImagePath(resident: Resident, phone: string): string {
    if (resident && resident.hasContactImage(phone)) {
      return resident.attachmentImagePath(resident.getContactImageName(phone));
    }

    return GENERIC_PROFILE_IMAGE;
  }

  updateContactImage(
    resident: Resident,
    phone: string,
    imageDataUri: string
  ): Observable<Resident> {
    if (
      !resident ||
      !phone ||
      !imageDataUri ||
      !imageDataUri.startsWith('data:')
    ) {
      return of(resident);
    }

    const attachment = this.getAttachmentFromDataUri(
      resident.getContactImageName(phone),
      imageDataUri
    );
    return this.updateResident(resident, attachment);
  }

  // --- Family Portal connections ---

  moveFamilyPortalConnections(
    prevResidentId: string,
    newResidentId: string
  ): Observable<FamilyPortalUser[]> {
    return this.getResidentFamilyPortalConnections(prevResidentId).pipe(
      mergeMap(familyPortalConnections => {
        const pendingUserUpdates: Observable<FamilyPortalUser>[] = [];

        for (const familyPortalUser of familyPortalConnections.members) {
          const residentConnection = familyPortalUser.associated_residents.find(
            r => r._id === prevResidentId
          );
          if (residentConnection) {
            residentConnection._id = newResidentId;
            pendingUserUpdates.push(
              this.databaseApi.updateDoc(
                familyPortalUser,
                true,
                undefined,
                true
              )
            );
          }
        }
        return forkJoin(pendingUserUpdates);
      })
    );
  }

  // --- Scrapbook Entries ---

  moveScrapbookEntries(
    prevResidentId: string,
    newResidentId: string
  ): Observable<ScrapbookEntry[]> {
    return this.getResidentScrapbookEntries(prevResidentId).pipe(
      mergeMap(scrapbookEntryList => {
        const pendingScrapbookUpdates: Observable<ScrapbookEntry>[] = [];

        if (scrapbookEntryList?.members?.length) {
          for (const scrapbookEntry of scrapbookEntryList.members) {
            scrapbookEntry.resident_id = newResidentId;
            pendingScrapbookUpdates.push(
              this.databaseApi.updateDoc(scrapbookEntry)
            );
          }
        }
        return forkJoin(pendingScrapbookUpdates);
      })
    );
  }

  // Couchbase lite now stores images at resident.profile_image and resident._attachments['blob_/profile_image']
  // but Sync Gateway still stores it the old way as resident._attachments['profile_image']
  // We need to clean up the images before updating them.
  private deleteProfileImageKeys(resident: Resident) {
    if (resident._attachments) {
      Object.keys(resident._attachments)
        .filter(key => key.endsWith(RESIDENT_PROFILE_IMAGE_FILENAME))
        .forEach(key => {
          delete (resident._attachments || {})[key];
        });
    }

    delete resident[RESIDENT_PROFILE_IMAGE_FILENAME];
  }

  getGenericProfileImage() {
    return GENERIC_PROFILE_IMAGE;
  }
}
