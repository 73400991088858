import { AutoCompleteModule } from 'primeng/autocomplete';

import { NgModule } from '@angular/core';
import { TreeModule } from '@circlon/angular-tree-component';

import { SharedModule } from '../shared/shared.module';
import { ViewIdeaBoardComponent } from './content/idea-board/view-idea-board/view-idea-board.component';
import { LayoutContainerFormComponent } from './content/layout/layout-container-form/layout-container-form.component';
import { LayoutCopyModal } from './content/layout/layout-copy-modal/layout-copy-modal.component';
import { LayoutLinkToFormComponent } from './content/layout/layout-linkto-form/layout-linkto-form.component';
import { LayoutTreeComponent } from './content/layout/layout-tree/layout-tree.component';
import { ContentLibraryItemFormComponent } from './content/library/content-library-item-form/content-library-item-form.component';
import { ContentLibraryItemComponent } from './content/library/content-library-item/content-library-item.component';
import { ContentLibraryListComponent } from './content/library/content-library-list/content-library-list.component';
import { ContentPackageListComponent } from './content/packages/content-package-list/content-package-list.component';

@NgModule({
  imports: [SharedModule, AutoCompleteModule, TreeModule],
  providers: [],
  declarations: [
    // Layout
    LayoutContainerFormComponent,
    LayoutLinkToFormComponent,
    LayoutCopyModal,
    LayoutTreeComponent,

    // Library
    ContentLibraryItemFormComponent,
    ContentLibraryItemComponent,
    ContentLibraryListComponent,

    // Idea Board
    ViewIdeaBoardComponent,

    // Packages
    ContentPackageListComponent,
  ],
  exports: [
    // Layout
    LayoutContainerFormComponent,
    LayoutLinkToFormComponent,
    LayoutTreeComponent,

    // Library
    ContentLibraryItemFormComponent,
    ContentLibraryItemComponent,
    ContentLibraryListComponent,

    // Idea Board
    ViewIdeaBoardComponent,

    // Packages
    ContentPackageListComponent,
  ],
})
export class PresentationModule {}
