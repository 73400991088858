import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { HttpClient } from '@angular/common/http';
// @ts-ignore
import { Injectable } from '@angular/core';

import { DocTypeConstants } from '../../constants/doc-types';
import { UserConstants } from '../../constants/user.constants';
import { MediaInterfaces } from '../../model/media/media-interfaces';
import { AccountAdminUser } from '../../model/user/account-admin-user';
import { FacilityAdminUser } from '../../model/user/facility-admin-user';
import { FacilityUser } from '../../model/user/facility-user';
import { In2lAdminUser } from '../../model/user/in2l-admin-user';
import { In2lContentUser } from '../../model/user/in2l-content-user';
import { PortalUserList } from '../../model/user/portal-user-list';
import { In2lUserType } from '../../model/user/user';
import { UserInterfaces } from '../../model/user/user.interfaces';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { DatabaseApiService } from '../database-api/database-api.service';
import { StateManager } from '../state/state-manager';

@Injectable()
export class UserService extends DatabaseApiService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    stateManager: StateManager,
    protected cacheService: CacheService
  ) {
    super(http, jwtService, stateManager, cacheService);
  }

  searchUsersByPhrase(
    searchPhrase: string,
    statuses?: string[]
  ): Observable<In2lUserType[]> {
    // This function currently used DB API, but it should be changed to use User API when the endpoint has been moved
    return this.getAllWithCustomQueryParams(
      DocTypeConstants.NAMESPACES.USER,
      DocTypeConstants.TYPES.USER.PORTAL_USER,
      {
        search: searchPhrase,
        ...(statuses && statuses.length && { statuses: statuses.join(',') }),
      },
      PortalUserList,
      '',
      true
    ).pipe(
      map(
        docs =>
          <In2lUserType[]>(
            docs.members
              .map((doc: UserInterfaces.IUser) => this.buildUser(doc))
              .filter(item => !!item)
          )
      )
    );
  }

  // formerly in user-api.service.ts using user-api
  getCurrentUser(): Observable<In2lUserType> {
    return this.get<In2lUserType>(`${this.userApiUrl}/doc`).pipe(
      map(user => <In2lUserType>this.buildUser(user))
    );
  }

  getUser(userId: string): Observable<In2lUserType | null> {
    if (!userId) {
      return of(null);
    }

    return this.get<In2lUserType>(`${this.userApiUrl}/docs/${userId}`).pipe(
      map(user => this.buildUser(user))
    );
  }

  getUserProfileImage(
    s3Key: string,
    docNamespace: string,
    docType: string
  ): Observable<string> {
    return this.mediaApiService
      .requestMediaDownloadLink(docNamespace, docType, s3Key)
      .pipe(map((media: MediaInterfaces.IMediaOutput) => media.signed_url));
  }

  getAllUsers(params: {
    userType?: string;
    accountId?: string;
    facilityId?: string;
    email?: string;
    status?: string;
    limit?: number;
  }): Observable<In2lUserType[]> {
    const queryString = [
      params.userType ? `type=${params.userType}` : '',
      params.accountId ? `account_id=${params.accountId}` : '',
      params.facilityId ? `facility_id=${params.facilityId}` : '',
      params.email
        ? `email=${encodeURIComponent(params.email.toLowerCase())}`
        : '',
      params.status && params.status !== 'all' ? `status=${params.status}` : '',
      params.limit ? `limit=${params.limit}` : '',
    ]
      .filter(val => !!val)
      .join('&');
    return this.get<In2lUserType[]>(
      `${this.userApiUrl}/docs${queryString ? '?' : ''}${queryString}`
    ).pipe(
      map(
        users =>
          <In2lUserType[]>(
            users.map(user => this.buildUser(user)).filter(user => !!user)
          )
      )
    );
  }

  getUserByEmail(
    email: string,
    facilityId?: string
  ): Observable<In2lUserType | null> {
    const params = facilityId ? { email, facilityId } : { email };
    return this.getAllUsers(params).pipe(
      map(results => (results.length ? results[0] : null))
    );
  }

  getUsersForFacility(
    accountId: string,
    facilityId: string
  ): Observable<In2lUserType[]> {
    return this.getAllUsers({ accountId, facilityId });
  }

  getAllFacilityAdmins(): Observable<FacilityAdminUser[]> {
    return this.getAllUsers({
      userType: DocTypeConstants.TYPES.USER.FACILITY_ADMIN,
    }).pipe(map(users => users.filter(user => user.status !== 'invited')));
  }

  /**
   * getUserWithStatus(...statusParams)
   * pass in multiple user status values as separate function arguments to get a list
   * of users that match each type provided
   */
  getLimitedUsersWithStatus(
    status: string,
    limit: number
  ): Observable<In2lUserType[]> {
    return this.getAllUsers({ status, limit });
  }

  getInvitedUsers(): Observable<In2lUserType[]> {
    return this.getAllUsers({
      status: UserConstants.USER_STATUS_INVITED,
      limit: 100,
    });
  }

  updateUser(
    user: In2lUserType,
    media?: {
      contentType: string;
      etag: string;
      imageBlob: Blob;
      name: string;
    }
  ): Observable<In2lUserType> {
    const userId = user._id || uuidv4();
    if (
      ![
        DocTypeConstants.TYPES.USER.FACILITY_ADMIN,
        DocTypeConstants.TYPES.USER.FACILITY_USER,
      ].includes(user.doc_type || '')
    ) {
      delete user.facility_ids;
    }

    if (
      [
        DocTypeConstants.TYPES.USER.IN2L_ADMIN,
        DocTypeConstants.TYPES.USER.IN2L_CONTENT,
      ].includes(user.doc_type || '')
    ) {
      delete user.account_id;
    }

    if (!media || !media.name) {
      return this.updateUserWithoutMedia(user, userId);
    }

    return this.mediaApiService
      .uploadMedia(
        DocTypeConstants.NAMESPACES.USER,
        user.doc_type || '',
        {
          mime_type: media?.contentType,
          key: {
            sourceDocId: userId,
            mediaType: media?.name,
          },
        },
        media?.imageBlob
      )
      .pipe(
        mergeMap(result => {
          if (result && result.s3Key && result.s3Key.media_key) {
            user.media = user.media || {};
            user.media[UserConstants.USER_PROFILE_IMAGE_KEY] = {
              content_type: media.contentType,
              etag: media.etag,
              s3_key: result.s3Key.media_key,
              status: 'Completed',
            };
          }

          return this.updateUserWithoutMedia(user, userId);
        })
      );
  }

  updateUserWithoutMedia(
    user: In2lUserType,
    userId: string
  ): Observable<In2lUserType> {
    return this.put<In2lUserType>(
      `${this.userApiUrl}/docs/${userId}`,
      <In2lUserType>this.buildUser(user)
    ).pipe(map(user => <In2lUserType>this.buildUser(user)));
  }

  // create a user in an invited status .. these are user profiles that exists
  // after a user with permission sends an invite, but before the user completes their profile
  createInvitedUser(
    userType: string,
    email: string,
    accountId: string,
    facilityIds: string[],
    firstName: string,
    lastName: string,
    phone: string,
    title: string,
    pin: string,
    isTemporaryPin: boolean,
    residentIds: string[],
    residentMode: string
  ): Observable<In2lUserType> {
    const invitedUser = <UserInterfaces.IPortalInvitedUser>{
      account_id: accountId || '',
      doc_namespace: DocTypeConstants.NAMESPACES.USER,
      doc_type: userType,
      email,
      facility_ids: facilityIds || [],
      first_name: firstName,
      has_temporary_pin: !!isTemporaryPin,
      last_name: lastName || '',
      pin: pin || '',
      phone: phone || '',
      resident_ids: residentIds || [],
      resident_mode: residentMode || UserConstants.USER_RESIDENT_MODE_ALL,
      status:
        userType === DocTypeConstants.TYPES.USER.FACILITY_USER
          ? UserConstants.USER_STATUS_ACTIVE
          : UserConstants.USER_STATUS_INVITED,
      title: title || '',
    };

    return this.post<In2lUserType>(
      `${this.userApiUrl}/invite`,
      invitedUser
    ).pipe(map(user => <In2lUserType>this.buildUser(user)));
  }

  async getProfileImageArrayFromBlob(
    imageBlob: Blob | File
  ): Promise<ArrayBuffer> {
    return await imageBlob.arrayBuffer();
  }

  removeProfileImage(user: In2lUserType): Observable<In2lUserType> {
    // TODO [Ben]: Need to actually delete from S3 as well
    if (user.media && user.media[UserConstants.USER_PROFILE_IMAGE_KEY]) {
      delete user.media[UserConstants.USER_PROFILE_IMAGE_KEY];
      return this.updateUser(user);
    }

    return of(user);
  }

  deleteUser(user: In2lUserType): Observable<boolean> {
    // TODO [Ben]: Remove profile image from S3?
    return this.delete(`${this.userApiUrl}/docs/${user._id || ''}`).pipe(
      map(() => {
        return true;
      }),
      catchError(error => {
        return (error || {}).status === 404 ? of(true) : of(false);
      })
    );
  }

  // --- Disable / Re-Activate Account ---

  disableUser(user: In2lUserType): Observable<In2lUserType> {
    user.status = UserConstants.USER_STATUS_INACTIVE;
    return this.updateUser(user);
  }

  activateUser(user: In2lUserType): Observable<In2lUserType> {
    user.status = UserConstants.USER_STATUS_ACTIVE;
    return this.updateUser(user);
  }

  getPublicUserStatus(userId: string): Observable<string> {
    return this.getWithoutToken<{ status: string }>(
      `${this.userApiUrl}/docs/${userId}/status`
    ).pipe(
      map(result => {
        return (result || { status: 'unknown' }).status;
      }),
      catchError(error => {
        return of('unknown');
      })
    );
  }

  buildUser(doc: unknown): In2lUserType | null {
    if (!doc || !doc['doc_type']) {
      return null;
    }

    const user = doc as UserInterfaces.IUser;
    switch (user.doc_type) {
      case DocTypeConstants.TYPES.USER.ACCOUNT_ADMIN:
        return new AccountAdminUser(user);
      case DocTypeConstants.TYPES.USER.FACILITY_ADMIN:
        return new FacilityAdminUser(user);
      case DocTypeConstants.TYPES.USER.FACILITY_USER:
        return new FacilityUser(user);
      case DocTypeConstants.TYPES.USER.IN2L_ADMIN:
        return new In2lAdminUser(user);
      case DocTypeConstants.TYPES.USER.IN2L_CONTENT:
        return new In2lContentUser(user);
      default:
        return null;
    }
  }
}
