export const ROUTES = {
  STATUS: { PATH: 'status', ACTIONS: {} },
  LOGIN: { PATH: 'login', ACTIONS: {} },
  TOKEN: { PATH: 'token', ACTIONS: {} },
  PORTALUSER: {
    PATH: 'portal-user',
    ACTIONS: {
      INVITE: 'invite',
    },
  },
  USER: {
    PATH: 'user',
    ACTIONS: {
      CONNECTREQUEST: 'connect-request',
      INVITESEND: 'invite-send',
      PASSWORD: 'password',
      RESET_PASSWORD: 'reset-password-send',
      VERIFYSEND: 'verify-send',
      VERIFY: 'verify',
    },
  },
};
