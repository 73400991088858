<!-- START Community Usage Chart -->
<div class="panel panel-default inner-panel">
  <div class="panel-body">
    <div class="chart-heading" *ngIf="!!chartDescription">{{ chartDescription }}</div>
    <div
      *ngIf="chart.dataset && chart.dataset.length > 0"
      flot
      [dataset]="chart.dataset"
      [options]="chart.options"
      (plotClick)="chartClick($event)"
      (ready)="chartReady($event)"
      height="300"
    ></div>
    <div class="text-center text-muted" *ngIf="!chart.dataset || chart.dataset.length === 0">
      Data unavailable
    </div>
  </div>
</div>
<!-- END Community Usage Chart -->
