// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=qa` then `environment.qa.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  portal: {
    url: 'https://portal-qa.in2l.com',
  },
  portalApi: {
    url: 'https://api-qa.in2lolympus.com',
  },
  portalCompositeApi: {
    url: 'https://api-qa.in2lconnect.com/in2l-portal-cmp/v1',
  },
  deviceApi: {
    url: 'https://api-qa.in2lconnect.com/device/v1',
  },
  authApi: {
    url: 'https://api-qa.in2lconnect.com/auth/v1',
    tokenExpirationMin: 14,
  },
  databaseApi: {
    url: 'https://api-qa.in2lconnect.com/database/v1',
  },
  mediaApi: {
    url: 'https://api-qa.in2lconnect.com/media/v1',
  },
  userApi: {
    url: 'https://api-qa.in2lconnect.com/user/v1',
  },
  contentApi: {
    url: 'https://api-qa.in2lconnect.com/content/v1',
  },
  google: {
    analytics: {
      ua: 'UA-97566542-1', // qa ua id
    },
  },
  bucketPolicy: {
    fields: {
      acl: 'public-read',
      'Content-Type': '',
      key: '',
      bucket: 'in2l-qa',
      'x-amz-algorithm': 'AWS4-HMAC-SHA256',
      'x-amz-credential':
        'AKIAJRUQUDOIUW2DZNOA/20300603/us-east-1/s3/aws4_request',
      'x-amz-date': '20300603T162458Z',
      policy:
        'eyJleHBpcmF0aW9uIjoiMjAzMC0wNi0wM1QxNjoyNDo1OC41ODlaIiwiY29uZGl0aW9ucyI6W3siYWNsIjoicHVibGljLXJlYWQifSxbInN0YXJ0cy13aXRoIiwiJENvbnRlbnQtVHlwZSIsIiJdLFsic3RhcnRzLXdpdGgiLCIka2V5IiwiIl0seyJidWNrZXQiOiJpbjJsLXFhIn0seyJ4LWFtei1hbGdvcml0aG0iOiJBV1M0LUhNQUMtU0hBMjU2In0seyJ4LWFtei1jcmVkZW50aWFsIjoiQUtJQUpSVVFVRE9JVVcyRFpOT0EvMjAzMDA2MDMvdXMtZWFzdC0xL3MzL2F3czRfcmVxdWVzdCJ9LHsieC1hbXotZGF0ZSI6IjIwMzAwNjAzVDE2MjQ1OFoifV19',
      'x-amz-signature':
        '60db183f17236de4face3aaa4784fab94174ce1c0af8d162ed320897db71132e',
    },
    host: 'https://in2l-qa.s3.dualstack.us-east-1.amazonaws.com',
    starts_with: [{ 'Content-Type': '' }, { key: '' }],
  },
};
