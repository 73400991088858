<div
  class="modal fade"
  bsModal
  #layoutCopyModal="bs-modal"
  [config]="{ backdrop: false }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="layoutCopyModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content" style="top: 100px">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          {{ modalTitle }}
        </h4>
      </div>
      <div class="modal-body">
        <div class="row mb-lg" *ngIf="currentPhase === 'selectDest'">
          <div style="display: flex" class="col-sm-12">
            <h4>Product:</h4>
            <select
              class="form-control wd-auto ml-lg"
              [(ngModel)]="selectedDestProduct"
              (change)="switchDestProduct()"
            >
              <option [value]="null">Select Product</option>
              <option *ngFor="let item of products" [value]="item">
                {{ getProductDisplay(item) }}
              </option>
            </select>
            <h4 class="ml-lg">Layout:</h4>
            <select
              class="form-control wd-auto ml-lg"
              [(ngModel)]="selectedDestRootId"
              (change)="switchDestRoot()"
            >
              <option [value]="null">Select Layout Root</option>
              <option
                *ngFor="let item of filteredDestLayoutRoots"
                [value]="item._id"
              >
                {{ getLayoutTitle(item) }}
              </option>
            </select>
          </div>
          <p *ngIf="!!selectedDestRootId" class="text-muted text-center">
            Click next to use layout root as destination.
          </p>
        </div>
        <div
          *ngIf="
            currentPhase === 'selectDest' || currentPhase === 'selectSource'
          "
          style="height: 65vh"
        >
          <tree-root
            [nodes]="nodes"
            [(state)]="treeState"
            [focused]="true"
            [options]="treeOptions"
          >
            <ng-template #treeNodeTemplate let-node="node" let-index="index">
              <span [class]="getNodeClass(node.data)">{{
                node.data.name
              }}</span>
            </ng-template>
          </tree-root>
        </div>
        <div *ngIf="currentPhase === 'confirm'" class="row mt-lg">
          <div class="col-sm-12">
            <div class="text-center" style="font-size: 18px; padding: 50px">
              <div
                class="loading-indicator-container-bottom"
                *ngIf="loading"
              ></div>
              <p>
                Are you sure you want to complete the copy of this container?
              </p>
              <p class="text-bold">{{ getSourceDescription() }}</p>
              <p>into</p>
              <p class="text-bold">{{ getDestDescription() }}</p>
              <p class="mt-lg">
                <span class="text-bold">Reminder: </span>All link-tos will be
                copied in with evergreen active dates and will need to manually
                changed if they are to be expired or rotated.
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="currentPhase === 'doCopy' && isCopying" class="row mt-lg">
          <div class="col-sm-12">
            <div class="text-center" style="font-size: 18px; padding: 50px">
              <div class="loading-indicator-container-top">
                <i class="fas fa-circle-notch fa-spin"></i> Copying Container...
              </div>
              <p class="text-muted mt-lg">This may take a moment.</p>
            </div>
          </div>
        </div>
        <div *ngIf="currentPhase === 'doCopy' && !isCopying" class="row mt-lg">
          <div class="col-sm-12">
            <div class="text-center" style="font-size: 18px; padding: 50px">
              <div *ngIf="!copyError">
                <h2 class="text-center text-success">
                  Copy Operation Complete
                </h2>
                <p class="text-center mt-lg">
                  Containers Copied: {{ containersCopied }}
                </p>
                <p class="text-center mt-lg">
                  LinkTos Copied: {{ linkTosCopied }}
                </p>
              </div>
              <div *ngIf="copyError">
                <h2 class="text-center text-danger">
                  {{ copyError }}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-lg">
          <div class="col-sm-6">
            <button
              type="button"
              class="pull-left btn"
              aria-label="Back"
              *ngIf="
                currentPhase !== 'selectSource' && currentPhase !== 'doCopy'
              "
              (click)="back()"
            >
              Back
            </button>
          </div>
          <div class="col-sm-6">
            <button
              type="button"
              class="pull-right btn btn-primary ml-lg"
              aria-label="Next"
              [disabled]="nextBtnEnabled ? null : true"
              (click)="next()"
            >
              {{ nextBtnText }}
            </button>
            <button
              *ngIf="currentPhase !== 'doCopy'"
              type="button"
              class="pull-right btn"
              aria-label="Cancel"
              (click)="hide()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
