import { Injectable } from '@angular/core';

@Injectable()
export class PhoneNumberUtils {
  //PORTAL STANDARD FOR SAVED PHONE NUMBERS: All phone numbers should be stored as 11-digit numbers with no non-numeric characters
  static setPhoneNumberToStandardSavedFormat = (
    phoneNumberToSave: string
  ): string => {
    return !!phoneNumberToSave ? phoneNumberToSave.replace(/[^0-9]/g, '') : '';
  };

  //PORTAL STANDARD FOR DISPLAYED PHONE NUMBERS: All phone numbers should be displayed in the format “1(303)123-4567” when shown on the view or edit page
  static setPhoneNumberToStandardDisplayFormat = (
    phoneNumberToDisplay: string
  ): string => {
    return !!phoneNumberToDisplay
      ? PhoneNumberUtils.handlePhoneNumberToDisplayOnView(phoneNumberToDisplay)
      : '';
  };

  static handlePhoneNumberToDisplayOnView(phoneNumber: string): string {
    const strippedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');

    if (strippedPhoneNumber.length === 10) {
      // phone number does not have preceding '1'.  use case: 2345678900
      const number = '1' + strippedPhoneNumber;
      return PhoneNumberUtils.formatDisplayPhoneNumber(number);
    } else if (strippedPhoneNumber.length === 11) {
      // use case 12345678900
      return PhoneNumberUtils.formatDisplayPhoneNumber(strippedPhoneNumber);
    }

    return phoneNumber;
  }

  static formatDisplayPhoneNumber(phoneNumber: string): string {
    //use case receives numbers in format '12345678900' and converts to “1(303)123-4567”
    return (
      phoneNumber.substring(0, 1) +
      ' (' +
      phoneNumber.substring(1, 4) +
      ') ' +
      phoneNumber.substring(4, 7) +
      '-' +
      phoneNumber.substring(7)
    );
  }
}
