<h3
  class="text-center text-thin"
  [innerHTML]="'offsidebar.setting.SETTINGS'"
></h3>
<div class="p">
  <h4 class="text-muted text-thin">Themes</h4>
  <div class="table-grid mb">
    <div class="col mb">
      <div class="setting-color">
        <label>
          <input
            type="radio"
            name="setting-theme"
            [(ngModel)]="currentTheme"
            (ngModelChange)="setTheme()"
            value="A"
          />
          <span class="icon-check"></span>
          <span class="split">
            <span class="color bg-info"></span>
            <span class="color bg-info-light"></span>
          </span>
          <span class="color bg-white"></span>
        </label>
      </div>
    </div>
    <div class="col mb">
      <div class="setting-color">
        <label>
          <input
            type="radio"
            name="setting-theme"
            [(ngModel)]="currentTheme"
            (ngModelChange)="setTheme()"
            value="B"
          />
          <span class="icon-check"></span>
          <span class="split">
            <span class="color bg-green"></span>
            <span class="color bg-green-light"></span>
          </span>
          <span class="color bg-white"></span>
        </label>
      </div>
    </div>
    <div class="col mb">
      <div class="setting-color">
        <label>
          <input
            type="radio"
            name="setting-theme"
            [(ngModel)]="currentTheme"
            (ngModelChange)="setTheme()"
            value="C"
          />
          <span class="icon-check"></span>
          <span class="split">
            <span class="color bg-purple"></span>
            <span class="color bg-purple-light"></span>
          </span>
          <span class="color bg-white"></span>
        </label>
      </div>
    </div>
    <div class="col mb">
      <div class="setting-color">
        <label>
          <input
            type="radio"
            name="setting-theme"
            [(ngModel)]="currentTheme"
            (ngModelChange)="setTheme()"
            value="D"
          />
          <span class="icon-check"></span>
          <span class="split">
            <span class="color bg-danger"></span>
            <span class="color bg-danger-light"></span>
          </span>
          <span class="color bg-white"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="table-grid mb">
    <div class="col mb">
      <div class="setting-color">
        <label>
          <input
            type="radio"
            name="setting-theme"
            [(ngModel)]="currentTheme"
            (ngModelChange)="setTheme()"
            value="E"
          />
          <span class="icon-check"></span>
          <span class="split">
            <span class="color bg-info-dark"></span>
            <span class="color bg-info"></span>
          </span>
          <span class="color bg-gray-dark"></span>
        </label>
      </div>
    </div>
    <div class="col mb">
      <div class="setting-color">
        <label>
          <input
            type="radio"
            name="setting-theme"
            [(ngModel)]="currentTheme"
            (ngModelChange)="setTheme()"
            value="F"
          />
          <span class="icon-check"></span>
          <span class="split">
            <span class="color bg-green-dark"></span>
            <span class="color bg-green"></span>
          </span>
          <span class="color bg-gray-dark"></span>
        </label>
      </div>
    </div>
    <div class="col mb">
      <div class="setting-color">
        <label>
          <input
            type="radio"
            name="setting-theme"
            [(ngModel)]="currentTheme"
            (ngModelChange)="setTheme()"
            value="G"
          />
          <span class="icon-check"></span>
          <span class="split">
            <span class="color bg-purple-dark"></span>
            <span class="color bg-purple"></span>
          </span>
          <span class="color bg-gray-dark"></span>
        </label>
      </div>
    </div>
    <div class="col mb">
      <div class="setting-color">
        <label>
          <input
            type="radio"
            name="setting-theme"
            [(ngModel)]="currentTheme"
            (ngModelChange)="setTheme()"
            value="H"
          />
          <span class="icon-check"></span>
          <span class="split">
            <span class="color bg-danger-dark"></span>
            <span class="color bg-danger"></span>
          </span>
          <span class="color bg-gray-dark"></span>
        </label>
      </div>
    </div>
  </div>
</div>
<div class="p">
  <h4 class="text-muted text-thin">Layout</h4>
  <div class="clearfix">
    <p class="pull-left">Fixed</p>
    <div class="pull-right">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="settings.layout.isFixed" />
        <span></span>
      </label>
    </div>
  </div>
  <div class="clearfix">
    <p class="pull-left">Boxed</p>
    <div class="pull-right">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="settings.layout.isBoxed" />
        <span></span>
      </label>
    </div>
  </div>
  <!--
    <div class="clearfix">
      <p class="pull-left">RTL</p>
      <div class="pull-right">
         <label class="switch">
            <input type="checkbox" [(ngModel)]="layoutRTL"/>
            <span></span>
         </label>
      </div>
    </div>
    -->
</div>
<div class="p" *ngIf="!settings.layout.horizontal">
  <h4 class="text-muted text-thin">Aside</h4>
  <div class="clearfix">
    <p class="pull-left">Collapsed</p>
    <div class="pull-right">
      <label class="switch">
        <input
          type="checkbox"
          [(ngModel)]="settings.layout.isCollapsed"
          [disabled]="settings.layout.isCollapsedText"
        />
        <span></span>
      </label>
    </div>
  </div>
  <div class="clearfix">
    <p class="pull-left">Collapsed Text</p>
    <div class="pull-right">
      <label class="switch">
        <input
          type="checkbox"
          [(ngModel)]="settings.layout.isCollapsedText"
          [disabled]="settings.layout.isCollapsed"
        />
        <span></span>
      </label>
    </div>
  </div>
  <div class="clearfix">
    <p class="pull-left">Float</p>
    <div class="pull-right">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="settings.layout.isFloat" />
        <span></span>
      </label>
    </div>
  </div>
  <div class="clearfix">
    <p class="pull-left">Hover</p>
    <div class="pull-right">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="settings.layout.asideHover" />
        <span></span>
      </label>
    </div>
  </div>
  <div class="clearfix">
    <p class="pull-left">Show Scrollbar</p>
    <div class="pull-right">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="settings.layout.asideScrollbar" />
        <span></span>
      </label>
    </div>
  </div>
</div>
<!-- <div class="p">
    <h4 class="text-muted text-thin">View Animation</h4>
    <select class="form-control" [(ngMmodel)]="settings.viewAnimation">
        <option value="ng-fadeInUp" selected="selected">fadeInUp</option>
        <option value="ng-fadeInDown">fadeInDown</option>
        <option value="ng-fadeInRight">fadeInRight</option>
        <option value="ng-fadeInLeft">fadeInLeft</option>
        <option value="ng-fadeInUpBig">fadeInUpBig</option>
        <option value="ng-fadeInDownBig">fadeInDownBig</option>
        <option value="ng-fadeInRightBig">fadeInRightBig</option>
        <option value="ng-fadeInLeftBig">fadeInLeftBig</option>
        <option value="ng-fadeIn">fadeIn</option>
    </select>
</div> -->
