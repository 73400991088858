import { AngularTokenModule } from 'angular-token';

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LoaderService } from './core/loader/loader.service';
import { LayoutModule } from './layout/layout.module';
import { RoutesModule } from './routes/routes.module';
import { LoaderInterceptor } from './shared/components/loader-interceptor.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    CoreModule,
    LayoutModule,
    RoutesModule,
    SharedModule.forRoot(),
    AngularTokenModule.forRoot({
      apiBase: environment.portalApi.url,
      resetPasswordCallback:
        window.location.protocol && window.location.host
          ? `${window.location.origin}/password-reset`
          : `${environment.portal.url}/password-reset`,
      signInRedirect: 'login',
      signInStoredUrlStorageKey: 'olympusLoginRedirect',
      signOutFailedValidate: false,
    }),
  ],
  providers: [
    AngularTokenModule,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
