import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { IDeviceTypes } from '../../model/device-types/device-types';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { DatabaseApiService } from '../database-api/database-api.service';
import { StateManager } from '../state/state-manager';

const DEVICE_TYPES_URL = environment.deviceApi.url + '/device-types/doc';

@Injectable()
export class DeviceTypesService extends DatabaseApiService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    cacheService: CacheService,
    stateManager: StateManager
  ) {
    super(http, jwtService, stateManager, cacheService);
  }

  getDeviceTypes(): Observable<IDeviceTypes> {
    return this.get<IDeviceTypes>(DEVICE_TYPES_URL);
  }

  updateDeviceTypes(deviceTypes: IDeviceTypes): Observable<IDeviceTypes> {
    return this.put<IDeviceTypes>(DEVICE_TYPES_URL, deviceTypes);
  }
}
