import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { IFeature } from '../../model/database-api/features';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { DatabaseApiService } from '../database-api/database-api.service';
import { StateManager } from '../state/state-manager';

const FEATURE_FLAGS_URL =
  environment.databaseApi.url + '/namespace/reference/type/features/doc';

@Injectable()
export class FeatureFlagService extends DatabaseApiService {
  protected readonly databaseApiUrl: string;
  private features: IFeature;

  constructor(
    http: HttpClient,
    jwtService: JwtService,
    cacheService: CacheService,
    stateManager: StateManager
  ) {
    super(http, jwtService, stateManager, cacheService);
  }
  getFeatureFlags(): Observable<string[]> {
    return this.get<string[]>(FEATURE_FLAGS_URL);
  }
}
