export namespace ContentConstants {
  export type LabelValueMap = Array<{
    label: string;
    value: string | number;
  }>;

  export const LAYOUT_ROOT_ID = 'layout-root';
  export const ENGAGE_LAYOUT_ROOT_ID = 'engage-layout-root';
  export const FOCUS_LAYOUT_ROOT_ID = 'focus-layout-root';
  export const REHAB_LAYOUT_ROOT_ID = 'rehab-layout-root';
  export const ENGAGE_SCENARIOS_ID = 'scenarios-container';

  export const ENGAGE_STANDARD_PACKAGE_ID = 'engage-standard-package';
  export const FOCUS_STANDARD_PACKAGE_ID = 'focus-standard-package';
  export const REHAB_STANDARD_PACKAGE_ID = 'rehab-standard-package';

  export const NON_DISPLAYABLE_PARENT_CONTAINER_ID_QA =
    '7912f594-c1ac-0186-50b3-9a836ac961a9';
  export const NON_DISPLAYABLE_PARENT_CONTAINER_ID_UAT =
    'f1a80782-003e-9ef3-ae91-fb2caff4740d';
  export const NON_DISPLAYABLE_PARENT_CONTAINER_ID_PROD =
    'be55dcb6-9332-2eb1-8d89-f275f625073d';

  export const NON_DISPLAYABLE_PARENT_CONTAINER_IDS_FOR_ALL_ENVIRONMENTS = [
    NON_DISPLAYABLE_PARENT_CONTAINER_ID_QA,
    NON_DISPLAYABLE_PARENT_CONTAINER_ID_UAT,
    NON_DISPLAYABLE_PARENT_CONTAINER_ID_PROD,
  ];

  export const NEVER_DELETE_CONTENT_IDS = [
    LAYOUT_ROOT_ID,
    ENGAGE_LAYOUT_ROOT_ID,
    FOCUS_LAYOUT_ROOT_ID,
    REHAB_LAYOUT_ROOT_ID,
    ENGAGE_STANDARD_PACKAGE_ID,
    FOCUS_STANDARD_PACKAGE_ID,
    REHAB_STANDARD_PACKAGE_ID,
    ENGAGE_SCENARIOS_ID,
    NON_DISPLAYABLE_PARENT_CONTAINER_ID_QA,
    NON_DISPLAYABLE_PARENT_CONTAINER_ID_UAT,
    NON_DISPLAYABLE_PARENT_CONTAINER_ID_PROD,
  ];

  export const NEVER_EDIT_CONTENT_IDS = [
    LAYOUT_ROOT_ID,
    ENGAGE_STANDARD_PACKAGE_ID,
    FOCUS_STANDARD_PACKAGE_ID,
    REHAB_STANDARD_PACKAGE_ID,
  ];

  export const CONTENT_QA = 'qa';
  export const CONTENT_APPROVED = 'approved';
  export const CONTENT_CANCELED = 'canceled';
  export const CONTENT_INACTIVE = 'inactive';
  export const CONTENT_ACTIVE = 'active';
  export const CONTENT_ACCOUNT_MODE_SELECT = 'select';
  export const CONTENT_ACCOUNT_MODE_ALL = 'all';

  export const CONTENT_TYPE = {
    HAPPYNEURON: 'happyneuron',
    PDF: 'pdf',
    VIDEO: 'video',
    WEBAPP: 'webapp',
    WEBSITE: 'website',
    DISTRIBUTEDEXE: 'distributedexe',
    PRELOADEDEXE: 'preloadedexe',
    YOUTUBEPLAYLIST: 'youtubeplaylist',
    INTEGRATEDWEBSITE: 'integrated_website',
  };

  export const CONTENT_TYPE_NAMES = {
    happyneuron: 'Happy Neuron',
    pdf: 'Pdf',
    video: 'Video',
    webapp: 'Web App',
    website: 'Website',
    distributedexe: 'Distributed Windows Exe',
    preloadedexe: 'Pre-loaded Windows Exe',
    youtubeplaylist: 'YouTube Playlist',
    integrated_website: 'Integrated Website',
  };

  export const DISPLAY_TYPE = {
    ACTIVITY: 'activity',
    AUDIO: 'audio',
    AUDIOBOOK: 'audiobook',
    DOCUMENT: 'document',
    GAME: 'game',
    IMAGE: 'image',
    LIVESTREAM: 'livestream',
    MUSIC: 'music',
    PUZZLE: 'puzzle',
    RECIPE: 'recipe',
    SLIDESHOW: 'slideshow',
    TRIVIA: 'trivia',
    VIDEO: 'video',
    WEBSITE: 'website',
  };

  export const DISPLAY_TYPE_NAMES = {
    activity: 'Activity',
    audio: 'Audio',
    audiobook: 'Audio Book',
    document: 'Document',
    game: 'Game',
    image: 'Image',
    livestream: 'Livestream',
    music: 'Music',
    puzzle: 'Puzzle',
    recipe: 'Recipe',
    slideshow: 'Slideshow',
    trivia: 'Trivia',
    video: 'Video',
    website: 'Website',
  };

  // sorted so that items appear in alphabetical order on content form
  export const DISPLAY_TYPE_LABEL_VALUE_LIST: LabelValueMap = [
    { label: 'Activity', value: DISPLAY_TYPE.ACTIVITY },
    { label: 'Audio', value: DISPLAY_TYPE.AUDIO },
    { label: 'Audio Book', value: DISPLAY_TYPE.AUDIOBOOK },
    { label: 'Document', value: DISPLAY_TYPE.DOCUMENT },
    { label: 'Game', value: DISPLAY_TYPE.GAME },
    { label: 'Image', value: DISPLAY_TYPE.IMAGE },
    { label: 'Livestream', value: DISPLAY_TYPE.LIVESTREAM },
    { label: 'Music', value: DISPLAY_TYPE.MUSIC },
    { label: 'Puzzle', value: DISPLAY_TYPE.PUZZLE },
    { label: 'Recipe', value: DISPLAY_TYPE.RECIPE },
    { label: 'Slideshow', value: DISPLAY_TYPE.SLIDESHOW },
    { label: 'Trivia', value: DISPLAY_TYPE.TRIVIA },
    { label: 'Video', value: DISPLAY_TYPE.VIDEO },
    { label: 'Website', value: DISPLAY_TYPE.WEBSITE },
  ].sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

  export const HAPPY_NEURON_LANG = {
    ENGLISH: 'en',
    SPANISH: 'es',
  };

  export const HAPPY_NEURON_LANG_LABEL_VALUE_LIST: LabelValueMap = [
    { label: 'English', value: HAPPY_NEURON_LANG.ENGLISH },
    { label: 'Spanish', value: HAPPY_NEURON_LANG.SPANISH },
  ];

  export const USAGE_TYPE = {
    GROUP: 'group',
    JOINT: 'joint',
    SOLO: 'solo',
  };

  export const USAGE_TYPE_MAP: LabelValueMap = [
    { label: 'Solo Use', value: USAGE_TYPE.SOLO },
    { label: 'Joint Use', value: USAGE_TYPE.JOINT },
    { label: 'Group Use', value: USAGE_TYPE.GROUP },
  ];

  export const ACCESSIBILITY = {
    HEARING_IMPAIRMENT: 'hearing impairment',
    PHYSICAL_IMPAIRMENT: 'physical impairment',
    VISION_IMPAIRMENT: 'vision impairment',
  };

  export const ACCESSIBILITY_MAP: LabelValueMap = [
    { label: 'Vision Impairment', value: ACCESSIBILITY.VISION_IMPAIRMENT },
    { label: 'Hearing Impairment', value: ACCESSIBILITY.HEARING_IMPAIRMENT },
    { label: 'Physical Impairment', value: ACCESSIBILITY.PHYSICAL_IMPAIRMENT },
  ];

  export const PREFERRED_LAYOUT_ROOT_IDS: string[] = [
    'staff-basic-layout-root',
  ];

  export const TILE_IMAGE_ATTACHMENT_NAME = 'tile_image';

  export const INVALID_TITLE_CHARACTERS = ['"', '/', '~', '%'];

  export const INVALID_CHARACTERS_DESCRIPTION =
    'double quotes, forward slashes, percent signs, or tildas';

  export const MAX_FILE_SIZE = 5 * 1024 * 1024 * 1024; // 5gb
}
