import { Address } from '../address/address';

export const FACILITY_TRAINING_TYPE_WEB = 'web';
export const FACILITY_TRAINING_TYPE_ONSITE = 'onsite';

export const FACILITY_TYPE_ASSISTED_LIVING = 'assisted_living';
export const FACILITY_TYPE_MEMORY_CARE = 'memory_care';
export const FACILITY_TYPE_INDEPENDANT_LIVING = 'independent_living';
export const FACILITY_TYPE_SKILLED_NURSING = 'skilled_nursing';
export const FACILITY_TYPE_CCRC = 'ccrc';
export const FACILITY_TYPE_ADULT_DAY = 'adult_day';
export const FACILITY_TYPE_PACE = 'pace';
export const FACILITY_TYPE_HOME_HEALTH = 'home_health';
export const FACILITY_TYPE_HOME_CARE = 'home_care';
export const FACILITY_TYPE_REHAB_THERAPY = 'rehab_therapy';
export const FACILITY_TYPE_GREENHOUSE = 'greenhouse';
export const FACILITY_TYPE_DESIGN_ARCHITECT = 'design_architect';
export const FACILITY_TYPE_HOSPICE = 'hospice';
export const FACILITY_TYPE_OTHER = 'other';

export const FacilityTypes = [
  { name: 'Assisted Living', type: FACILITY_TYPE_ASSISTED_LIVING },
  { name: 'Memory Care', type: FACILITY_TYPE_MEMORY_CARE },
  { name: 'Independent Living', type: FACILITY_TYPE_INDEPENDANT_LIVING },
  { name: 'Skilled Nursing', type: FACILITY_TYPE_SKILLED_NURSING },
  { name: 'CCRC', type: FACILITY_TYPE_CCRC },
  { name: 'Adult Day', type: FACILITY_TYPE_ADULT_DAY },
  { name: 'PACE', type: FACILITY_TYPE_PACE },
  { name: 'Home Health', type: FACILITY_TYPE_HOME_HEALTH },
  { name: 'Home Care', type: FACILITY_TYPE_HOME_CARE },
  { name: 'Rehab Therapy', type: FACILITY_TYPE_REHAB_THERAPY },
  { name: 'Greenhouse', type: FACILITY_TYPE_GREENHOUSE },
  { name: 'Design Architect', type: FACILITY_TYPE_DESIGN_ARCHITECT },
  { name: 'Hospice', type: FACILITY_TYPE_HOSPICE },
  { name: 'Other', type: FACILITY_TYPE_OTHER },
];

export const SUBSCRIPTION_PACKAGE_1 = 'package_1';
export const SUBSCRIPTION_PACKAGE_2 = 'package_2';
export const SUBSCRIPTION_PACKAGE_3 = 'package_3';
export const SUBSCRIPTION_PACKAGE_4 = 'package_4';
export const SUBSCRIPTION_PACKAGE_5 = 'package_5';
export const SUBSCRIPTION_PACKAGE_6 = 'package_6';

export const SubscriptionPackages = [
  { name: 'Package 1', type: SUBSCRIPTION_PACKAGE_1 },
  { name: 'Package 2', type: SUBSCRIPTION_PACKAGE_2 },
  { name: 'Package 3', type: SUBSCRIPTION_PACKAGE_3 },
  { name: 'Package 4', type: SUBSCRIPTION_PACKAGE_4 },
  { name: 'Package 5', type: SUBSCRIPTION_PACKAGE_5 },
  { name: 'Package 6', type: SUBSCRIPTION_PACKAGE_6 },
];

// --- define facility profile model ---
export interface IFacilityProfile {
  address?: Address;
  customer_community_id?: string;
  eden_community_status?: boolean;
  facility_types?: string[];
  lifeloop_facility_id?: string;
  name?: string;
  notes?: string;
  census?: number;
  inbound_tablet_messaging?: boolean;
  phone?: string;
  region?: string; //remove this
  region_assignment?: IRegionAssignment[];
  require_staff_login?: boolean;
  subscriptions?: string[];
  technical_contact?: string;
  technical_contact_phone?: string;
  technical_contact_email?: string;
  training_type?: string;
  zoom_id?: string;
  zoom_meeting_title?: string;
  zoom_password?: string;
}

export interface IRegionAssignment {
  region_id: string;
  start_date: string;
  end_date?: string;
}

export class FacilityProfile implements IFacilityProfile {
  address: Address;
  census: number;
  customer_community_id: string;
  eden_community_status: boolean;
  facility_types: string[];
  inbound_tablet_messaging?: boolean;
  lifeloop_facility_id: string;
  name: string;
  notes: string;
  phone: string;
  region: string;
  region_assignment: IRegionAssignment[];
  require_staff_login: boolean;
  subscriptions: string[];
  technical_contact: string;
  technical_contact_phone: string;
  technical_contact_email: string;
  training_type: string;
  zoom_meeting_title: string;
  zoom_id: string;
  zoom_password: string;

  constructor(data: IFacilityProfile = {}) {
    this.address = new Address(data.address);
    this.customer_community_id = data.customer_community_id || '';
    this.census = data.census || 0;
    this.eden_community_status = !!data.eden_community_status;
    this.facility_types = data.facility_types || [];
    this.inbound_tablet_messaging = !!data.inbound_tablet_messaging;
    this.lifeloop_facility_id = data.lifeloop_facility_id || '';
    this.name = (data.name || '').trim();
    this.notes = data.notes || '';
    this.phone = data.phone || '';
    this.region_assignment = data.region_assignment || [];
    this.region = data.region || '';
    this.require_staff_login = !!data.require_staff_login;
    this.subscriptions = data.subscriptions || [];
    this.technical_contact = data.technical_contact || '';
    this.technical_contact_phone = data.technical_contact_phone || '';
    this.technical_contact_email = data.technical_contact_email || '';
    this.training_type = data.training_type || '';
    this.training_type = data.training_type || '';
    this.zoom_id = data.zoom_id || '';
    this.zoom_meeting_title = data.zoom_meeting_title || '';
    this.zoom_password = data.zoom_password || '';
  }
}

// --- create an empty base account profile --
export const EmptyFacilityProfile = new FacilityProfile();
