import { Address, IAddress } from '../address/address';

export interface IContact {
  address?: IAddress;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
}

export class Contact implements IContact {
  address: Address;
  email = '';
  first_name = '';
  last_name = '';
  phone = '';

  constructor(data: IContact = {}) {
    this.email = data.email || '';
    this.first_name = data.first_name || '';
    this.last_name = data.last_name || '';
    this.phone = data.phone || '';
    this.address = new Address(data.address);
  }
}
