import * as _ from 'lodash';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ContentConstants } from '../../constants/content.constants';
import { DocTypeConstants } from '../../constants/doc-types';
import { ContentLayoutInterfaces } from '../../model/content/content-layout.interfaces';
import { ContentModels } from '../../model/content/content.models';
import { SyncGatewayAttachment } from '../../model/sync-gateway/sync-gateway-attachment';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { ContentApiService } from '../content-api/content-api.service';
import { MediaApiService } from '../media/media-api.service';
import { StateManager } from '../state/state-manager';
import { ContentLayoutHelpers } from './content-layout.helpers';

import LinkTo = ContentModels.LinkTo;
import Container = ContentModels.Container;
import LayoutRoot = ContentModels.LayoutRoot;
import { merge } from 'lodash';
@Injectable()
export class ContentLayoutService extends ContentApiService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    cacheService: CacheService,
    mediaApiService: MediaApiService,
    stateManager: StateManager
  ) {
    super(http, jwtService, mediaApiService, stateManager, cacheService);
  }

  /*
      Layout-Roots
     */
  getAllLayoutRoots(): Observable<LayoutRoot[]> {
    return this.getAllByNamespaceType<LayoutRoot>(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.LAYOUT_ROOT,
      LayoutRoot
    );
  }

  getLayoutRootFromLinkTo(
    linkTo: LinkTo
  ): Observable<ContentModels.LayoutRoot> {
    return this.getContainer(linkTo.parent_id).pipe(
      mergeMap(container => {
        return this.getLayoutRoot(container.layout_root_id ?? '');
      })
    );
  }

  getLayoutRoot(layoutRootId: string): Observable<LayoutRoot> {
    return this.getById<LayoutRoot>(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.LAYOUT_ROOT,
      layoutRootId,
      LayoutRoot
    );
  }

  upsertLayoutRoot(layoutRoot: LayoutRoot): Observable<LayoutRoot> {
    if (!(layoutRoot._id || '').endsWith(ContentConstants.LAYOUT_ROOT_ID)) {
      return throwError(new Error('Invalid ID for layout root'));
    }

    return this.upsertSingleLayoutRoot(layoutRoot).pipe(
      mergeMap((updatedLayoutRoot: LayoutRoot) => {
        this.cacheService.updateSingleDoc(updatedLayoutRoot);
        return of(updatedLayoutRoot);
      })
    );
  }

  copyContainer(
    sourceId: string,
    destinationId: string
  ): Observable<ContentLayoutInterfaces.IContainerCopyResult> {
    return this.post<ContentLayoutInterfaces.IContainerCopyResult>(
      `${this.contentApiUrl}/layout/copymove/source/${sourceId}/dest/${destinationId}`,
      undefined,
      {}
    ).pipe(
      mergeMap(result => {
        // TODO - Jake: Clear method to just clear layout related data.
        // See created ticket https://in2lprojects.atlassian.net/browse/IN2L-8649
        this.cacheService.clearCache();
        return of(result);
      })
    );
  }

  /*
      Containers
     */
  getAllContainers(): Observable<Container[]> {
    return this.getAllByNamespaceType<Container>(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.CONTAINER,
      Container
    );
  }

  getContainer(id: string): Observable<Container> {
    return this.getById<Container>(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.CONTAINER,
      id,
      Container
    );
  }

  createContainer(container: Container): Observable<Container> {
    return this.upsertSingleContainer(container).pipe(
      mergeMap((updatedContainer: Container) => {
        this.cacheService.updateSingleDoc(updatedContainer);
        return of(updatedContainer);
      })
    );
  }

  upsertContainer(
    container: Container,
    attachment?: SyncGatewayAttachment
  ): Observable<Container> {
    return this.upsertSingleContainer(container, attachment).pipe(
      mergeMap((updatedContainer: Container) => {
        this.cacheService.updateSingleDoc(updatedContainer);
        return of(updatedContainer);
      })
    );
  }

  deleteContainerWithChildren(containerId: string): Observable<unknown[]> {
    return forkJoin([
      this.getAllByNamespaceType<LayoutRoot>(
        DocTypeConstants.NAMESPACES.CONTENT,
        DocTypeConstants.TYPES.CONTENT.LAYOUT_ROOT,
        LayoutRoot
      ),
      this.getAllByNamespaceType<Container>(
        DocTypeConstants.NAMESPACES.CONTENT,
        DocTypeConstants.TYPES.CONTENT.CONTAINER,
        Container
      ),
      this.getAllByNamespaceType<LinkTo>(
        DocTypeConstants.NAMESPACES.CONTENT,
        DocTypeConstants.TYPES.CONTENT.LINK_TO,
        LinkTo
      ),
    ]).pipe(
      mergeMap(
        ([layoutRoots, containers, linkTos]: [
          LayoutRoot[],
          Container[],
          LinkTo[]
        ]) => {
          const layoutRootMap = layoutRoots.reduce((result, item) => {
            result[item._id] = item;
            return result;
          }, {});

          const containerMap = containers.reduce((result, item) => {
            result[item._id] = item;
            return result;
          }, {});

          const linkToMap = linkTos.reduce((result, item) => {
            result[item._id] = item;
            return result;
          }, {});

          const gatherChildren = (id: string): any[] => {
            const c = containerMap[id];
            if (!c || !c.children || !c.children.length) {
              return [];
            }

            return (c.children || []).concat(
              ...c.children.map(childId => gatherChildren(childId))
            );
          };

          const container = containerMap[containerId];
          const parentContainer = container
            ? <Container>containerMap[container.parent_id] ||
              <LayoutRoot>layoutRootMap[container.parent_id] ||
              null
            : null;

          const treeIds = _.uniq(
            [containerId].concat(gatherChildren(containerId))
          );
          const updateMap = treeIds.reduce((result, id) => {
            const doc = containerMap[id] || linkToMap[id];
            if (!doc) {
              return result;
            }

            doc._deleted = true;
            result[id] = doc;
            return result;
          }, {});

          const deletedLinkToIds = Object.keys(updateMap)
            .map(id => updateMap[id])
            .filter(
              doc => doc.doc_type === DocTypeConstants.TYPES.CONTENT.LINK_TO
            )
            .map(doc => doc._id);

          if (parentContainer) {
            // Remove the container being deleted and any other containers or link-tos that no longer exist
            parentContainer.children = parentContainer.children.filter(id => {
              const doc = containerMap[id] || linkToMap[id];
              return id !== containerId && !!doc;
            });

            if (parentContainer instanceof Container) {
              parentContainer.active_dates = (
                parentContainer.active_dates || []
              ).filter(ad => !deletedLinkToIds.includes(ad.link_to_id));
            }
            updateMap[parentContainer._id] = parentContainer;

            if (parentContainer instanceof Container) {
              let parent = containerMap[parentContainer.parent_id];
              while (parent) {
                parent.active_dates = (parent.active_dates || []).filter(
                  ad => !deletedLinkToIds.includes(ad.link_to_id)
                );

                updateMap[parent._id] = parent;

                parent = containers.find(c => c._id === parent.parent_id);
              }
            }
          }

          const updateDocs = Object.keys(updateMap).map(id => updateMap[id]);
          const layoutRootObs = updateDocs
            .filter(
              doc => doc.doc_type === DocTypeConstants.TYPES.CONTENT.LAYOUT_ROOT
            )
            .map(doc => this.upsertLayoutRoot(doc));

          const containerObs = updateDocs
            .filter(
              doc => doc.doc_type === DocTypeConstants.TYPES.CONTENT.CONTAINER
            )
            .map(doc => this.upsertContainer(doc));

          const linkToObs = updateDocs
            .filter(
              doc => doc.doc_type === DocTypeConstants.TYPES.CONTENT.LINK_TO
            )
            .map(doc => this.upsertLinkTo(doc));

          return forkJoin([...layoutRootObs, ...containerObs, ...linkToObs]);
        }
      )
    );
  }

  /*
      Link-Tos
     */
  getAllLinkTos(): Observable<LinkTo[]> {
    return this.getAllByNamespaceType<LinkTo>(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.LINK_TO,
      LinkTo
    );
  }

  getLinkTo(id: string): Observable<LinkTo> {
    return this.getById<LinkTo>(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.LINK_TO,
      id,
      LinkTo
    );
  }

  upsertLinkTo(
    linkTo: LinkTo,
    attachment?: SyncGatewayAttachment
  ): Observable<LinkTo> {
    return this.upsertSingleLinkTo(linkTo, attachment).pipe(
      mergeMap((updatedLinkTo: LinkTo) => {
        this.cacheService.updateSingleDoc(updatedLinkTo);
        return forkJoin([this.getAllContainers(), this.getAllLinkTos()]).pipe(
          mergeMap(([containers, linkTos]: [Container[], LinkTo[]]) => {
            const itemMap = [...containers, ...linkTos].reduce(
              (result, item) => {
                result[item._id] = item;
                return result;
              },
              {}
            );

            // If we are adding a linkTo to the root, we don't need to update its parent containers
            if (
              !containers.find(c => c._id === linkTo.parent_id) ||
              (updatedLinkTo.parent_id &&
                updatedLinkTo.parent_id.endsWith(
                  ContentConstants.LAYOUT_ROOT_ID
                ))
            ) {
              return of(updatedLinkTo);
            }

            const updateDocs = ContentLayoutHelpers.updateAncestorContainers(
              updatedLinkTo,
              itemMap
            );

            const updateObs = updateDocs.map(doc => this.upsertContainer(doc));
            if (!updateObs || updateObs.length < 1) {
              return of(updatedLinkTo);
            }

            return forkJoin(updateObs).pipe(map(() => updatedLinkTo));
          })
        );
      })
    );
  }

  deleteLinkTo(linkTo: LinkTo): Observable<boolean> {
    return this.deleteDoc(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.LINK_TO,
      linkTo._id
    ).pipe(
      mergeMap(() => {
        return this.getAllContainers().pipe(
          mergeMap(containers => {
            const parentContainerMap = {};
            let parent = containers.find(c => c._id === linkTo.parent_id);

            // If the parent is deleted or doesn't exist anymore, just return
            if (!parent) {
              return of(true);
            }

            while (parent) {
              parent.active_dates = (parent.active_dates || []).filter(
                ad => ad.link_to_id !== linkTo._id
              );
              parentContainerMap[parent._id] = parent;

              parent = containers.find(c => c._id === parent.parent_id);
            }

            const updates = Object.keys(parentContainerMap).map(
              id => parentContainerMap[id]
            );

            const containerObs = updates.map(doc => this.upsertContainer(doc));

            return forkJoin(containerObs).pipe(map(() => true));
          })
        );
      })
    );
  }
}
