import * as _ from 'lodash';
import { CustomValidators } from 'ng2-validation';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';

import { DocTypeConstants } from '../../../constants/doc-types';
import { UserConstants } from '../../../constants/user.constants';
import { AccountService } from '../../../core/account/account.service';
import { FacilityService } from '../../../core/facility/facility.service';
import { InputService } from '../../../core/input/input.service';
import { ResidentService } from '../../../core/resident/resident.service';
import { StateManager } from '../../../core/state/state-manager';
import { Account } from '../../../model/account/account';
import { Facility } from '../../../model/facility/facility';
import { Resident } from '../../../model/resident/resident';
import { User } from '../../../model/user/user';
import { UnsubscribeOnDestroy } from '../../../util/unsubscribe-on-destroy';

@Component({
  selector: 'app-user-type-select',
  templateUrl: './user-type-select.component.html',
  styleUrls: ['./user-type-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserTypeSelectComponent
  extends UnsubscribeOnDestroy
  implements OnInit {
  @Input()
  parentForm: FormGroup;
  @Input()
  existingUser: any;
  @Output()
  componentReady: EventEmitter<boolean> = new EventEmitter<boolean>();

  viewReady = false;
  facilityUserFormLoading = false;
  filteredFacilities: Facility[] = [];

  phoneMask = InputService.US_INT_PHONE_MASK;

  defaults = {
    accountId: '',
    accountName: '',
    userType: '',
    email: '',
    facilityIds: null,
    facilityName: '',
    firstName: '',
    lastName: '',
    pin: '',
    phone: '',
    residentIds: [],
    residentMode: UserConstants.USER_RESIDENT_MODE_ALL,
    title: '',
  };

  facilityUserAllowed: boolean;

  userTypes: Array<{ name: string; type: string }> = [];
  userTypeNames: Array<{ id: string; text: string }> = [];

  accounts: Account[] = []; // full account docs, needed for facility data
  accountNames: Array<{ id: string; text: string }> = []; // key value of account ids and account name

  facilities: Facility[];
  availableFacilities: Facility[];

  residents: Resident[] = [];

  selectedUserType: string;
  selectedAccountId: string;

  selectedFacilities: Facility[] = [];
  selectedResidentMode: string = UserConstants.USER_RESIDENT_MODE_ALL;
  selectedResidentIds: string[] = [];
  selectedResidentsDropdown: Array<{ id: string; text: string }> = [];
  filteredResidents: Array<{ id: string; text: string }> = [];

  currentUser: User;

  randomPin: string = _.padStart(_.random(99999).toString(), 5, '0');

  userTypeValues = {
    [DocTypeConstants.TYPES.USER.IN2L_ADMIN]: 5,
    [DocTypeConstants.TYPES.USER.IN2L_CONTENT]: 4,
    [DocTypeConstants.TYPES.USER.ACCOUNT_ADMIN]: 3,
    [DocTypeConstants.TYPES.USER.FACILITY_ADMIN]: 2,
    [DocTypeConstants.TYPES.USER.FACILITY_USER]: 1,
  };

  constructor(
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private facilityService: FacilityService,
    private residentService: ResidentService,
    private stateManager: StateManager
  ) {
    super();
  }

  ngOnInit() {
    this.currentUser = this.stateManager.getCurrentUser();

    this.subscriptionTracker.track = this.activatedRoute.params
      .pipe(
        mergeMap((params: Params) => {
          const accountId =
            params.id || _.get(this, 'existingUser.account_id', '');
          const facilityId =
            params.facility_id ||
            (this.existingUser &&
            this.existingUser.facility_ids &&
            this.existingUser.facility_ids.length
              ? this.existingUser.facility_ids[0]
              : undefined);
          this.facilityUserAllowed = !!accountId && !!facilityId;

          if (this.existingUser) {
            this.setExistingUserValuesAsDefault();
          }

          this.defaults.accountId = this.defaults.accountId || accountId || '';
          if (!this.defaults.facilityIds || !this.defaults.facilityIds.length) {
            this.defaults.facilityIds = !!facilityId ? [facilityId] : [];
          }

          return forkJoin([
            this.accountService.getAccounts(),
            this.facilityService.getAllFacilities(),
          ]);
        })
      )
      .subscribe(([accounts, facilities]: [Account[], Facility[]]) => {
        accounts.sort((a, b) =>
          _.trim(a.profile.account_name).localeCompare(
            _.trim(b.profile.account_name)
          )
        );
        facilities.sort((a, b) =>
          _.trim(a.profile.name).localeCompare(_.trim(b.profile.name))
        );

        // User Role Type: Set the account types that the logged in user can select
        this.userTypes = this.setUserTypes();

        this.setUserTypeDropdownValues();

        // Accounts: Set the accounts that the logged in user can select (should only be one unless it is a IN2L Admin)
        this.accounts = this.setAccounts(accounts);

        this.setAccountDropdownValues();

        // Facilities: Set the list of facilities based on what the logged in user has access to
        this.facilities = this.setFacilities(facilities);

        this.setFacilityDropdownValues();

        this.addControlsToParentForm();

        this.viewReady = true;
        this.componentReady.emit(true);
      });
  }

  setExistingUserValuesAsDefault(): void {
    this.defaults.userType = this.existingUser.doc_type || '';
    this.defaults.accountId = this.existingUser.account_id || '';
    this.defaults.email = this.existingUser.email || '';
    this.defaults.facilityIds =
      this.existingUser.facility_ids && this.existingUser.facility_ids.length
        ? this.existingUser.facility_ids
        : [];
    this.defaults.firstName = this.existingUser.first_name || '';
    this.defaults.lastName = this.existingUser.last_name || '';
    this.defaults.phone = this.existingUser.phone || '';
    this.defaults.pin = this.existingUser.pin || '';
    this.defaults.residentIds = this.existingUser.resident_ids || [];
    this.defaults.residentMode =
      this.existingUser.resident_mode ||
      UserConstants.USER_RESIDENT_MODE_SELECT;
    this.defaults.title = this.existingUser.title || '';
  }

  setUserTypes(): any {
    return UserConstants.USER_TYPES_LIST.filter(value => {
      if (this.existingUser && !this.currentUserAllowedToEditFacilities()) {
        // Exclude the existing users current type
        if (value.type === this.existingUser.doc_type) {
          return false;
        }

        // Do not allow downgrading an existing user with Portal access to no-access community user
        if (
          this.existingUser.doc_type !==
            DocTypeConstants.TYPES.USER.FACILITY_USER &&
          value.type === DocTypeConstants.TYPES.USER.FACILITY_USER
        ) {
          return false;
        }
      }

      // Only show facility user if this is an account/facility staff invite page
      if (
        value.type === DocTypeConstants.TYPES.USER.FACILITY_USER &&
        !this.facilityUserAllowed
      ) {
        return false;
      }

      // Only show the user types that have equal or less rights than the current users type
      return (
        this.userTypeValues[this.currentUser.doc_type] >=
        this.userTypeValues[value.type]
      );
    });
  }

  setAccounts(accounts: Account[]): Account[] {
    return this.currentUser.doc_type === DocTypeConstants.TYPES.USER.IN2L_ADMIN
      ? accounts
      : accounts.filter(a => a._id === this.currentUser.account_id);
  }

  setFacilities(facilities: Facility[]): Facility[] {
    return this.isFacilityAdmin(this.currentUser.doc_type)
      ? facilities.filter(f => this.currentUser.facility_ids.includes(f._id))
      : facilities;
  }

  addControlsToParentForm(): void {
    this.parentForm.addControl('email', new FormControl(this.defaults.email));
    this.parentForm.get('email').validator =
      !this.existingUser && this.facilityUserAllowed
        ? CustomValidators.email
        : Validators.compose([Validators.required, CustomValidators.email]);

    this.parentForm.addControl(
      'userType',
      new FormControl(this.defaults.userType, Validators.required)
    );
    this.parentForm.addControl(
      'accountId',
      new FormControl(this.defaults.accountId)
    );
    const facilityIds = (this.defaults.facilityIds || []).filter(f => !!f);
    this.parentForm.addControl('facilityIds', new FormControl(facilityIds));
    this.parentForm.get('facilityIds').validator = Validators.compose([
      Validators.required,
    ]);
    this.parentForm.addControl(
      'firstName',
      new FormControl(this.defaults.firstName, Validators.required)
    );
    this.parentForm.addControl(
      'lastName',
      new FormControl(this.defaults.lastName)
    );
    this.parentForm.addControl('pin', new FormControl(this.defaults.pin));
    this.parentForm.addControl('title', new FormControl(this.defaults.title));
    this.parentForm.addControl('phone', new FormControl(this.defaults.phone));
    this.parentForm.addControl(
      'residentMode',
      new FormControl(this.defaults.residentMode)
    );
    this.parentForm.addControl(
      'residentIds',
      new FormControl(this.defaults.residentIds)
    );
  }

  setUserTypeDropdownValues() {
    this.userTypeNames = this.userTypes.map(value => ({
      id: value.type,
      text: value.name,
    }));
    this.setSelectedUser();
  }

  setSelectedUser(): void {
    this.selectedUserType =
      this.selectedUserType || this.defaults.userType || null;
  }

  setAccountDropdownValues() {
    this.accountNames = this.accounts.map(a => ({
      id: a._id,
      text: a.profile.account_name,
    }));

    this.selectedAccountId =
      this.selectedAccountId || this.defaults.accountId || null;
  }

  setFacilityDropdownValues() {
    this.availableFacilities = this.selectedAccountId
      ? this.facilities.filter(f => f.account_id === this.selectedAccountId)
      : [];
    this.availableFacilities.sort((a, b) =>
      a.profile.name.localeCompare(b.profile.name)
    );

    if (this.availableFacilities.length === 1) {
      this.selectedFacilities = this.availableFacilities;
    } else if (this.selectedFacilities.length === 0) {
      this.selectedFacilities = this.availableFacilities.filter(f =>
        this.defaults.facilityIds.includes(f._id)
      );
    } else {
      this.selectedFacilities = this.selectedFacilities.filter(f =>
        this.availableFacilities.map(n => n._id).includes(f._id)
      );
    }

    if (this.parentForm && this.parentForm.get('facilityIds')) {
      this.parentForm
        .get('facilityIds')
        .setValue(this.selectedFacilities.map(f => f._id));
    }
  }

  // observable watching account type value changes
  onUserTypeChange(selectedUserType: string) {
    this.facilityUserFormLoading = this.isFacilityUser(selectedUserType);

    const residentsObservable = this.isFacilityUser(selectedUserType)
      ? this.residentService.getAllResidentsForFacility(
          this.defaults.accountId,
          this.defaults.facilityIds[0]
        )
      : of([]);

    this.subscriptionTracker.track = residentsObservable.subscribe(
      (residents: Resident[]) => {
        // only show active residents
        this.residents = residents.filter(resident => resident.isActive());

        this.selectedUserType = selectedUserType;
        this.parentForm.get('userType').setValue(this.selectedUserType);

        if (!this.selectedUserType) {
          return;
        }

        this.parentForm.get('email').validator = this.isFacilityUser(
          this.selectedUserType
        )
          ? CustomValidators.email
          : Validators.compose([Validators.required, CustomValidators.email]);
        this.parentForm.get('email').updateValueAndValidity();

        this.parentForm.get('accountId').validator = this.isIn2lType(
          this.selectedUserType
        )
          ? Validators.nullValidator
          : Validators.required;
        this.parentForm.get('accountId').updateValueAndValidity();

        this.parentForm.get('facilityIds').validator =
          this.isFacilityAdmin(this.selectedUserType) ||
          this.isFacilityUser(this.selectedUserType)
            ? Validators.compose([Validators.required, Validators.minLength(1)])
            : Validators.nullValidator;
        this.parentForm.get('facilityIds').updateValueAndValidity();

        this.parentForm.get('lastName').validator = this.isFacilityUser(
          this.selectedUserType
        )
          ? Validators.required
          : Validators.nullValidator;

        // Account admin and below require an account selection so default it to the default account
        if (
          this.userTypeValues[this.selectedUserType] <=
          this.userTypeValues[DocTypeConstants.TYPES.USER.ACCOUNT_ADMIN]
        ) {
          this.setAccountDropdownValues();
        }

        // Facility admin and below require a facility selection so default it to the default facilities
        if (this.isFacilityAdmin(this.selectedUserType)) {
          this.setFacilityDropdownValues();
        }

        if (this.isFacilityUser(selectedUserType)) {
          this.parentForm.get('accountId').setValue(this.defaults.accountId);
          const facilityIds = (this.defaults.facilityIds || []).filter(
            f => !!f
          );
          this.parentForm.get('facilityIds').setValue(facilityIds);
          this.parentForm.get('firstName').setValue(this.defaults.firstName);
          this.parentForm.get('lastName').setValue(this.defaults.lastName);
          this.parentForm.get('phone').setValue(this.defaults.phone);
          this.parentForm.get('phone').validator = Validators.pattern(
            InputService.US_INT_PHONE_VALIDATION_PATTERN
          );
          this.parentForm.get('pin').setValue(this.randomPin);
          this.parentForm
            .get('residentMode')
            .setValue(this.defaults.residentMode);
          this.parentForm.get('residentIds').setValue(this.selectedResidentIds);
          this.parentForm.get('title').setValue(this.defaults.title);
        } else {
          // Reset fields that aren't on non-facility users
          this.parentForm.get('phone').reset();
          this.parentForm.get('lastName').reset();
          this.parentForm.get('title').reset();
        }

        this.facilityUserFormLoading = false;
      }
    );
  }

  // observable watching account value changes
  onAccountChange(selectedAccountId: string) {
    this.selectedAccountId = selectedAccountId;
    this.selectedFacilities = [];
    this.setFacilityDropdownValues();
  }

  showEmailFirstName(): boolean {
    if (
      this.existingUser &&
      this.existingUser.doc_type ===
        DocTypeConstants.TYPES.USER.FACILITY_USER &&
      this.selectedUserType !== DocTypeConstants.TYPES.USER.FACILITY_USER
    ) {
      return true;
    }

    return !this.existingUser && !!this.selectedUserType;
  }

  accountName(): string {
    return _.get(
      this.accounts.find(a => a._id === this.defaults.accountId),
      'profile.account_name',
      ''
    );
  }

  facilityName(): string {
    const facilityId = this.defaults.facilityIds[0];
    return _.get(
      this.facilities.find(f => f._id === facilityId),
      'profile.name',
      ''
    );
  }

  isAccountAdmin(type: string): boolean {
    return type === DocTypeConstants.TYPES.USER.ACCOUNT_ADMIN;
  }

  isFacilityAdmin(type: string): boolean {
    return type === DocTypeConstants.TYPES.USER.FACILITY_ADMIN;
  }

  isFacilityUser(type: string): boolean {
    return type === DocTypeConstants.TYPES.USER.FACILITY_USER;
  }

  isIn2lAdmin(type: string): boolean {
    return type === DocTypeConstants.TYPES.USER.IN2L_ADMIN;
  }

  isIn2lType(type: string): boolean {
    return (
      type === DocTypeConstants.TYPES.USER.IN2L_ADMIN ||
      type === DocTypeConstants.TYPES.USER.IN2L_CONTENT
    );
  }

  showAccountDropdown() {
    return (
      this.selectedUserType &&
      (this.isAccountAdmin(this.selectedUserType) ||
        this.isFacilityAdmin(this.selectedUserType))
    );
  }

  allowFacilityEdit(): boolean {
    return (
      this.currentUserAllowedToEditFacilities() &&
      this.selectedUserType === DocTypeConstants.TYPES.USER.FACILITY_ADMIN
    );
  }

  currentUserAllowedToEditFacilities(): boolean {
    // Community Admins cannot edit their own community authorities
    return (
      this.isIn2lAdmin(this.currentUser.doc_type) ||
      this.isAccountAdmin(this.currentUser.doc_type)
    );
  }

  showFacilityDropdown() {
    if (this.allowFacilityEdit()) {
      return true;
    }
    return (
      this.selectedUserType &&
      this.selectedAccountId &&
      this.isFacilityAdmin(this.selectedUserType)
    );
  }

  showFacilityUserForm() {
    return !this.existingUser && this.isFacilityUser(this.selectedUserType);
  }

  showResidentSelector() {
    return (
      this.showFacilityUserForm() &&
      this.parentForm.get('residentMode').value ===
        UserConstants.USER_RESIDENT_MODE_SELECT
    );
  }

  // community admin select dropdown
  filterFacilitiesForQuery(event = null) {
    // Filter the list of facilities so that it only includes those that haven't already been selected
    // In addition, filter the list based on any query string that may have been entered by the user
    this.filteredFacilities = this.availableFacilities.filter(
      af =>
        !this.selectedFacilities.includes(af) &&
        (event?.query
          ? af.profile.name.toLowerCase().indexOf(event.query.toLowerCase()) ===
            0
          : true)
    );
  }

  selectedFacilitiesChanged() {
    this.parentForm
      .get('facilityIds')
      .setValue(this.selectedFacilities.map(f => f._id));
    this.filterFacilitiesForQuery();
  }

  setResidentModeAll() {
    // Clear selected resident Ids
    this.selectedResidentIds = [];
    this.selectedResidentsDropdown = [];
    this.parentForm.get('residentIds').setValue(this.selectedResidentIds);

    this.parentForm
      .get('residentMode')
      .setValue(UserConstants.USER_RESIDENT_MODE_ALL);
    this.selectedResidentIds = [];
    this.parentForm.get('residentIds').setValue(this.selectedResidentIds);

    // Remove 'required' validator for resident select box
    this.parentForm.get('residentIds').clearValidators();
    this.parentForm.get('residentIds').updateValueAndValidity();
  }

  setResidentModeSelect() {
    this.parentForm
      .get('residentMode')
      .setValue(UserConstants.USER_RESIDENT_MODE_SELECT);

    // Add 'required' validator for resident select box
    this.parentForm
      .get('residentIds')
      .setValidators(Validators.compose([Validators.required]));
    this.parentForm.get('residentIds').updateValueAndValidity();
  }

  handleAddResident(select) {
    this.selectedResidentIds = [...this.selectedResidentIds, select.id];
    this.parentForm.get('residentIds').setValue(this.selectedResidentIds);
  }

  handleRemoveResident(select) {
    this.selectedResidentIds = [
      ...this.selectedResidentIds.filter(id => id !== select.id),
    ];
    this.parentForm.get('residentIds').setValue(this.selectedResidentIds);
  }

  filterResidents(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.residents.length; i++) {
      const resident = {
        id: this.residents[i]._id,
        text: `${this.residents[i].first_name} ${this.residents[i].last_name}`,
      };
      if (
        resident.text.toLowerCase().indexOf(query.toLowerCase()) === 0 &&
        !this.selectedResidentIds.includes(resident.id)
      ) {
        filtered.push(resident);
      }
    }

    this.filteredResidents = filtered;
  }
}
