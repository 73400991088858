import * as _ from 'lodash';

import { Injectable } from '@angular/core';

import { DocTypeConstants } from '../../constants/doc-types';
import { SyncGatewayChange } from '../../model/sync-gateway/sync-gateway-change';

const CACHE_KEY_DELIMITER = '~';

@Injectable()
export class CacheService {
  // ID subsets broken out by namespace, type, accountId, and facilityId
  // Used to return data for a request without having to make the actual request
  private static idCacheByRequestKey = {};

  // Full collection of all documents received from API SG Pass-thru GET requests
  private static docCacheById = {};

  constructor() {}

  setCache(
    docs: any[],
    namespace: string,
    type?: string,
    accountId?: string,
    facilityId?: string
  ) {
    // Load all docs into the document map cache
    docs.forEach(doc => {
      CacheService.docCacheById[doc._id] = doc;
    });

    const isFacilityData = type && accountId && facilityId;
    const isAccountData = type && accountId && !facilityId;
    const isTypeData = type && !accountId && !facilityId;
    const isNamespaceData = !type && !accountId && !facilityId;

    const isInvalidRequestKey =
      !isFacilityData && !isAccountData && !isTypeData && !isNamespaceData;

    if (isInvalidRequestKey) {
      return;
    }

    const docIds = docs.map(doc => doc._id);
    const requestKey = this.buildCacheRequestKey(
      namespace,
      type,
      accountId,
      facilityId
    );

    let selectedCacheKey = requestKey;

    // Find lowest level cache to add these docs to
    const existingCacheKey = this.lowestLevelCacheKey(
      namespace,
      type,
      accountId,
      facilityId
    );

    // If the lowest existing cache key is lower than the request key, use the existing cache key
    if (existingCacheKey && existingCacheKey.length <= requestKey.length) {
      selectedCacheKey = existingCacheKey;
    }

    // Cache IDs for request, not full docs, in order to prevent duplicates in the cache
    CacheService.idCacheByRequestKey[selectedCacheKey] = _.uniq(
      (CacheService.idCacheByRequestKey[selectedCacheKey] || []).concat(docIds)
    );

    // Clean out sub-keys that have duplicate id lists
    Object.keys(CacheService.idCacheByRequestKey)
      .filter(
        key =>
          key.startsWith(selectedCacheKey) &&
          key.length > selectedCacheKey.length
      )
      .forEach(key => delete CacheService.idCacheByRequestKey[key]);
  }

  /**
   * Get data from the cache that has the required data
   */
  getCache(
    namespace: string,
    type?: string,
    accountId?: string,
    facilityId?: string
  ): any[] {
    const requestKey = this.lowestLevelCacheKey(
      namespace,
      type,
      accountId,
      facilityId
    );
    if (
      !requestKey ||
      !CacheService.idCacheByRequestKey[requestKey] ||
      !CacheService.idCacheByRequestKey[requestKey].length
    ) {
      return null;
    }

    const docs = CacheService.idCacheByRequestKey[requestKey]
      .map(id => CacheService.docCacheById[id])
      .filter(doc => !!doc);

    // Returned filtered docs based on what is or is not set
    let filteredDocs = docs;
    if (type) {
      filteredDocs = filteredDocs.filter(doc => doc.doc_type === type);
    }

    if (accountId) {
      filteredDocs = filteredDocs.filter(
        doc =>
          doc._id === accountId ||
          doc.account_id === accountId ||
          doc.correlation_account_id === accountId
      );
    }

    if (facilityId) {
      filteredDocs = filteredDocs.filter(
        doc => doc._id === facilityId || doc.facility_id === facilityId
      );
    }

    return filteredDocs;
  }

  getCachedDoc(id: string): any {
    return CacheService.docCacheById[id] || null;
  }

  /**
   * Change feed updates
   * Documents submitted to updateChangedDocs may be from any namespace or type
   * and therefore need to be processed individually
   */
  updateChangedDocs(changes: SyncGatewayChange[]) {
    if (!changes.length) {
      return;
    }

    changes.forEach(change => {
      if (!change.id) {
        return;
      }

      change.deleted = !!change.deleted || !!change.doc['_deleted'];
      const doc =
        change.deleted &&
        CacheService.docCacheById[change.id] &&
        CacheService.docCacheById[change.id]['doc_namespace']
          ? CacheService.docCacheById[change.id]
          : change.doc;

      const existingCacheKey = this.lowestLevelCacheKey(
        doc['doc_namespace'],
        doc['doc_type'],
        doc['account_id'],
        doc['facility_id']
      );

      if (
        change.deleted ||
        (doc['doc_namespace'] === DocTypeConstants.NAMESPACES.CONTENT &&
          doc['doc_type'] === DocTypeConstants.TYPES.CONTENT.CONTENT_ITEM &&
          doc['archived'])
      ) {
        delete CacheService.docCacheById[doc['_id']];

        if (existingCacheKey) {
          CacheService.idCacheByRequestKey[
            existingCacheKey
          ] = CacheService.idCacheByRequestKey[existingCacheKey].filter(
            id => id !== doc['_id']
          );
        }

        return;
      }

      // Update the document cache
      CacheService.docCacheById[doc['_id']] = doc;

      // Add the updated document to the ID cache one already exists, otherwise ignore it
      if (existingCacheKey) {
        CacheService.idCacheByRequestKey[existingCacheKey] = _.uniq(
          CacheService.idCacheByRequestKey[existingCacheKey].concat(doc['_id'])
        );
      }
    });
  }

  updateSingleDoc(doc: any) {
    this.updateChangedDocs([{ id: doc['_id'], doc, seq: 0 }]);
  }

  // Allow cache to be cleared when user signs out
  clearCache() {
    CacheService.idCacheByRequestKey = {};
    CacheService.docCacheById = {};
  }

  private buildCacheRequestKey(
    namespace: string,
    type?: string,
    accountId?: string,
    facilityId?: string
  ): string {
    return [namespace, type, accountId, facilityId]
      .filter(val => !!val)
      .join(CACHE_KEY_DELIMITER);
  }

  private lowestLevelCacheKey(
    namespace: string,
    type?: string,
    accountId?: string,
    facilityId?: string
  ): string {
    if (!namespace) {
      return null;
    }

    if (
      CacheService.idCacheByRequestKey[namespace] &&
      CacheService.idCacheByRequestKey[namespace].length
    ) {
      return namespace;
    }

    const typeRequestKey = this.buildCacheRequestKey(namespace, type);
    if (
      CacheService.idCacheByRequestKey[typeRequestKey] &&
      CacheService.idCacheByRequestKey[typeRequestKey].length
    ) {
      return typeRequestKey;
    }

    const accountRequestKey = this.buildCacheRequestKey(
      namespace,
      type,
      accountId
    );
    if (
      CacheService.idCacheByRequestKey[accountRequestKey] &&
      CacheService.idCacheByRequestKey[accountRequestKey].length
    ) {
      return accountRequestKey;
    }

    const facilityRequestKey = this.buildCacheRequestKey(
      namespace,
      type,
      accountId,
      facilityId
    );
    if (
      CacheService.idCacheByRequestKey[facilityRequestKey] &&
      CacheService.idCacheByRequestKey[facilityRequestKey].length
    ) {
      return facilityRequestKey;
    }

    return null;
  }
}
