import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { User } from '../../model/user/user';
import { AuthApiService } from '../auth-api/auth-api.service';
import { PortalAPIService } from '../portal-api/portal-api.service';
import { UserService } from '../user/user.service';

@Injectable()
export class InviteService {
  redirectURL: string;

  constructor(
    private authAPIService: AuthApiService,
    private portalAPIService: PortalAPIService,
    private userService: UserService
  ) {}

  createInvite(
    docType: string,
    user_profile_id: string,
    email: string,
    firstName: string
  ) {
    return this.authAPIService.sendInvite({
      doc_type: docType,
      email: email,
      first_name: firstName,
      id: user_profile_id,
    });
  }

  deleteInvite(user: User): Observable<boolean> {
    return this.authAPIService.deleteInvite(user).pipe(
      mergeMap((isSuccess: boolean) => {
        if (!isSuccess) {
          return of(false);
        }

        return this.userService.deleteUser(user);
      })
    );
  }
}
