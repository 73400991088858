<div class="item user-block" *ngIf="userBlockIsVisible()" routerLink="/profile">
  <!-- User picture-->
  <div class="user-block-picture">
    <div class="user-block-status">
      <img
        class="img-thumbnail img-circle"
        [appAttachmentSrc]="profileImageSrc"
        alt="Avatar"
      />
      <div class="circle circle-success circle-lg"></div>
    </div>
  </div>
  <!-- Name and Job-->
  <div class="user-block-info">
    <span class="user-block-name">
      Welcome {{ firstName }}
      {{ lastName }}
    </span>
    <span class="user-block-role">{{ accountTypeName }}</span>
  </div>
</div>
