import { SyncGatewayInterfaces } from './sync-gateway.interfaces';

export class SyncGatewayModel
  implements SyncGatewayInterfaces.ISyncGatewayModel {
  _id?: string;
  _deleted?: boolean;
  _rev?: string;
  _attachments?: { [key: string]: SyncGatewayInterfaces.IAttachment };
  created_by?: string;
  created_date?: string;
  doc_namespace?: string;
  doc_type?: string;
  modified_by?: string;
  modified_date?: string;

  // Apollo Data Import fields
  // Required temporarily until all Apollo data has been successfully imported
  apollo_id?: number;
  apollo_import?: boolean;

  constructor(
    _id?: string,
    _rev?: string,
    _attachments?: { [key: string]: SyncGatewayInterfaces.IAttachment },
    created_by?: string,
    created_date?: string,
    doc_namespace?: string,
    doc_type?: string,
    modified_by?: string,
    modified_date?: string,
    apollo_id?: number,
    apollo_import?: boolean
  ) {
    this._id = _id;
    this._rev = _rev;
    this._attachments = _attachments;
    this.created_by = created_by || '';
    this.created_date = created_date || '';
    this.doc_namespace = doc_namespace;
    this.doc_type = doc_type;
    this.modified_by = modified_by || '';
    this.modified_date = modified_date || '';
    this.apollo_id = apollo_id;
    this.apollo_import = apollo_import;
  }

  hasAttachmentWithName(name: string): boolean {
    return (
      this._attachments &&
      Object.keys(this._attachments).some(
        key => key === name || key === `blob_/${name}`
      )
    );
  }

  getAttachmentFullName(name: string): string {
    if (this._attachments) {
      if (Object.keys(this._attachments).includes(name)) {
        return name;
      }

      if (Object.keys(this._attachments).includes(`blob_/${name}`)) {
        // Double-encoding the forward slash since Sync GW doesn't interpret it correctly with single-encoding
        return encodeURIComponent(encodeURIComponent(`blob_/${name}`));
      }
    }

    return name;
  }

  attachmentImagePath(name: string): string {
    const attachmentName = encodeURIComponent(this.getAttachmentFullName(name));
    return `${this._id}/${attachmentName}${
      this._rev ? '?rev=' + this._rev : ''
    }`;
  }

  removeAttachmentWithName(name: string) {
    if (this.hasAttachmentWithName(name)) {
      delete this._attachments[this.getAttachmentFullName(name)];
    }
  }

  tileImageKey(): string {
    return 'tile_image';
  }

  hasTileImage(): boolean {
    return this.hasAttachmentWithName(this.tileImageKey());
  }

  tileImagePath(): string {
    if (this.hasTileImage()) {
      return this.attachmentImagePath(this.tileImageKey());
    }

    return null;
  }

  removeTileImage() {
    this.removeAttachmentWithName(this.tileImageKey());
  }

  profileImageKey(): string {
    return 'profile_image';
  }

  hasProfileImage(): boolean {
    return this.hasAttachmentWithName(this.profileImageKey());
  }

  profileImagePath(): string {
    if (this.hasProfileImage()) {
      const path = this.attachmentImagePath(this.profileImageKey());
      return path;
    }

    return '/assets/img/user/generic.png';
  }

  removeProfileImage() {
    this.removeAttachmentWithName(this.profileImageKey());
  }
}
