import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { IInviteRequest } from '../../model/auth-api/inviteRequest';
import { User } from '../../model/user/user';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { MicroserviceApiBaseService } from '../micro-api/microservice-api-base.service';
import { StateManager } from '../state/state-manager';
import { ROUTES } from './auth-api.constants';

@Injectable()
export class AuthApiService extends MicroserviceApiBaseService {
  protected readonly authApiUrl: string;

  constructor(
    http: HttpClient,
    jwtService: JwtService,
    stateManager: StateManager,
    private cacheService: CacheService
  ) {
    super(http, jwtService, stateManager);
    this.authApiUrl = environment.authApi.url;
  }

  protected createDoc<T>(
    doc: T,
    route: string,
    action?: string,
    parameters?: Record<string, string>
  ): Observable<T> {
    return this.post<T>(
      this.determineAuthDocPath(route, action),
      doc,
      parameters
    );
  }

  protected determineAuthDocPath(route: string, action?: string) {
    return action
      ? `${this.authApiUrl}/auth/${route}/${action}`
      : `${this.authApiUrl}/auth/${route}`;
  }

  protected postEmptyDoc<T>(route: string, path: string) {
    return this.post<T>(`${this.authApiUrl}/auth/${route}/${path}`, {});
  }

  resetPassword<T>(userEmail: string) {
    return this.postWithoutToken<T>(
      this.determineAuthDocPath(
        `${ROUTES.USER.PATH}/${userEmail}`,
        ROUTES.USER.ACTIONS.RESET_PASSWORD
      ),
      { source: 'portal' }
    );
  }

  updatePasswordWithResetToken<T>(password: string, resetToken: string) {
    return this.http.put<T>(
      this.generateUrl(
        this.determineAuthDocPath(
          ROUTES.USER.PATH,
          ROUTES.USER.ACTIONS.PASSWORD
        ),
        { source: 'portal' }
      ),
      { new_password: password },
      {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + resetToken,
        }),
        observe: 'response',
      }
    );
  }

  updatePassword<T>(oldPassword: string, newPassword: string) {
    return this.jwtService.getToken().pipe(
      mergeMap(token =>
        this.http.put<T>(
          this.generateUrl(
            this.determineAuthDocPath(
              ROUTES.USER.PATH,
              ROUTES.USER.ACTIONS.PASSWORD
            ),
            { source: 'portal' }
          ),
          { current_password: oldPassword, new_password: newPassword },
          {
            headers: new HttpHeaders({
              Authorization: 'Bearer ' + token,
            }),
            observe: 'response',
          }
        )
      )
    );
  }

  sendInvite(inviteData: IInviteRequest): Observable<any> {
    return this.jwtService.getToken().pipe(
      mergeMap(token =>
        this.http.post(
          this.generateUrl(
            this.determineAuthDocPath(
              ROUTES.PORTALUSER.PATH,
              ROUTES.PORTALUSER.ACTIONS.INVITE
            )
          ),
          { ...inviteData },
          {
            headers: new HttpHeaders({
              Authorization: 'Bearer ' + token,
            }),
            observe: 'response',
          }
        )
      )
    );
  }

  deleteInvite(user: User): Observable<any> {
    return this.jwtService.getToken().pipe(
      mergeMap(token =>
        this.http.delete(
          this.generateUrlPathFromParams(
            this.determineAuthDocPath(
              ROUTES.PORTALUSER.PATH,
              ROUTES.PORTALUSER.ACTIONS.INVITE
            ),
            [user._id || '']
          ),
          {
            headers: new HttpHeaders({
              Authorization: 'Bearer ' + token,
            }),
            observe: 'response',
          }
        )
      )
    );
  }
}
