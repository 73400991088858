import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable()
export class UnderMaintenanceService {
  /**
   * If we need to put up the maintenance page
   * Deploy Portal using the tag named "maintenance-page-only"
   * which will force it to show
   */
  siteIsUnderMaintenance(): Observable<boolean> {
    return of(false);
  }
}
