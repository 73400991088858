import { DocTypeConstants } from '../../constants/doc-types';
import { SyncGatewayModel } from '../sync-gateway/sync-gateway-model';
import { ResidentContactList } from './resident-contact-list';
import { ResidentInterfaces } from './resident.interfaces';

export const RESIDENT_PROFILE_IMAGE_FILENAME = 'profile_image';
export const RESIDENT_CONTACT_IMAGE_FILENAME_PREFIX = 'contact_image_';

export const RESIDENT_STATUS_ACTIVE = 'active';
export const RESIDENT_STATUS_INACTIVE = 'inactive';

export class Resident extends SyncGatewayModel {
  account_id: string;
  birthdate: string;
  bookmarks: { [key: string]: string };
  customer_resident_id: string;
  lifeloop_resident_id: string;
  eventing: { [eventingTaskName: string]: string };
  facility_id: string;
  family: ResidentContactList;
  helios_email: string;
  interests: string[];
  allow_family_interest_modification: boolean;
  first_name: string;
  last_name: string;
  move_in_date: string;
  move_out_date: string;
  pin: string;
  room_number: string;
  resident_matches: ResidentInterfaces.IResidentMatch[];
  nickname: string;
  status: string;
  recommended_search_terms = '';

  constructor(data: any = {}) {
    super(
      data._id,
      data._rev,
      data._attachments,
      data.created_by,
      data.created_date,
      DocTypeConstants.NAMESPACES.RESIDENT,
      DocTypeConstants.TYPES.RESIDENT.RESIDENT,
      data.modified_by,
      data.modified_date,
      data.apollo_id,
      data.apollo_import
    );

    this.account_id = data.account_id || '';
    this.birthdate = data.birthdate;
    this.bookmarks = !!data.bookmarks ? data.bookmarks : {};
    this.customer_resident_id = data.customer_resident_id;
    this.lifeloop_resident_id = data.lifeloop_resident_id || '';
    this.eventing = data.eventing || {};
    this.facility_id = data.facility_id;
    this.family =
      data && data.family && data.family.members
        ? new ResidentContactList(data.family.members)
        : new ResidentContactList();
    this.helios_email = data.helios_email;
    this.interests = !!data.interests ? data.interests : [];
    // Default to true if null or undefined; only set to false if allow_family_interest_modification has been explicitly set as false
    this.allow_family_interest_modification =
      data.allow_family_interest_modification === false ? false : true;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.move_in_date = data.move_in_date || '';
    this.move_out_date = data.move_out_date || '';
    this.nickname = data.nickname || '';
    this.pin = data.pin;
    this.room_number = data.room_number;
    this.resident_matches = data.resident_matches || [];
    this.recommended_search_terms = data.recommended_search_terms;
    this.status = data.status;
  }

  getContactImageName(phone?: string): string {
    const strippedPhone = !!phone ? phone.replace(/[^0-9]/g, '') : '';
    return RESIDENT_CONTACT_IMAGE_FILENAME_PREFIX + strippedPhone;
  }

  hasContactImage(phone: string): boolean {
    return this.hasAttachmentWithName(this.getContactImageName(phone));
  }

  isActive() {
    // Residents exist in that database without a status property set. We want to consider
    // these residents as 'active' residents.
    return this.status === RESIDENT_STATUS_ACTIVE || this.status === '';
  }
}
