<div *ngIf="this.dataIsLoaded">
  <div class="panel bb0">
    <form
      [formGroup]="form"
      class="form-validate"
      role="form"
      name="linkToForm"
      novalidate=""
      (submit)="saveLinkTo()"
    >
      <div class="panel-body bb bt">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <button
                *ngIf="editMode"
                class="btn btn-danger btn-outline pull-right"
                type="button"
                (click)="showDeleteModal()"
                [disabled]="deleteDisabled(linkTo._id)"
              >
                Delete
              </button>
              <label class="control-label">Link-To Type</label>
              <div class="radio c-radio">
                <label>
                  <input
                    type="radio"
                    formControlName="is_play_all"
                    value="false"
                    (click)="removePlayAllTitle()"
                  />
                  <span class="fa fa-circle"></span>
                  Content Item
                </label>
              </div>
              <div class="radio c-radio">
                <label>
                  <input
                    type="radio"
                    formControlName="is_play_all"
                    value="true"
                    (click)="setPlayAllTitle()"
                  />
                  <span class="fa fa-circle"></span>
                  Play All
                </label>
              </div>
            </div>
            <div
              id="p-autocomplete-form-group"
              class="form-group"
              *ngIf="contentItemRequired()"
            >
              <label class="control-label">Linked Content Item</label>
              <div style="width: 100%">
                <p-autoComplete
                  [(ngModel)]="selectedContentItemText"
                  [ngModelOptions]="{ standalone: true }"
                  (completeMethod)="filterAvailableContentItems($event)"
                  [suggestions]="filteredContentItems"
                  (onSelect)="selectContentItem($event)"
                  [multiple]="true"
                  field="text"
                  delay="1000"
                  [dropdown]="true"
                  [style]="{ width: '100%' }"
                  [inputStyle]="{ width: '100%' }"
                  class="p-autocomplete"
                  name="contentItemDropdown"
                  placeholder="Type content item library folder and/or title to search"
                >
                  <ng-template let-contentItem pTemplate="item">
                    <div>
                      <div>{{ contentItem.text }}</div>
                    </div>
                  </ng-template>
                </p-autoComplete>

                <span
                  class="text-danger"
                  *ngIf="contentItemRequired() && !contentId"
                  >This field is required</span
                >
              </div>
            </div>

            <div class="form-group" *ngIf="contentItem">
              <label class="control-label">Content Item Title:</label>
              {{ contentItem.title }}
            </div>

            <div class="form-group" *ngIf="contentItem">
              <label class="control-label">Content Item Tile Image</label>
              <div>
                <img [appAttachmentSrc]="contentItemTileImageSrc" />
              </div>
            </div>

            <!-- Override Content Tile Image -->
            <div class="form-group">
              <label class="control-label">Override Content Tile Image</label>
              <app-image-crop-control
                #cropControl
                canvasWidth="200"
                canvasHeight="150"
                width="200"
                height="150"
                croppedWidth="300"
                croppedHeight="200"
                label="Image"
                [keepAspect]="false"
                [existingImageSrc]="overrideTileImageSrc"
                (onRemoveImage)="removeOverrideTileImage()"
              >
              </app-image-crop-control>
            </div>

            <!-- Override Title -->
            <div class="form-group">
              <label class="control-label">Override Title</label>
              <input
                class="form-control"
                placeholder="Override Title"
                type="text"
                formControlName="override_title"
                tabindex="0"
                aria-invalid="true"
              />
              <span class="text-danger" *ngIf="overrideTitleRequired()"
                >This field is required for "Play All" Link-Tos</span
              >
            </div>

            <!-- Packages -->
            <div
              id="p-dropwdown-package-container"
              class="form-group"
              style="width: 100%"
            >
              <label class="control-label">Packages</label>
              <p-autoComplete
                [(ngModel)]="selectedPackageItems"
                [ngModelOptions]="{ standalone: true }"
                (completeMethod)="filterAvailablePackages($event)"
                [suggestions]="filteredPackages"
                (onSelect)="selectPackage($event)"
                (onUnselect)="removePackage($event)"
                [multiple]="true"
                field="text"
                formControlName="packages"
                [dropdown]="true"
                [style]="{ width: '100%' }"
                [inputStyle]="{ width: '100%' }"
                class="p-autocomplete"
                name="packageItemDropdown"
                placeholder="Type package name or abbreviation to search"
              >
                <ng-template let-package pTemplate="item">
                  <div>
                    <div>{{ package.text }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
              <div>
                <span
                  class="text-danger"
                  *ngIf="activePackageSelections.length == 0"
                  >Please select at least one package before saving</span
                >
              </div>
            </div>

            <!-- Content Status -->
            <div class="form-group">
              <label class="control-label">Content Status</label>
              <div class="radio c-radio">
                <label>
                  <input
                    type="radio"
                    formControlName="is_approved"
                    value="true"
                  />
                  <span class="fa fa-circle"></span>
                  Approved
                </label>
              </div>
              <div class="radio c-radio">
                <label>
                  <input
                    type="radio"
                    formControlName="is_approved"
                    value="false"
                  />
                  <span class="fa fa-circle"></span>
                  In Review
                </label>
              </div>
            </div>

            <!-- Active Dates -->
            <div class="form-group">
              <label class="control-label">Active Date Ranges</label>

              <div class="well bg-gray-lighter">
                <div
                  class="row"
                  *ngFor="
                    let range of form.controls.active_dates.controls;
                    let i = index
                  "
                >
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label">Active Start Date</label>

                      <input
                        bsDatepicker
                        placement="bottom left"
                        [bsConfig]="{ containerClass: 'theme-blue' }"
                        [compareToEndControl]="range.controls.end_date"
                        class="form-control"
                        placeholder="Active Start Date (MM/DD/YYYY)"
                        type="text"
                        [formControl]="range.controls.start_date"
                      />

                      <span
                        class="text-danger"
                        *ngIf="hasDateError(range.controls.start_date)"
                        >Invalid date</span
                      >
                      <span
                        class="text-danger"
                        *ngIf="range.controls.start_date.hasError('endDate')"
                        >Date must be before the end date</span
                      >
                      <span
                        class="text-danger"
                        *ngIf="required(range.controls.start_date)"
                        >This field is required</span
                      >
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label">Active End Date</label>
                      <input
                        bsDatepicker
                        placement="bottom left"
                        [bsConfig]="{ containerClass: 'theme-blue' }"
                        [compareToStartControl]="range.controls.start_date"
                        class="form-control"
                        placeholder="Active End Date (MM/DD/YYYY)"
                        type="text"
                        [formControl]="range.controls.end_date"
                      />
                      <span
                        class="text-danger"
                        *ngIf="range.controls.end_date.hasError('date')"
                        >Invalid date</span
                      >
                      <span
                        class="text-danger"
                        *ngIf="range.controls.end_date.hasError('startDate')"
                        >Date must be after the start date</span
                      >
                      <span
                        class="text-danger"
                        *ngIf="required(range.controls.end_date)"
                        >This field is required</span
                      >
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <label class="control-label">&nbsp;</label>
                    <div>
                      <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        (click)="removeDateRange(i)"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  class="btn btn-primary btn-xs"
                  (click)="addDateRange()"
                >
                  Add Date Range
                </button>
              </div>
            </div>

            <!-- iN2L Suggests with Dates -->
            <div class="form-group">
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    [checked]="isIn2lSuggests"
                    (change)="isIn2lSuggests = !isIn2lSuggests"
                  />
                  <em class="bg-indigo-500"></em> iN2L Suggests
                </label>
              </div>
            </div>

            <div class="form-group" *ngIf="isIn2lSuggests">
              <div class="well bg-gray-lighter">
                <div
                  *ngFor="
                    let range of form.controls.in2l_suggests_dates.controls;
                    let i = index
                  "
                >
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="control-label">Start Date</label>
                        <input
                          bsDatepicker
                          placement="bottom left"
                          [bsConfig]="{ containerClass: 'theme-blue' }"
                          [compareToEndControl]="range.controls.end_date"
                          class="form-control"
                          placeholder="Start Date (MM/DD/YYYY)"
                          type="text"
                          [formControl]="range.controls.start_date"
                        />
                        <span
                          class="text-danger"
                          *ngIf="hasDateError(range.controls.start_date)"
                          >Invalid date</span
                        >
                        <span
                          class="text-danger"
                          *ngIf="range.controls.start_date.hasError('endDate')"
                          >Date must be before the end date</span
                        >
                        <span
                          class="text-danger"
                          *ngIf="required(range.controls.start_date)"
                          >This field is required</span
                        >
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="control-label">End Date</label>
                        <input
                          bsDatepicker
                          placement="bottom left"
                          [bsConfig]="{ containerClass: 'theme-blue' }"
                          [compareToStartControl]="range.controls.start_date"
                          class="form-control"
                          placeholder="End Date (MM/DD/YYYY)"
                          type="text"
                          [formControl]="range.controls.end_date"
                        />
                        <span
                          class="text-danger"
                          *ngIf="hasDateError(range.controls.end_date)"
                          >Invalid date</span
                        >
                        <span
                          class="text-danger"
                          *ngIf="range.controls.end_date.hasError('startDate')"
                          >Date must be after the start date</span
                        >
                        <span
                          class="text-danger"
                          *ngIf="required(range.controls.end_date)"
                          >This field is required</span
                        >
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <label class="control-label">&nbsp;</label>
                      <div>
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          (click)="removeIn2lSuggestsDateRange(i)"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      invalidSubDates(
                        range.controls.start_date,
                        range.controls.end_date
                      )
                    "
                  >
                    <div class="col-sm-12 text-danger mb-lg">
                      Date range must be within already existing active date
                      range.
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  class="btn btn-primary btn-xs"
                  (click)="addIn2lSuggestsDateRange()"
                >
                  Add Date Range
                </button>
              </div>
            </div>

            <!-- Recently Added with Dates -->
            <div class="form-group">
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    [checked]="isRecentlyAdded"
                    (change)="isRecentlyAdded = !isRecentlyAdded"
                  />
                  <em class="bg-indigo-500"></em> Recently Added
                </label>
              </div>
            </div>

            <div class="form-group" *ngIf="isRecentlyAdded">
              <div class="well bg-gray-lighter">
                <div
                  *ngFor="
                    let range of form.controls.recently_added_dates.controls;
                    let i = index
                  "
                >
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="control-label">Start Date</label>
                        <input
                          bsDatepicker
                          placement="bottom left"
                          [bsConfig]="{ containerClass: 'theme-blue' }"
                          [compareToEndControl]="range.controls.end_date"
                          class="form-control"
                          placeholder="Start Date (MM/DD/YYYY)"
                          type="text"
                          [formControl]="range.controls.start_date"
                        />
                        <span
                          class="text-danger"
                          *ngIf="hasDateError(range.controls.start_date)"
                          >Invalid date</span
                        >
                        <span
                          class="text-danger"
                          *ngIf="range.controls.start_date.hasError('endDate')"
                          >Date must be before the end date</span
                        >
                        <span
                          class="text-danger"
                          *ngIf="required(range.controls.start_date)"
                          >This field is required</span
                        >
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="control-label">End Date</label>
                        <input
                          bsDatepicker
                          placement="bottom left"
                          [bsConfig]="{ containerClass: 'theme-blue' }"
                          [compareToStartControl]="range.controls.start_date"
                          class="form-control"
                          placeholder="End Date (MM/DD/YYYY)"
                          type="text"
                          [formControl]="range.controls.end_date"
                        />
                        <span
                          class="text-danger"
                          *ngIf="hasDateError(range.controls.end_date)"
                          >Invalid date</span
                        >
                        <span
                          class="text-danger"
                          *ngIf="range.controls.end_date.hasError('startDate')"
                          >Date must be after the start date</span
                        >
                        <span
                          class="text-danger"
                          *ngIf="required(range.controls.end_date)"
                          >This field is required</span
                        >
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <label class="control-label">&nbsp;</label>
                      <div>
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          (click)="removeRecentlyAddedDateRange(i)"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      invalidSubDates(
                        range.controls.start_date,
                        range.controls.end_date
                      )
                    "
                  >
                    <div class="col-sm-12 text-danger mb-lg">
                      Date range must be within already existing active date
                      range.
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  class="btn btn-primary btn-xs"
                  (click)="addRecentlyAddedDateRange()"
                >
                  Add Date Range
                </button>
              </div>
            </div>
            <div
              *ngIf="showIsWelcomeVideoCheckbox()"
              data-toggle="tooltip"
              data-placement="left"
              [title]="setWelcomeVideoTitle()"
              class="form-group"
            >
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    [checked]="form.get('is_welcome_video').value"
                    (change)="
                      form
                        .get('is_welcome_video')
                        .setValue(!form.get('is_welcome_video').value)
                    "
                    [disabled]="
                      isAnotherVideoSetAsWelcomeVideo('is_welcome_video')
                    "
                  />
                  <em class="bg-indigo-500"></em> Set as Welcome Video
                </label>
              </div>
            </div>

            <div
              *ngIf="showSingleMultiWelcomeCheckboxes()"
              data-toggle="tooltip"
              data-placement="left"
              [title]="onHoverVideoTitle('is_single_user_welcome_video')"
              class="form-group"
            >
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    [checked]="
                      form.controls['is_single_user_welcome_video'].value
                    "
                    (change)="
                      toggleUserWelcomeVideoCheckBoxValues(
                        'is_single_user_welcome_video'
                      )
                    "
                    [disabled]="
                      isAnotherVideoSetAsWelcomeVideo(
                        'is_single_user_welcome_video'
                      )
                    "
                  />
                  <em class="bg-indigo-500"></em> Set as Single User Welcome
                  Video
                </label>
              </div>
            </div>
            <div
              *ngIf="showSingleMultiWelcomeCheckboxes()"
              data-toggle="tooltip"
              data-placement="left"
              [title]="onHoverVideoTitle('is_multi_user_welcome_video')"
              class="form-group"
            >
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    [checked]="
                      form.controls['is_multi_user_welcome_video'].value
                    "
                    (change)="
                      toggleUserWelcomeVideoCheckBoxValues(
                        'is_multi_user_welcome_video'
                      )
                    "
                    [disabled]="
                      isAnotherVideoSetAsWelcomeVideo(
                        'is_multi_user_welcome_video'
                      )
                    "
                  />
                  <em class="bg-indigo-500"></em> Set as Community Welcome Video
                </label>
              </div>
            </div>

            <div class="form-group">
              <label class="control-label">Notes</label>
              <textarea
                class="form-control"
                placeholder="Notes"
                formControlName="notes"
                rows="4"
                aria-multiline="true"
                tabindex="0"
                aria-invalid="false"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="panel-body bg-gray-lighter">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <button
              class="btn btn-labeled btn-info"
              type="submit"
              [disabled]="isSubmitDisabled()"
            >
              <span class="btn-label"><i class="fa fa-save"></i></span>
              Save Link-To
            </button>
          </div>
          <div class="col-sm-12 col-md-4 mt-xl" *ngIf="errorMessage">
            <p class="text-danger">{{ errorMessage }}</p>
          </div>
          <div class="col-sm-12 col-md-4 pull-right">
            <button
              (click)="cancel()"
              class="btn btn-danger pull-right"
              type="button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="row">
  <div class="col-xs-12">
    <app-document-changed-by [document]="linkTo"></app-document-changed-by>
  </div>
</div>

<!-- Delete LinkTo Modal -->
<div
  style="margin-top: 250px"
  class="modal fade"
  bsModal
  #deleteLinkToModal="bs-modal"
  [config]="{
    animated: true,
    keyboard: false,
    backdrop: false,
    ignoreBackdropClick: false
  }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteLinkToModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          Delete LinkTo {{ linkTo ? linkTo.override_title : '' }}
        </h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="closeDeleteModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mt-lg">
          <div class="col-sm-12">
            <button
              type="button"
              class="pull-left btn"
              aria-label="Cancel"
              (click)="closeDeleteModal()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="pull-right btn btn-primary"
              aria-label="Delete"
              (click)="deleteLinkTo()"
              [disabled]="deleteSubmitted"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end modal -->
