import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import {
  IActionMapping,
  ITreeState,
  TREE_ACTIONS,
  TreeModel,
  TreeNode,
} from '@circlon/angular-tree-component';

import { ContentConstants } from '../../../../constants/content.constants';
import { DocTypeConstants } from '../../../../constants/doc-types';
import { ContentLayoutInterfaces } from '../../../../model/content/content-layout.interfaces';
import { ContentModels } from '../../../../model/content/content.models';
import { LayoutCopyModal } from '../layout-copy-modal/layout-copy-modal.component';

@Component({
  selector: 'app-content-layout-tree',
  styleUrls: ['./layout-tree.component.scss'],
  templateUrl: './layout-tree.component.html',
})
export class LayoutTreeComponent implements OnChanges {
  @ViewChild('layoutCopyModal')
  copyModal: LayoutCopyModal;

  @Input()
  layoutRoots: ContentModels.LayoutRoot[];

  @Input()
  itemMap: { [id: string]: any }; // map of all containers, link-tos, content-items, and packages

  @Input()
  selectedRoot: ContentModels.LayoutRoot = null;

  @Input()
  expandedNodeIds: { [id: string]: boolean } = {};

  @Input()
  changesInProgress = false;

  @Output()
  changeEvent: EventEmitter<ContentLayoutInterfaces.ILayoutChangeEvent> = new EventEmitter<ContentLayoutInterfaces.ILayoutChangeEvent>();

  // Tree Properties
  nodes: ContentLayoutInterfaces.ILayoutNode[];
  treeState: ITreeState;
  internalChangesInProgress = false;
  actionMapping: IActionMapping = {
    mouse: {
      click: (tree, node, $event) => {
        this.nodeClick(node.data);
      },
      drop: (
        tree: TreeModel,
        destination: TreeNode,
        $event: any,
        ref: { from: TreeNode; to: { parent: TreeNode; index: number } }
      ) => {
        const draggedNode = ref.from; // The item being moved - container or link-to
        const fromParent = draggedNode.parent; // Parent container where the moving node currently lives - may be the root
        const toParent = destination; // The container the moving item is going to - may be the root
        const moveToIndex = ref.to.index; // The index location amongst the toParent's children

        this.moveNode(draggedNode, fromParent, toParent, moveToIndex)
          // use TREE_ACTIONS.MOVE_NODE to invoke the original action
          .then((adjustedIndex: number) => {
            ref.to.index = adjustedIndex;
            return TREE_ACTIONS.MOVE_NODE(tree, destination, $event, ref);
          })
          .then(() => {
            this.updateNodeStatus(fromParent.data);
            this.updateNodeStatus(toParent.data);
          });
      },
    },
  };

  options = {
    idField: 'id',
    childrenField: 'children',
    actionMapping: this.actionMapping,
    allowDrag: true,
    allowDrop: (element, to) => {
      // return true / false based on element, to.parent, to.index. e.g.
      return to.parent.hasChildren;
    },
  };

  changeDetails: ContentLayoutInterfaces.ILayoutChangeEvent = {};

  containerCount = 0;
  linkToCount = 0;

  constructor() {}

  ngOnChanges() {
    if (this.itemMap) {
      this.containerCount = Object.keys(this.itemMap || {}).filter(
        id =>
          this.itemMap[id].doc_type ===
            DocTypeConstants.TYPES.CONTENT.CONTAINER &&
          this.itemMap[id] &&
          this.selectedRoot &&
          this.itemMap[id].layout_root_id === this.selectedRoot.layout_root_id
      ).length;

      this.linkToCount = Object.keys(this.itemMap || {}).filter(id => {
        let isInCurrentRoot = false;
        if (this.itemMap[this.itemMap[id].parent_id]) {
          isInCurrentRoot =
            this.itemMap[this.itemMap[id].parent_id] &&
            this.selectedRoot &&
            this.itemMap[this.itemMap[id].parent_id].layout_root_id ===
              this.selectedRoot.layout_root_id;
        }

        return (
          this.itemMap[id].doc_type ===
            DocTypeConstants.TYPES.CONTENT.LINK_TO && isInCurrentRoot
        );
      }).length;

      this.refreshLayoutNodes();
    }
  }

  getCopyCompleteObservable(): Observable<
    ContentLayoutInterfaces.IContainerCopyResult | undefined
  > {
    return this.copyModal.copyCompleteSubject;
  }

  startCopy() {
    if (this.selectedRoot) {
      this.copyModal.show();
    }
  }

  getNodeStatusCssClass(status: string) {
    if (status === ContentConstants.CONTENT_QA) {
      return 'btn btn-xs btn-danger status-icon';
    } else if (status === ContentConstants.CONTENT_APPROVED) {
      return 'btn btn-xs btn-primary status-icon';
    } else if (status === ContentConstants.CONTENT_ACTIVE) {
      return 'btn btn-xs btn-success status-icon';
    } else if (status === ContentConstants.CONTENT_INACTIVE) {
      return 'btn btn-xs btn-default status-icon';
    } else if (status === ContentConstants.CONTENT_CANCELED) {
      return 'btn btn-xs btn-inverse status-icon';
    }

    // Invalid item status
    return 'btn btn-xs btn-warning status-icon';
  }

  getContainerCount(): number {
    return this.containerCount;
  }

  getLinkToCount(): number {
    return this.linkToCount;
  }

  isLastChild(node: TreeNode): boolean {
    return (
      node.parent.children[node.parent.children.length - 1].id === node.data.id
    );
  }

  /**
   * Tree Creation
   **/

  refreshLayoutNodes() {
    if (!this.selectedRoot) {
      return;
    }
    const root = this.itemMap[this.selectedRoot._id];

    if (!root || !root.children) {
      return throwError(new Error('Content layout root is missing.'));
    }

    this.nodes = root.children
      .map(id => this.getLayoutNode(id, this.itemMap, {}))
      .filter(item => !!item);

    this.appendCreateContainerLinkToNodes(
      this.selectedRoot._id,
      this.nodes,
      true
    );

    if (this.treeState) {
      this.treeState = Object.assign(this.treeState, {
        expandedNodeIds: this.expandedNodeIds,
      });
    } else {
      this.treeState = { expandedNodeIds: this.expandedNodeIds };
    }
  }

  getLayoutNode(
    id: string,
    itemMap: {
      [id: string]: any;
    },
    expandedMap: { [id: string]: boolean } = {}
  ): ContentLayoutInterfaces.ILayoutNode {
    if (!itemMap[id]) {
      return null;
    }

    const isLayoutRoot =
      itemMap[id].doc_type === DocTypeConstants.TYPES.CONTENT.LAYOUT_ROOT;
    const isContainer =
      itemMap[id].doc_type === DocTypeConstants.TYPES.CONTENT.CONTAINER;
    const isLinkTo =
      itemMap[id].doc_type === DocTypeConstants.TYPES.CONTENT.LINK_TO;

    const node = {
      id: id,
      children: [],
      isAddContainer: false,
      isAddLinkTo: false,
      isBold: false,
      isFolder: isContainer,
      isExpanded: !!expandedMap[id],
      isLayoutRoot,
      isContainer,
      isLinkTo,
      name: '',
      parentId: '',
      packageAbbreviations: [],
      packageAbbreviationsText: '',
      status: null,
    };

    if (isContainer) {
      const item = <ContentModels.Container>itemMap[id];

      // Create child nodes
      node.children = (item.children || [])
        .map(itemId => this.getLayoutNode(itemId, itemMap, expandedMap))
        .filter(currentItem => !!currentItem);

      this.appendCreateContainerLinkToNodes(node.id, node.children, false);

      node.packageAbbreviations = _.uniq(
        [].concat(
          ...(node.children || []).map(kid => kid.packageAbbreviations || [])
        )
      )
        .filter(p => !!p)
        .sort();

      node.packageAbbreviationsText = node.packageAbbreviations.length
        ? `(${node.packageAbbreviations.join(', ')})`
        : '';

      node.name = item.title;

      this.updateNodeStatus(node);

      return node;
    }

    if (isLinkTo) {
      const item = <ContentModels.LinkTo>itemMap[id];
      const contentItem = <ContentModels.ContentItem>itemMap[item.content_id];

      node.packageAbbreviations = (item.packages || [])
        .map(packageId => itemMap[packageId])
        .filter(p => !!p)
        .map(
          contentPackage =>
            (<ContentModels.ContentPackage>contentPackage).abbreviation
        )
        .sort();

      node.packageAbbreviationsText = node.packageAbbreviations.length
        ? ` (${node.packageAbbreviations.join(', ')})`
        : '';

      node.name =
        item.override_title ||
        _.get(contentItem, 'title', '*** CONTENT ITEM IS MISSING ***');

      // Set the Link To node status
      // Leave status as null if link-to points to a missing content item
      if (!contentItem) {
        return node;
      }

      const isQA = contentItem.content_status === ContentConstants.CONTENT_QA;
      if (isQA) {
        node.status = ContentConstants.CONTENT_QA;
        return node;
      }

      const contentItemIsCancelled =
        contentItem.license_expiration_date &&
        moment
          .utc(contentItem.license_expiration_date, 'MM/DD/YYYY')
          .isBefore(moment.utc());

      if (contentItemIsCancelled) {
        node.status = ContentConstants.CONTENT_CANCELED;
        return node;
      }

      const isActive = (item.active_dates || []).some(
        ad =>
          moment(ad.start_date, 'YYYY-MM-DD').utc().isBefore(moment().utc()) &&
          moment(ad.end_date, 'YYYY-MM-DD')
            .utc()
            .add(1, 'day')
            .isAfter(moment().utc())
      );

      if (isActive) {
        node.status = ContentConstants.CONTENT_ACTIVE;
        return node;
      }

      const hasBeenActive = (item.active_dates || []).some(ad =>
        moment(ad.end_date, 'YYYY-MM-DD')
          .utc()
          .add(1, 'day')
          .isBefore(moment().utc())
      );

      if (hasBeenActive) {
        node.status = ContentConstants.CONTENT_INACTIVE;
        return node;
      }

      node.status = ContentConstants.CONTENT_APPROVED;
      return node;
    }

    return node;
  }

  updateNodeStatus(node: ContentLayoutInterfaces.ILayoutNode) {
    // Set container status based on child statuses
    if (
      node.children.some(kid => kid.status === ContentConstants.CONTENT_ACTIVE)
    ) {
      node.status = ContentConstants.CONTENT_ACTIVE;
    } else if (
      node.children.some(
        kid => kid.status === ContentConstants.CONTENT_APPROVED
      )
    ) {
      node.status = ContentConstants.CONTENT_APPROVED;
    } else if (
      node.children.some(kid => kid.status === ContentConstants.CONTENT_QA)
    ) {
      node.status = ContentConstants.CONTENT_QA;
    } else if (
      node.children.some(
        kid => kid.status === ContentConstants.CONTENT_INACTIVE
      )
    ) {
      node.status = ContentConstants.CONTENT_INACTIVE;
    } else if (
      node.children.some(
        kid => kid.status === ContentConstants.CONTENT_CANCELED
      )
    ) {
      node.status = ContentConstants.CONTENT_CANCELED;
    } else {
      node.status = ContentConstants.CONTENT_INACTIVE;
    }
  }

  appendCreateContainerLinkToNodes(
    parentId: string,
    children: ContentLayoutInterfaces.ILayoutNode[],
    isRoot: boolean
  ) {
    // Add container and link to links
    children.push({
      id: `${parentId}_add-container`,
      isAddContainer: true,
      isAddLinkTo: false,
      isBold: true,
      isContainer: false,
      isLinkTo: false,
      isFolder: false,
      isExpanded: false,
      name: '+ Add Container',
      parentId,
      status: 'none',
      packageAbbreviations: [],
      packageAbbreviationsText: '',
    });

    if (!isRoot) {
      children.push({
        id: `${parentId}_add-link-to`,
        isAddContainer: false,
        isAddLinkTo: true,
        isBold: true,
        isContainer: false,
        isLinkTo: false,
        isFolder: false,
        isExpanded: false,
        name: '+ Add Link-To',
        parentId,
        status: 'none',
        packageAbbreviations: [],
        packageAbbreviationsText: '',
      });
    }
  }

  /**
   * Tree Events
   */
  nodeClick(data: ContentLayoutInterfaces.ILayoutNode) {
    if (data.isContainer) {
      this.changeDetails = {
        editContainer: {
          containerId: data.id,
        },
      };
    } else if (data.isLinkTo) {
      this.changeDetails = {
        editLinkTo: {
          linkToId: data.id,
        },
      };
    } else if (data.isAddContainer) {
      this.changeDetails = {
        addContainer: {
          parentId: data.parentId,
        },
      };
    } else if (data.isAddLinkTo) {
      this.changeDetails = {
        addLinkTo: {
          parentId: data.parentId,
        },
      };
    }

    this.changeDetails.layoutState = {
      expandedNodeIds: _.get(this, 'treeState.expandedNodeIds', {}),
    };

    this.emitAndClearChange();
  }

  moveNode(node: TreeNode, from: TreeNode, to: TreeNode, moveToIndex: number) {
    return new Promise((resolve, reject) => {
      if (this.internalChangesInProgress) {
        this.changeDetails = {
          error: {
            message:
              'Cannot move items while an existing move operation is in process!',
          },
        };

        this.emitAndClearChange();

        return reject();
      }

      const itemId = node.data.id;
      const fromParentId =
        from.level !== 0 ? from.data.id : this.selectedRoot._id;
      const toParentId = to.level !== 0 ? to.data.id : this.selectedRoot._id;

      if (itemId === toParentId) {
        this.changeDetails = {
          error: {
            message: 'Cannot move item into itself!',
          },
        };

        this.emitAndClearChange();

        return reject();
      }

      // ceil the index if it's greater than position (we have create nodes that aren't data items)
      if (to.children) {
        const maxRealChildren = to.children.length - 2;
        moveToIndex =
          moveToIndex >= maxRealChildren ? maxRealChildren : moveToIndex;
      }

      // we can't do multiple move operations at the same time
      this.internalChangesInProgress = true;

      // perform action
      this.changeDetails = {
        moveItem: {
          itemId,
          fromParentId,
          toParentId,
          moveToIndex,
          moveResult: (isSuccessful: boolean) => {
            this.internalChangesInProgress = false;
            if (isSuccessful) {
              resolve(moveToIndex);
            } else {
              reject();
            }
          },
        },
        layoutState: {
          expandedNodeIds: _.get(this, 'treeState.expandedNodeIds', {}),
        },
      };

      this.emitAndClearChange();
    });
  }

  /**
   * Component Events
   */
  emitAndClearChange() {
    this.emitChanges();
    this.clearChanges();
  }

  emitChanges() {
    console.log(this.changeDetails);
    this.changeEvent.emit(this.changeDetails);
  }

  clearChanges() {
    this.changeDetails = {};
  }
}
