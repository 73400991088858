import { Contact } from '../account-contact/contact';

// --- define facility billing profile model ---
export interface IFacilityBillingProfile {
  id?: string;
  contact?: Contact;
  non_profit?: boolean;
  status?: 'active' | 'inactive';
  tax_exempt?: boolean;
}

export class FacilityBillingProfile {
  id: string;
  contact: Contact;
  non_profit: boolean;
  status: 'active' | 'inactive';
  tax_exempt: boolean;

  constructor(data: IFacilityBillingProfile = {}) {
    this.id = data.id || '';
    this.contact = new Contact(data.contact);
    this.non_profit = !!data.non_profit;
    this.status = data.status || 'active';
    this.tax_exempt = !!data.tax_exempt;
  }
}
