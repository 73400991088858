import { AngularTokenService } from 'angular-token';

import { NgModule, Optional, SkipSelf } from '@angular/core';

import { AccountService } from './account/account.service';
import { AuthApiService } from './auth-api/auth-api.service';
import { AuthenticationService } from './authentication/authentication.service';
import { JwtService } from './authentication/jwt.service';
import { AwsService } from './aws/aws.service';
import { BreadcrumbService } from './breadcrumb/breadcrumb.service';
import { CacheService } from './cache/cache.service';
import { DeviceTypesService } from './configuration/device-types.service';
import { FeatureFlagService } from './configuration/feature-flag.service';
import { InterestsService } from './configuration/interests.service';
import { PricingTierService } from './configuration/pricing-tier.service';
import { ContentVendorService } from './content-api/content-vendor.service';
import { ContentLayoutService } from './content-layout/content-layout.service';
import { ContentLibraryDbService } from './content-library/content-library-db.service';
import { ContentLibraryService } from './content-library/content-library.service';
import { ContentPackageService } from './content-packages/content-package.service';
import { DashboardApiService } from './dashboard-api/dashboard-api.service';
import { DashboardService } from './dashboard/dashboard.service';
import { DatabaseApiService } from './database-api/database-api.service';
import { DeviceService } from './device/device.service';
import { OldDeviceService } from './device/old-device.service';
import { FacilityService } from './facility/facility.service';
import { FamilyPortalUserAuthService } from './family-portal-user/family-portal-user-auth.service';
import { FamilyPortalUserDbService } from './family-portal-user/family-portal-user-db.service';
import { FileUtilityService } from './file/file-utility.service';
import { AccountGuard } from './guard/account-guard';
import { AuthGuard } from './guard/auth-guard';
import { RoleGuard } from './guard/role-guard';
import { InputService } from './input/input.service';
import { InviteService } from './invite/invite.service';
import { MicroserviceApiBaseService } from './micro-api/microservice-api-base.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { PortalAPIService } from './portal-api/portal-api.service';
import { PortalCompositeApiService } from './portal-composite-api/portal-composite-api.service';
import { RegionsService } from './regions/regions.service';
import { ResidentFactoryService } from './resident/resident-factory.service';
import { ResidentFormService } from './resident/resident-form.service';
import { ResidentStatusService } from './resident/resident-status.service';
import { ResidentService } from './resident/resident.service';
import { RoleService } from './role/role.service';
import { SettingsService } from './settings/settings.service';
import { AuthStateService } from './state/auth-state.service';
import { SidebarStateService } from './state/sidebar-state.service';
import { StateManager } from './state/state-manager';
import { StateSubscriptionManager } from './state/state-subscription-manager';
import { UrlStateService } from './state/url-state.service';
import { TextMessageService } from './text-message/text-message.service';
import { ThemesService } from './themes/themes.service';
import { UiEventService } from './ui-event-service/ui-event-service';
import { UnderMaintenanceService } from './under-maintenance/under-maintenance.service';
import { ProgressService } from './upload/progress.service';
import { UploadService } from './upload/upload.service';
import { UserService } from './user/user.service';

@NgModule({
  imports: [],
  providers: [
    AccountGuard,
    AccountService,
    AngularTokenService,
    AuthenticationService,
    JwtService,
    AuthGuard,
    AwsService,
    BreadcrumbService,
    CacheService,
    ContentLayoutService,
    ContentLibraryService,
    ContentLibraryDbService,
    ContentPackageService,
    ContentVendorService,
    DashboardService,
    DashboardApiService,
    DatabaseApiService,
    AuthApiService,
    DeviceService,
    OldDeviceService,
    FacilityService,
    FileUtilityService,
    InviteService,
    InputService,
    InterestsService,
    MicroserviceApiBaseService,
    PortalAPIService,
    PortalCompositeApiService,
    PricingTierService,
    ProgressService,
    RegionsService,
    ResidentService,
    FeatureFlagService,
    FamilyPortalUserAuthService,
    FamilyPortalUserDbService,
    ResidentStatusService,
    ResidentFactoryService,
    ResidentFormService,
    RoleGuard,
    RoleService,
    SettingsService,
    TextMessageService,
    ThemesService,
    UiEventService,
    UnderMaintenanceService,
    UserService,
    DeviceTypesService,
    UploadService,

    // State Managers
    StateManager,
    StateSubscriptionManager,
    AuthStateService,
    SidebarStateService,
    UrlStateService,
  ],
  declarations: [],
  exports: [],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
