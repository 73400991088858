import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  AfterViewInit,
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
} from '@angular/core';
import { Router } from '@angular/router';

import { StateManager } from '../../../core/state/state-manager';
import { SidebarAccountsGroupComponent } from '../sidebar-accounts-group/sidebar-accounts-group.component';
import { SidebarLinkComponent } from '../sidebar-link/sidebar-link.component';

declare var $: any;

const ACCOUNTS_COMMUNITIES_HEADER = 'Accounts & Communities';
const COMMUNITIES_HEADER = 'Communities';
const CONTENT_HEADER = 'Content';

@Component({
  selector: 'app-sidebar-group',
  templateUrl: './sidebar-group.component.html',
  styleUrls: ['./sidebar-group.component.scss'],
})
export class SidebarGroupComponent implements AfterViewInit, OnInit {
  @Input()
  name = '';
  @Input()
  main = false;
  @Input()
  alwaysShow = false;
  @Input()
  defaultRoute = ''; // expected sidebar-link text as string to match against

  @ContentChildren(SidebarLinkComponent)
  childLinks: QueryList<SidebarLinkComponent>;
  @ContentChildren(SidebarGroupComponent)
  childGroups: QueryList<SidebarGroupComponent>;
  @ContentChildren(SidebarAccountsGroupComponent)
  childAccountGroups: QueryList<SidebarLinkComponent>;

  open = false;
  showHeader = false;
  childDisplayInitialized = false;

  viewInitCompleted = new BehaviorSubject<boolean>(false);

  constructor(private router: Router, private stateManager: StateManager) {}

  ngOnInit() {
    this.updateHeaderDisplay();

    // Monitor for user authentication AND ngAfterViewInit completed
    // Update sidebar header display
    combineLatest([
      this.viewInitCompleted,
      this.stateManager.getAuthStateSubject(),
    ])
      .pipe(
        filter(([readyForViewUpdates, authState]) => {
          return readyForViewUpdates && authState.userSignedIn;
        })
      )
      .subscribe(([readyForViewUpdates, authState]) => {
        setTimeout(() => {
          this.updateHeaderDisplay();
        });
      });

    // Monitor selection changes
    // TODO: Can this be reduced to only using one BehaviorSubject?
    // combineLatest([
    //   this.stateManager.getSidebarAccountIdSelectionSubject(),
    //   this.stateManager.getSidebarFacilityIdSelectionSubject()
    // ])
    this.stateManager
      .getAuthStateSubject()
      .pipe(filter(authState => authState.userSignedIn))
      .subscribe(authState => {
        // Get the communities subsection to the default state if user is logged out
        if (!authState.userSignedIn && this.open) {
          this.toggleVisibility();
          return;
        }

        const accountId = authState.urlIds.accountId;
        const facilityId = authState.urlIds.facilityId;

        // Open the communities subsection if the accountId is set
        const accountIdAndFacilityIdSet = !!accountId && !!facilityId;
        if (
          this.name === COMMUNITIES_HEADER &&
          this.open !== accountIdAndFacilityIdSet
        ) {
          this.toggleVisibility();
        }
      });
  }

  ngAfterViewInit() {
    this.viewInitCompleted.next(true);
  }

  updateHeaderDisplay() {
    const currentUser = this.stateManager.getCurrentUser();
    if (!currentUser) {
      this.showHeader = false;
      return;
    }

    const userDocType = currentUser.doc_type;
    if (this.stateManager.userIsIn2lAdmin(userDocType)) {
      this.showHeader = true;
    } else if (this.stateManager.userIsIn2lContent(userDocType)) {
      this.showHeader = this.name === CONTENT_HEADER;
    } else if (
      this.name === ACCOUNTS_COMMUNITIES_HEADER ||
      this.name === COMMUNITIES_HEADER
    ) {
      this.showHeader = true;
    } else {
      // only concerned with checking sidebar-groups that are main (top-level) groups
      if (this.main) {
        // create list of active (visible) SidebarLinkComponents
        const activeChildLinks = (this.childLinks || []).filter(
          childComponent => {
            if (childComponent instanceof SidebarLinkComponent) {
              return childComponent.visible;
            } else {
              return false;
            }
          }
        );

        // for main sidebar groups, hide header if there are no child SidebarLinkComponents visible
        // or its not the accounts header (which is visible for all users)
        if (
          !(this.childAccountGroups || []).length &&
          !activeChildLinks.length
        ) {
          this.showHeader = false;
        }
      } else {
        this.showHeader = this.hasActiveChildrenComponents();
      }
    }
  }

  toggleVisibility() {
    this.open = !this.open;

    if (this.open && this.defaultRoute) {
      const link = this.childLinks.find(
        childLink => childLink.href === this.defaultRoute
      );

      if (link && link.visible) {
        const accountId = this.stateManager.getSidebarAccountIdSelection();
        const facilityId = this.stateManager.getSidebarFacilityIdSelection();
        const url = this.defaultRoute
          .replace(':account_id', accountId)
          .replace(':facility_id', facilityId);
        this.router.navigateByUrl(url);
      }
    }
  }

  /**
   * return boolean based on if this sidebar group has active child elements
   * currently checks sidebar-groups and sidebar-link components (recursively)
   */
  hasActiveChildrenComponents(): boolean {
    const activeGroups = (this.childGroups || []).filter(group => {
      // include groups that are set to always show, do not check children
      if (group.alwaysShow) {
        return true;
      }

      // note: QueryLists return self in list, need to detect in childGroup filter
      //   to avoid infinite loop, do not include as active child
      // @see: https://github.com/angular/angular/issues/10098
      if (group === this) {
        return false;
      }

      return group.hasActiveChildrenComponents();
    });

    const activeLinks = (this.childLinks || []).filter(link => link.visible);

    return activeGroups.length + activeLinks.length > 0;
  }
}
