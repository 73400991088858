<div class="panel panel-default outer-panel">
  <div class="panel-body">
    <div></div>
    <div class="panel-body">
      <form
        [formGroup]="form"
        class="form-validate"
        novalidate=""
        (submit)="submit(form.value)"
      >
        <div class="panel bb0">
          <div class="panel-body bb bt">
            <div class="row">
              <div class="col-sm-12 col-md-9">
                <div class="flex-row">
                  <div class="form-group col-12">
                    <label class="control-label">Tile Image</label>
                    <app-image-crop-control
                      #cropControl
                      canvasWidth="200"
                      canvasHeight="150"
                      width="200"
                      height="150"
                      croppedWidth="300"
                      croppedHeight="200"
                      label="Image"
                      [keepAspect]="false"
                      [existingImageSrc]="tileImagePath"
                      (onRemoveImage)="removeTileImage()"
                    >
                    </app-image-crop-control>
                  </div>
                  <div
                    *ngIf="contentItem._id"
                    class="form-group col-4 flex-column"
                  >
                    <div>
                      <label class="control-label">Hosting Status: </label>
                      <span
                        [ngClass]="{
                          'text-green': contentItem.hosting_status == 'Ready',
                          'text-yellow-dark':
                            contentItem.hosting_status == 'Pending',
                          'text-danger': contentItem.hosting_status == 'Error'
                        }"
                      >
                        {{ contentItem.hosting_status }}
                      </span>
                    </div>
                    <button
                      *ngIf="contentItem.hosting_status != 'N/A'"
                      class="btn-purple"
                      type="button"
                      (click)="upload()"
                    >
                      Force Hosting Upload
                    </button>
                    <button
                      *ngIf="contentItem.hosting_status === 'Ready'"
                      class="btn-warning"
                      type="button"
                      (click)="validate()"
                    >
                      Validate Upload
                    </button>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Title</label>
                  <input
                    class="form-control"
                    placeholder="Title"
                    type="text"
                    formControlName="title"
                    tabindex="0"
                    aria-invalid="true"
                  />
                  <span
                    class="text-danger"
                    *ngIf="form.controls.title.hasError('required')"
                    >This field is required</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="hasInvalidCharacter(form.controls.title.value)"
                    >Content titles cannot contain
                    {{ invalidCharactersDescription() }}.</span
                  >
                </div>

                <div class="form-group">
                  <label class="control-label">Platforms</label>
                  <div class="row ml-xl">
                    <div class="checkbox mt-sm mb-sm">
                      <label>
                        <input
                          type="checkbox"
                          [formControl]="form.controls.platforms.get('android')"
                        />
                        <em class="bg-indigo-500"></em> Android
                      </label>
                    </div>
                  </div>
                  <div class="row ml-xl">
                    <div class="checkbox mt-sm mb-sm">
                      <label>
                        <input
                          type="checkbox"
                          [formControl]="form.controls.platforms.get('pc')"
                        />
                        <em class="bg-indigo-500"></em> PC
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-4"></div>
                </div>

                <div class="form-group">
                  <label class="control-label">Products</label>
                  <div class="row ml-xl">
                    <div class="checkbox mt-sm mb-sm">
                      <label>
                        <input
                          type="checkbox"
                          [formControl]="form.controls.products.get('engage')"
                        />
                        <em class="bg-indigo-500"></em> ENGAGE
                      </label>
                    </div>
                  </div>
                  <div class="row ml-xl">
                    <div class="checkbox mt-sm mb-sm">
                      <label>
                        <input
                          type="checkbox"
                          [formControl]="form.controls.products.get('focus')"
                        />
                        <em class="bg-indigo-500"></em> FOCUS
                      </label>
                    </div>
                  </div>
                  <div class="row ml-xl">
                    <div class="checkbox mt-sm mb-sm">
                      <label>
                        <input
                          type="checkbox"
                          [formControl]="form.controls.products.get('rehab')"
                        />
                        <em class="bg-indigo-500"></em> REHAB
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group mb-lg">
                  <label class="control-label"
                    >Enabled LifeLoop Platforms</label
                  >
                  <div
                    class="row ml-xl"
                    *ngFor="let item of getEnabledPlatformOptions()"
                  >
                    <div
                      class="checkbox mt-sm mb-sm"
                      [ngClass]="{ disabled: item.isDisabled }"
                      (click)="toggleEnabledPlatforms(item.value)"
                    >
                      <label>
                        <input
                          type="checkbox"
                          [disabled]="item.isDisabled"
                          [checked]="item.isChecked"
                        />
                        <em class="bg-indigo-500"></em> {{ item.label }}
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Content Type</label>
                  <select
                    class="form-control"
                    formControlName="content_type"
                    (change)="onContentTypeChange()"
                  >
                    <option
                      *ngFor="let item of contentTypeDropdownList"
                      [value]="item.value"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                  <span
                    class="text-danger"
                    *ngIf="form.controls.content_type.hasError('required')"
                    >This field is required</span
                  >
                </div>

                <div class="form-group">
                  <label class="control-label">Display Type</label>
                  <select class="form-control" formControlName="display_type">
                    <option
                      *ngFor="let item of displayTypeDropdownList"
                      [value]="item.value"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                  <span
                    class="text-danger"
                    *ngIf="form.controls.display_type.hasError('required')"
                    >This field is required</span
                  >
                </div>
                <div
                  id="exclude_from_accounts"
                  class="form-group"
                  *ngIf="isIn2lAdmin()"
                >
                  <label class="control-label">Exclude from Accounts</label>
                  <div class="select-container" style="width: 100%">
                    <p-autoComplete
                      [(ngModel)]="selectedAccountsList"
                      [ngModelOptions]="{ standalone: true }"
                      (completeMethod)="filterAvailableAccounts($event)"
                      [suggestions]="filteredAccounts"
                      (onSelect)="handleAddAccount($event)"
                      (onUnselect)="handleRemoveAccount($event)"
                      [multiple]="true"
                      field="text"
                      [dropdown]="true"
                      [style]="{ width: '100%' }"
                      [inputStyle]="{ width: '100%' }"
                      class="p-autocomplete"
                      name="accountSelectDropdown"
                      placeholder="No accounts selected"
                    >
                      <ng-template let-account pTemplate="item">
                        <div>
                          <div>{{ account.text }}</div>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                  </div>
                </div>

                <div class="form-group">
                  <input
                    type="checkbox"
                    [formControl]="form.controls.us_only"
                  />
                  <label class="align-middle ml-sm">US Only</label>
                </div>

                <div class="form-group" *ngIf="fileUploadRequired()">
                  <p class="lead bb">Content File</p>

                  <label class="control-label">File</label>

                  <div
                    *ngIf="
                      contentItem.s3_key &&
                      contentItem.s3_key !== '' &&
                      !replaceFile
                    "
                  >
                    <button
                      (click)="replaceFile = true"
                      type="button"
                      class="btn btn-primary file-name-spacer"
                    >
                      Replace File
                    </button>
                    {{ contentItem.s3_key }}
                  </div>
                  <div
                    *ngIf="
                      !contentItem.s3_key ||
                      contentItem.s3_key === '' ||
                      replaceFile
                    "
                  >
                    <input type="file" (change)="fileChangeListener($event)" />
                    <span
                      *ngIf="!isFileUploadAttemptSuccessful()"
                      class="text-danger"
                      >A file is required for this content type</span
                    >

                    <div
                      class="text-danger"
                      *ngIf="fileValidationMessage.length > 0"
                    >
                      {{ fileValidationMessage }}
                    </div>
                  </div>
                </div>

                <div
                  class="form-group"
                  *ngIf="showVendor(form.controls.content_type.value)"
                >
                  <label class="control-label"
                    >Content Integration Vendor</label
                  >
                  <select class="form-control" formControlName="content_vendor">
                    <option
                      *ngFor="let item of vendorDropdownList | async"
                      [value]="item.value"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                  <span
                    class="text-danger"
                    *ngIf="form.controls.content_vendor.hasError('required')"
                    >This field is required</span
                  >
                </div>

                <div
                  class="form-group"
                  *ngIf="showContentUrl(form.controls.content_type.value)"
                >
                  <label class="control-label">Content URL</label>
                  <input
                    class="form-control"
                    placeholder="e.g.: https://www.wikipedia.com"
                    type="text"
                    formControlName="content_url"
                    tabindex="0"
                    aria-invalid="true"
                  />
                  <span
                    class="text-danger"
                    *ngIf="form.controls.content_url.hasError('url')"
                    >Invalid url</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="form.controls.content_url.hasError('required')"
                    >URL required</span
                  >
                </div>

                <div
                  class="form-group"
                  *ngIf="
                    showNewSongRequestLink(
                      form.controls.content_type.value,
                      form.controls.display_type.value
                    )
                  "
                >
                  <label class="control-label"
                    >New Song Request URL (for Coro)</label
                  >
                  <input
                    class="form-control"
                    placeholder="e.g.: https://api.corohealth.com/V2/Programs/544239833/MediaRequests"
                    type="text"
                    formControlName="new_song_request_link"
                    tabindex="0"
                    aria-invalid="true"
                  />
                  <span
                    class="text-danger"
                    *ngIf="form.controls.new_song_request_link.hasError('url')"
                    >Invalid url</span
                  >
                </div>

                <div
                  class="form-group"
                  *ngIf="showPreviewUrl(form.controls.content_type.value)"
                >
                  <label class="control-label">Preview: </label>
                  <span>{{ previewUrl | async }}</span>
                </div>

                <div
                  class="form-group"
                  *ngIf="
                    showWindowsExeFilename(form.controls.content_type.value)
                  "
                >
                  <label class="control-label">Windows EXE Filename</label>
                  <span
                    class="text-danger"
                    *ngIf="
                      form.controls.windows_exe_filename.hasError('required')
                    "
                    >This field is required</span
                  >
                  <input
                    class="form-control"
                    placeholder="Filename of the exe contained in the zip file (e.g.: HappyNeuron.exe)"
                    type="text"
                    formControlName="windows_exe_filename"
                    tabindex="0"
                    aria-invalid="true"
                  />
                </div>

                <div
                  class="form-group"
                  *ngIf="
                    showWindowsExeLocations(form.controls.content_type.value)
                  "
                >
                  <label class="control-label">Windows EXE Locations</label>
                  <span
                    class="text-danger"
                    *ngIf="
                      form.controls.windows_exe_locations.hasError('required')
                    "
                    >This field is required</span
                  >
                  <textarea
                    class="form-control"
                    formControlName="windows_exe_locations"
                    rows="10"
                    aria-multiline="true"
                    tabindex="0"
                    aria-invalid="false"
                  ></textarea>
                </div>

                <div
                  class="form-group"
                  *ngIf="showYouTubePlaylist(form.controls.content_type.value)"
                >
                  <label class="control-label">YouTube Video IDs</label>
                  <span
                    class="text-danger"
                    *ngIf="form.controls.youtube_video_ids.hasError('required')"
                    >This field is required</span
                  >
                  <textarea
                    class="form-control"
                    formControlName="youtube_video_ids"
                    rows="10"
                    aria-multiline="true"
                    tabindex="0"
                    aria-invalid="false"
                  ></textarea>
                </div>

                <div
                  class="form-group"
                  *ngIf="form.controls.content_type.value === 'video'"
                >
                  <label class="control-label">Video Info</label>
                  <app-rich-text-editor
                    formControlName="video_description"
                  ></app-rich-text-editor>
                </div>

                <div
                  class="row"
                  formGroupName="happy_neuron"
                  *ngIf="form.controls.content_type.value === 'happyneuron'"
                >
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">Happy Neuron Game ID</label>
                      <input
                        class="form-control"
                        placeholder="Game ID"
                        type="text"
                        formControlName="game_id"
                        tabindex="0"
                        aria-invalid="true"
                      />
                      <span
                        class="text-danger"
                        *ngIf="
                          form.controls.happy_neuron.controls.game_id.hasError(
                            'required'
                          )
                        "
                        >Game ID required</span
                      >
                      <span
                        class="text-danger"
                        *ngIf="
                          form.controls.happy_neuron.controls.game_id.hasError(
                            'digits'
                          )
                        "
                        >Game ID must be an integer</span
                      >
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div
                      class="form-group"
                      *ngIf="form.controls.content_type.value === 'happyneuron'"
                    >
                      <label class="control-label">Happy Neuron Language</label>
                      <select class="form-control" formControlName="lang">
                        <option
                          *ngFor="let lang of happyNeuronLangOptions"
                          [value]="lang.value"
                        >
                          {{ lang.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="form-group mb-xl">
                  <label class="control-label">Search Keyword(s)</label>
                  <!--  TODO ngx-chips -->
                  <tag-input
                    formControlName="keywords"
                    placeholder="+ word or phrase"
                    secondaryPlaceholder="Enter keywords here"
                    [errorMessages]="keywordErrorMessageMap"
                    [modelAsStrings]="true"
                    [validators]="keywordValidators"
                    [addOnBlur]="true"
                    [addOnPaste]="true"
                  >
                  </tag-input>
                </div>

                <div class="form-group">
                  <label class="control-label"
                    >Restrict Content to Pricing Tiers</label
                  >
                  <div class="select-container" style="width: 100%">
                    <p-autoComplete
                      [(ngModel)]="selectedPricingTiers"
                      [ngModelOptions]="{ standalone: true }"
                      (completeMethod)="filterPricingTiers($event)"
                      [suggestions]="suggestedPricingTiers"
                      [multiple]="true"
                      [dropdown]="true"
                      [style]="{ width: '100%' }"
                      [inputStyle]="{ width: '100%' }"
                      [forceSelection]="true"
                      class="p-autocomplete"
                      name="pricingTierSelectDropdown"
                      placeholder="No pricing tiers selected"
                    >
                      <ng-template let-pricingTier pTemplate="item">
                        <div>
                          <div>{{ pricingTier }}</div>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                  </div>
                </div>

                <p class="lead bb">Usage Settings</p>

                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            [formControl]="
                              form.controls.usage_settings.get('solo_use')
                            "
                          />
                          <em class="bg-indigo-500"></em> Solo Use
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            [formControl]="
                              form.controls.usage_settings.get('joint_use')
                            "
                          />
                          <em class="bg-indigo-500"></em> Joint Use
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            [formControl]="
                              form.controls.usage_settings.get('group_use')
                            "
                          />
                          <em class="bg-indigo-500"></em> Group Use
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <p class="lead bb">Accessibility Considerations</p>

                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            [formControl]="
                              form.controls.accessibility.get(
                                'hearing_impairment'
                              )
                            "
                          />
                          <em class="bg-indigo-500"></em> Hearing Impairment
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            [formControl]="
                              form.controls.accessibility.get(
                                'physical_impairment'
                              )
                            "
                          />
                          <em class="bg-indigo-500"></em> Physical Impairment
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            [formControl]="
                              form.controls.accessibility.get(
                                'vision_impairment'
                              )
                            "
                          />
                          <em class="bg-indigo-500"></em> Vision Impairment
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="control-label">Source</label>
                  <input
                    class="form-control"
                    placeholder="Source"
                    type="text"
                    formControlName="source"
                    tabindex="0"
                    aria-invalid="true"
                  />
                </div>

                <div class="form-group">
                  <label class="control-label">License Expiration Date</label>
                  <input
                    bsDatepicker
                    #licenseDatePicker="bsDatepicker"
                    placement="bottom left"
                    [bsConfig]="{ containerClass: 'theme-blue' }"
                    class="form-control"
                    placeholder="License Expiration Date (MM/DD/YYYY)"
                    type="text"
                    formControlName="license_expiration_date"
                  />
                  <span
                    class="text-danger"
                    *ngIf="
                      form.get('license_expiration_date').value
                        ? !isValidDate(
                            form.get('license_expiration_date').value,
                            'MM/DD/YYYY'
                          )
                        : false
                    "
                    >Invalid date</span
                  >
                </div>

                <div class="form-group">
                  <label class="control-label">Notes</label>
                  <textarea
                    class="form-control"
                    placeholder="Notes"
                    formControlName="notes"
                    rows="4"
                    aria-multiline="true"
                    tabindex="0"
                    aria-invalid="false"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="row bg-gray-lighter mt-lg pt-lg pb-lg">
                <div class="col-sm-12 col-md-9">
                  <button
                    class="btn btn-labeled btn-info"
                    type="submit"
                    [disabled]="submitDisabled() || submitButtonClicked()"
                  >
                    <span class="btn-label"><i class="fa fa-save"></i></span>
                    Save
                  </button>
                  <button class="btn btn-labeled pull-right" (click)="cancel()">
                    <span class="btn-label"><i class="fas fa-times"></i></span>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div *ngIf="editMode" class="row">
  <div class="col-xs-12">
    <app-document-changed-by [document]="contentItem"></app-document-changed-by>
  </div>
</div>
