import { filter, map } from 'rxjs/operators';

import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { RoleService } from '../../core/role/role.service';
import { SettingsService } from '../../core/settings/settings.service';
import { StateManager } from '../../core/state/state-manager';
import { UiEventService } from '../../core/ui-event-service/ui-event-service';
import { SidebarSizeChangeMessage } from '../../core/ui-event-service/ui-sidebar-size-change';
import {
  ROLE_ADD_ACCOUNT,
  ROLE_ADD_DEVICE,
  ROLE_ADD_FACILITY,
  ROLE_ADD_RESIDENT,
  ROLE_ADMIN_MANAGE_INVITES,
  ROLE_ADMIN_USERS,
  ROLE_EDIT_CONTENT,
  ROLE_EDIT_REGIONS,
  ROLE_VIEW_ACCOUNT,
  ROLE_VIEW_CONTENT,
  ROLE_VIEW_DEVICE,
  ROLE_VIEW_DEVICE_STATUS,
  ROLE_VIEW_FACILITY,
  ROLE_VIEW_FACILITY_STAFF,
  ROLE_VIEW_RESIDENT,
} from '../../model/role/role';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  accountsDropdownDisabled = true;
  isDashboardEnabled = false;

  // This is used in the view
  roles = {
    ROLE_ADD_ACCOUNT,
    ROLE_ADD_DEVICE,
    ROLE_ADD_FACILITY,
    ROLE_ADD_RESIDENT,
    ROLE_ADMIN_MANAGE_INVITES,
    ROLE_ADMIN_USERS,
    ROLE_VIEW_ACCOUNT,
    ROLE_VIEW_CONTENT,
    ROLE_EDIT_CONTENT,
    ROLE_VIEW_DEVICE,
    ROLE_EDIT_REGIONS,
    ROLE_VIEW_DEVICE_STATUS,
    ROLE_VIEW_FACILITY,
    ROLE_VIEW_FACILITY_STAFF,
    ROLE_VIEW_RESIDENT,
  };

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected roleService: RoleService,
    public settings: SettingsService,
    public router: Router,
    protected stateManager: StateManager,
    protected uiEventService: UiEventService
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => <NavigationEnd>event)
      )
      .subscribe(event => {
        // close any submenu opened when route changes
        this.removeFloatingNav();
        // scroll view to top
        window.scrollTo(0, 0);
      });

    this.stateManager.getAuthStateSubject().subscribe(authState => {
      this.isDashboardEnabled = authState.dashboardsEnabled;
    });

    this.stateManager.getSidebarAccountListSubject().subscribe(
      accounts => {
        this.accountsDropdownDisabled = !accounts || !accounts.length;
      },
      e => {
        console.log(e);
      }
    );
  }

  isIn2lContentUser(): boolean {
    const authState = this.stateManager.getAuthState();
    return this.stateManager.userIsIn2lContent(authState.userDocType);
  }

  // Close menu collapsing height
  closeMenu(elem) {
    if (!elem.hasClass('disable-auto-close')) {
      elem.height(elem[0].scrollHeight); // set height
      elem.height(0); // and move to zero to collapse
      elem.removeClass('opening');
    }
  }

  listenForExternalClicks() {
    const $doc = $(document).on('click.sidebar', e => {
      if (!$(e.target).parents('.aside').length) {
        this.removeFloatingNav();
        $doc.off('click.sidebar');
      }
    });
  }

  removeFloatingNav() {
    $('.nav-floating').remove();
  }

  isSidebarCollapsed() {
    return this.settings.layout.isCollapsed;
  }

  toggleSidebar() {
    this.settings.setLayoutSetting(
      'isCollapsed',
      !this.settings.layout.isCollapsed
    );
    this.uiEventService.dispatch(
      new SidebarSizeChangeMessage(this.settings.layout.isCollapsed)
    );
  }
}
