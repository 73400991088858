import { ExportToCsv } from 'export-to-csv';
import * as _ from 'lodash';
import * as moment from 'moment';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { DocTypeConstants } from '../../../constants/doc-types';
import { UserConstants } from '../../../constants/user.constants';
import { StateManager } from '../../../core/state/state-manager';
import { UiEventService } from '../../../core/ui-event-service/ui-event-service';
import { ToasterMessage } from '../../../core/ui-event-service/ui-toaster-message';
import { UserService } from '../../../core/user/user.service';
import { Account } from '../../../model/account/account';
import { Facility } from '../../../model/facility/facility';
import { In2lUserType, User } from '../../../model/user/user';

interface IUserCsvExport {
  'First Name'?: string;
  'Last Name'?: string;
  Title?: string;
  Communities?: string;
  Email?: string;
  Username?: string;
  Phone?: string;
  'Text Phone'?: string;
  Role?: string;
  Status?: string;
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styles: [
    '.action-cell { width: 150px }',
    '.profile-img-cell { width: 60px; padding-right: 0 }',
    '.status-cell { width: 80px }',
    '.email-cell { width: 100px }',
    '.type-cell { width: 120px }',
  ],
})
export class UserListComponent implements OnChanges {
  @Input() users: User[];
  @Input() accounts: Account[] = [];
  @Input() facilities: Facility[] = [];
  @Input() showAccountColumn = false;
  @Input() showAccountStatusColumn = false;
  @Input() showEditButton = false;
  @Input() showFacilityColumn = false;
  @Input() showViewButton = false;
  @Input() showProfileImages = true;
  @Output() onEditUser: EventEmitter<User> = new EventEmitter<User>();
  @Output() onViewUser: EventEmitter<User> = new EventEmitter<User>();
  @Input() showEmailColumn = false;

  filteredUsers: User[] = [];
  search = '';
  userProfilePics: {
    [k: string]: string;
  } = {};

  constructor(
    private userService: UserService,
    private uiEventService: UiEventService,
    private stateManager: StateManager
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['users']) {
      this.filteredUsers = this.users.sort((a, b) => {
        // Added extra checks to search for null emails due to known bug
        a.last_name = a.last_name ? a.last_name : '';
        b.last_name = b.last_name ? b.last_name : '';
        return a.last_name.localeCompare(b.last_name);
      });
    }
  }

  filterUsers() {
    if (this.search.length) {
      const searchPhrase = this.search.toLowerCase();

      this.userService
        .searchUsersByPhrase(searchPhrase, ['active', 'inactive'])
        .subscribe(
          (users: In2lUserType[]) => {
            this.filteredUsers = users;
            for (const retUser of users) {
              if (!this.users.find(user => retUser._id === user._id)) {
                this.users.push(retUser);
              }
            }
          },
          error => {
            this.uiEventService.dispatch(
              new ToasterMessage({ body: error, type: 'error' })
            );
          }
        );
    } else {
      this.filteredUsers = this.users;
    }
  }

  getAccountName(user: User): string {
    if (!user.account_id) {
      return '';
    }

    const usersAccount = this.getAccountForUser(user);
    return usersAccount && usersAccount.profile
      ? usersAccount.profile.account_name
      : '';
  }

  getFacilityNames(user: User): string {
    if (!user.facility_ids || !user.facility_ids.length) {
      return '';
    }

    const usersAccount = this.getAccountForUser(user);
    if (!usersAccount) {
      return '';
    }

    return this.facilities
      .filter(f => (user.facility_ids || []).includes(f._id))
      .map(f => _.get(f, 'profile.name', ''))
      .join(', ');
  }

  getAccountForUser(user: User): Account {
    if (user.account_id) {
      const usersAccount = this.accounts.find(
        account => account._id === user.account_id
      );

      if (usersAccount) {
        return usersAccount;
      }
    }

    return null;
  }

  getEmailForUser(user: User): string {
    return user.email || '';
  }

  profileImageForUser(user: User, showProfileImages: boolean): any {
    if (this.userProfilePics[user._id]) {
      return this.userProfilePics[user._id];
    }
    const profileImageKey =
      user.media &&
      user.media[UserConstants.USER_PROFILE_IMAGE_KEY] &&
      user.media[UserConstants.USER_PROFILE_IMAGE_KEY].status === 'Completed'
        ? user.media[UserConstants.USER_PROFILE_IMAGE_KEY].s3_key
        : '';

    if (showProfileImages && profileImageKey) {
      this.userService
        .getUserProfileImage(profileImageKey, user.doc_namespace, user.doc_type)
        .subscribe(res => {
          this.userProfilePics[user._id] = res;
        });
    } else {
      this.userProfilePics[user._id] = '/assets/img/user/generic.png';
    }
  }

  userIsActive(user: User): boolean {
    return user.status === UserConstants.USER_STATUS_ACTIVE;
  }

  userIsInactive(user: User): boolean {
    return user.status === UserConstants.USER_STATUS_INACTIVE;
  }

  userIsInvited(user: User): boolean {
    return user.status === UserConstants.USER_STATUS_INVITED;
  }

  userTypeName(user: User): string {
    switch (user.doc_type) {
      case DocTypeConstants.TYPES.USER.IN2L_ADMIN:
        return 'iN2L Admin';
      case DocTypeConstants.TYPES.USER.IN2L_CONTENT:
        return 'iN2L User';
      case DocTypeConstants.TYPES.USER.ACCOUNT_ADMIN:
        return 'Account Admin';
      case DocTypeConstants.TYPES.USER.FACILITY_ADMIN:
        return 'Community Admin';
      case DocTypeConstants.TYPES.USER.FACILITY_USER:
        return 'Community User';
      default:
        return '';
    }
  }

  handleEditUserClick(e, user) {
    this.onEditUser.emit(user);
  }

  handleViewUserClick(e, user) {
    this.onViewUser.emit(user);
  }

  showUserExport(): boolean {
    return this.isIn2lAdmin() && this.users?.length > 0;
  }

  isIn2lAdmin(): boolean {
    const authState = this.stateManager.getAuthState();
    return this.stateManager.userIsIn2lAdmin(authState.userDocType);
  }

  buildUserExportObject(user: User): IUserCsvExport {
    return {
      'First Name': user.first_name,
      'Last Name': user.last_name,
      Title: user.title,
      Communities: this.getFacilityNames(user),
      Email: user.email,
      Username: user.email?.substring(0, user.email?.indexOf('@')),
      Phone: user.phone,
      'Text Phone': user.phone,
      Role: this.userTypeName(user),
      Status: user.status,
    };
  }

  exportCsv(): void {
    const csvOptions = {
      fieldSeparator: ',',
      filename: `staff_user_list_${moment()
        .format('YYYY-MM-DDTHH:mm')
        .split('T')
        .join(' ')}`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Staff User List Export',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvDataList: IUserCsvExport[] = [];

    this.users.forEach(user => {
      csvDataList.push(this.buildUserExportObject(user));
    });

    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(csvDataList);
  }
}
