import { UUID } from 'angular2-uuid';
import * as moment from 'moment';
import { CustomValidators } from 'ng2-validation';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ContentConstants } from '../../../../constants/content.constants';
import { FileUtilityService } from '../../../../core/file/file-utility.service';
import { InputService } from '../../../../core/input/input.service';
import { MediaApiService } from '../../../../core/media/media-api.service';
import { ContentLayoutInterfaces } from '../../../../model/content/content-layout.interfaces';
import { ContentInterfaces } from '../../../../model/content/content.interfaces';
import { ContentModels } from '../../../../model/content/content.models';
import { ImageCropControlComponent } from '../../../../shared/components/image-crop-control/image-crop-control.component';
import { DateUtils } from '../../../../util/date-utils';
import { AppValidator, BaseFormComponent } from '../../../../util/form-utils';

@Component({
  selector: 'app-layout-linkto-form',
  styleUrls: ['./layout-linkto-form.component.scss'],
  templateUrl: './layout-linkto-form.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LayoutLinkToFormComponent
  extends BaseFormComponent
  implements OnChanges {
  @ViewChild('cropControl')
  cropControl: ImageCropControlComponent;
  @ViewChild('staticModal', { static: true })
  public staticModal: ModalDirective;

  @Input()
  allLinkTos: ContentModels.LinkTo[];

  @Input()
  linkTo: ContentModels.LinkTo;

  @Input()
  contentItems: ContentModels.ContentItem[];

  @Input()
  contentPackages: ContentModels.ContentPackage[];

  @Output()
  changeEvent: EventEmitter<ContentLayoutInterfaces.ILayoutChangeEvent> = new EventEmitter<ContentLayoutInterfaces.ILayoutChangeEvent>();

  @ViewChild('deleteLinkToModal')
  deleteLinkToModal: ModalDirective;

  dataIsLoaded = false;
  dateMask: Array<string | RegExp> = InputService.DATE_MASK;
  editMode: boolean;
  formSubmitted = false;

  changeDetails: ContentLayoutInterfaces.ILayoutChangeEvent = {};

  errorMessage: string;
  form: FormGroup;

  contentId: string;
  contentItem: ContentModels.ContentItem;
  selectableContentItems: { id: string; text: string }[];
  filteredContentItems: { id: string; text: string }[];
  selectedItem: { id: string; text: string } = null;
  activeContentItemSelections: { id: string; text: string }[] = [];
  selectedContentItemText: { id: string; text: string }[] = [];

  selectablePackages: { id: string; text: string }[];
  activePackageSelections: { id: string; text: string }[] = [];
  filteredPackages: { id: string; text: string }[] = [];
  selectedPackageItems: { id: string; text: string }[] = [];

  overrideTileImageSrc = '';
  contentItemTileImageSrc = '';

  activeDates: ContentInterfaces.IActiveDateRange[];
  activeDateForms: FormArray;

  isIn2lSuggests = false;
  in2lSuggestsDateForms: FormArray;

  isWelcomeVideoSet = false;
  linkToSetAsWelcomeVideo: ContentModels.LinkTo[] = [];

  isRecentlyAdded = false;
  recentlyAddedDates: ContentInterfaces.IActiveDateRange[];
  recentlyAddedDateForms: FormArray;

  deleteSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private mediaApiService: MediaApiService
  ) {
    super();
  }

  ngOnChanges() {
    if (
      !this.linkTo ||
      !this.contentItems ||
      !this.contentPackages ||
      !this.allLinkTos
    ) {
      return;
    }

    this.configureUI();
  }

  configureUI(): void {
    this.setEditMode();

    this.setSelectableContentItems();

    this.setSelectablePackages();

    this.setActivePackageSelections();

    this.setContentItemValues();

    this.loadAllForms();

    this.setTitleImagePath();
  }

  setEditMode(): void {
    this.editMode = !!this.linkTo._id;
  }

  loadAllForms(): void {
    this.formSubmitted = false;

    this.activeDatesFormInit();

    this.isIn2lSuggestsFormInit();

    this.recentlyAddedDatesFormInit();

    this.parentFormInit();
  }

  setTitleImagePath(): void {
    if (this.linkTo.hasTileImage()) {
      this.subscriptionTracker.track = this.mediaApiService
        .getAttachment(this.linkTo._id, this.linkTo.tileImageKey())
        .pipe(
          mergeMap((imageBlob: Blob) => {
            // rec blob, convert to dataURI
            return imageBlob
              ? FileUtilityService.convertBlobToDataURI(imageBlob)
              : of(null);
          })
        )
        .subscribe(
          (imageDataURI: string) => {
            const uri = imageDataURI || null;

            if (uri) {
              this.overrideTileImageSrc = uri;
            }
          },
          error => {
            console.warn('Could not convert image to dataURI', error);
          }
        );
    } else {
      this.overrideTileImageSrc = null;
    }

    if (this.contentItem.hasTileImage()) {
      this.contentItemTileImageSrc = `${
        this.contentItem._id
      }/${this.contentItem.tileImageKey()}`;
    } else {
      this.contentItemTileImageSrc = null;
    }
  }

  setSelectableContentItems(): void {
    this.selectableContentItems = this.contentItems
      .map(item => {
        return {
          id: item._id,
          text:
            item.library_path === '/'
              ? `/${item.title}`
              : `${item.library_path}/${item.title}`,
        };
      })
      .sort((a, b) => a.text.localeCompare(b.text));
  }

  activeDatesFormInit(): void {
    const activeDates = (this.linkTo.active_dates || []).map(range =>
      this.mapActiveDateRange(range)
    );
    this.activeDateForms = this.fb.array(activeDates);
  }

  isIn2lSuggestsFormInit(): void {
    this.isIn2lSuggests =
      !!this.linkTo.in2l_suggests_dates &&
      this.linkTo.in2l_suggests_dates.length > 0;
    const in2lSuggestsDates = (
      this.linkTo.in2l_suggests_dates || []
    ).map(range => this.mapActiveDateRange(range));
    this.in2lSuggestsDateForms = this.fb.array(in2lSuggestsDates);
  }

  recentlyAddedDatesFormInit(): void {
    this.isRecentlyAdded =
      !!this.linkTo.recently_added_dates &&
      this.linkTo.recently_added_dates.length > 0;
    const recentlyAddedDates = (
      this.linkTo.recently_added_dates || []
    ).map(range => this.mapActiveDateRange(range));
    this.recentlyAddedDateForms = this.fb.array(recentlyAddedDates);
  }

  parentFormInit(): void {
    this.form = this.fb.group({
      active_dates: this.activeDateForms,
      in2l_suggests_dates: this.in2lSuggestsDateForms,
      recently_added_dates: this.recentlyAddedDateForms,
      override_title: [this.linkTo.override_title || ''],
      packages: [[], Validators.required],
      is_approved: [this.linkTo.is_approved ? 'true' : 'false'],
      is_play_all: [this.linkTo.is_play_all ? 'true' : 'false'],
      is_welcome_video: [this.linkTo.is_welcome_video],
      is_single_user_welcome_video: [
        !!this.linkTo.is_single_user_welcome_video,
      ],
      is_multi_user_welcome_video: [!!this.linkTo.is_multi_user_welcome_video],
      notes: [this.linkTo.notes || ''],
    });

    this.dataIsLoaded = true;
  }

  /**
   * Content Item Selection
   */

  setContentItemValues(): void {
    if (this.linkTo.content_id) {
      const contentItem = this.contentItems.find(
        item => item._id === this.linkTo.content_id
      );
      if (contentItem) {
        this.contentId = this.linkTo.content_id;
        this.contentItem = contentItem;
        this.activeContentItemSelections = this.selectableContentItems.filter(
          item => item.id === this.contentId
        );
        this.selectedContentItemText = [...this.activeContentItemSelections];
      } else {
        this.contentId = null;
        this.contentItem = null;
        this.activeContentItemSelections = [];
      }
    }
  }

  selectContentItem(event: { id: string; text: string }) {
    this.contentId = event.id;
    this.contentItem = this.contentItems.find(item => item._id === event.id);
    this.activeContentItemSelections = [event];
    this.selectedContentItemText = [event];
  }

  filterAvailableContentItems(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.selectableContentItems.length; i++) {
      const contentItem = this.selectableContentItems[i];
      if (contentItem.text.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(contentItem);
      }
    }
    // returns top 1000 results which increases render performance
    this.filteredContentItems = filtered.slice(0, 1000);
  }

  /**
   * Package Selection
   */

  setSelectablePackages(): void {
    this.selectablePackages = (this.contentPackages || []).map(item => {
      return { id: item._id, text: `${item.name} (${item.abbreviation})` };
    });
  }

  setActivePackageSelections(): void {
    this.activePackageSelections = (this.linkTo.packages || [])
      .map(packageId =>
        this.selectablePackages.find(item => item.id === packageId)
      )
      .filter(item => !!item);
    this.selectedPackageItems = [...this.activePackageSelections];
  }

  selectPackage(event: { id: string; text: string }) {
    this.activePackageSelections = [...this.activePackageSelections, event];
  }

  removePackage(event: { id: string; text: string }) {
    this.activePackageSelections = this.activePackageSelections.filter(
      item => item.id !== event.id
    );
  }

  filterAvailablePackages(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.selectablePackages.length; i++) {
      const item = this.selectablePackages[i];
      if (item.text.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(item);
      }
    }
    this.filteredPackages = filtered;
  }

  /**
   * Active Date Selection
   */

  serializedActiveDates(range: ContentInterfaces.IActiveDateRange) {
    return {
      start_date: DateUtils.setSavedDateFormat(range.start_date),
      end_date: DateUtils.setSavedDateFormat(range.end_date),
    };
  }

  mapActiveDateRange(range: ContentInterfaces.IActiveDateRange) {
    return this.fb.group({
      start_date: [
        DateUtils.setDateDisplayFormat(range.start_date) || '',
        Validators.compose([
          Validators.required,
          CustomValidators.date,
          AppValidator.dateExists(),
        ]),
      ],
      end_date: [
        DateUtils.setDateDisplayFormat(range.end_date) || '',
        Validators.compose([
          Validators.required,
          CustomValidators.date,
          AppValidator.dateExists(),
        ]),
      ],
    });
  }

  addDateRange() {
    this.activeDateForms.push(
      this.mapActiveDateRange({
        start_date: '',
        end_date: '',
      })
    );
  }

  removeDateRange(index: number) {
    this.activeDateForms.removeAt(index);
  }

  /**
   * iN2L Suggests & Recently Added Dates
   */
  addIn2lSuggestsDateRange() {
    this.in2lSuggestsDateForms.push(
      this.mapActiveDateRange({
        start_date: '',
        end_date: '',
      })
    );
  }

  addRecentlyAddedDateRange() {
    this.recentlyAddedDateForms.push(
      this.mapActiveDateRange({
        start_date: '',
        end_date: '',
      })
    );
  }
  removeIn2lSuggestsDateRange(index: number) {
    this.in2lSuggestsDateForms.removeAt(index);
  }

  removeRecentlyAddedDateRange(index: number) {
    this.recentlyAddedDateForms.removeAt(index);
  }

  invalidSubDates(startDateControl, endDateControl) {
    if (!startDateControl.value || !endDateControl.value) {
      return false;
    }

    const startDate = moment(startDateControl.value).format('YYYY-MM-DD');
    const endDate = moment(endDateControl.value).format('YYYY-MM-DD');

    for (let i = 0; i < this.activeDateForms.value.length; i++) {
      const range = this.activeDateForms.value[i];
      const adStartDate = moment(range.start_date, 'MM/DD/YYYY').format(
        'YYYY-MM-DD'
      );
      const adEndDate = moment(range.end_date, 'MM/DD/YYYY').format(
        'YYYY-MM-DD'
      );

      if (adStartDate <= startDate && adEndDate >= endDate) {
        return false;
      }
    }

    return true;
  }

  hasInvalidDateRange() {
    for (let i = 0; i < this.in2lSuggestsDateForms.value.length; i++) {
      const range = this.in2lSuggestsDateForms.value[i];
      if (
        !range.start_date ||
        !range.end_date ||
        this.invalidSubDates(
          { value: range.start_date },
          { value: range.end_date }
        )
      ) {
        return true;
      }
    }

    for (let i = 0; i < this.recentlyAddedDateForms.value.length; i++) {
      const range = this.recentlyAddedDateForms.value[i];
      if (
        !range.start_date ||
        !range.end_date ||
        this.invalidSubDates(
          { value: range.start_date },
          { value: range.end_date }
        )
      ) {
        return true;
      }
    }

    return false;
  }

  setDateToStringMMDDYYYFormat(dateToFormat: string): string {
    return DateUtils.setDateDisplayFormat(dateToFormat);
  }
  /**
   * Welcome Video Logic
   */

  setWelcomeVideoTitle(): string {
    return !!this.linkToSetAsWelcomeVideo.length
      ? this.linkToSetAsWelcomeVideo[0].override_title
      : '';
  }

  showIsWelcomeVideoCheckbox(): boolean {
    if (!this.contentItem) {
      return false;
    }
    // only show if content and display type are video AND not in focus layout root
    return (
      this.contentItem.content_type === ContentConstants.DISPLAY_TYPE.VIDEO &&
      this.contentItem.display_type === ContentConstants.DISPLAY_TYPE.VIDEO &&
      this.linkTo.layout_root_id !== ContentConstants.FOCUS_LAYOUT_ROOT_ID
    );
  }

  showSingleMultiWelcomeCheckboxes(): boolean {
    if (!this.contentItem) {
      return false;
    }
    // only show if content and display type are video  in focus layout root and parent container title is 'Non-Displayable"
    // and linkTo._id is included in ContentConstants.NON_DISPLAYABLE_PARENT_CONTAINER_IDS_FOR_ALL_ENVIRONMENTS

    return (
      this.contentItem.content_type === ContentConstants.DISPLAY_TYPE.VIDEO &&
      this.contentItem.display_type === ContentConstants.DISPLAY_TYPE.VIDEO &&
      this.linkTo.layout_root_id === ContentConstants.FOCUS_LAYOUT_ROOT_ID &&
      ContentConstants.NON_DISPLAYABLE_PARENT_CONTAINER_IDS_FOR_ALL_ENVIRONMENTS.includes(
        this.linkTo.parent_id
      )
    );
  }

  onHoverVideoTitle(welcomeVideoField: string): string {
    const videoAlreadySetAsWelcomeVideo = this.getCurrentLinkToSetAsWelcomeVideo(
      welcomeVideoField
    )[0];
    return videoAlreadySetAsWelcomeVideo
      ? videoAlreadySetAsWelcomeVideo.override_title
      : '';
  }

  getCurrentLinkToSetAsWelcomeVideo(
    welcomeVideoField: string
  ): ContentModels.LinkTo[] {
    return this.allLinkTos.filter(
      (linkTo: ContentModels.LinkTo) =>
        linkTo[welcomeVideoField] &&
        this.linkTo.layout_root_id === linkTo.layout_root_id
    );
  }

  isAnotherVideoSetAsWelcomeVideo(welcomeVideoField: string): boolean {
    this.linkToSetAsWelcomeVideo = this.getCurrentLinkToSetAsWelcomeVideo(
      welcomeVideoField
    );
    return (
      this.linkToSetAsWelcomeVideo.length > 0 &&
      this.linkToSetAsWelcomeVideo[0]._id !== this.linkTo._id
    );
  }

  toggleUserWelcomeVideoCheckBoxValues(field: string) {
    const input = this.form.controls[field];
    input.setValue(!input.value);

    if (input.value) {
      const falseCheckBoxField = [
        'is_single_user_welcome_video',
        'is_multi_user_welcome_video',
      ].filter(value => value !== field)[0];

      // Only one of these values can be true
      this.form.controls[falseCheckBoxField].setValue(false);
    }
  }

  /**
   * Overrides
   */

  removeOverrideTileImage() {
    this.overrideTileImageSrc = '';
  }

  setPlayAllTitle() {
    if (!this.form.controls['override_title'].value) {
      this.form.controls['override_title'].setValue('Play All');
    }
  }

  removePlayAllTitle() {
    if (this.form.controls['override_title'].value === 'Play All') {
      this.form.controls['override_title'].setValue('');
    }
  }

  /**
   * Validation
   */

  contentItemRequired(): boolean {
    return this.form.controls['is_play_all'].value === 'false';
  }

  overrideTitleRequired(): boolean {
    return (
      this.form.controls['is_play_all'].value === 'true' &&
      !this.form.controls['override_title'].value
    );
  }

  /**
   * Events
   */

  isSubmitDisabled(): boolean {
    return (
      !this.form.valid ||
      (this.contentItemRequired() && !this.contentId) ||
      this.hasInvalidDateRange() ||
      this.formSubmitted
    );
  }

  saveLinkTo() {
    const isPlayAll = this.form.controls['is_play_all'].value === 'true';

    if (!this.form.valid) {
      return;
    }

    if (!isPlayAll && !this.contentId) {
      return;
    }

    if (
      !this.overrideTileImageSrc &&
      !this.cropControl.getCroppedImageDataUrl()
    ) {
      this.linkTo.removeTileImage();
    }
    const isApproved = this.form.controls['is_approved'].value === 'true';
    this.changeDetails = {
      saveLinkTo: {
        imageSrc:
          this.overrideTileImageSrc ||
          this.cropControl.getCroppedImageDataUrl(),
        linkTo: Object.assign(new ContentModels.LinkTo(), this.linkTo, {
          _id: this.linkTo._id || UUID.UUID(),
          content_id: this.contentId,
          override_title: this.form.controls['override_title'].value,
          notes: this.form.controls['notes'].value || '',
          active_dates: (this.form.value.active_dates || []).map(range =>
            this.serializedActiveDates(range)
          ),
          in2l_suggests_dates: (this.isIn2lSuggests
            ? this.form.value.in2l_suggests_dates || []
            : []
          ).map(range => this.serializedActiveDates(range)),
          recently_added_dates: (this.isRecentlyAdded
            ? this.form.value.recently_added_dates || []
            : []
          ).map(range => this.serializedActiveDates(range)),
          is_approved: isApproved,
          is_play_all: isPlayAll,
          is_welcome_video: this.form.get('is_welcome_video').value,
          is_multi_user_welcome_video: this.form.get(
            'is_multi_user_welcome_video'
          ).value,
          is_single_user_welcome_video: this.form.get(
            'is_single_user_welcome_video'
          ).value,
          packages: this.activePackageSelections.map(item => item.id),
        }),
        tileImageDataUrl:
          this.cropControl.getCroppedImageDataUrl() || undefined,
      },
    };

    this.formSubmitted = true;
    this.emitAndClearChange();
  }

  cancel() {
    this.changeDetails = {
      cancel: {},
    };
    this.emitAndClearChange();
  }

  emitAndClearChange() {
    this.emitChanges();
    this.clearChanges();
  }

  emitChanges() {
    this.changeEvent.emit(this.changeDetails);
  }

  clearChanges() {
    this.changeDetails = {};
  }

  //   Delete Modal

  showDeleteModal() {
    this.deleteSubmitted = false;
    this.deleteLinkToModal.show();
  }

  closeDeleteModal() {
    this.deleteLinkToModal.hide();
  }

  deleteLinkTo() {
    if (!this.linkTo || !this.linkTo._id) {
      return;
    }
    this.changeDetails = {
      deleteLinkTo: {
        linkToId: this.linkTo._id,
      },
    };
    this.deleteSubmitted = true;
    this.emitAndClearChange();
  }

  deleteDisabled(id: string): boolean {
    return ContentConstants.NEVER_DELETE_CONTENT_IDS.includes(id);
  }
}
