import { ScrapbookEntry } from './scrapbook-entry';
import { IScrapbookEntry } from './scrapbook-entry-interfaces';

export class ScrapbookEntryList {
  members: IScrapbookEntry[];

  constructor(data: any = {}) {
    this.members = [];
    if (data && Array.isArray(data)) {
      data.forEach(entry => {
        this.members.push(new ScrapbookEntry(entry));
      });
    }
  }
}
