import * as moment from 'moment';
import { CustomValidators } from 'ng2-validation';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Md5 } from 'ts-md5';

import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DocTypeConstants } from '../../../constants/doc-types';
import { UserConstants } from '../../../constants/user.constants';
import { InputService } from '../../../core/input/input.service';
import { ResidentService } from '../../../core/resident/resident.service';
import { StateManager } from '../../../core/state/state-manager';
import { UiEventService } from '../../../core/ui-event-service/ui-event-service';
import { ToasterMessage } from '../../../core/ui-event-service/ui-toaster-message';
import { UserService } from '../../../core/user/user.service';
import { Resident } from '../../../model/resident/resident';
import { User } from '../../../model/user/user';
import { ImageCropControlComponent } from '../../../shared/components/image-crop-control/image-crop-control.component';
import { BaseFormComponent, FormUtils } from '../../../util/form-utils';
import { PhoneNumberUtils } from '../../../util/phone-number-utils';

@Component({
  selector: 'app-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserProfileFormComponent
  extends BaseFormComponent
  implements OnInit {
  @ViewChild('cropControl')
  cropControl: ImageCropControlComponent;

  @Input() user: User | undefined;

  existingImageSrc = '';
  profileForm: FormGroup;
  phoneMask: Array<string | RegExp>;

  residentNames: Array<{ id: string; text: string }> = [];
  selectedResidents: Array<{ id: string; text: string }> = [];
  filteredResidents: Array<{ id: string; text: string }> = [];
  selectedResidentsList: Array<{ id: string; text: string }> = [];

  removeProfileImage = false;

  constructor(
    private formBuilder: FormBuilder,
    private residentService: ResidentService,
    private stateManager: StateManager,
    private uiEventService: UiEventService,
    private userService: UserService
  ) {
    super();
    this.phoneMask = InputService.US_INT_PHONE_MASK;
  }

  ngOnInit() {
    this.profileForm = this.formBuilder.group({
      first_name: [this.user.first_name, Validators.required],
      last_name: [this.user.last_name, Validators.required],
      title: [this.user.title],
    });

    // dynamically add phone control if user should collect it
    if (this.user.shouldCollectPhoneNumber()) {
      this.profileForm.addControl(
        'phone',
        this.formBuilder.control(
          PhoneNumberUtils.setPhoneNumberToStandardDisplayFormat(
            this.user.phone
          )
        )
      );
    }

    if (this.hasPin() && this.canShowPin()) {
      this.profileForm.addControl(
        'pin',
        this.formBuilder.control(this.user.pin, [
          Validators.required,
          Validators.pattern(/^[0-9]{5}$/),
        ])
      );
    }

    if (this.isFacilityUser()) {
      this.profileForm.addControl(
        'email',
        this.formBuilder.control(this.user.email, CustomValidators.email)
      );
      this.profileForm.addControl(
        'resident_mode',
        this.formBuilder.control(
          this.user.resident_mode || UserConstants.USER_RESIDENT_MODE_ALL
        )
      );
      this.profileForm.addControl(
        'resident_ids',
        this.formBuilder.control(this.user.resident_ids || [])
      );
    }

    this.subscriptionTracker.track = this.getImageSrc().subscribe(src => {
      this.existingImageSrc = src;
    });

    // this.existingImageSrc = this.user.profileImagePath();

    if (this.isFacilityUser()) {
      this.subscriptionTracker.track = this.residentService
        .getAllResidentsForFacility(
          this.user.account_id,
          this.user.facility_ids[0]
        )
        .subscribe((residents: Resident[]) => {
          this.residentNames = residents.map(r => ({
            id: r._id,
            text: `${r.first_name} ${r.last_name}`,
          }));
          this.selectedResidents = this.residentNames.filter(r =>
            (this.user.resident_ids || []).includes(r.id)
          );
          this.selectedResidentsList = [...this.selectedResidents];
        });
    }
  }

  isFacilityAdmin(): boolean {
    return this.user.doc_type === DocTypeConstants.TYPES.USER.FACILITY_ADMIN;
  }

  isFacilityUser(): boolean {
    return this.user.doc_type === DocTypeConstants.TYPES.USER.FACILITY_USER;
  }

  isIn2lType(): boolean {
    return (
      this.user.doc_type === DocTypeConstants.TYPES.USER.IN2L_ADMIN ||
      this.user.doc_type === DocTypeConstants.TYPES.USER.IN2L_CONTENT
    );
  }

  isResidentModeAll(): boolean {
    return (
      this.profileForm.get('resident_mode').value ===
      UserConstants.USER_RESIDENT_MODE_ALL
    );
  }

  isResidentModeSelect(): boolean {
    return (
      this.profileForm.get('resident_mode').value ===
      UserConstants.USER_RESIDENT_MODE_SELECT
    );
  }

  setResidentModeAll() {
    this.profileForm
      .get('resident_mode')
      .setValue(UserConstants.USER_RESIDENT_MODE_ALL);
  }

  setResidentModeSelect() {
    this.profileForm
      .get('resident_mode')
      .setValue(UserConstants.USER_RESIDENT_MODE_SELECT);
  }

  filterAvailableResidents(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.residentNames.length; i++) {
      let item = this.residentNames[i];
      if (item.text.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(item);
      }
    }
    this.filteredResidents = filtered;
  }

  handleAddResident(selected) {
    this.selectedResidents = this.selectedResidents
      .filter(f => f.id !== selected.id)
      .concat(selected);
    this.profileForm
      .get('resident_ids')
      .setValue(this.selectedResidents.map(f => f.id));
  }

  handleRemoveResident(selected) {
    this.selectedResidents = this.selectedResidents.filter(
      f => f.id !== selected.id
    );
    this.profileForm
      .get('resident_ids')
      .setValue(this.selectedResidents.map(f => f.id));
  }

  hasPin(): boolean {
    return this.isFacilityAdmin() || this.isFacilityUser();
  }

  canShowPin(): boolean {
    return this.user._id === this.stateManager.getCurrentUser()._id;
  }

  checkProfileNumber(input: string): void {
    // https://github.com/text-mask/text-mask/issues/236
    if (!!this.profileForm.get(input).value) {
      this.profileForm
        .get(input)
        .setValidators(
          Validators.pattern(InputService.US_INT_PHONE_VALIDATION_PATTERN)
        );
    }
  }

  submitForm($event, formValues) {
    $event.preventDefault();

    // reset success and error messages
    this.resetMessages();

    FormUtils.markAllAsTouched(this.profileForm);

    // handle valid form submission
    if (this.profileForm.valid) {
      // first, reload user to make sure we have the most up to date version of the user data
      this.subscriptionTracker.track = this.userService
        .getUser(this.user._id)
        .pipe(
          mergeMap((user: User) => {
            if (user.doc_type === DocTypeConstants.TYPES.USER.FACILITY_USER) {
              user.email = formValues.email || '';
            } else {
              user.email = formValues.email || user.email || '';
            }
            user.first_name = formValues.first_name || user.first_name || '';
            user.last_name = formValues.last_name || user.last_name || '';
            if (
              user.doc_type === DocTypeConstants.TYPES.USER.FACILITY_ADMIN ||
              user.doc_type === DocTypeConstants.TYPES.USER.FACILITY_USER
            ) {
              user.pin = formValues.pin;
            }

            if (formValues.phone && user.shouldCollectPhoneNumber()) {
              user.phone = PhoneNumberUtils.setPhoneNumberToStandardSavedFormat(
                formValues.phone
              );
            }

            user.title = formValues.title || '';

            if (user.doc_type === DocTypeConstants.TYPES.USER.FACILITY_USER) {
              user.resident_mode = formValues.resident_mode;
              user.resident_ids =
                user.resident_mode === UserConstants.USER_RESIDENT_MODE_ALL
                  ? []
                  : formValues.resident_ids;
            }

            // Adjust created and modified dates to correct the format in case it came from Eclipse
            if (user.created_date) {
              user.created_date = moment(user.created_date).toISOString();
            }

            if (user.modified_date) {
              user.modified_date = moment(user.modified_date).toISOString();
            }

            // for now, just return the updated user and skip the email address check
            return of(user);
          }),
          mergeMap((updatedUser: User) => {
            // see if a profile image update was made, if so update
            // otherwise just pass along updatedUser
            const profileImageBlob = this.cropControl.getCroppedFile();
            const etag = Md5.hashStr(
              this.cropControl.getCroppedImageDataUrl() || ''
            );

            if (
              profileImageBlob &&
              (!updatedUser.media ||
                !updatedUser.media[UserConstants.USER_PROFILE_IMAGE_KEY] ||
                etag !==
                  updatedUser.media[UserConstants.USER_PROFILE_IMAGE_KEY].etag)
            ) {
              const attachment = profileImageBlob;
              return this.userService.updateUser(updatedUser, {
                name: UserConstants.USER_PROFILE_IMAGE_KEY,
                contentType: attachment.type,
                imageBlob: attachment,
                etag: etag,
              });
            } else if (this.removeProfileImage) {
              return this.userService.removeProfileImage(updatedUser);
            }

            return this.userService.updateUser(updatedUser);
          }),
          map((updatedUser: User) => {
            // refresh image path in case it was updated
            // this.existingImageSrc = updatedUser.profileImagePath();

            if (updatedUser._id === this.stateManager.getCurrentUser()._id) {
              this.stateManager.updateCurrentUser(updatedUser);
            }

            return updatedUser;
          })
        )
        .subscribe(
          (updatedUser: User) => {
            this.uiEventService.dispatch(
              new ToasterMessage({
                body: 'User profile has been updated',
                type: 'success',
              })
            );

            this.profileForm.reset();

            if (this.onSubmit) {
              this.onSubmit.emit(updatedUser);
            }
          },
          error => {
            this.errorMessage = error;
          }
        );
    }
  }

  markRemoveProfileImage($event) {
    this.removeProfileImage = true;
    this.existingImageSrc = '';
  }

  handleCancelClick() {
    if (this.onCancel) {
      this.onCancel.emit();
    }
  }

  getImageSrc(): Observable<string> {
    const profileImageKey =
      this.user.media &&
      this.user.media[UserConstants.USER_PROFILE_IMAGE_KEY] &&
      this.user.media[UserConstants.USER_PROFILE_IMAGE_KEY].status ===
        'Completed'
        ? this.user.media[UserConstants.USER_PROFILE_IMAGE_KEY].s3_key
        : '';

    return profileImageKey
      ? this.userService.getUserProfileImage(
          profileImageKey,
          this.user.doc_namespace,
          this.user.doc_type
        )
      : of('');
  }
}
