import { Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DocTypeConstants } from '../../constants/doc-types';
import { FamilyPortalUser } from '../../model/family-portal-user/family-portal-user';
import { NewFamilyPortalUserRequest } from '../../model/family-portal-user/new-family-portal-user-request';
import { ROUTES } from '../auth-api/auth-api.constants';
import { AuthApiService } from '../auth-api/auth-api.service';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { StateManager } from '../state/state-manager';

@Injectable()
export class FamilyPortalUserAuthService extends AuthApiService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    cacheService: CacheService,
    stateManager: StateManager,
    private authApi: AuthApiService
  ) {
    super(http, jwtService, stateManager, cacheService);
  }

  createNewFamilyPortalUser(
    email: string,
    associatedResidentId: string,
    roles?: string[],
    relation?: string,
    status?: string
  ): Observable<FamilyPortalUser> {
    return this.createDoc<FamilyPortalUser>(
      new NewFamilyPortalUserRequest({
        user_type: DocTypeConstants.TYPES.USER.FAMILY_PORTAL_USER,
        email: email,
        associated_residents: [
          {
            _id: associatedResidentId,
            relation: relation,
            roles: roles ?? [],
            status: status ?? 'pending',
          },
        ],
      }),
      ROUTES.USER.PATH
    );
  }

  sendFamilyPortalInvite(
    familyPortalUserId: string,
    residentId: string
  ): Observable<void> {
    return this.postEmptyDoc(
      ROUTES.USER.PATH,
      `${familyPortalUserId}/${ROUTES.USER.ACTIONS.INVITESEND}/${residentId}`
    );
  }

  sendFamilyPortalConnectRequest(
    familyPortalUserId: string,
    residentId: string
  ): Observable<void> {
    return this.postEmptyDoc(
      ROUTES.USER.PATH,
      `${familyPortalUserId}/${ROUTES.USER.ACTIONS.CONNECTREQUEST}/${residentId}`
    );
  }

  handleError<T>(error: any): Observable<T> {
    if (error.status === 401) {
      this.stateManager.handleErrorEvent({
        type: 'UNAUTHORIZED',
      });
    } else if (error.status === 409) {
      // Error status 409 is used by the Family Portal endpoint in the Auth API to reflect that a user already exists
      // Throwing the error allows us to handle it higher in the call stack and redirect to the endpoint to update a user
      throw error;
    } else {
      this.stateManager.handleErrorEvent({
        type: 'UNKNOWN_ERROR',
      });
    }

    return of(null);
  }
}
