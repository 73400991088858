import { filter } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';

import { StateManager } from '../../../core/state/state-manager';
import { IAuthState } from '../../../model/state/auth-state';
import { UserblockService } from './userblock.service';

const DEFAULT_PROFILE_IMAGE_URL = '/assets/img/user/generic.png';

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss'],
})
export class UserblockComponent implements OnInit {
  userSignedIn = false;
  firstName: string;
  lastName: string;
  accountTypeName: string;
  profileImageSrc = DEFAULT_PROFILE_IMAGE_URL;

  constructor(
    private stateManager: StateManager,
    private userblockService: UserblockService
  ) {}

  ngOnInit() {
    this.stateManager
      .getAuthStateSubject()
      .pipe(
        filter((authState: IAuthState) => {
          const isStableAuthState = this.stateManager.verifyAuthStableState();

          const shouldProcessChange = isStableAuthState;
          return shouldProcessChange;
        })
      )
      .subscribe((authState: IAuthState) => {
        // Getting the user instead of using the current user subject
        // in order to get the user from local storage if necessary
        const currentUser = this.stateManager.getCurrentUser();
        if (!currentUser || !authState.userSignedIn) {
          this.userSignedIn = false;
          this.firstName = '';
          this.lastName = '';
          this.accountTypeName = '';
          this.profileImageSrc = DEFAULT_PROFILE_IMAGE_URL;
          return;
        }

        this.userSignedIn = true;
        this.firstName = currentUser.first_name || '';
        this.lastName = currentUser.last_name || '';
        this.accountTypeName = currentUser.accountTypeName() || '';
        // this.profileImageSrc = currentUser.profileImagePath();
      });
  }

  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }
}
