import { forkJoin, of, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Facility } from '../../model/facility/facility';
import { IAuthState } from '../../model/state/auth-state';
import { navigateToSelectionUrl } from '../../util/navigation-utils';
import { FacilityService } from '../facility/facility.service';
import { StateManager } from './state-manager';

const SERVICE_NAME = 'SIDEBAR_STATE_SERVICE';

@Injectable()
export class SidebarStateService {
  constructor(
    private facilityService: FacilityService,
    private router: Router,
    private stateManager: StateManager
  ) {}

  /**
   * Monitor for changes to the selected account from the sidebar
   * Load the sidebar community list based on the signed-in user’s permissions
   */
  sidebarAccountSelectionChangeSubscription(): Subscription {
    return this.stateManager
      .getSidebarAccountIdSelectionSubject()
      .pipe(
        filter((selectedAccountId: string) => {
          const allowExecution = this.stateManager.allowSubscriptionExecution();
          const isStableAuthState = this.stateManager.verifyAuthStableState();

          const accountList = this.stateManager.getSidebarAccountList();
          const accountListsSet = !!accountList.length;

          const accountSelected = !!selectedAccountId;

          return (
            allowExecution &&
            isStableAuthState &&
            accountListsSet &&
            accountSelected
          );
        }),
        mergeMap((selectedAccountId: string) => {
          const authState = this.stateManager.getAuthState();

          // Don't get the facilities again if they are already set
          // The only time they will change is if the user creates or updates a facility
          const currentFacilities = this.stateManager.getSidebarFacilityList();
          const facilitiesAlreadySet =
            !!currentFacilities.length &&
            currentFacilities.every(f => f.account_id === selectedAccountId);
          if (facilitiesAlreadySet) {
            return forkJoin([of(authState), of(currentFacilities)]);
          }

          const facilitiesObservable = this.facilityService
            .getAccountFacilities(selectedAccountId)
            .pipe(
              map(facilities => {
                return this.stateManager.userIsFacilityAdmin(
                  authState.userDocType
                )
                  ? facilities.filter(f =>
                      authState.userFacilityIds.includes(f._id)
                    )
                  : facilities;
              })
            );

          return forkJoin([of(authState), facilitiesObservable]);
        })
      )
      .subscribe(
        ([authState, facilities]: [IAuthState, Facility[]]) => {
          // Update the sidebar communities list
          this.stateManager.updateSidebarFacilityList(facilities);

          // Update the selected facility based on the urlIds or user default community
          // Let the facilityId selection subscription handle redirects to default url
          const adjustedFacilityId = authState.userDefaultFacilityId || '';
          this.stateManager.updateSidebarFacilityIdSelection(
            adjustedFacilityId
          );
        },
        error => {
          console.log(
            `Sidebar selected account ID default navigation subscription failed`
          );
          console.log(error);
        }
      );
  }

  /**
   * Monitor for changes to the selected community
   * Redirect to community default page on facilityId selection
   */
  sidebarFacilitySelectionChangeSubscription(): Subscription {
    return this.stateManager
      .getSidebarFacilityIdSelectionSubject()
      .pipe(
        filter((selectedFacilityId: string) => {
          const allowExecution = this.stateManager.allowSubscriptionExecution();
          const isStableAuthState = this.stateManager.verifyAuthStableState();

          return allowExecution && isStableAuthState;
        })
      )
      .subscribe(
        (selectedFacilityId: string) => {
          // The facility selection has changed so we need to
          // redirect to the default facility page
          const authState = this.stateManager.getAuthState();
          const selectedAccountId = this.stateManager.getSidebarAccountIdSelection();

          // Don't change url if already on a url inside the correct account or community
          if (
            authState.urlIds.accountId === selectedAccountId &&
            authState.urlIds.facilityId === selectedFacilityId
          ) {
            return;
          }

          navigateToSelectionUrl(
            this.router,
            authState,
            selectedAccountId,
            selectedFacilityId
          );
        },
        error => {
          console.log(
            `Sidebar selected account ID default navigation subscription failed`
          );
          console.log(error);
        }
      );
  }
}
