import { Observable, of, throwError } from 'rxjs';
/**
 *  PortalAPIService
 **
 *  This class will setup communication with the portal api
 *
 *  Communication happens through a HTTP REST calls, guarded by the passing
 *  authentication information in the header from the AngularTokenService
 *  http wrappers
 */
import { catchError, map, mergeMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { User } from '../../model/user/user';
import { JwtService } from '../authentication/jwt.service';
import { StateManager } from '../state/state-manager';

const RequestMethod = {
  Get: 0,
  Put: 1,
  Post: 2,
  Delete: 3,
};

@Injectable()
export class PortalAPIService {
  static normalizeUrl(path) {
    if (path.startsWith('/')) {
      path = path.slice(1);
    }

    return path;
  }

  static requestMethodToString(requestMethod): string {
    switch (requestMethod) {
      case RequestMethod.Get:
        return 'GET';
      case RequestMethod.Put:
        return 'PUT';
      case RequestMethod.Post:
        return 'POST';
      case RequestMethod.Delete:
        return 'DELETE';
      default:
        return 'GET';
    }
  }

  constructor(
    private http: HttpClient,
    private stateManager: StateManager,
    private jwtService: JwtService
  ) {}

  // --- Invite requests ---

  sendInvite(inviteData): Observable<any> {
    return this.sendRequest('/api/invites', inviteData, RequestMethod.Post);
  }

  deleteInvite(user: User): Observable<boolean> {
    return this.sendRequest(
      `/api/invites/${user._id}`,
      { id: user._id, email: user.email },
      RequestMethod.Delete
    ).pipe(
      map((result: any) => {
        return !!result.success;
      }),
      catchError(error => {
        return error.status === 404 ? of(true) : throwError(error);
      })
    );
  }

  // --- Welcome Text ---

  sendWelcomeTextMessage(data: {
    contact_phone: string;
    facility_name: string;
    resident_name: string;
  }): Observable<any> {
    return this.sendRequest(
      '/api/text_messages/send_welcome_text',
      data,
      RequestMethod.Post
    );
  }

  // --- AWS ---

  deleteS3Object(key: string) {
    return this.sendRequest(
      `/api/content/media/${encodeURIComponent(key)}`,
      null,
      RequestMethod.Delete
    );
  }

  // --- send actual request ---

  private sendRequest(
    path: string,
    data?: Object,
    method = RequestMethod.Get,
    processResponse: boolean = true
  ) {
    path = PortalAPIService.normalizeUrl(path);

    let requestObservable;

    return this.jwtService.getToken().pipe(
      mergeMap(token => {
        // unfortunately calling this.tokenService.request() was not working, so
        // we need this longer if-else block
        if (method === RequestMethod.Get) {
          requestObservable = this.http.get(
            `${environment.portalApi.url}/${path}`,
            { headers: { authorization: token } }
          );
        } else if (method === RequestMethod.Put) {
          requestObservable = this.http.put(
            `${environment.portalApi.url}/${path}`,
            data,
            { headers: { authorization: token } }
          );
        } else if (method === RequestMethod.Post) {
          requestObservable = this.http.post(
            `${environment.portalApi.url}/${path}`,
            data,
            { headers: { authorization: token } }
          );
        } else if (method === RequestMethod.Delete) {
          requestObservable = this.http.delete(
            `${environment.portalApi.url}/${path}`,
            { headers: { authorization: token } }
          );
        } else {
          requestObservable = throwError(
            new Error(`Unsupported api request method (${method})`)
          );
        }

        // do not process response, just return raw observable
        return requestObservable.pipe(
          map(response => {
            return !processResponse ? <string>response : <any>response;
          }),
          catchError((error: any) => {
            if (error.status === 401) {
              this.stateManager.handleErrorEvent({
                type: 'UNAUTHORIZED',
              });

              return of(null);
            }

            this.stateManager.handleErrorEvent({
              type: 'UNKNOWN_ERROR',
            });

            return throwError(error || new Error('Unknown server error'));
          })
        );
      })
    );
  }
}
