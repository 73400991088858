import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-document-changed-by',
  templateUrl: './document-changed-by.component.html',
})
export class DocumentChangedByComponent {
  @Input() document: Object;

  isArray(obj: unknown): boolean {
    return Array.isArray(obj);
  }
}
