import * as Croppie from 'croppie';
import { ModalDirective } from 'ngx-bootstrap/modal';

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-croppie-control',
  templateUrl: './croppie-control.component.html',
  styleUrls: ['./croppie-control.component.scss'],
})
export class CroppieControlComponent implements OnInit, AfterViewInit {
  @Input() existingImageSrc: string;
  @Input() canvasWidth: number = 300;
  @Input() canvasHeight: number = 300;
  @Input() label: string = 'Profile Image';
  @Input() disableInput: boolean = false;

  @ViewChild('removeImageModal')
  removeImageModal: ModalDirective;

  @Output() onCrop: EventEmitter<any> = new EventEmitter();
  @Output() onImageChanged: EventEmitter<any> = new EventEmitter();
  @Output() onRemoveImage: EventEmitter<any> = new EventEmitter();

  imageData: any = {};
  loading = false;
  showUpload: boolean;
  uploadedFile: File;
  uploadError = '';
  imageContainer?: Croppie;
  allowedFilesRegex?: RegExp;

  constructor() {}

  ngOnInit() {
    this.allowedFilesRegex = new RegExp(/\.(jpe?g|png)$/, 'i');

    this.imageContainer = new Croppie(
      document.getElementById('img-cropper') as HTMLElement,
      {
        viewport: {
          width: this.canvasWidth,
          height: this.canvasHeight,
          type: 'square',
        },
        boundary: {
          width: this.canvasWidth,
          height: this.canvasHeight,
        },
        enableZoom: true,
        enableResize: false,
        showZoomer: true,
        enableOrientation: true,
      }
    );
  }

  ngAfterViewInit(): void {
    // Manually relocate the rotate button to the cr-slider div
    // This is necessary since the div doesn't exist until the croppie is generated
    const rotateBtn = document.getElementById('rotate-btn');
    const helpText = document.getElementById('help-text');

    if (rotateBtn && helpText) {
      const container = document.querySelector('.cr-slider-wrap');
      container?.appendChild(rotateBtn);
      container?.appendChild(helpText);
      container?.setAttribute('style', `width: ${this.canvasWidth}px`);
    }
  }

  cropped($event) {
    this.onCrop.emit($event);
  }

  fileChangeListener($event) {
    this.uploadedFile = $event.target.files[0];
    const imageReader = new FileReader();

    imageReader.onloadend = (event: any) => {
      this.imageContainer.bind({
        url: imageReader.result as string,
      });

      this.showUpload = true;
    };
    // same check run in ImageCropperComponent, pulled out to show error
    if (this.allowedFilesRegex.test(this.uploadedFile.name)) {
      imageReader.readAsDataURL(this.uploadedFile);
      this.uploadError = '';
    } else {
      this.showUpload = false;
      this.uploadError = 'Please select a jpg or png image for upload.';
    }
  }

  removeImage($event) {
    if ($event.preventDefault) {
      $event.preventDefault();
    }

    this.removeImageModal.show();
  }

  removeImageConfirm() {
    this.onRemoveImage.emit();
    this.removeImageModal.hide();
  }

  cancelConfirm() {
    this.removeImageModal.hide();
  }

  cancelUpload() {
    this.showUpload = false;
  }

  async getCroppedImageDataUrl(): Promise<string> {
    return this.imageContainer.result({ type: 'base64' });
  }

  rotateImage(): void {
    this.imageContainer?.rotate(-90);
  }
}
