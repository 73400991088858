import * as _ from 'lodash';
import { concat, forkJoin, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DocTypeConstants } from '../../constants/doc-types';
import { Device } from '../../model/device/device';
import {
  Resident,
  RESIDENT_STATUS_ACTIVE,
} from '../../model/resident/resident';
import { SyncGatewayInterfaces } from '../../model/sync-gateway/sync-gateway.interfaces';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { DatabaseApiService } from '../database-api/database-api.service';
import { ResidentService } from '../resident/resident.service';
import { StateManager } from '../state/state-manager';
import { DeviceService } from './device.service';

@Injectable()
export class OldDeviceService extends DatabaseApiService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    cacheService: CacheService,
    stateManager: StateManager,
    private deviceService: DeviceService,
    private residentService: ResidentService
  ) {
    super(http, jwtService, stateManager, cacheService);
  }

  // TODO: Move to Device Service IN2L-9098
  addResidentToFacilityDevices(resident: Resident): Observable<boolean> {
    return forkJoin([
      this.residentService.getAllResidentsForFacility(
        resident.account_id,
        resident.facility_id
      ),
      this.deviceService.getAllDevices(
        resident.account_id,
        resident.facility_id
      ),
    ]).pipe(
      mergeMap(([residents, devices]: [Resident[], Device[]]) => {
        const activeResidentIds = residents
          .filter(r => r.status === RESIDENT_STATUS_ACTIVE)
          .map(r => r._id);

        const allResidentsModeDevices = devices
          .map(device => {
            if (
              device.isInAllResidentMode() &&
              _.intersection(device.resident_ids, activeResidentIds).length !==
                activeResidentIds.length
            ) {
              device.resident_ids = activeResidentIds;
              return device;
            }

            return null;
          })
          .filter(d => !!d);

        return this.bulkUpdate(
          DocTypeConstants.NAMESPACES.ACCOUNT,
          DocTypeConstants.TYPES.ACCOUNT.DEVICE,
          allResidentsModeDevices
        );
      }),
      map((results: SyncGatewayInterfaces.IBulkUpdateResult[]) => {
        return results.every(result => !result.error);
      })
    );
  }

  removeResidentFromFacilityDevices(
    resident: Resident,
    accountId: string,
    facilityId: string
  ): Observable<boolean> {
    return this.deviceService.getAllDevices(accountId, facilityId).pipe(
      mergeMap((devices: Device[]) => {
        const residentDevices = devices
          .map(device => {
            if (!device.resident_ids.includes(resident._id)) {
              return null;
            }

            device.resident_ids = _.uniq(
              device.resident_ids.filter(
                residentId => residentId !== resident._id
              )
            );
            return device;
          })
          .filter(d => !!d);

        return this.bulkUpdate(
          DocTypeConstants.NAMESPACES.ACCOUNT,
          DocTypeConstants.TYPES.ACCOUNT.DEVICE,
          residentDevices
        );
      }),
      map((results: SyncGatewayInterfaces.IBulkUpdateResult[]) => {
        return results.every(result => !result.error);
      })
    );
  }

  moveResidentBetweenFacilityDevices(
    resident: Resident,
    fromAccountId: string,
    fromFacilityId: string,
    toAccountId: string,
    toFacilityId: string
  ): Observable<boolean> {
    return forkJoin([
      this.deviceService.getAllDevices(fromAccountId, fromFacilityId),
      this.deviceService.getAllDevices(toAccountId, toFacilityId),
    ]).pipe(
      mergeMap(([fromDevices, toDevices]: [Device[], Device[]]) => {
        const removeResidentDevices = fromDevices
          .map(device => {
            if (!device.resident_ids.includes(resident._id)) {
              return null;
            }

            device.resident_ids = _.uniq(
              device.resident_ids.filter(
                residentId => residentId !== resident._id
              )
            );
            return device;
          })
          .filter(d => !!d);

        const addResidentDevices = toDevices
          .map(device => {
            if (
              !device.isInAllResidentMode() ||
              device.resident_ids.includes(resident._id)
            ) {
              return null;
            }

            device.resident_ids = _.uniq(
              device.resident_ids.concat(resident._id)
            );
            return device;
          })
          .filter(d => !!d);

        return this.bulkUpdate<Device>(
          DocTypeConstants.NAMESPACES.ACCOUNT,
          DocTypeConstants.TYPES.ACCOUNT.DEVICE,
          [...removeResidentDevices, ...addResidentDevices]
        );
      }),
      map((results: SyncGatewayInterfaces.IBulkUpdateResult[]) => {
        return results.every(result => !result.error);
      })
    );
  }
}
