<!-- START Search form-->
<form
  class="navbar-form"
  role="search"
  action="search.html"
  [class.open]="visible"
  (submit)="handleForm()"
>
  <div class="form-group has-feedback">
    <input
      [(ngModel)]="term"
      name="term"
      class="form-control"
      type="text"
      placeholder="{{ 'header.search.PLACEHOLDER' }}"
    />
    <div
      class="fa fa-times form-control-feedback"
      (click)="closeNavSearch()"
    ></div>
  </div>
  <button class="hidden btn btn-default" type="submit">Submit</button>
</form>
<!-- END Search form-->
