import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PresentationModule } from '../presentation/presentation.module';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { routes } from './routes';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes),

    // Use enableTracing to diagnose redirects
    // RouterModule.forRoot(
    //   routes,
    //   { enableTracing: true } // <-- debugging purposes only
    // ),

    PagesModule,
    PresentationModule,
  ],
  declarations: [],
  exports: [RouterModule],
})
export class RoutesModule {}
