import {
  IMediaContainer,
  IScrapbookAuthor,
  IScrapbookComment,
  IScrapbookEntry,
  IScrapbookLink,
} from './scrapbook-entry-interfaces';

export class ScrapbookEntry implements IScrapbookEntry {
  _id?: string;
  _rev?: string;
  created_by?: IScrapbookAuthor;
  created_date?: string;
  doc_namespace: string;
  doc_type: string;
  caption: string;
  entry_date: string;
  modified_by?: IScrapbookAuthor;
  modified_date?: string;
  location?: string;
  order_index: number;
  resident_id: string;
  comments: IScrapbookComment[];
  links: IScrapbookLink;
  media: IMediaContainer;

  constructor(data?: IScrapbookEntry) {
    data = data || ({} as IScrapbookEntry);

    this._id = data._id || '';
    this._rev = data._rev || '';
    this.created_by = data.created_by;
    this.created_date = data.created_date;
    this.doc_namespace = 'scrapbook';
    this.doc_type = 'entry';
    this.caption = data.caption || '';
    this.entry_date = data.entry_date;
    this.modified_by = data.modified_by;
    this.modified_date = data.modified_date;
    this.location = data.location || '';
    this.order_index = data.order_index;
    this.resident_id = data.resident_id;
    this.comments = data.comments;
    this.links = data.links;
    this.media = data.media;
  }
}
