import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Directive, Input, OnInit } from '@angular/core';

import { SidebarStateService } from '../../../core/state/sidebar-state.service';
import { StateManager } from '../../../core/state/state-manager';
import { Account } from '../../../model/account/account';
import { Facility } from '../../../model/facility/facility';
import { IAuthState } from '../../../model/state/auth-state';

const ACCOUNT_PARAM = 'accountId';
const FACILITY_PARAM = 'facilityId';

@Directive()
export abstract class SidebarResourceGroupComponent implements OnInit {
  @Input()
  param: string; // string to replace out
  @Input()
  placeholder: string; // select placeholder
  @Input()
  displayKey = '_id';
  @Input()
  clearParams: string[] = [];
  @Input()
  paramDependency = '';
  @Input()
  defaultRoute: string;

  resources: any[];

  value = '';
  listReady = true;
  dependentKey = '';

  constructor(
    protected stateManager: StateManager,
    protected sidebarStateService: SidebarStateService
  ) {}

  ngOnInit() {
    // when the URL changes, the sidebar selections need to be updated to reflect IDs in the url
    if (this.param === ACCOUNT_PARAM) {
      this.stateManager
        .getAuthStateSubject()
        .subscribe((authState: IAuthState) => {
          setTimeout(() => {
            this.value = authState.urlIds.accountId;
          });
        });
    } else if (this.param === FACILITY_PARAM) {
      this.stateManager
        .getAuthStateSubject()
        .subscribe((authState: IAuthState) => {
          setTimeout(() => {
            this.value = authState.urlIds.facilityId;
          });
        });
    }

    // populate lists when account or facility lists change
    let sidebarListObservable;
    switch (this.param) {
      case ACCOUNT_PARAM:
        sidebarListObservable = this.stateManager.getSidebarAccountListSubject();
        break;
      case FACILITY_PARAM:
        sidebarListObservable = this.stateManager.getSidebarFacilityListSubject();
        break;
      default:
    }

    if (sidebarListObservable) {
      sidebarListObservable
        .pipe(
          mergeMap((data: Account[] | Facility[]) => {
            // request target resource
            return this.resolveResource(data);
          })
        )
        .subscribe(
          resources => {
            if (!resources) {
              return;
            }
            this.resources = [
              _.set(
                { _id: '', placeholder: true },
                this.displayKey,
                this.placeholder
              ),
              ...resources,
            ];
          },
          e => {
            console.log(e);
          }
        );
    }
  }

  abstract resolveResource(
    data: Account[] | Facility[]
  ): Observable<Account[] | Facility[]>;

  onChange(selectionId: string) {
    // this.value = selectionId;
    if (this.param === ACCOUNT_PARAM) {
      this.stateManager.updateSidebarAccountIdSelection(selectionId);
    } else if (this.param === FACILITY_PARAM) {
      this.stateManager.updateSidebarFacilityIdSelection(selectionId);
    }
  }

  getDisplayValue(item): string {
    let value = _.get(item, this.displayKey, '').toString();

    if (this.stateManager.getSidebarAccountList().length > 0) {
      const isInactive = item.billing?.status === 'inactive';
      const entityType = item.account_type || item.facility_type || 'customer';

      const entityName = isInactive
        ? 'INACTIVE '
        : entityType !== 'customer'
        ? `${entityType.toUpperCase()} `
        : '';
      value = entityName + _.get(item, this.displayKey, '').toString();
    }

    // escape hatch if data isn't structured properly
    if (!value || value === '') {
      return item._id;
    }

    return value;
  }
}
