import { DocTypeConstants } from '../../constants/doc-types';
import { SyncGatewayInterfaces } from '../sync-gateway/sync-gateway.interfaces';
import { User } from './user';

export class In2lAdminUser extends User {
  constructor(
    data: {
      email?: string;
      first_name?: string;
      last_name?: string;
      created_by?: string;
      created_date?: string;
      media?: {
        [key: string]: {
          content_type: string;
          etag: string;
          s3_key: string;
          status: string;
        };
      };
      status?: string;
      _id?: string;
      _rev?: string;
      _attachments?: { [key: string]: SyncGatewayInterfaces.IAttachment };
    } = {}
  ) {
    super({
      doc_type: DocTypeConstants.TYPES.USER.IN2L_ADMIN,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      created_by: data.created_by,
      created_date: data.created_date,
      media: data.media,
      status: data.status,
      _id: data._id,
      _rev: data._rev,
      _attachments: data._attachments,
    });
  }
}
