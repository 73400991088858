import { ContentConstants } from '../../../constants/content.constants';
import { UnsubscribeOnDestroy } from '../../../util/unsubscribe-on-destroy';

export class ContentLibraryBaseComponent extends UnsubscribeOnDestroy {
  hasInvalidCharacter(text: string): boolean {
    return (
      text &&
      ContentConstants.INVALID_TITLE_CHARACTERS.some(invalidCharacter =>
        text.includes(invalidCharacter)
      )
    );
  }

  invalidCharactersDescription(): string {
    return ContentConstants.INVALID_CHARACTERS_DESCRIPTION;
  }
}
