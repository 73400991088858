import * as _ from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  PRIMARY_OUTLET,
  Router,
} from '@angular/router';

import { Account } from '../../model/account/account';
import { Device } from '../../model/device/device';
import { Facility } from '../../model/facility/facility';
import { IRegion, Region, Regions } from '../../model/regions/regions';
import { Resident } from '../../model/resident/resident';
import { User } from '../../model/user/user';
import { AccountService } from '../account/account.service';
import { DeviceService } from '../device/device.service';
import { FacilityService } from '../facility/facility.service';
import { RegionsService } from '../regions/regions.service';
import { ResidentService } from '../resident/resident.service';
import { UserService } from '../user/user.service';

export interface IBreadcrumb {
  label: string;
  url: string;
}

export interface IRouteParameters {
  id?: string;
  facility_id?: string;
  device_id?: string;
  region_id?: string;
  resident_id?: string;
  user_id?: string;
}

const ROUTE_DATA_BREADCRUMB_KEY = 'breadcrumb';
const ROUTE_DATA_BREADCRUMB_ARRAY_KEY = 'breadcrumbs';

@Injectable()
export class BreadcrumbService {
  breadcrumbs: Observable<IBreadcrumb[]>;
  private _breadcrumbs: BehaviorSubject<IBreadcrumb[]>;
  private breadcrumbStore: {
    breadcrumbs: IBreadcrumb[];
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private facilityService: FacilityService,
    private deviceService: DeviceService,
    private regionsService: RegionsService,
    private residentService: ResidentService,
    private userService: UserService
  ) {
    this.breadcrumbStore = { breadcrumbs: [] };
    this._breadcrumbs = new BehaviorSubject<IBreadcrumb[]>(
      this.breadcrumbStore.breadcrumbs
    );
    this.breadcrumbs = this._breadcrumbs.asObservable();

    // subscribe to the NavigationEnd event
    if (this.router.events) {
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          mergeMap(() => {
            const params = this.getParams(this.activatedRoute.root);
            return this.buildRouteParameterBreadcrumbs(params);
          })
        )
        .subscribe((breadcrumbs: IBreadcrumb[]) => {
          const routeBreadcrumbs = this.getBreadcrumbsForRoute(
            this.activatedRoute.root
          ).filter(crumb => !!crumb.label);
          if (
            routeBreadcrumbs &&
            routeBreadcrumbs.length &&
            routeBreadcrumbs[0].label === 'Content Library'
          ) {
            return;
          }

          this.updateBreadcrumbs(breadcrumbs.concat(routeBreadcrumbs));
        });
    }
  }

  updateBreadcrumbs(breadcrumbs: IBreadcrumb[]) {
    if (breadcrumbs && !!breadcrumbs.length) {
      breadcrumbs[breadcrumbs.length - 1].url = '';
    }

    this.breadcrumbStore.breadcrumbs = breadcrumbs;
    this._breadcrumbs.next(Object.assign({}, this.breadcrumbStore).breadcrumbs);
  }

  private getParams(
    activatedRoute: ActivatedRoute,
    params: Params = {}
  ): Params {
    const children: ActivatedRoute[] = activatedRoute.children;

    if (!children.length) {
      return params;
    }

    let newParams = params;
    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET || !child.snapshot.params) {
        continue;
      }

      newParams = this.getParams(
        child,
        Object.assign({}, newParams, child.snapshot.params)
      );
    }

    return newParams;
  }

  // based on: http://brianflove.com/2016/10/23/angular2-breadcrumb-using-router/
  private getBreadcrumbsForRoute(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: IBreadcrumb[] = []
  ): IBreadcrumb[] {
    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (let i = 0; i < children.length; i++) {
      const child = children[i];

      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB_KEY)) {
        return this.getBreadcrumbsForRoute(child, url, breadcrumbs);
      }

      // check for shortcut breadcrumbs key route
      if (child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB_ARRAY_KEY)) {
        return child.snapshot.data[ROUTE_DATA_BREADCRUMB_ARRAY_KEY];
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url
        .map(segment => segment.path)
        .join('/');

      // append route URL to URL
      url += `/${routeURL}`;

      // add breadcrumb
      const breadcrumb: IBreadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB_KEY],
        url: url,
      };

      breadcrumbs.push(breadcrumb);

      // recursive
      return this.getBreadcrumbsForRoute(child, url, breadcrumbs);
    }
  }

  private buildRouteParameterBreadcrumbs(
    params: IRouteParameters
  ): Observable<IBreadcrumb[]> {
    if (!params.id) {
      return of([]);
    }

    return forkJoin({
      account: params.id
        ? this.accountService.getAccount(params.id)
        : of<Account>(null),
      regions: params.id
        ? this.regionsService.getRegionsDocument(params.id)
        : of<Regions>(null),
      region:
        params.id && params.region_id
          ? this.regionsService.getRegion(params.id, params.region_id)
          : of<Region>(null),
      facility: params.facility_id
        ? this.facilityService.getFacility(params.facility_id)
        : of<Facility>(null),
      device: params.device_id
        ? this.deviceService.getDeviceIncludingArchived(params.device_id)
        : of<Device>(null),
      resident: params.resident_id
        ? this.residentService.getResident(params.resident_id)
        : of<Resident>(null),
      user: params.user_id
        ? this.userService.getUser(params.user_id)
        : of<User>(null),
    }).pipe(
      mergeMap(result => {
        const crumbs = [];
        let facilityRegion: IRegion;
        if (result.account) {
          crumbs.push({
            label: _.get(result.account, 'profile.account_name', ''),
            url: `/account/${params.id}/dashboard`,
          });
        }

        if (result.facility) {
          if (
            result.facility.profile.region_assignment &&
            result.facility.profile.region_assignment.find(r => !r.end_date) &&
            result.regions
          ) {
            facilityRegion = result.regions.regions.find(
              r =>
                r.region_id ===
                result.facility.profile.region_assignment.find(
                  currentRegion => !currentRegion.end_date
                ).region_id
            );
            if (facilityRegion) {
              crumbs.push({
                label: _.get(facilityRegion, 'region_name', ''),
                url: `/account/${params.id}/region/${facilityRegion.region_id}/dashboard`,
              });
            }
          }
          crumbs.push({
            label: _.get(result.facility, 'profile.name', ''),
            url: `/account/${params.id}/facility/${params.facility_id}/dashboard`,
          });
        }
        if (!facilityRegion && result.region) {
          crumbs.push({
            label: _.get(result.region, 'region_name', ''),
            url: `/account/${params.id}/region/${result.region.region_id}/dashboard`,
          });
        }
        if (result.device) {
          crumbs.push({
            label: 'Device List',
            url: `/account/${params.id}/facility/${params.facility_id}/devices`,
          });

          crumbs.push({
            label: _.get(
              result.device,
              'nickname',
              _.get(result.device, 'serial_number', 'Device')
            ),
            url: `/account/${params.id}/facility/${params.facility_id}/devices/${params.device_id}`,
          });
        } else if (result.resident) {
          const firstName = _.get(result.resident, 'first_name', '');
          const lastName = _.get(result.resident, 'last_name', '');
          const name = `${firstName} ${lastName}`;

          crumbs.push({
            label: 'Resident List',
            url: `/account/${params.id}/facility/${params.facility_id}/resident`,
          });

          crumbs.push({
            label: name,
            url: `/account/${params.id}/facility/${params.facility_id}/resident/${params.resident_id}`,
          });
        } else if (result.user) {
          const firstName = _.get(result.user, 'first_name', '');
          const lastName = _.get(result.user, 'last_name', '');
          const name = `${firstName} ${lastName}`;

          if (params.id && params.facility_id) {
            crumbs.push({
              label: 'Staff List',
              url: `/account/${params.id}/facility/${params.facility_id}/staff`,
            });

            crumbs.push({
              label: name,
              url: '',
            });
          } else {
            crumbs.push({
              label: 'User List',
              url: '/admin/user/list',
            });

            crumbs.push({
              label: name,
              url: '',
            });
          }
        }

        return of(crumbs);
      })
    );
  }
}
