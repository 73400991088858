import { ModalDirective } from 'ngx-bootstrap/modal';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';

import { ContentConstants } from '../../../../constants/content.constants';
import { Account } from '../../../../model/account/account';
import { ContentLibraryInterfaces } from '../../../../model/content/content-library.interfaces';
import { ContentModels } from '../../../../model/content/content.models';
import { DateUtils } from '../../../../util/date-utils';
import { ContentLibraryBaseComponent } from '../../content-library-base/content-library-base.component';

@Component({
  selector: 'app-content-library-item',
  templateUrl: './content-library-item.component.html',
  styleUrls: ['./content-library-item.component.scss'],
})
export class ContentLibraryItemComponent
  extends ContentLibraryBaseComponent
  implements OnChanges {
  @ViewChild('deleteModal')
  public deleteModal: ModalDirective;
  @ViewChild('deleteNotAllowedModal')
  deleteNotAllowedModal: ModalDirective;

  @Input()
  accounts: Account[];
  @Input()
  isUserAdmin: boolean;
  @Input()
  contentItem: ContentModels.ContentItem;
  @Output()
  changeEvent: EventEmitter<ContentLibraryInterfaces.IChangeEvent> = new EventEmitter<ContentLibraryInterfaces.IChangeEvent>();
  accountsLoaded = false;
  contentTypeNames = ContentConstants.CONTENT_TYPE_NAMES;
  displayTypeNames = ContentConstants.DISPLAY_TYPE_NAMES;

  constructor() {
    super();
  }

  ngOnChanges() {
    if (this.accounts) {
      this.accountsLoaded = true;
    }
  }

  getAccessibilityLabels() {
    return [
      {
        label: 'Hearing Impairment',
        value: this.contentItem.accessibility.hearing_impairment,
      },
      {
        label: 'Physical Impairment',
        value: this.contentItem.accessibility.physical_impairment,
      },
      {
        label: 'Vision Impairment',
        value: this.contentItem.accessibility.vision_impairment,
      },
    ]
      .filter(item => item.value)
      .map(item => item.label)
      .join(', ');
  }

  getUsageSettingsLabels() {
    return [
      {
        label: 'Solo Use',
        value: this.contentItem.usage_settings.solo_use,
      },
      {
        label: 'Joint Use',
        value: this.contentItem.usage_settings.joint_use,
      },
      {
        label: 'Group Use',
        value: this.contentItem.usage_settings.group_use,
      },
    ]
      .filter(item => item.value)
      .map(item => item.label)
      .join(', ');
  }

  setDateDisplayFormat(dateToFormat: string): string {
    return DateUtils.setDateDisplayFormat(dateToFormat);
  }

  getKeywords(): string {
    return (this.contentItem.keywords || []).join(', ');
  }

  isIn2lAdmin(): boolean {
    return this.isUserAdmin;
  }

  getExcludedAccountNames(): string {
    if (!this.accountsLoaded) {
      return;
    }
    if (
      this.contentItem.excluded_account_ids.length &&
      this.accounts.length !== 0
    ) {
      return this.accounts
        .filter(account =>
          this.contentItem.excluded_account_ids.includes(account._id)
        )
        .map(account => account.profile.account_name)
        .sort((a, b) => a.localeCompare(b))
        .join(', ');
    } else {
      return '';
    }
  }

  editContent() {
    this.changeEvent.emit({
      editContentItem: {
        itemId: this.contentItem._id,
        library_path: this.contentItem.library_path,
      },
    });
  }

  showDeleteModal() {
    const isLinkedTo = !!(this.contentItem.link_to_paths || []).length;
    if (isLinkedTo) {
      this.deleteNotAllowedModal.show();
      return;
    }

    this.deleteModal.show();
  }

  submitDelete() {
    this.changeEvent.emit({
      deleteItems: {
        contentItem: this.contentItem,
      },
    });
    this.deleteModal.hide();
  }

  cancelModal() {
    this.deleteModal.hide();
    this.deleteNotAllowedModal.hide();
  }
}
