import { forkJoin } from 'rxjs';

import { Component, OnInit } from '@angular/core';

import { environment } from '../../../../environments/environment';
import {
  BUILD_ENV,
  BUILD_NUMBER,
  BUILD_VERSION,
  JENKINS_BUILD_NUMBER,
} from '../../../build-number';
import { MicroserviceApiBaseService } from '../../../core/micro-api/microservice-api-base.service';
import { UnsubscribeOnDestroy } from '../../../util/unsubscribe-on-destroy';

interface IApiStatus {
  env?: string;
  couchbaseQueryStatus?: string;
  buildNumber?: string;
  buildVersion?: string;
  ciBuildNumber?: string;
}

@Component({
  selector: 'app-build-number',
  templateUrl: './build-number.component.html',
})
export class BuildNumberComponent
  extends UnsubscribeOnDestroy
  implements OnInit {
  buildEnv = BUILD_ENV;
  buildNumber = BUILD_NUMBER;
  buildVersion = BUILD_VERSION;
  jenkinsBuildNumber = JENKINS_BUILD_NUMBER;

  apiName: string;
  apiBuildEnv: string;
  apiBuildNumber: number;
  apiBuildVersion: string;
  apiJenkinsBuildNumber: number;

  microserviceApiStatus: { name: string; status: IApiStatus }[];
  sortedApiStatus: { name: string; status: IApiStatus }[];

  constructor(private microService: MicroserviceApiBaseService) {
    super();
  }

  ngOnInit() {
    this.microserviceApiStatus = [];
    this.subscriptionTracker.track = forkJoin({
      'Auth API': this.microService.getApiStatus<IApiStatus>(
        environment.authApi.url
      ),
      'Content API': this.microService.getApiStatus<IApiStatus>(
        environment.contentApi.url
      ),
      'Database API': this.microService.getApiStatus<IApiStatus>(
        environment.databaseApi.url
      ),
      'Device API': this.microService.getApiStatus<IApiStatus>(
        environment.deviceApi.url
      ),
      'Media API': this.microService.getApiStatus<IApiStatus>(
        environment.mediaApi.url
      ),
      'Portal Composite API': this.microService.getApiStatus<IApiStatus>(
        environment.portalCompositeApi.url
      ),
      'User API': this.microService.getApiStatus<IApiStatus>(
        environment.userApi.url
      ),
    }).subscribe(results => {
      for (const prop in results) {
        this.microserviceApiStatus.push({ name: prop, status: results[prop] });
      }
      this.sortApis();
    });
  }

  sortApis() {
    this.sortedApiStatus = this.microserviceApiStatus.sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    return this.sortedApiStatus;
  }
}
