import { Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DocTypeConstants } from '../../constants/doc-types';
import { Facility, FacilityFactory } from '../../model/facility/facility';
import { JwtService } from '../authentication/jwt.service';
import { MicroserviceApiBaseService } from '../micro-api/microservice-api-base.service';
import { StateManager } from '../state/state-manager';

@Injectable()
export class FacilityService extends MicroserviceApiBaseService {
  constructor(
    http: HttpClient,
    stateManager: StateManager,
    jwtService: JwtService
  ) {
    super(http, jwtService, stateManager);
  }

  getFacility(facilityId: string): Observable<Facility> {
    return this.get<Facility>(
      this.determineDbApiDocPath(DocTypeConstants.NAMESPACES.ACCOUNT, {
        type: DocTypeConstants.TYPES.ACCOUNT.FACILITY,
        id: facilityId,
      }),
      {}
    ).pipe(
      map(result => {
        const facility = FacilityFactory.createFacility(result);
        this.refreshFacilityCache(facility);

        return facility;
      })
    );
  }

  getAccountFacilities(accountId: string): Observable<Facility[]> {
    if (!accountId) {
      return throwError(
        new Error('Cannot get account facilities due to missing account ID')
      );
    }

    return this.getAllFacilities(accountId);
  }

  getAllFacilities(accountId?: string): Observable<Facility[]> {
    const params: Record<string, string> = accountId
      ? { account_id: accountId }
      : {};
    return this.get<Facility[]>(
      this.determineDbApiDocPath(DocTypeConstants.NAMESPACES.ACCOUNT, {
        type: DocTypeConstants.TYPES.ACCOUNT.FACILITY,
      }),
      params
    ).pipe(
      map(results => {
        const facilities = results.map(result => {
          const facility = FacilityFactory.createFacility(result);
          this.refreshFacilityCache(facility);
          return facility;
        });

        return facilities;
      })
    );
  }

  createFacility(facility: Facility): Observable<Facility> {
    return this.post<Facility>(
      this.determineDbApiDocPath(DocTypeConstants.NAMESPACES.ACCOUNT, {
        type: DocTypeConstants.TYPES.ACCOUNT.FACILITY,
      }),
      FacilityFactory.createFacility(facility)
    ).pipe(
      map(createdFacility => FacilityFactory.createFacility(createdFacility)),
      tap(createdFacility => this.refreshFacilityCache(createdFacility))
    );
  }

  updateFacility(facility: Facility): Observable<Facility> {
    return this.put<Facility>(
      this.determineDbApiDocPath(DocTypeConstants.NAMESPACES.ACCOUNT, {
        type: DocTypeConstants.TYPES.ACCOUNT.FACILITY,
        id: facility._id,
      }),
      FacilityFactory.createFacility(facility)
    ).pipe(
      map(updatedFacility => FacilityFactory.createFacility(updatedFacility)),
      tap(updatedFacility => this.refreshFacilityCache(updatedFacility))
    );
  }

  private refreshFacilityCache(facility: Facility) {
    const selectedFacilityId = this.stateManager.getSidebarFacilityIdSelection();
    this.stateManager.updateFacilityDoc(facility);
    this.stateManager.updateSidebarFacilityIdSelection(selectedFacilityId);
  }
}
