import * as moment from 'moment';

import { ISidebarSelection } from './sidebar-state';

interface IAuthState {
  previousUrl: string;
  currentUrl: string;
  urlIds: ISidebarSelection;
  username: string;
  userSignedIn: boolean;
  dashboardsEnabled: boolean;
  userDocType: string;
  userDefaultAccountId: string;
  userDefaultFacilityId: string;
  userFacilityIds: string[];
}

interface IJwtAuthResponse {
  readonly username: string;
  readonly user_type: string;
  readonly account_id: string;
  readonly facility_ids: string[];
  readonly token: string;
  readonly refresh_token: string;
  readonly created_time: moment.Moment;
}

interface IErrorEvent {
  type: 'NOT_SET' | 'UNAUTHORIZED' | 'UNKNOWN_ERROR';
  message?: string;
}

const EMPTY_AUTH_STATE: IAuthState = {
  previousUrl: null,
  currentUrl: null,
  urlIds: { accountId: '', facilityId: '' },
  username: '',
  userSignedIn: false,
  dashboardsEnabled: false,
  userDocType: '',
  userDefaultAccountId: '',
  userDefaultFacilityId: '',
  userFacilityIds: [],
};

export { EMPTY_AUTH_STATE, IAuthState, IJwtAuthResponse, IErrorEvent };
