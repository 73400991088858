import * as _ from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { DocTypeConstants } from '../../../constants/doc-types';
import { UserConstants } from '../../../constants/user.constants';
import { AccountService } from '../../../core/account/account.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { FacilityService } from '../../../core/facility/facility.service';
import { ResidentService } from '../../../core/resident/resident.service';
import { IPermissions, RoleService } from '../../../core/role/role.service';
import { UiEventService } from '../../../core/ui-event-service/ui-event-service';
import { ToasterMessage } from '../../../core/ui-event-service/ui-toaster-message';
import { UserService } from '../../../core/user/user.service';
import { Account } from '../../../model/account/account';
import { Facility } from '../../../model/facility/facility';
import { Resident } from '../../../model/resident/resident';
import { ROLE_CHANGE_USER_TYPE } from '../../../model/role/role';
import { User } from '../../../model/user/user';
import { PhoneNumberUtils } from '../../../util/phone-number-utils';
import { UnsubscribeOnDestroy } from '../../../util/unsubscribe-on-destroy';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styles: ['.type-cell { width: 160px }', '.action-cell { width: 140px }'],
})
export class UserViewComponent
  extends UnsubscribeOnDestroy
  implements OnChanges {
  @Input() user: User | undefined; // needs to be defined this way to avoid WARNING: export 'User' was not found
  @Input() showChangePasswordButton = false;
  @Input() showEditButton = false;
  @Input() showSendResetButton = false;
  @Input() showUserStateButton = false;
  @Output() onChangePassword: EventEmitter<User> = new EventEmitter<User>();
  @Output() onEditUser: EventEmitter<User> = new EventEmitter<User>();
  @Output() onSendUserReset: EventEmitter<User> = new EventEmitter<User>();
  @Output() onUserStateChange: EventEmitter<User> = new EventEmitter<User>();

  accountName: string;
  facilityNames: string;
  permissions: IPermissions;

  userResidents: Resident[];

  dataLoaded = false;
  sendingReset = false;

  imageSrc: string = '/assets/img/user/generic.png';

  constructor(
    private accountService: AccountService,
    private authenticationService: AuthenticationService,
    private facilityService: FacilityService,
    private uiEventService: UiEventService,
    private residentService: ResidentService,
    private userService: UserService,
    private roleService: RoleService
  ) {
    super();
    this.permissions = this.roleService.currentUserPermissionsObject([
      { keyName: 'changeUserType', role: ROLE_CHANGE_USER_TYPE },
    ]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['user'] && this.user) {
      this.user.resident_ids = this.user.resident_ids || [];
      this.subscriptionTracker.track = this.getAllResidentsForFacilities().subscribe(
        (residents: Resident[]) => {
          this.userResidents = residents.filter(r =>
            this.user.resident_ids.includes(r._id)
          );
        }
      );
      this.subscriptionTracker.track = this.getImageSrc().subscribe(src => {
        this.imageSrc = src;
      });
    }
  }

  getAllResidentsForFacilities(): Observable<Resident[]> {
    return this.user.resident_ids.length
      ? this.residentService.getAllResidentsForFacilities(
          this.user.account_id,
          this.user.facility_ids
        )
      : of([]);
  }

  dataIsLoaded() {
    this.dataLoaded = true;
  }

  userIsActive(): boolean {
    return this.user.status === UserConstants.USER_STATUS_ACTIVE;
  }

  hasPin(): boolean {
    return (
      (this.user.doc_type === DocTypeConstants.TYPES.USER.FACILITY_ADMIN ||
        this.user.doc_type === DocTypeConstants.TYPES.USER.FACILITY_USER) &&
      !!this.user.pin
    );
  }

  isFacilityUser(): boolean {
    return this.user.doc_type === DocTypeConstants.TYPES.USER.FACILITY_USER;
  }

  handleChangePasswordClick(e, user) {
    this.onChangePassword.emit(user);
  }

  handleUserStateClick(e, user: User): void {
    if (user.status === UserConstants.USER_STATUS_ACTIVE) {
      this.disableUser(user);
    } else {
      this.activateUser(user);
    }
  }

  handleEditUserClick(e, user) {
    this.onEditUser.emit(user);
  }

  handleSendUserResetClick(e, user) {
    if (!user.email) {
      this.showErrorMessage(
        'User password cannot be reset because the user does not have an email.'
      );
      return;
    }

    this.sendingReset = true;

    this.subscriptionTracker.track = this.authenticationService
      .resetPassword(user.email)
      .subscribe(
        result => {
          if ([200, 202].includes(result.status)) {
            this.uiEventService.dispatch(
              new ToasterMessage({
                body: `Instructions for password reset have been sent to ${user.email}`,
                type: 'success',
              })
            );
          } else {
            this.showErrorMessage(
              'We could not reset your password because of an unknown error.'
            );
          }

          this.sendingReset = false;
        },
        error => {
          this.showErrorMessage(
            this.authenticationService.processResetPasswordError(error)
          );
          this.sendingReset = false;
        }
      );
    this.onSendUserReset.emit(user);
  }

  getImageSrc(): Observable<string> {
    const profileImageKey =
      this.user.media &&
      this.user.media[UserConstants.USER_PROFILE_IMAGE_KEY] &&
      this.user.media[UserConstants.USER_PROFILE_IMAGE_KEY].status ===
        'Completed'
        ? this.user.media[UserConstants.USER_PROFILE_IMAGE_KEY].s3_key
        : '';

    return profileImageKey
      ? this.userService.getUserProfileImage(
          profileImageKey,
          this.user.doc_namespace,
          this.user.doc_type
        )
      : of('');
  }

  resetPin() {
    this.user.has_temporary_pin = true;
    this.user.pin = _.padStart(_.random(99999).toString(), 5, '0');
    this.subscriptionTracker.track = this.userService
      .updateUser(this.user)
      .subscribe(
        (updatedUser: User) => {
          this.user = updatedUser;
          this.onUserStateChange.emit(updatedUser);
          const message = `This Community User's password has been reset to a temp password: ${this.user.pin}`;
          this.uiEventService.dispatch(
            new ToasterMessage({
              body: message,
              type: 'info',
            })
          );
        },
        error => {
          this.showErrorMessage(error);
        }
      );
  }

  setDisplayPhoneNumber(phone: string) {
    return PhoneNumberUtils.setPhoneNumberToStandardDisplayFormat(phone);
  }

  private getUserAccountFacilities(): Observable<boolean> {
    const hasAccountTypes = [
      DocTypeConstants.TYPES.USER.ACCOUNT_ADMIN,
      DocTypeConstants.TYPES.USER.FACILITY_ADMIN,
    ];

    const accountObservable =
      hasAccountTypes.includes(this.user.doc_type) && this.user.account_id
        ? this.accountService.getAccount(this.user.account_id)
        : of(null);

    // see if we should include community info
    const facilitiesObservable =
      this.user.doc_type === DocTypeConstants.TYPES.USER.FACILITY_ADMIN &&
      this.user.facility_ids.length
        ? this.facilityService.getAllFacilities()
        : of([]);

    return forkJoin([accountObservable, facilitiesObservable]).pipe(
      mergeMap(([account, facilities]: [Account | null, Facility[]]) => {
        this.accountName = account ? account.profile.account_name : '';
        this.facilityNames = facilities
          .filter(f => this.user.facility_ids.includes(f._id))
          .map(f => f.profile.name)
          .join(', ');

        return of(true);
      })
    );
  }

  private activateUser(user: User): void {
    this.subscriptionTracker.track = this.userService
      .activateUser(user)
      .subscribe(
        (updatedUser: User) => {
          this.user = updatedUser;
          this.onUserStateChange.emit(updatedUser);
          this.showSuccessMessage(
            `${this.user.first_name} ${this.user.last_name} has been activated`
          );
        },
        error => {
          this.showErrorMessage(error);
        }
      );
  }

  private disableUser(user: User): void {
    this.subscriptionTracker.track = this.userService
      .disableUser(user)
      .subscribe(
        (updatedUser: User) => {
          this.user = updatedUser;
          this.onUserStateChange.emit(updatedUser);
          this.showSuccessMessage(
            `${this.user.first_name} ${this.user.last_name} has been disabled`
          );
        },
        error => {
          this.showErrorMessage(error);
        }
      );
  }

  private showErrorMessage(message) {
    this.uiEventService.dispatch(
      new ToasterMessage({ body: message, type: 'error' })
    );
  }

  private showSuccessMessage(message): void {
    this.uiEventService.dispatch(
      new ToasterMessage({ body: message, type: 'success' })
    );
  }
}
