import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Device,
  DEVICE_RESIDENT_MODE_ALL,
  IDeviceStatus,
  IDeviceStatusResponse,
} from '../../model/device/device';
import { JwtService } from '../authentication/jwt.service';
import { MicroserviceApiBaseService } from '../micro-api/microservice-api-base.service';
import { StateManager } from '../state/state-manager';

@Injectable()
export class DeviceService extends MicroserviceApiBaseService {
  constructor(
    http: HttpClient,
    stateManager: StateManager,
    jwtService: JwtService
  ) {
    super(http, jwtService, stateManager);
  }

  // for each area where you see map(d => new Device)
  // https://in2lprojects.atlassian.net/browse/IN2L-9101

  getDevice(deviceId: string): Observable<Device> {
    return this.get<Device>(`${this.deviceApiUrl}/docs/${deviceId}`).pipe(
      map(d => new Device(d))
    );
  }

  getAllDevices(accountId?: string, facilityId?: string): Observable<Device[]> {
    let params: Record<string, string> = {};

    if (accountId) {
      params.account_id = accountId;
    }

    if (facilityId) {
      params.facility_id = facilityId;
    }

    return this.get<Device[]>(`${this.deviceApiUrl}/docs`, params).pipe(
      map(devices => devices.map(d => new Device(d)))
    );
  }

  getDeviceIncludingArchived(deviceId: string): Observable<Device> {
    return this.get<Device>(`${this.deviceApiUrl}/docs/${deviceId}`, {
      allowArchived: 'true',
    }).pipe(map(d => new Device(d)));
  }

  getDeviceBySerialNumber(
    serialNumber: string
  ): Observable<Device | undefined> {
    if (!serialNumber) {
      throw new Error('serialNumber is required.');
    }

    return this.get<Device[]>(`${this.deviceApiUrl}/docs`, {
      filter: `serial_number=${serialNumber}`,
    }).pipe(
      map(devices => (devices.length ? new Device(devices[0]) : undefined))
    );
  }

  getDeviceByDeviceName(deviceName: string): Observable<Device | undefined> {
    if (!deviceName) {
      throw new Error('deviceName is required.');
    }

    return this.get<Device[]>(`${this.deviceApiUrl}/docs`, {
      filter: `nickname=${deviceName}`,
    }).pipe(
      map(devices => (devices.length ? new Device(devices[0]) : undefined))
    );
  }

  createDevice(device: Device): Observable<Device> {
    return this.post(`${this.deviceApiUrl}/docs`, device);
  }

  deleteDevice(device: Device): Observable<boolean> {
    return this.delete(`${this.deviceApiUrl}/docs/${device._id}`);
  }

  updateDevice(device: Device): Observable<Device> {
    return this.put(`${this.deviceApiUrl}/docs/${device._id}`, device);
  }

  moveDevice(
    deviceId: string,
    newAccountId: string,
    newFacilityId: string,
    newFacilityResidentIds: string[]
  ): Observable<Device> {
    return this.getDevice(deviceId).pipe(
      mergeMap((device: Device) => {
        // Make a new device to save under the new account/community
        const newDevice = new Device(device);
        delete newDevice._id;
        delete newDevice._rev;
        newDevice.account_id = newAccountId;
        newDevice.facility_id = newFacilityId;
        newDevice.terms_of_use_agreement = [];
        newDevice.addTermsOfUseSigned(newAccountId, newFacilityId);
        newDevice.resident_mode = DEVICE_RESIDENT_MODE_ALL;
        newDevice.resident_ids = newFacilityResidentIds;
        return this.deleteDevice(device).pipe(
          mergeMap(() => this.createDevice(newDevice))
        );
      })
    );
  }

  getDeviceStatus(deviceSerialNumber: string): Observable<IDeviceStatus> {
    return this.get<IDeviceStatus>(
      `${this.deviceApiUrl}/status/docs/${deviceSerialNumber}`,
      undefined,
      {},
      [404]
    );
  }

  getAllDeviceStatuses(
    options: {
      accountId?: string;
      facilityId?: string;
      filter?: string;
      includeCount?: boolean;
      limit?: number;
      offset?: number;
    } = {}
  ): Observable<IDeviceStatusResponse> {
    let queryStringKeyValues: string[] = [];
    if (options.accountId && options.facilityId) {
      queryStringKeyValues = [
        `accountId=${options.accountId}`,
        `facilityId=${options.facilityId}`,
      ];
    } else if (options.accountId) {
      queryStringKeyValues = [`accountId=${options.accountId}`];
    }

    if (options.filter) {
      queryStringKeyValues.push(`search=${options.filter}`);
    }

    if (options.includeCount) {
      queryStringKeyValues.push(`includeCount=${options.includeCount}`);
    }

    if (options.limit) {
      queryStringKeyValues.push(`limit=${options.limit}`);
    }

    if (options.offset) {
      queryStringKeyValues.push(`offset=${options.offset}`);
    }

    const queryString = queryStringKeyValues.length
      ? `?${queryStringKeyValues.join('&')}`
      : '';

    const url = `${this.deviceApiUrl}/status/docs` + queryString;
    return this.get<IDeviceStatusResponse>(url);
  }
}
