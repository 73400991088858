import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { DocTypeConstants } from '../../constants/doc-types';
import { Account } from '../../model/account/account';
import { ContentInterfaces } from '../../model/content/content.interfaces';
import { Facility } from '../../model/facility/facility';
import {
  EMPTY_AUTH_STATE,
  IAuthState,
  IErrorEvent,
  IJwtAuthResponse,
} from '../../model/state/auth-state';
import { ISidebarSelection } from '../../model/state/sidebar-state';
import { AccountAdminUser } from '../../model/user/account-admin-user';
import { FacilityAdminUser } from '../../model/user/facility-admin-user';
import { FacilityUser } from '../../model/user/facility-user';
import { In2lAdminUser } from '../../model/user/in2l-admin-user';
import { In2lContentUser } from '../../model/user/in2l-content-user';
import { In2lUserType, User } from '../../model/user/user';
import { UserInterfaces } from '../../model/user/user.interfaces';
import { CacheService } from '../cache/cache.service';

const JWT_AUTH_RESPONSE_KEY = 'JWT_AUTH_RESPONSE';
const AUTHENTICATED_USER_KEY = 'AUTHENTICATED_USER';
const AUTH_STATE_KEY = 'AUTH_STATE';
const ACCOUNT_ID_KEY = 'ACCOUNT_ID';
const FACILITY_ID_KEY = 'FACILITY_ID';
const ACCOUNT_LIST_KEY = 'ACCOUNT_LIST';
const FACILITY_LIST_KEY = 'FACILITY_LIST';
const LAYOUT_SELECTION_KEY = 'LAYOUT_SELECTION';

type RestorableItem =
  | User
  | IAuthState
  | IJwtAuthResponse
  | ISidebarSelection
  | Account[]
  | Facility[]
  | ContentInterfaces.ILayoutRootSelection
  | string;

// Jake - TODO: We should return as observable type rather than subjects,
// to hide the subject implementation from consumer.

// Jake - TODO: If we are going to add more here, than the code needs to be
// more generic. Or we go full fledge with a RxJs state management solution.

/**
 * State Manager should handle all state BehaviorSubjects
 * State Manager should never have any dependencies in order to
 * guarantee that there will be no circular dependencies as long as BehaviorSubject state
 * is only modified through the this.
 */
@Injectable({
  providedIn: 'root',
})
export class StateManager {
  private subscriptionExecutionPaused = false;
  private cacheService: CacheService;

  private previousUrl: BehaviorSubject<string>;
  private currentUrl: BehaviorSubject<string>;

  private currentUser: User;
  private dashboardsEnabled: BehaviorSubject<boolean>;
  private authState: BehaviorSubject<IAuthState>;
  private currentJwtAuth: IJwtAuthResponse;
  private errorEvent: BehaviorSubject<IErrorEvent>;

  private sidebarAccountIdSelection: BehaviorSubject<string>;
  private sidebarFacilityIdSelection: BehaviorSubject<string>;

  private sidebarAccountList: BehaviorSubject<Account[]>;
  private sidebarFacilityList: BehaviorSubject<Facility[]>;

  private layoutRootSelection: BehaviorSubject<ContentInterfaces.ILayoutRootSelection>;

  private localStorageKeys: string[] = [
    'accessToken',
    'client',
    'expiry',
    'uid',
    'tokenType',
  ];

  private redirectUrl: string;

  allowSubscriptionExecution(): boolean {
    return !this.subscriptionExecutionPaused;
  }

  // Get stored values to instantiate from local storage on page load or reload
  initialize(cacheService: CacheService) {
    this.cacheService = cacheService;
    this.cacheService.clearCache();

    // Block subscriptions from running until state is initialized
    this.pauseSubscriptions();

    // URL State Init
    this.previousUrl = new BehaviorSubject<string>(null);
    this.currentUrl = new BehaviorSubject<string>(null);

    this.currentJwtAuth = <IJwtAuthResponse>(
      this.getLocallyStoredRestorableStateItem(JWT_AUTH_RESPONSE_KEY)
    );

    const initialAuthState = <IAuthState>(
      this.getLocallyStoredRestorableStateItem(AUTH_STATE_KEY)
    );
    this.authState = new BehaviorSubject<IAuthState>(
      initialAuthState || EMPTY_AUTH_STATE
    );

    this.currentUser = <User>(
      this.getLocallyStoredRestorableStateItem(AUTHENTICATED_USER_KEY)
    );

    this.dashboardsEnabled = new BehaviorSubject<boolean>(false);

    this.errorEvent = new BehaviorSubject<IErrorEvent>({
      type: 'NOT_SET',
    });

    const initialAccountId = <string>(
      this.getLocallyStoredRestorableStateItem(ACCOUNT_ID_KEY)
    );
    this.sidebarAccountIdSelection = new BehaviorSubject<string>(
      initialAccountId
    );

    const initialFacilityId = <string>(
      this.getLocallyStoredRestorableStateItem(FACILITY_ID_KEY)
    );
    this.sidebarFacilityIdSelection = new BehaviorSubject<string>(
      initialFacilityId
    );

    const initialAccountList = <Account[]>(
      this.getLocallyStoredRestorableStateItem(ACCOUNT_LIST_KEY)
    );
    this.sidebarAccountList = new BehaviorSubject<Account[]>(
      initialAccountList
    );

    const initialFacilityList = <Facility[]>(
      this.getLocallyStoredRestorableStateItem(FACILITY_LIST_KEY)
    );
    this.sidebarFacilityList = new BehaviorSubject<Facility[]>(
      initialFacilityList
    );

    const initalLayoutSelection = <ContentInterfaces.ILayoutRootSelection>(
      this.getLocallyStoredRestorableStateItem(LAYOUT_SELECTION_KEY)
    );
    this.layoutRootSelection = new BehaviorSubject<ContentInterfaces.ILayoutRootSelection>(
      initalLayoutSelection
    );

    this.resumeSubscriptions();
  }

  resetAuthState() {
    this.cacheService.clearCache();

    this.pauseSubscriptions();

    // Clear local storage and plain variable currentJwtAuth first (no side effects)
    this.clearLocalStorage();

    // Never clear previousUrl or currentUrl subjects

    this.currentJwtAuth = null;

    this.currentUser = null;

    this.authState.next(EMPTY_AUTH_STATE);

    this.errorEvent.next({ type: 'NOT_SET' });

    this.sidebarAccountIdSelection.next('');
    this.sidebarFacilityIdSelection.next('');

    this.sidebarAccountList.next([]);
    this.sidebarFacilityList.next([]);

    this.resumeSubscriptions();
  }

  pauseSubscriptions() {
    this.subscriptionExecutionPaused = true;
  }

  resumeSubscriptions() {
    this.subscriptionExecutionPaused = false;
  }

  // Verify that the authSte is consistent with the current user state and current URL
  verifyAuthStableState(): boolean {
    const currentUrl = this.getCurrentUrl();
    const currentUser = this.getCurrentUser();
    const authState = this.getAuthState();

    // Current user is null but authState still says signed in
    if (!currentUser && authState.userSignedIn) {
      return false;
    }

    // Current user is set but authState says signed out
    if (currentUser && !authState.userSignedIn) {
      return false;
    }

    // currentUrl has not been set or authState has not been updated with currentUrl
    if (currentUrl === null || authState.currentUrl !== currentUrl) {
      return false;
    }

    // authState has not been updated with current user details
    const currentUserEmail = (currentUser || {}).email || '';

    return currentUserEmail === authState.username;
  }

  /**
   * Auth State
   */
  getAuthState(): IAuthState {
    return this.authState ? this.authState.getValue() : EMPTY_AUTH_STATE;
  }

  getAuthStateSubject(): BehaviorSubject<IAuthState> {
    return this.authState;
  }

  setAuthState(newState: IAuthState) {
    this.updateRestorableItem(AUTH_STATE_KEY, newState);

    this.authState.next(newState);
  }

  getJwtAuth(): IJwtAuthResponse {
    return this.currentJwtAuth;
  }

  setJwtAuth(auth: IJwtAuthResponse) {
    this.updateRestorableItem(JWT_AUTH_RESPONSE_KEY, auth);
    this.currentJwtAuth = auth;
  }

  getRedirectUrl(): string {
    return this.redirectUrl;
  }

  setRedirectUrl(redirect: string) {
    this.redirectUrl = redirect;
  }

  getLocalStorageUser(): User {
    return <User>(
      this.getLocallyStoredRestorableStateItem(AUTHENTICATED_USER_KEY)
    );
  }

  getCurrentUser(): User {
    return this.currentUser;
  }

  updateCurrentUser(user: User) {
    const previousUser = this.getCurrentUser();

    if (!user && !previousUser) {
      return;
    }

    this.updateRestorableItem(AUTHENTICATED_USER_KEY, user);

    this.currentUser = user;
  }

  updateDashboardsEnabled(enabled: boolean) {
    this.dashboardsEnabled.next(enabled);
  }

  userIsIn2lAdminOrContent(userDocType: string): boolean {
    return [
      DocTypeConstants.TYPES.USER.IN2L_ADMIN,
      DocTypeConstants.TYPES.USER.IN2L_CONTENT,
    ].includes(userDocType);
  }

  userIsIn2lAdmin(userDocType: string): boolean {
    return userDocType === DocTypeConstants.TYPES.USER.IN2L_ADMIN;
  }

  userIsIn2lContent(userDocType: string): boolean {
    return userDocType === DocTypeConstants.TYPES.USER.IN2L_CONTENT;
  }

  userIsAccountOrFacilityAdmin(userDocType: string): boolean {
    return [
      DocTypeConstants.TYPES.USER.ACCOUNT_ADMIN,
      DocTypeConstants.TYPES.USER.FACILITY_ADMIN,
    ].includes(userDocType);
  }

  userIsFacilityAdmin(userDocType: string): boolean {
    return userDocType === DocTypeConstants.TYPES.USER.FACILITY_ADMIN;
  }

  getDefaultAccountId(currentUser: User): string {
    if (!this.userIsAccountOrFacilityAdmin(currentUser.doc_type)) {
      return '';
    }

    return currentUser.account_id || '';
  }

  getDefaultFacilityId(currentUser: User): string {
    if (!currentUser || !this.userIsFacilityAdmin(currentUser.doc_type)) {
      return '';
    }

    const facilityIds =
      currentUser.facility_ids && currentUser.facility_ids.length
        ? currentUser.facility_ids
        : [''];
    return facilityIds[0];
  }

  getErrorEventSubject(): BehaviorSubject<IErrorEvent> {
    return this.errorEvent;
  }

  handleErrorEvent(errorEventBehavior: IErrorEvent) {
    this.errorEvent.next(errorEventBehavior);
  }

  /**
   * URL State - previous and current URLs
   * current and previous urls are updated in routerEventStream
   * when a GuardsCheckStart router event occurs
   */
  updatePreviousUrl(url: string) {
    this.previousUrl.next(url);
  }

  getCurrentUrl(): string {
    return this.currentUrl.getValue();
  }

  updateCurrentUrl(url: string) {
    if (url === null || url === this.getCurrentUrl()) {
      return;
    }

    this.currentUrl.next(url);
  }

  /**
   * Layout Root Selected
   */
  getLayoutRootSelection(): ContentInterfaces.ILayoutRootSelection {
    return this.layoutRootSelection.getValue();
  }

  getLayoutRootSelectionObservable(): Observable<ContentInterfaces.ILayoutRootSelection> {
    return this.layoutRootSelection;
  }

  modifyLayoutRootSelection(
    modifier: (selection: ContentInterfaces.ILayoutRootSelection) => void
  ) {
    const current = this.getLayoutRootSelection();
    modifier(current);
    this.updateLayoutRootSelection(current);
  }

  updateLayoutRootSelection(selection: ContentInterfaces.ILayoutRootSelection) {
    this.setLocalStorageItem(LAYOUT_SELECTION_KEY, JSON.stringify(selection));
    this.layoutRootSelection.next(selection);
  }

  /**
   * Sidebar accountId selection
   */
  getSidebarAccountIdSelection(): string {
    return this.sidebarAccountIdSelection.getValue();
  }

  getSidebarAccountIdSelectionSubject(): BehaviorSubject<string> {
    return this.sidebarAccountIdSelection;
  }

  updateSidebarAccountIdSelection(newAccountId: string) {
    this.setLocalStorageItem(ACCOUNT_ID_KEY, newAccountId);
    this.sidebarAccountIdSelection.next(newAccountId);
  }

  /**
   * Sidebar facilityId selection
   */
  getSidebarFacilityIdSelection(): string {
    return this.sidebarFacilityIdSelection.getValue();
  }

  getSidebarFacilityIdSelectionSubject(): BehaviorSubject<string> {
    return this.sidebarFacilityIdSelection;
  }

  updateSidebarFacilityIdSelection(newFacilityId: string) {
    this.setLocalStorageItem(FACILITY_ID_KEY, newFacilityId);
    this.sidebarFacilityIdSelection.next(newFacilityId);
  }

  /**
   * Sidebar account and facility dropdown lists
   */
  getSidebarAccountList(): Account[] {
    return this.sidebarAccountList.getValue();
  }

  getSidebarFacilityList(): Facility[] {
    return this.sidebarFacilityList.getValue();
  }

  getSidebarAccountListSubject(): BehaviorSubject<Account[]> {
    return this.sidebarAccountList;
  }

  getSidebarFacilityListSubject(): BehaviorSubject<Facility[]> {
    return this.sidebarFacilityList;
  }

  updateSidebarAccountList(accounts: Account[]) {
    this.setLocalStorageItem(ACCOUNT_LIST_KEY, JSON.stringify(accounts));
    this.sidebarAccountList.next(accounts);
  }

  updateSidebarFacilityList(facilities: Facility[]) {
    this.setLocalStorageItem(FACILITY_LIST_KEY, JSON.stringify(facilities));
    this.sidebarFacilityList.next(facilities);
  }

  updateAccountDoc(account: Account) {
    const accounts = this.getSidebarAccountList();
    const updatedAccounts = [
      account,
      ...accounts.filter(a => a._id !== account._id),
    ];
    this.updateSidebarAccountList(updatedAccounts);
  }

  updateFacilityDoc(facility: Facility) {
    const selectedAccountId = this.getSidebarAccountIdSelection();
    if (facility.account_id !== selectedAccountId) {
      return;
    }

    const facilities = this.getSidebarFacilityList();
    const updatedFacilities = [
      facility,
      ...facilities.filter(f => f._id !== facility._id),
    ];
    this.updateSidebarFacilityList(updatedFacilities);
  }

  /**
   * Local Storage Auth Management
   */
  getLocalStorageItem(key: string): string {
    if (!this.localStorageKeys.includes(key)) {
      this.localStorageKeys.push(key);
    }

    return localStorage.getItem(key);
  }

  setLocalStorageItem(key: string, value: string) {
    this.localStorageKeys = _.uniq([key, ...this.localStorageKeys]);
    localStorage.setItem(key, value);
  }

  clearLocalStorage() {
    this.localStorageKeys.forEach(key => {
      localStorage.removeItem(key);
    });
  }

  private getLocallyStoredRestorableStateItem(key: string): RestorableItem {
    const item: RestorableItem = null;
    switch (key) {
      case JWT_AUTH_RESPONSE_KEY:
        const jwtAuthString = this.getLocalStorageItem(JWT_AUTH_RESPONSE_KEY);
        if (!jwtAuthString) {
          return null;
        }
        return <IJwtAuthResponse>JSON.parse(jwtAuthString);

      case AUTHENTICATED_USER_KEY:
        const userString = this.getLocalStorageItem(AUTHENTICATED_USER_KEY);
        if (!userString) {
          return null;
        }
        const parsedUser = <User>JSON.parse(userString);
        return this.buildUser(parsedUser);

      case AUTH_STATE_KEY:
        const authStateString = this.getLocalStorageItem(AUTH_STATE_KEY);
        if (!authStateString) {
          return EMPTY_AUTH_STATE;
        }
        return <IAuthState>JSON.parse(authStateString);

      case ACCOUNT_ID_KEY:
        return this.getLocalStorageItem(ACCOUNT_ID_KEY) || '';

      case FACILITY_ID_KEY:
        return this.getLocalStorageItem(FACILITY_ID_KEY) || '';

      case ACCOUNT_LIST_KEY:
        const accountListString = this.getLocalStorageItem(ACCOUNT_LIST_KEY);
        if (!accountListString) {
          return [];
        }

        return <Account[]>JSON.parse(accountListString);

      case FACILITY_LIST_KEY:
        const facilityListString = this.getLocalStorageItem(FACILITY_LIST_KEY);
        if (!facilityListString) {
          return [];
        }

        return <Facility[]>JSON.parse(facilityListString);
      case LAYOUT_SELECTION_KEY:
        const layoutSelectionStr = this.getLocalStorageItem(
          LAYOUT_SELECTION_KEY
        );
        if (!layoutSelectionStr) {
          return {} as ContentInterfaces.ILayoutRootSelection;
        }

        return <ContentInterfaces.ILayoutRootSelection>(
          JSON.parse(layoutSelectionStr)
        );
    }

    return item;
  }

  private updateRestorableItem(key: string, item: RestorableItem) {
    if (!item) {
      localStorage.removeItem(key);
      return;
    }

    this.setLocalStorageItem(key, JSON.stringify(item));
  }

  // This code is duplicated from the UserService
  // For the sake of avoiding circular dependencies, it is recreated here
  private buildUser(doc: UserInterfaces.IUser): In2lUserType {
    if (!doc || !doc.doc_type) {
      return null;
    }

    switch (doc.doc_type) {
      case DocTypeConstants.TYPES.USER.ACCOUNT_ADMIN:
        return new AccountAdminUser(doc);
      case DocTypeConstants.TYPES.USER.FACILITY_ADMIN:
        return new FacilityAdminUser(doc);
      case DocTypeConstants.TYPES.USER.FACILITY_USER:
        return new FacilityUser(doc);
      case DocTypeConstants.TYPES.USER.IN2L_ADMIN:
        return new In2lAdminUser(doc);
      case DocTypeConstants.TYPES.USER.IN2L_CONTENT:
        return new In2lContentUser(doc);
      default:
        return null;
    }
  }
}
