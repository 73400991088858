import { of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { DocTypeConstants } from '../../../constants/doc-types';
import { UserConstants } from '../../../constants/user.constants';
import { InviteService } from '../../../core/invite/invite.service';
import { StateManager } from '../../../core/state/state-manager';
import { UserService } from '../../../core/user/user.service';
import { Account } from '../../../model/account/account';
import { Facility } from '../../../model/facility/facility';
import { FacilityAdminUser } from '../../../model/user/facility-admin-user';
import { User } from '../../../model/user/user';
import { BaseFormComponent, FormUtils } from '../../../util/form-utils';

@Component({
  selector: 'app-invite-form',
  templateUrl: './invite-form.component.html',
})
export class InviteFormComponent
  extends BaseFormComponent
  implements OnInit /* OnChanges */ {
  @Input() account: Account;
  @Input() facilities: Facility[];

  inviteForm: FormGroup;
  isFacilityUser: boolean;
  showFormSuccess = false;
  viewReady = false;

  constructor(
    private formBuilder: FormBuilder,
    private inviteService: InviteService,
    private userService: UserService,
    private stateManager: StateManager
  ) {
    super();
  }

  ngOnInit() {
    this.inviteForm = this.formBuilder.group({});
  }

  setViewReady(isReady: boolean) {
    this.viewReady = isReady;
  }

  submitForm($event) {
    $event.preventDefault();

    // reset messages
    this.resetErrorMessage();

    FormUtils.markAllAsTouched(this.inviteForm);

    if (this.inviteForm.valid) {
      const email = (this.inviteForm.get('email').value || '').toLowerCase();
      let getUserByEmailRequest = this.userService.getUserByEmail(email);
      // To get a valid check on the email by a facility admin, we need to send one of their facilityIds with
      if (this.stateManager.getCurrentUser() instanceof FacilityAdminUser) {
        getUserByEmailRequest = this.userService.getUserByEmail(
          email,
          this.facilities[0]._id
        );
      }
      let inviteErrors = null;
      // need to validate email address .. does it exist already in the system?
      this.subscriptionTracker.track = getUserByEmailRequest
        .pipe(
          // see if email exists already in user_profiles,
          // if exists, throw error, if does not exist, create user_profile_data record
          mergeMap(result => {
            if (
              !(
                this.inviteForm.get('userType').value ===
                  DocTypeConstants.TYPES.USER.FACILITY_USER && !email
              ) &&
              result
            ) {
              return throwError(
                new Error(
                  'Could not send invite because a user with this email already exists'
                )
              );
            }

            return this.userService.createInvitedUser(
              this.inviteForm.get('userType').value,
              email,
              this.inviteForm.get('accountId').value,
              this.inviteForm.get('facilityIds').value,
              this.inviteForm.get('firstName').value,
              this.inviteForm.get('lastName').value,
              this.inviteForm.get('phone').value,
              this.inviteForm.get('title').value,
              this.inviteForm.get('pin').value,
              !!this.inviteForm.get('pin').value,
              this.inviteForm.get('residentIds').value,
              this.inviteForm.get('residentMode').value
            );
          }),
          // result will be created IUser
          // send invite request and get user id from portal api
          mergeMap((user: User) => {
            // we need to capture the rails createInvite response
            // on error, we should re-delete the newly created IUser
            // to keep user_profile_data and user_auth_data sync'd
            // and prevent issues with users unable to complete invite
            this.isFacilityUser =
              user.doc_type === DocTypeConstants.TYPES.USER.FACILITY_USER;
            return this.isFacilityUser
              ? of(null)
              : this.inviteService
                  .createInvite(
                    user.doc_type,
                    user._id,
                    user.email,
                    user.first_name
                  )
                  .pipe(
                    catchError(error => {
                      inviteErrors = error;
                      return this.userService.deleteUser(user);
                    })
                  );
          })
        )
        // report results back to screen
        .subscribe(
          result => {
            if (!inviteErrors) {
              this.showFormSuccess = true;
            } else {
              console.log('ERR', inviteErrors);
              this.errorMessage = 'There was a problem sending a user invite.';
            }
          },
          error => {
            console.log('ERR', error);
            this.errorMessage = error;
          }
        );
    }
  }

  invitedUserType() {
    const userType = UserConstants.USER_TYPES_LIST.find(
      type => type.type === this.inviteForm.get('userType').value
    );
    return userType ? userType.name : '';
  }

  resetForm() {
    this.resetErrorMessage();
    this.inviteForm.reset({
      email: '',
      userType: '',
      accountId: '',
      facilityIds: [],
      firstName: '',
      lastName: '',
      pin: '',
      phone: '',
      title: '',
    });

    this.showFormSuccess = false;
  }

  // --- Private Functions ---

  private resetErrorMessage() {
    this.errorMessage = '';
  }
}
